import { equipmentAPI } from "api";

export const getAllMakerPackerAPI = (payload) => {
    return equipmentAPI.post("api/makerpacker/getall", payload)
        .then((res) => res);
};

export const updateMakerPackerAPI = (id, payload) => {
    return equipmentAPI.put(`/api/makerpacker/update/${id}`, payload)
        .then((res) => res);
};

export const deleteMakerPackerAPI = (id) => {
    return equipmentAPI.delete(`/api/makerpacker/delete/${id}`)
        .then((res) => res);
};

export const createMakerPackerAPI = (payload) => {
    return equipmentAPI.post(`/api/makerpacker/add`, payload)
        .then((res) => res);
};

export const getMakerPackerEquipmentByIdAPI = (payload) => {
    return equipmentAPI.get(`/api/equipment/getequipment/${payload}`)
        .then((res) => res);
};
