import { actionTypes } from "./actions";

export const initialState = {
  searchEquipments: [],
  isEquipmentsLoading: false,
  getEquipment: [],
 
};

// ===========================|| COMMON REDUCER ||=========================== //

const searchEquipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEACRH_EQUIPMENT_PENDING:
      return {
        ...state,
        isEquipmentsLoading: true,
      };
      case actionTypes.SEACRH_EQUIPMENT_RECEIVED:
      return {
        ...state,
        searchEquipments: action?.payload?.data.map((dt, index) => ({ ...dt, id: index })),
        pageInfo: action?.payload?.pageInfo,

        isEquipmentsLoading : false
      };
      case actionTypes.SEACRH_EQUIPMENT_FAILED:
      return {
        ...state,
        isEquipmentsLoading: false,
      };
      case actionTypes.REQUEST_EQUIPMENT_PENDING:
      return {
        ...state,
        isEquipmentsLoading: true,
      };
      case actionTypes.REQUEST_EQUIPMENT_RECEIVED:
      return {
        ...state,
        getEquipment: action.payload.map((dt, index)=>({...dt, id:index})),
        isEquipmentsLoading : false
      };
      case actionTypes.EQUIPMENT_REQUEST_PENDING:
      return {
        ...state,
        isEquipmentsLoading: false,
      };

      case actionTypes.EXPORT_ALL_LOAD:
      return {
        ...state,
        isExportAllLoading: true,
      };
      case actionTypes.EXPORT_ALL_RECEIVE:
      return {
        ...state,
        isExportAllLoading : false
      };
      case actionTypes.EXPORT_ALL_FAIL:
      return {
        ...state,
        isExportAllLoading: false,
      };
    default:
      return state;
  }
};

export default searchEquipmentReducer;
