import { actionTypes } from "./actions";

export const initialState = {
  currencies: [],
  currencyLoading: false
};

// ===========================|| COMMON REDUCER ||=========================== //

const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CURRENCY_PENDING:
      return {
        ...state,
        currencyLoading: true,
      };
      case actionTypes.CURRENCY_RECEIVED:
      return {
        ...state,
        currencies: action.payload.map((dt, index)=>({...dt, id:index})),
        currencyLoading: false,
      };
      case actionTypes.CURRENCY_FAILED:
      return {
        ...state,
        isCountryFail: false,
        currencyLoading: false
      };
    default:
      return state;
  }
};

export default currencyReducer;
