import { actionTypes } from "./actions";

export const initialState = {
  toast: {
    open: false,
    severity: "info",
    message: "",
  },
  openItem: ['dashboard'],
  openComponent: 'buttons',
  drawerOpen: true,
  componentDrawerOpen: false,
  areaList: [],
  productFormatTypes: [],
  activeCountry: [],
  activeAreaListForSearchEquipment: [],
  statusList: [],
  activeStatusList: [],
  functionLists: [],
  activeFunctions: [],
  functionalLocationLists:[]
};

// ===========================|| COMMON REDUCER ||=========================== //

const commonReducer = (state = initialState, action) => {  
  switch (action.type) {
    case actionTypes.SHOW_TOAST:
    case actionTypes.HIDE_TOAST:
      return {
        ...state,
        toast: action.payload,
      };
    case actionTypes.SET_ACTIVE_ITEM:
      return {
        ...state,
        openItem: action.payload.openItem
      }
    case actionTypes.ACTIVE_COMPONENT:
      return {
        ...state,
        openComponent: action.payload.openComponent
      }
    case actionTypes.OPEN_DRAWER:
      return {
        ...state,
        drawerOpen: action.payload.drawerOpen
      }
    case actionTypes.OPEN_COMPOENT_DRAWER:
      return {
        ...state,
        componentDrawerOpen: action.payload.componentDrawerOpen
      }
      case actionTypes.AREA_PENDING:
      return {
        ...state,
        loading: true,
      }
      case actionTypes.AREA_RECEIVED:        
      return {
        ...state,
        areaList: action.payload.map((dt, index)=>({...dt, id:index})),
        activeAreaListForSearchEquipment: action.payload.map((dt, index)=>({label:dt.areaName, value: dt.pkArea})),
        loading: false,
      }
      case actionTypes.AREA_FAILED:
      return {
        ...state,
        isAreaFail: false,
        loading: false
      }
      case actionTypes.PRODUCT_FORMAT_TYPE_PENDING:
      return {
        ...state,
        loading: true,
      }
      case actionTypes.PRODUCT_FORMAT_TYPE_RECEIVED:
      return {
        ...state,
        productFormatTypes: action.payload.map((dt, index)=>({...dt, id:index})),
        loading: false,
      }
      case actionTypes.PRODUCT_FORMAT_TYPE_FAILED:
      return {
        ...state,
        isProductFormatTypeFail: false,
        loading: false
      }
      case actionTypes.ACTIVE_COUNTRIES_PENDING:
      return {
        ...state,
        loading: true,
      }
      case actionTypes.ACTIVE_COUNTRIES_RECEIVED:
      return {
        ...state,
        activeCountry: action.payload.map((dt, index)=>({...dt, id:index})),
        loading: false,
      }
      case actionTypes.ACTIVE_COUNTRIES_FAILED:
      return {
        ...state,
        isActiveCountryFail: false,
        loading: false
      }
      case actionTypes.ACTIVE_STATUS_PENDING:
      return {
        ...state,
        loading: true,
      }
      case actionTypes.ACTIVE_STATUS_RECEIVED:        
      return {
        ...state,
        statusList: action.payload.map((dt, index)=>({...dt, id:index})),
        activeStatusList: action.payload.map((dt, index)=>({label:dt.statusName, value: dt.pkStatus})),
        loading: false,
      }
      case actionTypes.ACTIVE_STATUS_FAILED:
      return {
        ...state,
        isStatusFail: false,
        loading: false
      }
      //
      case actionTypes.ACTIVE_FUNCTION_PENDING:
      return {
        ...state,
        loading: true,
      }
      case actionTypes.ACTIVE_FUNCTION_RECEIVED: 
      const gftf = action.payload.filter((v,i,a)=>a.findIndex(v2=>(v2.functionName===v.functionName))===i)
      return {
        ...state,
        functionLists: action.payload.map((dt, index)=>({...dt, id:index})),
        activeFunctions: gftf.map((dt, index)=>({label:dt.functionName, value: dt.pkFunction})),
        loading: false,
      }
      case actionTypes.ACTIVE_FUNCTION_FAILED:
      return {
        ...state,
        isFunctionFail: false,
        loading: false
      }
      //
      case actionTypes.FUNCTIONAL_LOCATION_PENDING:
      return {
        ...state,
        locationLoading: true,
      }
      case actionTypes.FUNCTIONAL_LOCATION_RECEIVED:  
      const abc = action.payload.filter((v,i,a)=>a.findIndex(v2=>(v2.functionalLocationName===v.functionalLocationName))===i)
      return {
        ...state,
        functionalLocationLists: abc.map((dt, index)=>({label:dt.functionalLocationName, value: dt.functionalLocationName})),
        // activeFunctions: action.payload.map((dt, index)=>({label:dt.functionName, value: dt.pkFunction})),
        locationLoading: false,
      }
      case actionTypes.FUNCTIONAL_LOCATION_FAILED:
      return {
        ...state,
        isFunctionalLocationFail: false,
        locationLoading: false
      }
    default:
      return state;
  }
};

export default commonReducer;
