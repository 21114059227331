
import { Excel } from "antd-table-saveas-excel";

const eqExport = (list, formStateList, formStepList, fileName = "DRF_In_Prgogress.xlsx") => {

    const getStateName = (stateId) => {
        if (!formStateList) return '';
        const abc = formStateList.find((a) => a.stateValue === stateId);
        return abc ? `${abc.stateValue} ${abc.stateName}` : '';
    }
    const getStepName = (stepId) => {
        if (!formStepList) return '';
        const abc = formStepList.find((a) => a.stepValue === stepId);
        return abc ? `${abc.stepValue} ${abc.stepName}` : '';
    }

    const column = [
        {
            title: 'SAM Dr Ref',
            dataIndex: '',
            dataIndex: "samDrfref",
            key: "samDrfref"
        },
        {
            title: 'Current State ',
            dataIndex: "stateName",
            key: "stateName"
        },
        {
            title: 'SAM ID',
            dataIndex: '',
            dataIndex: "equipmentId",
            key: "equipmentId"
        },
        {
            title: 'Current Step',
            dataIndex: 'stepName',
            key: 'stepName',
        },
        {
            title: 'Location',
            dataIndex: "location",
            key: "location"
        },
        {
            title: 'Requester Company',
            dataIndex: 'requesterCompany', key: 'requesterCompany',
        },
        {
            title: 'Function',
            dataIndex: 'equipmentFunctionName', key: 'equipmentFunctionName',
        },
        {
            title: 'OEM',
            dataIndex: "equipmentOemname",
            key: "equipmentOemname"
        },
        {
            title: 'Model',
            dataIndex: "equipmentModelName",
            key: "equipmentModelName"
        },
        {
            title: 'Request Date',
            dataIndex: 'creationDate',
            key: 'creationDate',
        },
        {
            title: 'Requester Name',
            dataIndex: 'requesterDisplayName',
            key: 'requesterDisplayName',
        },
        {
            title: 'Serial Number',
            dataIndex: 'serialNumber',
            key: 'serialNumber'
        },
        {
            title: 'Last Update ',
            dataIndex: 'lastUpdateDate',
            key: 'lastUpdateDate'
        },
        {
            title: 'SAP/P1 Ref',
            dataIndex: 'sapP1ref',
            key: 'sapP1ref'
        },
        {
            title: 'Last Update By Publication Period',
            dataIndex: 'lastUpdateBy',
            key: 'lastUpdateBy'
        },
        {
            title: 'DR Rule',
            dataIndex: 'drRule',
            width: '5%',
            key: 'drRule'

        },
        {
            title: 'DR Decision',
            dataIndex: 'drDecision',
            key: 'drDecision'

        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: '5%',
            key: 'status',
            // render: OrderColumnComponent
        },
        {
            title: 'Condition',
            dataIndex: 'conditionId',
            key: 'conditionId'
        },
    ];

    const dataSource = list.map((a) => {
        return { ...a, stateName: getStateName(a.stateId), stepName: getStepName(a.stepId) }
    })

    const excel = new Excel();
    excel
        .addSheet("test")
        .addColumns(column)
        .addDataSource(dataSource, {
            str2Percent: true
        })
        .saveAs(fileName);

}

export default eqExport;

export const mrExport = (list, formStateList, formStepList, fileName = "MR_In_Prgogress.xlsx") => {

    const getStateName = (stateId) => {
        if (!formStateList) return '';
        const abc = formStateList.find((a) => a.stateValue === stateId);
        return abc ? `${abc.stateValue} ${abc.stateName}` : '';
    }
    const getStepName = (stepId) => {
        if (!formStepList) return '';
        const abc = formStepList.find((a) => a.stepValue === stepId);
        return abc ? `${abc.stepValue} ${abc.stepName}` : '';
    }

    const column = [
        {
            title: 'MRF Ref',
            dataIndex: "samMrfref",
            key: "samMrfref"
        },
        {
            title: 'Current State ',
            dataIndex: "stateName",
            key: "stateName"
        },
        {
            title: 'SAM ID',
            dataIndex: "equipmentId",
            key: "equipmentId"
        },
        {
            title: 'Current Step',
            dataIndex: 'stepName',
            key: 'stepName',
        },
        {
            title: 'Donor',
            dataIndex: "equipmentCompanyName",
            key: "equipmentCompanyName"
        },
        {
            title: 'Requester Company',
            dataIndex: 'requesterCompany', key: 'requesterCompany',
        },
        {
            title: 'Function',
            dataIndex: 'equipmentFunction', key: 'equipmentFunction',
        },
        {
            title: 'OEM',
            dataIndex: "equipmentOemname",
            key: "equipmentOemname"
        },
        {
            title: 'Model',
            dataIndex: "equipmentOemmodel",
            key: "equipmentOemmodel"
        },
        {
            title: 'Request Date',
            dataIndex: 'creationDate',
            key: 'creationDate',
        },
        {
            title: 'Request Date Valid until',
            dataIndex: 'requestValidityDate',
            key: 'requestValidityDate',
        },
        {
            title: 'Requester Name',
            dataIndex: 'requesterDisplayName',
            key: 'requesterDisplayName',
        },
        {
            title: 'Serial Number',
            dataIndex: 'serialNumber',
            key: 'serialNumber'
        },
        {
            title: 'Last Update ',
            dataIndex: 'lastUpdateDate',
            key: 'lastUpdateDate'
        },
        {
            title: 'SAP/P1 Ref',
            dataIndex: 'sapP1ref',
            key: 'sapP1ref'
        },
        {
            title: 'Last Update By Publication Period',
            dataIndex: 'lastUpdateBy',
            key: 'lastUpdateBy'
        },
        {
            title: 'Status',
            dataIndex: 'equipmentStatusName',
            key: 'equipmentStatusName'

        },
    ];

    const dataSource = list.map((a) => {
        return { ...a, stateName: getStateName(a.stateId), stepName: getStepName(a.stepId) }
    })

    const excel = new Excel();
    excel
        .addSheet("test")
        .addColumns(column)
        .addDataSource(dataSource, {
            str2Percent: true
        })
        .saveAs(fileName);

}

// export  mrExport;