
import {getSecurityCompanyCoordinatorListAPI, deleteSecurityCompanyCoordinatorAPI, getSecurityCoordinatorAPI, 
    createCompanyCoordinatorAPI, updateCompanyCoordinatorAPI, getCompanyCoordinatorAPI} from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    SECURITY_COMPANY_COORDINATOR_REQUEST: "SECURITY_COMPANY_COORDINATOR_REQUEST",
    SECURITY_COMPANY_COORDINATOR_RECIEVE: "SECURITY_COMPANY_COORDINATOR_RECIEVE",
    SECURITY_COMPANY_COORDINATOR_FAIL: "SECURITY_COMPANY_COORDINATOR_FAIL",
    SECURITY_COORDINATOR_REQUEST: "SECURITY_COORDINATOR_REQUEST",
    SECURITY_COORDINATOR_RECEIVE: "SECURITY_COORDINATOR_RECEIVE",
    SECURITY_COORDINATOR_FAILED: "SECURITY_COORDINATOR_FAILED",
    CREATE_COORDINATOR_PENDING: "CREATE_COORDINATOR_PENDING",
    CREATE_COORDINATOR_RECEIVED: "CREATE_COORDINATOR_RECEIVED",
    CREATE_COORDINATOR_FAILED: "CREATE_COORDINATOR_FAILED",
    UPDATE_COORDINATOR_PENDING: "UPDATE_COORDINATOR_PENDING",
    UPDATE_COORDINATOR_RECEIVED: "UPDATE_COORDINATOR_RECEIVED",
    UPDATE_COORDINATOR_FAILED: "UPDATE_COORDINATOR_FAILED",
    GET_COORDINATOR_PENDING: "GET_COORDINATOR_PENDING",
    GET_COORDINATOR_RECEIVED: "GET_COORDINATOR_RECEIVED",
    GET_COORDINATOR_FAILED: "GET_COORDINATOR_FAILED",
};
const requestSecurityCompanyCoordinator= ()=>({
    type: actionTypes.SECURITY_COMPANY_COORDINATOR_REQUEST,
})
const receiveSecurityCompanyCoordinator= (payload)=>({
    type: actionTypes.SECURITY_COMPANY_COORDINATOR_RECIEVE,
    payload
})
const failureSecurityCompanyCoordinator= ()=>({
    type: actionTypes.SECURITY_COMPANY_COORDINATOR_FAIL,
})

const securityCoordinatorRequest = () => ({
    type: actionTypes.SECURITY_COORDINATOR_REQUEST,
});

const securityCoordinatorReceived= (payload) => ({
    type: actionTypes.SECURITY_COORDINATOR_RECEIVE,
    payload,
});
const securityCoordinatorFailed = (payload) => ({
    type: actionTypes.SECURITY_COORDINATOR_FAILED,
    payload,
});

const createCoordinatorRequest = () => ({
    type: actionTypes.CREATE_COORDINATOR_PENDING,
});
const createCoordinatorReceived= (payload) => ({
    type: actionTypes.CREATE_COORDINATOR_RECEIVED,
    payload,
});
const createCoordinatorFailed = (payload) => ({
    type: actionTypes.CREATE_COORDINATOR_FAILED,
    payload,
});
const updateCoordinatorRequest = () => ({
    type: actionTypes.UPDATE_COORDINATOR_PENDING,
});
const updateCoordinatorReceived= (payload) => ({
    type: actionTypes.UPDATE_COORDINATOR_RECEIVED,
    payload,
});
const updateCoordinatorFailed = (payload) => ({
    type: actionTypes.UPDATE_COORDINATOR_FAILED,
    payload,
});

const getCoordinatorRequest = () => ({
    type: actionTypes.GET_COORDINATOR_PENDING,
});
const getCoordinatorReceived= (payload) => ({
    type: actionTypes.GET_COORDINATOR_RECEIVED,
    payload,
});
const getCoordinatorFailed = (payload) => ({
    type: actionTypes.GET_COORDINATOR_FAILED,
    payload,
});

export const getSecurityCoordinatorAction = () => {
    return async (dispatch) => {
        dispatch(securityCoordinatorRequest());
        try {
            const response = await getSecurityCoordinatorAPI();
            if (response?.status === 200) 
                dispatch(securityCoordinatorReceived(response?.data?.data));
        } catch (error) {
            dispatch(securityCoordinatorFailed(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const getSecurityCompanyCoordinatorAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestSecurityCompanyCoordinator());
        try {
            const response = await getSecurityCompanyCoordinatorListAPI(payload);
            if (response?.status === 200) 
                dispatch(receiveSecurityCompanyCoordinator(response?.data?.data));
        } catch (error) {
            dispatch(failureSecurityCompanyCoordinator(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const createCompanyCoordinatorAction = (payload,payloadObj) => { 
    return async (dispatch) => {
        dispatch(createCoordinatorRequest());
        try {
            const response = await createCompanyCoordinatorAPI(payload);  
            if (response?.status === 201) {
                dispatch(createCoordinatorReceived(response?.data?.data));
                dispatch(actions.successAlert("Company coordinator added successfully"));
                dispatch(getSecurityCompanyCoordinatorAction(payloadObj));
            }
        } catch (error) {  
            console.log("error",error)    
            dispatch(createCoordinatorFailed(error?.response?.data));        
            if (error?.response) {
                if(error?.response?.data?.errorMessage !== undefined)
                    dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
                else
                    dispatch(actions.errorAlert(error?.response?.data?.title));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const updateCompanyCoordinatorAction = (payload,id,payloadObj) => { 
    return async (dispatch) => {
        dispatch(updateCoordinatorRequest());
        try {
            const response = await updateCompanyCoordinatorAPI(payload,id);   
            
            if (response?.status === 200) {
                dispatch(updateCoordinatorReceived(response?.data?.data));
                dispatch(getSecurityCompanyCoordinatorAction(payloadObj));
                dispatch(actions.successAlert("Company coordinator updated successfully"));
            }
        } catch (error) {  
            console.log("error",error)    
            dispatch(updateCoordinatorFailed(error?.response?.data));        
            if (error?.response) {
                if(error?.response?.data?.errorMessage !== undefined)
                    dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
                else
                    dispatch(actions.errorAlert(error?.response?.data?.title));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const getCompanyCoordinatorAction = (id) => { 
    return async (dispatch) => {
        dispatch(getCoordinatorRequest());
        try {
            const response = await getCompanyCoordinatorAPI(id);    
            if (response?.status === 200) {
                dispatch(getCoordinatorReceived(response?.data?.data));
            }
        } catch (error) {  
            console.log("error",error)    
            dispatch(getCoordinatorFailed(error?.response?.data));        
            if (error?.response) {
                if(error?.response?.data?.errorMessage !== undefined)
                    dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
                else
                    dispatch(actions.errorAlert(error?.response?.data?.title));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const deleteSecurityCompanyCoordinatorAction = (id,payloadObj) => {
    return async (dispatch) => {
        dispatch(requestSecurityCompanyCoordinator());
        try {
            const response = await deleteSecurityCompanyCoordinatorAPI(id);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Company coordinator deleted successfully"));
                dispatch(getSecurityCompanyCoordinatorAction(payloadObj));
            }
        } catch (error) {
            dispatch(failureSecurityCompanyCoordinator(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));

            }
        }
    };
}