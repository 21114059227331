import { actionTypes } from "./actions";

export const initialState = {
  subProcessList: [],
  subProcessListNew: []
};

// ===========================|| COMMON REDUCER ||=========================== //

const subProReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUBPR_GET_REQUEST:
      return {
        ...state,
        isSubProLoading: true,
      };
    case actionTypes.SUBPR_GET_RECEIVE:
      const abc = action.payload.filter((v,i,a)=>a.findIndex(v2=>(v2.subProcessName===v.subProcessName))===i)
      return {
        ...state,
        subProcessListNew: action.payload.map((dt, index) => ({ ...dt, id: index })),
        subProcessList: abc.map((dt, index) => ({ ...dt, id: index })),
        isSubProLoading: false,
      };
    case actionTypes.SUBPR_GET_FAIL:
      return {
        ...state,
        isSubProLoading: false,
        isSubProFail: false,
      };
    default:
      return state;
  }
};

export default subProReducer;
