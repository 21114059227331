import { downloaddAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';
export const actionTypes = {
    DOWNLOAD_TEMPLATE_PENDING: "DOWNLOAD_TEMPLATE_PENDING",
    DOWNLOAD_TEMPLATE_RECEIVED: "DOWNLOAD_TEMPLATE_RECEIVED",
    DOWNLOAD_TEMPLATE_FAILED: "DOWNLOAD_TEMPLATE_FAILED",
};
const downloadPending= () => ({
    type: actionTypes.DOWNLOAD_TEMPLATE_PENDING,
});
const downloadReceived = (payload) => ({
    type: actionTypes.DOWNLOAD_TEMPLATE_RECEIVED,
    payload,
});
const downloadFailed = (payload) => ({
    type: actionTypes.DOWNLOAD_TEMPLATE_FAILED,
    payload,
})

export const downloadAction = () => { 
    return async (dispatch) => {
        dispatch(downloadPending());
        try {
            const response = await downloaddAPI();                      
            if (response?.status === 200) {
                dispatch(downloadReceived(response?.data?.data));
            }
        } catch (error) {    
            dispatch(downloadFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}