import { actionTypes } from "./actions";

export const initialState = {  
  productFormatTypeList: [],
  isProductFormatLoading: true,
  addProductFormatType:'',
  updateProductFormatType:'',
  deleteProductFormatType:'',
};
  const devProductFormatTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.GET_RODUCT_FORMAT_TYPE_REQUEST:
        return {
          ...state,
          isProductFormatLoading: true,
        };
      case actionTypes.GET_PRODUCT_FORMAT_TYPE_RECEIVED:
        return {
          ...state,
          productFormatTypeList: action.payload.map((dt, index) => ({ ...dt, id: index })),
          isProductFormatLoading: false,
        };
       
      case actionTypes.GET_PRODUCT_FORMAT_TYPE_FAILED:
        return {
          ...state,        
          isProductFormatLoading: false
        };
      case actionTypes.CREATE_PRODUCT_FORMAT_TYPE_REQUEST:
        return {
          ...state,
        };
      case actionTypes.CREATE_PRODUCT_FORMAT_TYPE_RECEIVED:
        return {
          ...state,
          addProductFormatType: action.payload,
        };
      case actionTypes.CREATE_PRODUCT_FORMAT_TYPE_FAILED:
        return {
          ...state,        
        };
      case actionTypes.UPDATE_PRODUCT_FORMAT_TYPE_REQUEST:
        return {
          ...state,
        };
      case actionTypes.UPDATE_PRODUCT_FORMAT_TYPE_RECEIVED:
        return {
          ...state,
          updateProductFormatType: action.payload,
        };
      case actionTypes.UPDATE_PRODUCT_FORMAT_TYPE_FAILED:
        return {
          ...state,        
        };
      case actionTypes.DELETE_PRODUCT_FORMAT_TYPE_REQUEST:
        return {
          ...state,
        };
      case actionTypes.DELETE_PRODUCT_FORMAT_TYPE_RECEIVED:
        return {
          ...state,
          deleteProductFormatType: action.payload,
        };
      case actionTypes.DELETE_PRODUCT_FORMAT_TYPE_FAILED:
        return {
          ...state,        
        };
      case 'CLEAR_PRODUCT_FORMAT':
        return {
          ...state,
          productFormatTypeList: []
        };
      default:
        return state;
    }
  };

export default devProductFormatTypeReducer