import { actionTypes } from "./actions";

export const initialState = {
  productFormatValue: {},
  formWorkFlowList:[],
  formStateList: [],
  formStepList: [],
  formTypeList:[]
};

// ===========================|| COMMON REDUCER ||=========================== //

export const pfvReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCT_FORMAT_VALUE_GET_REQUEST:
      return {
        ...state,
        isPFVLoading: true,
      };
    case actionTypes.PRODUCT_FORMAT_VALUE_GET_RECEIVE:
      return {
        ...state,
        productFormatValueList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isPFVLoading: false,
      };
    case actionTypes.PRODUCT_FORMAT_VALUE_GET_FAIL:
      return {
        ...state,
        isOemFail: false,
        isPFVLoading: false
      };
    default:
      return state;
  }
};

export const formWorkFlowReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FORM_WORKFLOW_GET_REQUEST:
        return {
          ...state,
          isFormWorkFlowLoading: true,
        };
      case actionTypes.FORM_WORKFLOW_GET_RECEIVE:
        return {
          ...state,
          formWorkFlowList: action.payload.map((dt, index) => ({ ...dt, id: index })),
          isFormWorkFlowLoading: false,
        };
      case actionTypes.FORM_WORKFLOW_GET_FAIL:
        return {
          ...state,
          
          isFormWorkFlowLoading: false
        };
      default:
        return state;
    }
  };

  export const formStateFlowReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FORM_STATE_GET_REQUEST:
        return {
          ...state,
          isFormStateLoading: true,
        };
      case actionTypes.FORM_STATE_GET_RECEIVE:
        return {
          ...state,
          formStateList: action.payload.map((dt, index) => ({ ...dt, id: index })),
          isFormStateLoading: false,
        };
      case actionTypes.FORM_STATE_GET_FAIL:
        return {
          ...state,
          isFormStateLoading: false
        };
      default:
        return state;
    }
  };
  export const stepReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FORM_STEP_GET_REQUEST:
        return {
          ...state,
          isFormStepLoading: true,
        };
      case actionTypes.FORM_STEP_GET_RECEIVE:
        return {
          ...state,
          formStepList: action.payload.map((dt, index) => ({ ...dt, id: index })),
          isFormStepLoading: false,
        };
      case actionTypes.FORM_STEP_GET_FAIL:
        return {
          ...state,
          isFormStepLoading: false
        };
      default:
        return state;
    }
  };
  export const formTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FORM_TYPE_GET_REQUEST:
        return {
          ...state,
          isFormTypeLoading: true,
        };
      case actionTypes.FORM_TYPE_GET_RECEIVE:
        return {
          ...state,
          formTypeList: action.payload.map((dt, index) => ({ ...dt, id: index })),
          isFormTypeLoading: false,
        };
      case actionTypes.FORM_TYPE_GET_FAIL:
        return {
          ...state,
          isFormTypeLoading: false
        };
      default:
        return state;
    }
  };
  