import { API } from "api";

export const getOemListAPI = (req) => {
    return API.get("api/oem/getall?page=0&size=0")
                .then((res) => res);
};

export const getAllActiveOemListAPI = (req) => {
    return API.get("api/oem/getallactive?page=0&size=0")
                .then((res) => res);
};

    export const createOemAPI = (payload) => {
        return API.post("/api/oem/add", payload)
                    .then((res) => res);
    }
    
    export const updateOemAPI = (id, payload) => {
        return API.put(`/api/oem/update/${id}`, payload)
                    .then((res) => res);
    }
    
    export const deleteOemAPI = (id) => {
        return API.delete(`/api/oem/delete/${id}`)
                    .then((res) => res);
    }
