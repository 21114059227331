
import {getAllActiveListTypeAPI, updateListTypeAPI, createListTypeAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    LIST_TYPE_GET_REQUEST: "LIST_TYPE_GET_REQUEST",
    LIST_TYPE_GET_RECEIVE: "LIST_TYPE_GET_RECEIVE",
    LIST_TYPE_GET_FAIL: "LIST_TYPE_GET_FAIL"

  };

const requestListType= () => ({
    type: actionTypes.LIST_TYPE_GET_REQUEST,
});
const receiveListType = (payload) => ({
    type: actionTypes.LIST_TYPE_GET_RECEIVE,
    payload,
});
const failureListType = (payload) => ({
    type: actionTypes.LIST_TYPE_GET_FAIL,
    payload,
});


export const getAllActiveListTypeAction = () => {
    return async (dispatch) => {
        dispatch(requestListType());
        try {
            const response = await getAllActiveListTypeAPI();
            if (response?.status === 200) {
                dispatch(receiveListType(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureListType(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};



export const createListTypeAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestListType());
        try {
            const response = await createListTypeAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("List Type added successfully"));
            }
            dispatch(getAllActiveListTypeAction());
        } catch (error){
            dispatch(failureListType(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateListTypeAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestListType());
        try {
            const response = await updateListTypeAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("List Type updated successfully"));
            }
            dispatch(getAllActiveListTypeAction());
        } catch (error) {
            dispatch(failureListType(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
