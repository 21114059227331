import { actionTypes } from "./actions";

export const initialState = {
    ruleGroupConfig:[],
    dscolmapper: [],
    dseqmtcolconfig: []  
};


const ruleGroupConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RULEGROUP_CONFIG_REQUEST:
      return {
        ...state,
        isRuleGroupConfigLoading: true,
      };
    case actionTypes.RULEGROUP_CONFIG_LOADING:
      return {
        ...state,
        isRuleGroupConfigLoading: false,
      };
    case actionTypes.RULEGROUP_CONFIG_RECEIVE:
      let ruleGroupConfigData = action.payload.machineTypeHeaderDetails
      return {
        ...state,
        ruleGroupConfig : ruleGroupConfigData.map((dt, index) => ({ ...dt, id: index })),
        // machineTypeDictUIColMappings: action.payload.machineTypeDictUIColMappings,
        isRuleGroupConfigLoading: true,
      };
    case actionTypes.RULEGROUP_CONFIG_FAIL:
      return {
        ...state,
        isRuleGroupConfigLoading: false,
        // isMachineTypeFail: false,
      };
      case actionTypes.EQMTCONFIG_CONFIG_REQUEST:
        return {
          ...state,
          isEqmntConfigLoading: false,
        };
        case actionTypes.EQMTCONFIG_CONFIG_LOADING:
          return {
            ...state,
            isEqmntConfigLoading: false,
          };
       case actionTypes.EQMTCONFIG_CONFIG_RECEIVE:
            let eqmntData = action.payload.machineTypeHeaderDetails
            console.log("Why fail",eqmntData);
            return {
              ...state,
              dseqmtcolconfig : eqmntData.map((dt, index) => ({ ...dt, id: index })),
              isEqmntConfigLoading: true,
            };
    default:
      return state;
  }
};

export default ruleGroupConfigReducer;
