import { Box, Typography } from "@mui/material/index";
import { Excel } from "antd-table-saveas-excel";


export const drRule = [
    { id: 'Cancel', label: 'Cancel' },
    { id: 'Scrap Immediately', label: 'Scrap Immediately' },
    { id: '3 Months Publishing', label: '3 Months Publishing' }
]

export const drDecision = [
    { id: 'Cancelled', label: 'Cancelled' },
    { id: 'Scrap', label: 'Scrap' },
    { id: 'Reserved', label: 'Reserved' }
]


export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export function getTreeData(data) {
    const abc = [];
    data.forEach((next) => {
        const { equipmentId,
            drRule,
            equipmentOemname,
            equipmentModelName,
            serialNumber,
            sapP1ref,
            equipmentFunctionName,
            drDecision,
            requesterCompany,
            drDecisionDate,
            status,
            requesterDisplayName,
            requesterCompanyName,
            condition,
            stateId,
            creationDate,
            stepId,
            lastUpdateDate,
            location,

            lastUpdateBy,
            requesterCountry,
            conditionId } = next;
        if (abc.find(({ samDrfref }) => next.samDrfref === samDrfref)) {
            abc.map((a) => {
                if (a.samDrfref === next.samDrfref) {
                    const pqr = abc.findIndex((f) => next.samDrfref === f.samDrfref)
                    abc[pqr].children = [...abc[pqr].children, {
                        equipmentId,
                        equipmentFunctionName,
                        equipmentOemname,
                        equipmentModelName,
                        serialNumber,
                        sapP1ref,
                        drDecision,
                        drDecisionDate,
                        status,
                        equipmentOemname,
                        equipmentModelName,
                        condition,
                        drRule,
                        conditionId, key: `${next.samDrfref}.${equipmentId}`
                    }]
                } else {
                    return a;
                }
            })
        } else {
            const children = [{
                equipmentId,
                equipmentFunctionName,
                equipmentOemname,
                equipmentModelName,
                serialNumber,
                sapP1ref,
                drDecision,
                drDecisionDate,
                status,
                drRule,
                equipmentOemname,
                equipmentModelName,
                condition,
                conditionId, equipmentId, key: `${next.samDrfref}.${next.equipmentId}`
            }];
            abc.push({ creationDate, lastUpdateBy, lastUpdateDate,requesterCompanyName, requesterDisplayName, requesterCompany, requesterCountry, stateId, stepId, location, samDrfref: next.samDrfref, key: next.samDrfref, children })
        }
    });
    return abc;
}









export function downloadDocument(data){
let rs = [];

data.forEach((f)=>{
    if(f.children && f.children.length){
        f.children.forEach((s)=>{
                if(s.children && s.children.length){
                    s.children.forEach((t)=>{
                        if(t.children && t.children.length){
                            t.children.forEach((p)=>{
                                if(rs.length>0)
                                {
                                    //var found = rs.some(item=>item.samiId=p.pkEquipment) 
                                    //if(!found)
                                    //{
                                        rs.push({'samDrRef': p.samDrRef, 'samMrfRef': p.samMrfRef, 'status':p.statusName,'samiId': p.pkEquipment,'srNo': p.serialNumber , 'oem':p.oemName, 'model':p.modelName,'function': p.functionName,'subProcess': t.subProcessName,'Functional_Location': s.functionalLocationName, 'Area': f.flareaname,"process": t.processName})     
                                    //}    
                                }
                                else
                                {
                                    rs.push({'samDrRef': p.samDrRef, 'samMrfRef': p.samMrfRef, 'status':p.statusName,'samiId': p.pkEquipment,'srNo': p.serialNumber , 'oem':p.oemName, 'model':p.modelName,'function': p.functionName,'subProcess': t.subProcessName,'Functional_Location': s.functionalLocationName, 'Area': f.flareaname,"process": t.processName})         
                                }
                                if(p.children && p.children.length){
                                    p.children.forEach((q)=>{
                                        rs.push({'samDrRef': q.samDrRef, 'samMrfRef': q.samMrfRef, 'status':q.statusName,'samiId': q.pkEquipment,'srNo': q.serialNumber , 'oem':q.oemName, 'model':q.modelName,'function': q.functionName,'subProcess': t.subProcessName,'Functional_Location': s.functionalLocationName, 'Area': f.flareaname,"process": t.processName}) 
                                    })
                                }
                                else
                                {
                                    rs.push({'samDrRef': p.samDrRef, 'samMrfRef': p.samMrfRef, 'status':p.statusName,'samiId': p.pkEquipment,'srNo': p.serialNumber , 'oem':p.oemName, 'model':p.modelName,'function': p.functionName,'subProcess': t.subProcessName,'Functional_Location': s.functionalLocationName, 'Area': f.flareaname,"process": t.processName})
                                }
                            })
                        }else{
                              rs.push({'Functional_Location': s.functionalLocationName, 'Area': f.flareaname,"process": t.processName,'samiId': t.pkEquipment,'srNo': t.serialNumber })
                        }
                    })
                }else{
                    rs.push({'Functional_Location': s.functionalLocationName, 'Area': f.flareaname})
                }
            })
    }else{
        rs.push({'Area': f.flareaname})
    }
})

const key = 'samiId';

const uniquerow = [...new Map(rs.map(item =>
    [item[key], item])).values()];
  

const column = [
    {
        title: 'Area',
        dataIndex: "Area",
        key: "Area"
    },
    {
        title: 'Process',
        dataIndex: "process",
        key: "process"
    },
    {
        title: 'Sub-Process',
        dataIndex: 'subProcess',
        key: 'subProcess',
    },
    {
        title: 'Functional Location',
        dataIndex: "Functional_Location",
        key: "Functional_Location"
    },
    {
        title: 'Function',
        dataIndex: 'function', key: 'function',
    },
    {
        title: 'Equipment Description',
        dataIndex: "samiId1",
        key: "samiId1"
    },
    {
        title: 'OEM',
        dataIndex: 'oem',
        key: 'oem',
    },
    {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
    },
    {
        title: 'SamDrRef',
        dataIndex: 'samDrRef',
        key: 'samDrRef',
    },
    {
        title: 'SamMrfRef',
        dataIndex: 'samMrfRef',
        key: 'samMrfRef',
    },
    {
        title: 'Serial Number',
        dataIndex: 'srNo',
        key: 'srNo'
    },
    {
        title: 'Product Format',
        dataIndex: 'productformat',
        key: 'productformat'
    },
    {
        title: 'SAMID',
        dataIndex: 'samiId',
        key: 'samiId'
    },
    {
        title: 'SAP P1',
        dataIndex: 'sap',
        key: 'sap'
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status'
    },
    {
        title: 'Condition',
        dataIndex: 'condition',
        key: 'condition'
    }
];

const excel = new Excel();
    excel
        .addSheet("test")
        .addColumns(column)
        .addDataSource(uniquerow, {
            str2Percent: true
        })
        .saveAs('Equipment by Company.xlsx');

//console.log("Welcome !", rs);
}





























