
import {  getEquipmentActionListAPI} from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
   
    EQUIPMENT_ACTION_REQUEST: "EQUIPMENT_ACTION_REQUEST",
    EQUIPMENT_ACTION_RECEIVE: "EQUIPMENT_ACTION_RECEIVE",
    EQUIPMENT_ACTION_FAIL: "EQUIPMENT_ACTION_FAIL"
};


const requestEquipmentAction = () => ({
    type: actionTypes.EQUIPMENT_ACTION_REQUEST,
});
const receiveEquipmentAction= (payload) => ({
    type: actionTypes.EQUIPMENT_ACTION_RECEIVE,
    payload,
});
const failureEquipmentAction = (payload) => ({
    type: actionTypes.EQUIPMENT_ACTION_FAIL,
    payload,
});

export const getEquipmentActionsAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestEquipmentAction());
        try {
            const response = await getEquipmentActionListAPI(payload);
            if (response?.status === 200) {
               
                dispatch(receiveEquipmentAction(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureEquipmentAction(error?.response?.data?.data || "Something went wrong, please contact administrator"));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage? error?.response?.data?.errorMessage: error?.response?.data?.title || "Something went wrong, please contact administrator"));
            } else {
                dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};