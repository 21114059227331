import { API } from "api";

export const getAllCurrencyAPI = () => {
    return API.get("/api/currency/getall?page=0&size=0")
                .then((res) => res);
};

export const createCurrencyAPI = (payload) => {
    return API.post("/api/currency/add", payload)
                .then((res) => res);
}

export const updateCurrencyAPI = (id, payload) => {
    return API.put(`/api/currency/update/${id}`, payload)
                .then((res) => res);
}

export const deleteCurrencyAPI = (id) => {
    return API.delete(`/api/currency/delete/${id}`)
                .then((res) => res);
}