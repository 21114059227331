import { actionTypes } from "./actions";

export const initialState = {
  machineReserList: [],
  machineReserByUserList: []
};

// ===========================|| COMMON REDUCER ||=========================== //

const machineReserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EQ_RESER_REQUEST:
      return {
        ...state,
        ismachineReserLoading: true,
        machineReserList: []
      }; 
      case 'CLEAR_RESR_DATA':
      return {
        ...state,
        machineReserList: [],
        machineReserByUserList: []
      };
    case actionTypes.EQ_RESER_RECEIVE:
      return {
        ...state,
        machineReserList: action?.payload?.data ? action?.payload?.data.map((dt, index) => ({ ...dt, id: index })) : [],
        pageInfo: action?.payload?.pageInfo,
        ismachineReserLoading: false,
      };
    case actionTypes.EQ_RESER_FAIL:
      return {
        ...state,
        ismachineReserLoading: false,
        ismachineReserFail: false,
        machineReserList: []
      };

    case actionTypes.EQ_RESER_BYUSER_REQUEST:
      return {
        ...state,
        ismachineReserUserLoading: true,
      };
    case actionTypes.EQ_RESER_BYUSER_RECEIVE:
      return {
        ...state,
        machineReserByUserList: action?.payload?.data.map((dt, index) => ({ ...dt, id: index })),
        pageInfoReserByUser: action?.payload?.pageInfo,
        ismachineReserUserLoading: false,
      };
    case actionTypes.EQ_RESER_BYUSER_FAIL:
      return {
        ...state,
        ismachineReserUserLoading: false,
      };
    default:
      return state;
  }
};

export default machineReserReducer;
