import { actionTypes } from "./actions";

export const initialState = {
  processList: [],
  processListNoD: []
};

// ===========================|| COMMON REDUCER ||=========================== //

const processReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROCESS_GET_REQUEST:
      return {
        ...state,
        isProcessLoading: true,
      };
    case actionTypes.PROCESS_GET_RECEIVE:
      const abc = action.payload.filter((v,i,a)=>a.findIndex(v2=>(v2.processName===v.processName))===i)
      return {
        ...state,
        processList: abc.map((dt, index) => ({ ...dt, id: index })),
        processListNoD: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isProcessLoading: false,
      };
    case actionTypes.PROCESS_GET_FAIL:
      return {
        ...state,
        isProcessLoading: false,
        isProcessFail: false,
      };
    default:
      return state;
  }
};

export default processReducer;
