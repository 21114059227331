
// import { actionTypes } from "./actions";
import { getAllCompaniesByCountryIdAPI, getAllCountrytAPI, getAllcompanyforEquipment } from './endpoints';
import { actions } from 'store/Reducer/actions';


export const actionTypes = {
    All_COMPANIES_REQUEST: "All_COMPANIES_REQUEST",
    All_COMPANIES_RECEIVE: "All_COMPANIES_RECEIVE",
    All_COMPANIES_FAIL: "All_COMPANIES_FAIL",
    
    All_COUNTRY_REQUEST: "All_COUNTRY_REQUEST",
    All_COUNTRY_RECEIVE: "All_COUNTRY_RECEIVE",
    All_COUNTRY_FAIL: "All_COUNTRY_FAIL",

    COMPANY_FOR_EQUIPMENT_REQUEST: "COMPANY_FOR_EQUIPMENT_REQUEST",
    COMPANY_FOR_EQUIPMENT_RECEIVE: "COMPANY_FOR_EQUIPMENT_RECEIVE",
    COMPANY_FOR_EQUIPMENT_FAIL: "COMPANY_FOR_EQUIPMENT_FAIL",
};
const requestCompanyForEquipment = () => ({
    type: actionTypes.COMPANY_FOR_EQUIPMENT_REQUEST,
});
const recieveCompanyForEquipment= (payload) => ({
    type: actionTypes.COMPANY_FOR_EQUIPMENT_RECEIVE,
    payload,
});
const failureCompanyForEquipment = (payload) => ({
    type: actionTypes.COMPANY_FOR_EQUIPMENT_FAIL,
    payload,
});
const requestCompanies = () => ({
    type: actionTypes.All_COMPANIES_REQUEST,
});
const receiveCompanies= (payload) => ({
    type: actionTypes.All_COMPANIES_RECEIVE,
    payload,
});
const failureCompanies = (payload) => ({
    type: actionTypes.All_COMPANIES_FAIL,
    payload,
});
const requestCountry = () => ({
    type: actionTypes.All_COUNTRY_REQUEST,
});
const receiveCountry= (payload) => ({
    type: actionTypes.All_COUNTRY_RECEIVE,
    payload,
});
const failureCountry = (payload) => ({
    type: actionTypes.All_COUNTRY_FAIL,
    payload,
});


export const getAllCompaniesByCountryIdAction = (payload) => {    
    return async (dispatch) => {
        dispatch(requestCompanies());
        try {
            const response = await getAllCompaniesByCountryIdAPI(payload);            
            if (response?.status === 200) {
                dispatch(receiveCompanies(response?.data?.data));
                // dispatch(actions.successAlert("Coutries fetched successfully"));
            }
        } catch (error) {
            dispatch(failureCompanies(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const getAllCompanyForEquipmentAction = () => {    
    return async (dispatch) => {
        dispatch(requestCompanyForEquipment());
        try {
            const response = await getAllcompanyforEquipment();            
            if (response?.status === 200) {
                dispatch(recieveCompanyForEquipment(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureCompanyForEquipment(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const getAllCountryAction = (regions) => {    
    return async (dispatch) => {
        dispatch(requestCountry());
        try {
            const response = await getAllCountrytAPI();            
            if (response?.status === 200) {
                const customArray = [];
                response?.data?.data?.forEach(element => {
                    const regionID = regions.find(({pkRegion})=>pkRegion===element.fkRegion)
                    
                    element.regionName = regionID?.regionName;
                    customArray.push(element);
                });
                dispatch(receiveCountry(customArray));
            }
        } catch (error) {
            dispatch(failureCountry(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
