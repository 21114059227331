import { API } from "api";

export const getAllActiveCompaniesAPI = () => {
    return API.get("/api/company/getallactive?page=0&size=0")
                .then((res) => res);
};
export const getCompanyListAPI = (req) => {
    return API.get("api/company/getall?page=0&size=0")
        .then((res) => res);
};

export const getAllActiveCompanyListAPI = (req) => {
    return API.get("api/company/getallactive?page=0&size=0")
        .then((res) => res);
};

export const createCompanyAPI = (payload) => {
    return API.post("/api/company/add", payload)
        .then((res) => res);
}

export const getCompanyPostAPI = (payload) => {
    return API.post("/api/company/getall?page=1&size=100000", payload)
        .then((res) => res);
}

export const updateCompanyAPI = (id, payload) => {   
    return API.put(`/api/company/update/${id}`, payload)
        .then((res) => res);
}

export const deleteCompanyAPI = (id) => {
    return API.delete(`/api/company/delete/${id}`)
        .then((res) => res);
}
