import { actionTypes } from "./actions";

export const initialState = {
  companyLists: [],
  loading: false,
  activeCompanyLists: [],
  companyList: [],
  samCompanyList:[]
};

// ===========================|| COMMON REDUCER ||=========================== //

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COMPANY_PENDING:
      return {
        ...state,
        loading: true,
        isCompanyLoading: true
      };
      case actionTypes.COMPANY_RECEIVED:
        let companies = action.payload.sort((a,b)=>a.longCompanyName>b.longCompanyName?1:-1)
      return {
        ...state,
        companyLists: companies.map((dt, index)=>({...dt, id:index})),
        companyList: companies.map((dt, index) => ({ ...dt, id: index })),
        samCompanyList: companies.map((dt, index)=>({label:dt.longCompanyName, value: dt.pkCompany})),
        activeCompanyLists: companies.map((dt, index)=>({label:dt.longCompanyName, value: dt.pkCompany})),
        loading: false,
        isCompanyLoading: false
      };
      case actionTypes.COMPANY_FAILED:
        return {
          ...state,
          loading: false,
          isCompanyLoading: false
        }
     
    default:
      return state;
  }
};

export default companyReducer;
