
import { getAllSpeedUnitAPI, createSpeedUnitAPI, updateSpeedUnitAPI, deleteSpeedUnitAPI, getAllActiveSpeedUnitAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    SPEED_UNIT_PENDING: "SPEED_UNIT_PENDING",
    SPEED_UNIT_RECEIVED: "SPEED_UNIT_RECEIVED",
    SPEED_UNIT_FAILED: "SPEED_UNIT_FAILED"
  };

const requestSpeedUnit= () => ({
    type: actionTypes.SPEED_UNIT_PENDING,
});
const receiveSpeedUnit = (payload) => ({
    type: actionTypes.SPEED_UNIT_RECEIVED,
    payload,
});
const failureSpeedUnit = (payload) => ({
    type: actionTypes.SPEED_UNIT_FAILED,
    payload,
});
export const getAllSpeedUnitAction = () => {
    return async (dispatch) => {
        dispatch(requestSpeedUnit());
        try {
            const response = await getAllSpeedUnitAPI();
            if (response?.status === 200) {
                dispatch(receiveSpeedUnit(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureSpeedUnit(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getAllActiveSpeedUnitAction = () => {
    return async (dispatch) => {
        dispatch(requestSpeedUnit());
        try {
            const response = await getAllActiveSpeedUnitAPI();
            if (response?.status === 200) {
                dispatch(receiveSpeedUnit(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureSpeedUnit(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const createSpeedUnitAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestSpeedUnit());
        try {
            const response = await createSpeedUnitAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Speed Unit added successfully"));
            }
            dispatch(getAllSpeedUnitAction());
        } catch (error) {
            dispatch(failureSpeedUnit(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateSpeedUnitAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestSpeedUnit());
        try {
            const response = await updateSpeedUnitAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Speed Unit updated successfully"));
            }
            dispatch(getAllSpeedUnitAction());
        } catch (error) {
            dispatch(failureSpeedUnit(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deleteSpeedUnitAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestSpeedUnit());
        try {
            const response = await deleteSpeedUnitAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Speed unit deleted successfully"));
            }
            dispatch(getAllSpeedUnitAction());
        } catch (error) {
            dispatch(failureSpeedUnit(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
