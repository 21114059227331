import { actionTypes } from "./actions";

export const initialState = {
  functionList: [],
  functionListNoD: [],
  activeFunctionList: []
};

// ===========================|| COMMON REDUCER ||=========================== //

const functionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FUNCTION_GET_REQUEST:
      return {
        ...state,
        isFunctionLoading: true,
      };
    case actionTypes.FUNCTION_GET_RECEIVE:
      const abc = action.payload.filter((v,i,a)=>a.findIndex(v2=>(v2.functionName===v.functionName))===i)
      return {
        ...state,
        functionList: abc.map((dt, index) => ({ ...dt, id: index })),
        functionListNoD: action.payload.map((dt, index) => ({ ...dt, id: index })),
        activeFunctionList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isFunctionLoading: false,
      };
    case actionTypes.FUNCTION_GET_FAIL:
      return {
        ...state,
        isFunctionLoading: false,
        isFunctionFail: false,
      };
    default:
      return state;
  }
};

export default functionReducer;
