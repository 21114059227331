
// import { actionTypes } from "./actions";
import { getModelFunctionAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    MODEL_FUNCTION_GET_REQUEST: "MODEL_FUNCTION_GET_REQUEST",
    MODEL_FUNCTION_GET_RECEIVE: "MODEL_FUNCTION_GET_RECEIVE",
    MODEL_FUNCTION_GET_FAIL: "MODEL_FUNCTION_GET_FAIL"
};

const requestModelFunction = () => ({
    type: actionTypes.MODEL_FUNCTION_GET_REQUEST,
});
const receiveModelFunction = (payload) => ({
    type: actionTypes.MODEL_FUNCTION_GET_RECEIVE,
    payload,
});
const failureModelFunction = (payload) => ({
    type: actionTypes.MODEL_FUNCTION_GET_FAIL,
    payload,
});
export const getModelFunctionAction = (id) => {
    return async (dispatch) => {
        dispatch(requestModelFunction());
        try {
            const response = await getModelFunctionAPI(id);
            if (response?.status === 200) {
                dispatch(receiveModelFunction(response?.data?.data));
                // dispatch(actions.successAlert("Coutries fetched successfully"));
            }
        } catch (error) {
            dispatch(failureModelFunction(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
