import React, { useEffect, useState } from 'react';
import { Grid, Button, LinearProgress,Typography,TextField, FormControl, 
    InputLabel, Select, MenuItem, CircularProgress,Box } from '@mui/material';
  import MultiSelectProductFormat from 'components/Form/MultiSelectProductFormat';
import { useSelector,useDispatch } from 'react-redux';
import { getEquipmentByIdAction,getAllProductFormatTypesAction, getProductFormatAssignmentFromAction, 
    getProductFormatAssignmentToAction, updateAction, updateKitAction } from './state/actions';

export default function ProductFormat({samid}) {
    const dispatch = useDispatch();
    const [productFormatFromList, setProductFormatFromList] = useState([]);
    const [productFormatToList, setProductFormatToList] = useState([]);
    const [isKit, setIsKit] = useState(false);
    const { detailEquipment={},isDetailLoading, productFormatFromData,isPFFromLoading,
    productFormatToData, isPFToLoading, childData=[],isChildLoading, updatePFData,isPFUpdated,
    updatePFKitData, isPFKitUpdated } = useSelector((state) => state.detailsEquipment);
    
    useEffect(() => {
        dispatch(getEquipmentByIdAction(samid));
        dispatch(getProductFormatAssignmentFromAction(samid));
        dispatch(getAllProductFormatTypesAction())
    }, []);

    useEffect(() => {
        if(detailEquipment?.isKit && !isDetailLoading){
            setIsKit(true)
            dispatch(getProductFormatAssignmentToAction(samid));
        }
        else
            setIsKit(false)
    }, [detailEquipment]);

    useEffect(() => {
        if((childData.length>0 && !isChildLoading) && (productFormatFromData.length>0 && !isPFFromLoading)) {
            let newData = []
            productFormatFromData.forEach((item)=>{
                if(item.fkProductFormatType !== '' && item.fkProductFormatType !== 0){
                let tmpData = childData.filter((childItem)=>childItem.fkProductFormatType ===item.fkProductFormatType)
                item.childArr = tmpData
                newData.push(item)
                }
                else
                newData.push(item)
            })
            setProductFormatFromList(newData)
        }
    }, [productFormatFromData,isPFFromLoading,childData, isChildLoading])
    
    useEffect(() => {
        if((childData.length>0 && !isChildLoading) && (productFormatToData.length>0 && !isPFToLoading)) {
            let newData = []
            productFormatToData.forEach((item)=>{
                if(item.fkProductFormatType !== '' && item.fkProductFormatType !== 0){
                    let tmpData = childData.filter((childItem)=>childItem.fkProductFormatType ===item.fkProductFormatType)
                    item.childArr = tmpData
                    newData.push(item)
                }
                else
                newData.push(item)
            })
            setProductFormatToList(newData)
        }
    }, [productFormatToData,isPFToLoading,childData, isChildLoading])

    const handleChange = (evt,indx, type)=>{
        const {name, value} = evt.target
        if(type === 'from'){
            const rowsInput = [...productFormatFromList];
            rowsInput[indx]['fkProductFormatTypeValue'] = value;
            setProductFormatFromList(rowsInput);
        }
        else if(type === 'to'){
            const rowsInput = [...productFormatToList];
            rowsInput[indx]['fkProductFormatTypeValue'] = value;
            setProductFormatToList(rowsInput);
        }

    }
    const onInputChange =(evt,indx, type)=>{
        const {name, value} = evt.target
        if(type === 'from'){
            const rowsInput = [...productFormatFromList];
            rowsInput[indx]['freeTextValue'] = value;
            setProductFormatFromList(rowsInput);
        }
        else if(type === 'to'){
            const rowsInput = [...productFormatToList];
            rowsInput[indx]['freeTextValue'] = value;
            setProductFormatToList(rowsInput);
        }
    }
    const onMultiSelectChange = (name, indx, type) => {
        if(type === 'from'){
            const rowsInput = [...productFormatFromList];
            let selectedOption = [...name.name]
            if(selectedOption.length >0){
                let freeText = ''
                selectedOption.forEach((item)=>{
                if(freeText !=='')
                    freeText = `${freeText}/${item.productFormatTypeValue}`
                else 
                    freeText = `${item.productFormatTypeValue}`
                }
                )
                rowsInput[indx]['freeTextValue'] = freeText;
            }
            else 
                rowsInput[indx]['freeTextValue'] = '';
            setProductFormatFromList(rowsInput);
        }
        else if(type === 'to'){
            const rowsInput = [...productFormatToList];
            let selectedOption = [...name.name]
            if(selectedOption.length >0){
                let freeText = ''
                selectedOption.forEach((item)=>{
                if(freeText !=='')
                    freeText = `${freeText}/${item.productFormatTypeValue}`
                else 
                    freeText = `${item.productFormatTypeValue}`
                }
                )
                rowsInput[indx]['freeTextValue'] = freeText;
            }
            else 
                rowsInput[indx]['freeTextValue'] = '';
            setProductFormatToList(rowsInput);
        }
    }
    const handleSave = ()=>{
        if(isKit){
          let payloadFromArr =[]
          let payloadToArr =[]
          productFormatFromList.forEach((item)=>{
            let inputObj =
              {
                fkProductFormat: item.fkProductFormat ===0?null :item.fkProductFormat,
                fkFunctionalLocation: item.fkFunctionalLocation ===0?null :item.fkFunctionalLocation,
                fkEquipment: samid,
                fkProductFormatTypeValue: item.fkProductFormatTypeValue ===0?null :item.fkProductFormatTypeValue,
                freeTextValue: item.freeTextValue
              }
              if(inputObj.freeTextValue || inputObj.fkProductFormatTypeValue ) payloadFromArr.push(inputObj)
          })
          productFormatToList.forEach((item)=>{
            let inputObj =
              {
                fkProductFormat: item.fkProductFormat ===0?null :item.fkProductFormat,
                fkFunctionalLocation: item.fkFunctionalLocation ===0?null :item.fkFunctionalLocation,
                fkEquipment: samid,
                fkProductFormatTypeValue: item.fkProductFormatTypeValue ===0?null :item.fkProductFormatTypeValue,
                freeTextValue: item.freeTextValue
              }
              if(inputObj.freeTextValue || inputObj.fkProductFormatTypeValue) payloadToArr.push(inputObj)             
          })
          let obj={
            "from":[...payloadFromArr],
            "to":[...payloadToArr]
          }
          dispatch(updateKitAction(samid,obj))
        }
        else{
            let payloadArr =[]
            productFormatFromList.forEach((item)=>{
              let inputObj =
                {
                  fkProductFormat: item.fkProductFormat ===0?null :item.fkProductFormat,
                  fkFunctionalLocation: item.fkFunctionalLocation ===0?null :item.fkFunctionalLocation,
                  fkEquipment: samid,
                  fkProductFormatTypeValue: item.fkProductFormatTypeValue ===0?null :item.fkProductFormatTypeValue,
                  freeTextValue: item.freeTextValue
                }
                if(inputObj.freeTextValue || inputObj.fkProductFormatTypeValue) payloadArr.push(inputObj)
            })
            dispatch(updateAction(samid,payloadArr))
          }
      }
    return <>
    {isPFFromLoading || isPFToLoading || isChildLoading ? <Box><LinearProgress /></Box>
    :
    <Box>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
                    <Box>
                        <Typography sx={{ paddingLeft: '10px' }}>Product Format</Typography>
                    </Box>
            </Grid>
            <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                    <Box >
                        <Typography sx={{  fontWeight: '600', paddingLeft: '10px' }}>Product format string</Typography>
                    </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
                    <Box>
                        <Typography sx={{ paddingLeft: '10px' }}> {detailEquipment.productFormatString}</Typography>
                    </Box>
            </Grid>
        </Grid>
        {(productFormatFromList.length === 0 && productFormatToList ==0)?
        <Grid container >
            <Grid item xs={12} sm={12}>
                    <Box>
                        <Typography sx={{ paddingLeft: '10px' }}> The equipment function does not have a product format asssigned</Typography>
                    </Box>
            </Grid>
        </Grid>
        :
        <>
        {productFormatFromList.length>0?
            <Grid container>
                <Grid item xs={12} sm={12} sx={{ mt: 2, pl:1,pr:1}} >
                    <table style={{width:'100%'}}>
                    <tr>
                        <th style={{width:'40%'}}>Product Format {isKit? 'From':''}</th>
                        <th>Value</th>
                    </tr>
                    {productFormatFromList.map((item, index)=>{
                        let selectedDroppdownData = ""
                        let tmpData =[]
                        if(item.fkProductFormatType !== 0 && !item.allowMultiSelect){
                        selectedDroppdownData = item.childArr.find((childItem)=>childItem.pkProductFormatTypeValue ===item.fkProductFormatTypeValue)===undefined? '':
                        item.childArr.find((childItem)=>childItem.pkProductFormatTypeValue ===item.fkProductFormatTypeValue).pkProductFormatTypeValue
                        }
                        if(item.fkProductFormatType !== 0 && item.allowMultiSelect){
                            if(item.freeTextValue !==undefined && item.freeTextValue !==null && item.freeTextValue !=='' ){
                                let tmpAreaArr = item.freeTextValue.split("/")
                                if(tmpAreaArr.length >0){
                                tmpAreaArr.forEach((dropdownItem)=>{
                                    let findData = item.childArr.find((childItem)=>childItem.productFormatTypeValue ===dropdownItem)
                                    if(findData !==undefined)
                                    tmpData.push(findData)
                                })
                                }
                            }
                        }
                    return <tr>
                        <td>{item.productFormatName}</td>
                        <td>
                            {item.fkProductFormatType === null || item.fkProductFormatType ===0?
                            <TextField
                            id={item.fkProductFormat}
                            fullWidth
                            inputProps={{ maxLength: 300 }}
                            size="small"
                            label=""
                            name={item.pkProductFormatAssignment}
                            value={item.freeTextValue}
                            onChange={(e)=>onInputChange(e,index, 'from')}
                            />
                            :item.fkProductFormatType !== '' && !item.allowMultiSelect?
                            <FormControl fullWidth>
                                <InputLabel id="list-label">{item.childArr.productFormatTypeValue}</InputLabel>
                                <Select
                                    labelId="list-label"
                                    id={item.fkProductFormat}
                                    name={item.pkProductFormatAssignment}
                                    size="small"
                                    value = {selectedDroppdownData}
                                    label=""
                                    onChange={(evnt) => (handleChange(evnt,index, 'from'))}
                                >
                                    {item.childArr.map((a,index) => <MenuItem key={index} value={a.pkProductFormatTypeValue}>{a.productFormatTypeValue}</MenuItem>)}
                                </Select>
                            </FormControl>
                            : 
                            <MultiSelectProductFormat
                                error=""
                                helperText=""
                                name={'name'}
                                fullWidth
                                tmpData ={tmpData}
                                onChange={(name)=>onMultiSelectChange(name,index, 'from')}
                                data={{ label: "", options: item.childArr }}
                            />
                            }
                        </td>
                        </tr>
                    })}
                    </table>
                </Grid>
            </Grid>
        :''}
        {productFormatToList.length>0?
            <Grid container >
                <Grid item xs={12} sm={12} sx={{ mt: 2, pl:1,pr:1}}>
                    <table style={{width:'100%'}}>
                    <tr>
                        <th style={{width:'40%'}}>Product Format {isKit? 'To':''}</th>
                        <th>Value</th>
                    </tr>
                    {productFormatToList.map((item, index)=>{
                        let selectedDroppdownData = ""
                        let tmpData =[]
                        if(item.fkProductFormatType !== 0 && !item.allowMultiSelect){
                        selectedDroppdownData = item.childArr.find((childItem)=>childItem.pkProductFormatTypeValue ===item.fkProductFormatTypeValue)===undefined? '':
                        item.childArr.find((childItem)=>childItem.pkProductFormatTypeValue ===item.fkProductFormatTypeValue).pkProductFormatTypeValue
                        }
                        if(item.fkProductFormatType !== 0 && item.allowMultiSelect){
                            if(item.freeTextValue !==undefined && item.freeTextValue !==null && item.freeTextValue !=='' ){
                                let tmpAreaArr = item.freeTextValue.split("/")
                                if(tmpAreaArr.length >0){
                                tmpAreaArr.forEach((dropdownItem)=>{
                                    let findData = item.childArr.find((childItem)=>childItem.productFormatTypeValue ===dropdownItem)
                                    if(findData !==undefined)
                                    tmpData.push(findData)
                                })
                                }
                            }
                        }
                    return <tr>
                        <td>{item.productFormatName}</td>
                        <td>
                            {item.fkProductFormatType === null || item.fkProductFormatType ===0?
                            <TextField
                            id={item.fkProductFormat}
                            fullWidth
                            inputProps={{ maxLength: 300 }}
                            size="small"
                            label=""
                            name={item.pkProductFormatAssignment}
                            value={item.freeTextValue}
                            onChange={(e)=>onInputChange(e,index, 'to')}
                            />
                            :item.fkProductFormatType !== '' && !item.allowMultiSelect?
                            <FormControl fullWidth>
                                <InputLabel id="list-label">{item.childArr.productFormatTypeValue}</InputLabel>
                                <Select
                                    labelId="list-label"
                                    id={item.fkProductFormat}
                                    name={item.pkProductFormatAssignment}
                                    value = {selectedDroppdownData}
                                    label=""
                                    onChange={(evnt) => (handleChange(evnt,index, 'to'))}
                                >
                                    {item.childArr.map((a,index) => <MenuItem key={index} value={a.pkProductFormatTypeValue}>{a.productFormatTypeValue}</MenuItem>)}
                                </Select>
                            </FormControl>
                            : 
                            <MultiSelectProductFormat
                                error=""
                                helperText=""
                                name={'name'}
                                tmpData ={tmpData}
                                onChange={(name)=>onMultiSelectChange(name,index,'to')}
                                data={{ label: "", options: item.childArr }}
                            />
                            }
                        </td>
                        </tr>
                    })}
                    </table>
                </Grid>
            </Grid>
        :''}
        <Grid container sx={{mt: 2}}>
            <Grid item pt={3} sm={12} >
                <Box display="flex" justifyContent="flex-end">
                {(isPFUpdated||isPFKitUpdated) ? <CircularProgress size={25} /> :
                    <Button
                    size="small"
                    variant="contained"
                    sx={{ mr: 4, mb: 2}}
                    onClick={handleSave}
                    >
                        Save
                    </Button>
                }
                </Box>
            </Grid>
        </Grid>
        </>
        }
    </Box>
    }
  </>
}