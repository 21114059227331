import { equipmentAPI, API } from "api";

export const getAllEqByCompanyAPI = (a, b, c) => {
    let url;
    if (b && c) {
        url = `fkcompany=${a}&isarchive=${b}&isemptylocation=${c}`;
    } else if (c && !b) {
        url = `fkcompany=${a}&isemptylocation=${c}`;
    } else if (b && !c) {
        url = `fkcompany=${a}&isarchive=${b}`;
    } else if (!b && !c) {
        url = `fkcompany=${a}`;
    }
    return equipmentAPI.get(`api/equipmentbycompany?${url}`)
        .then((res) => res);
};
export const createFLAPI = (payload) => {
    return API.post("/api/functionallocation/add", payload)
        .then((res) => res);
};

export const getAllFLAPI = (a) => {
    return API.get(`api/functionallocation/getallbyref?FkCompany=${a}&FkArea=0&page=0&size=0`)
        .then((res) => res);
};

export const getAllSUAPI = () => {
    return API.get(`api/speedunit/getallactive?page=0&size=0`)
        .then((res) => res);
};

// export const getAllConditionAPI = () => {   
//     return API.get(`api/condition/getallactive?page=0&size=0`)
//         .then((res) => res);
// };

export const getAllStatusAPI = () => {
    return API.get(`api/status/getallactive?page=0&size=0`)
        .then((res) => res);
};

export const getEqbyIdAPI = (payload) => {
    return equipmentAPI.get(`/api/equipment/getequipment/${payload}`)
        .then((res) => res);
};

export const createEQAPI = (payload) => {
    return equipmentAPI.post("/api/equipment/createequipment/add", payload)
        .then((res) => res);
};

export const updateEQAPI = (id, payload) => {
    return equipmentAPI.put(`/api/equipment/update/update/${id}`, payload)
        .then((res) => res);
};
export const deleteEQAPI = (id) => {
    return equipmentAPI.delete(`/api/equipment/delete/delete/${id}`)
        .then((res) => res);
};
export const updateFLlocationAPI = (id, payload) => {
    return API.put(`/api/functionallocation/update/${id}`, payload)
        .then((res) => res);
}
export const getParentEquipmentAPI = (payload) => {
    let url;
    if (!payload.FkArea && payload.FkFunctionalLocation)
        url = `FkEquipment=${payload.equipment ? payload.equipment:0}&FkArea=0&FkFunctionalLocation=${payload.FkFunctionalLocation}`;
    else
        url = `FkEquipment=${payload.equipment ? payload.equipment:0}&FkArea=${payload.FkArea}&FkFunctionalLocation=${payload.FkFunctionalLocation}`;

    return equipmentAPI.get(`/api/equipment/getequipmentparent?${url}`)
        .then((res) => res);
};

export const checkFunctionLocationAPI = (payload) => {
    let url = `/api/equipment/isshowfunctionallocationalert/${payload.id}?FkNewFunctionalLocation=${payload.FkNewFunctionalLocation}&FkNewStatus=${payload.FkNewStatus}&FkNewFunction=${payload.FkNewFunction}`;
    return equipmentAPI.get(url)
        .then((res) => res);
};
export const hasChildrenAPI = (samid, formid) => {
    let url = `/api/equipment/haschildrenfordrmr/${samid}?withArchive=false&formType=${formid}`;
    return equipmentAPI.get(url)
        .then((res) => res);
};
export const getChildrenAPI = (samid, formid) => {
    let url = `/api/equipment/getchildrenfordrmr/${samid}?withArchive=false&formType=${formid}`;
    return equipmentAPI.get(url)
        .then((res) => res);
};

export const getAllEqByCompanyExcelAPI = (a, b, c) => {
    let url;
    if (b && c) {
        url = `fkcompany=${a}&isarchive=${b}&isemptylocation=${c}`;
    } else if (c && !b) {
        url = `fkcompany=${a}&isemptylocation=${c}`;
    } else if (b && !c) {
        url = `fkcompany=${a}&isarchive=${b}`;
    } else if (!b && !c) {
        url = `fkcompany=${a}`;
    }
    return equipmentAPI.get(`api/equipmentbycompany/getexportexcel?${url}`)
        .then((res) => res);
};