import { actionTypes } from "../State/actions";
export const initialState = {
    equipmentType: [],
    equipmentColById:[],
    activeColumnNameList:[]
};

const equipmentTypeReducer = (state = initialState, action) => {
  console.log("My Action Columns", action.type);
  switch (action.type) {
    case actionTypes.EQUIPMENT_TYPE_GET_REQUEST:
      return {
        ...state,
        isequipmentTypeLoading: true,
      };
    case actionTypes.EQUIPMENT_TYPE_GET_RECEIVE:
      let equipmentData = action.payload.equipmentData
      return {
        ...state,
        equipmentType: equipmentData.map((dt, index) => ({ ...dt, id: index })),
        isequipmentTypeLoading: false,
      };

    case actionTypes.EQUIPMENT_TYPE_GET_FAIL:
      return {
        ...state,
        isequipmentTypeLoading: false,
       
      };
      case actionTypes.EQUIPMENT_COLUMN_BY_ID_TYPE_GET_REQUEST:
      return {
        ...state,
        isequipmentColByIdLoading: true,
      };
      case actionTypes.EQUIPMENT_COLUMN_BY_ID_TYPE_GET_RECEIVE:
        console.log("Dynamic Data Form", action.payload)

        let equipmenColbyIdtData = action.payload.equipmentTypeColResponse
        
        return {
          ...state,
          equipmentColById: equipmenColbyIdtData.map((dt, index) => ({ ...dt, id: index })),
          isequipmentTypeLoading: false,
        };
        // EQUIPMENT_COLUMN_BY_ID_TYPE_GET_FAIL
        case actionTypes.EQUIPMENT_COLUMN_BY_ID_TYPE_GET_FAIL:
          return {
            ...state,
            isequipmentColByIdLoading: false,
           
          };
          case actionTypes.COLUMN_TYPE_GET_REQUEST:
            return {
              ...state,
              isColumnTypeLoading: true,
            };
          case actionTypes.COLUMN_TYPE_GET_RECEIVE:
            let ColumnMapperData=action.payload.dsColumnMappper;
            console.log("ColbyIdtData", ColumnMapperData)
            return {
              ...state,
              activeColumnNameList: ColumnMapperData.map((dt, index) => ({ ...dt, id: index })),
              isColumnTypeLoading: false,
            };
          case actionTypes.COLUMN_TYPE_GET_FAIL:
            return {
              ...state,
              isColumnTypeLoading: false,
            };
        
    default:
      return state;
  }
};

export default equipmentTypeReducer;
