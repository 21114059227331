import PropTypes from 'prop-types';
import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { Collapse, List, ListItemText, Typography, ListItemIcon, Divider } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import { useTheme } from '@mui/material/styles';

// project imports
import NavItem from './NavItem';
import NavGroup from './NavGroup';

// assets
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useDispatch } from '../../../../../../node_modules/react-redux/es/exports';
import { actions } from 'store/Reducer/actions';

// ===========================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||=========================== //

const NavCollapse = ({ menu, level }) => {
    const classes = {};
    const theme = useTheme();
    const { drawerOpen, openItem } = useSelector((state) => state.common);

    const getstate = () =>{
        let data = false;
        if(menu && menu?.children && menu.children.type === 'group'){
           data = menu?.children?.children?.some(elem => openItem.includes(elem.id));
        }else{
            data = menu?.children?.some(elem => openItem.includes(elem.id));
            if(!data){
                data = menu?.children?.some(elem =>{
                   return elem?.children?.some(ele => openItem.includes(ele.id))
                });
            }
        }
        return data;
    }
    const [open, setOpen] = React.useState(getstate());    
    const [selected, setSelected] = React.useState(null);

    const isPartOfCurrentMenu = useCallback((data) => {
        if (!data) {
            return null;
        }
        let isAncestor = false;
        // eslint-disable-next-line no-restricted-syntax
        for (const element of data) {
            if ('children' in element) {
                isAncestor = isPartOfCurrentMenu(element.children) || isAncestor;
            }
            if (element?.children?.some(elem => openItem.includes(elem.id))) {
                isAncestor = true;
                break;
            }
        }
        return isAncestor;
    }, [openItem]);

    useEffect(() => {
        const data = menu?.children?.some(elem => openItem.includes(elem.id));
        if (data || isPartOfCurrentMenu([menu])) {
            setOpen(true);
            setSelected(menu.id);
        } else {
            setOpen(false);
            setSelected(null)
        }
    }, [openItem, menu]);

    const menus = menu.children.map((item) => {
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={level + 1} menuId={menu.id} />;
            case 'item':
                if(!drawerOpen) return null;
                return <NavItem key={item.id} item={item} level={level + 1} />;
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });
    const dispatch = useDispatch();

    const GetIcon = (menu) => {
        const Icon = menu.icon
        return <Icon
        onClick={()=>{
            if(!drawerOpen) dispatch(actions.openDrawer({ drawerOpen: true }));
        }}
        style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} />
    }

    const itemHandler = (id) => {
        setOpen(!open);
        setSelected(!selected ? id : null);
       // dispatch(actions.activeItem({ openItem: [id] }));
    };

 

    const textColor = '#fff';
    const iconSelectedColor = '#fff';

    return (
        <>
            <ListItemButton
                className={level > 1 ? classes.listItemNoBack : classes.listItem}
                selected={selected === menu.id}
                onClick={() => itemHandler(menu.id)}
                sx={{
                    zIndex: 1201,
                    pl: drawerOpen ? `${level * 20}px` : 1.5,
                    py: !drawerOpen && level === 1 ? 1.25 : 1,
                    ...(drawerOpen && {
                        '&:hover': {
                            bgcolor: 'primary.lighter'
                        },
                        '&.Mui-selected': {
                            bgcolor: 'primary.lighter',
                            borderRight: `2px solid ${theme.palette.primary.main}`,
                            color: iconSelectedColor,
                            '&:hover': {
                                color: iconSelectedColor,
                                bgcolor: 'primary.lighter'
                            }
                        }
                    }),
                    ...(!drawerOpen && {
                        '&:hover': {
                            bgcolor: 'transparent'
                        },
                        '&.Mui-selected': {
                            '&:hover': {
                                bgcolor: 'transparent'
                            },
                            bgcolor: 'transparent'
                        }
                    })
                }}
            >
                {menu.icon && (
                    <ListItemIcon
                        sx={{
                            color: selected === menu.id ? iconSelectedColor : textColor,
                            minWidth: 28,
                            ...(!drawerOpen && {
                                borderRadius: 1.5,
                                width: 36,
                                height: 36,
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': {
                                    bgcolor: 'secondary.lighter'
                                }
                            }),
                            ...(!drawerOpen &&
                                selected === menu.id && {
                                bgcolor: 'primary.lighter',
                                '&:hover': {
                                    bgcolor: 'primary.lighter'
                                }
                            })
                        }}
                    >
                        {GetIcon(menu)}
                    </ListItemIcon>
                )}
                {drawerOpen && <ListItemText
                    sx={{ color: selected === menu.id ? iconSelectedColor : textColor }}
                    primary={   
                         <Typography variant="h6" sx={{ 
                            color: selected === menu.id ? iconSelectedColor : textColor, 
                            fontWeight: selected === menu.id ? 600 : 400,
                            }}>
                            {menu.title}
                        </Typography>
                    }
                />}
                
                {drawerOpen && (open ? (
                    <ExpandLessOutlinedIcon sx={{ color: selected === menu.id ? iconSelectedColor : textColor }} stroke={1.5} size="1rem" className={level > 1 ? classes.collapseIconSub : classes.collapseIcon} />
                ) : (
                    <ExpandMoreOutlinedIcon sx={{ color: selected === menu.id ? iconSelectedColor : textColor }} stroke={1.5} size="1rem" className={level > 1 ? classes.collapseIconSub : classes.collapseIcon} />
                ))}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.collapseWrapper}>
                    { drawerOpen && menus}
                </List>
            </Collapse>
            <Divider sx={{ backgroundColor: `${theme.palette.common.lightGray}` }}/>
        </>
    );
};

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number
};

export default NavCollapse;
