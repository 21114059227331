import React, { useEffect, useState} from 'react';
import { useSearchParams} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MainCard from 'components/MainCard';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import MultiSelect from 'components/Form/MultiSelect';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {
    Accordion, AccordionDetails, AccordionSummary, Typography, Divider,
    Stack, List, ListItem, FormControlLabel, Paper, Grid, Badge, TextField, Checkbox, IconButton, Autocomplete,
    Button, FormControl, InputLabel, MenuItem, Select, LinearProgress
} from '@mui/material';

import ListItemButton from '@mui/material/ListItemButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SearchEquipment from 'containers/SearchViews/SearchEquipment'
import StyledDataGrid from 'components/DataGrid/index';
import Link from '@mui/material/Link';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';

import { getAllActiveCompanyAction } from 'containers/Management/Companies/state/actions';
import {
    getRegionByIdAction, formDocumentAction, submitMRAction, updateAction, reSubmitAction,
    completeAction, downloadDocAction, deleteDocAction, searchContactAction, cancelAction, decisionAction,
    formInitialAction, transferAction, formMrfAction
} from './state/actions';
import AlertModal from 'components/AlertModal';
import DynamicTable from './DynamicTable'
import DeleteModal from 'components/DeleteModal';
import FormIntial from '../FormIntial'
import ConfirmationPage from '../ConfirmationPage'
import moment from 'moment';
import { CircularProgress } from '@mui/material/index';
import { actions } from '../../../store/Reducer/actions';
import NewMRRequest from './NewMRRequest';


const AccordionSummaryRev = styled(AccordionSummary)({
    flexDirection: 'row-reverse',
    alignItems: "start",
    '& > div': { margin: '0 !important' }
});
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function MRRequestPrint({ setPageView, needBack = false, mrfId }) {
    const dispatch = useDispatch();
    const [comment, setComment] = useState('');
    const [historyExpanded, setHistoryExpanded] = React.useState(true);
    const { decodedToken } = useSelector((state) => state.token);
    const { companyLists = [] } = useSelector((state) => state.company);
    const { regionData = {}, formUploadData, docUrl, isDocDownloaded, isDocLoading,
        isDeleted, contactList = [], isContactLoading, PkForm, detailData, isDetailLoading,
        initialData = {}, isFormInitialLoading, showNotification } = useSelector((state) => state.mrfReducer);

    const [company, setCompany] = useState('');
    const [reasonForRequest, setReasonForRequest] = useState('');
    const [samComment, setSamComment] = useState('');
    const [isTpm, setIsTpm] = useState(false);
    const [isContactShow, setContactShow] = useState(true);
    const [contactValue, setContactValue] = useState({});
    const [selectedNameList, setSelectedNameList] = useState([]);
    const [selectedName, setSelectedName] = useState([]);
    const [finalNameList, setfinalNameList] = useState([]);
    const [selectedContact, setSelectedContact] = useState({});
    const [openAlertModal, toggleAlertModal] = useState({});
    const [files, setFiles] = useState('');
    const [FormId, setFormId] = useState('');
    const [pageView,setPageViews] = useState('forPrint');
    const [view, setView] = useState('main');
    const [openDeleteModal, toggleDeleteModal] = useState({});
    const [decision, setDecision] = useState('');
    const [isEdit, setIsEdit] = useState(true);
    const [isAdminEdit, setIsAdminEdit] = useState(true);
    const [mrData, setMrData] = useState({});
    const [documentList, setDocumentList] = useState([]);
    const [formDisabled, setFormDisabled] = useState(false);
    const [notificationAction, setNotificationAction] = useState('');
    const [regionName, setRegionName] = useState('');
    const [isDisableMrDecision, setIsDisableMrDecision] = useState(true);
    const [isError, setIsError] = useState(false);
    const [transferall, setTransferall] = useState(false);
    const [requestValidityDate, setRequestValidityDate] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [deleteDocId, setDeleteDocId] = useState('');
    const [showComplete, setShowComplete] = useState(false);
    const { roles } = useSelector((state) => state.token);
    
    const [initialInfox, setInitialInfox] = useState({});
    const [headerformList, setHeaderformList] = useState([]);
    const [FormPrintList, setFormPrintList] = useState([]);
    const [HeaderList, setFromHeaderList] = useState([]);
    const [FormPrint10, setFormPrintCount10] = useState(false);
    const [FormPrint20, setFormPrintCount20] = useState(false);
    const [FormPrint40, setFormPrintCount40] = useState(false);
    const [FormPrint60, setFormPrintCount60] = useState(false);
    const [FormPrint80, setFormPrintCount80] = useState(false);
    const breakline = '\u000A';
    const [searchParams, setSearchParams] = useSearchParams();
    const [CurrmrfId, setMrfId] = useState('');
    const mrUrlid =  searchParams.get("id");
    const { drawerOpen } = useSelector((state) => state.common);


    const [rowsData, setRowsData] = useState([{
        pkEquipment: '',
        location: '',
        transfer: false,
        transferredBy: '',
    }]);
    const decisionList = {
        label: "Decision",
        options: [
            { label: 'Select...', value: "" },
            { label: 'Accept', value: "Accept" },
            { label: 'Reject', value: "Reject" },
        ]
    }
    const ReasonList = {
        label: "Reason for Request",
        options: [
            { label: 'Capacity increase', value: "Capacity increase" },
            { label: 'Replacement', value: "Replacement" },
            { label: 'Special project', value: "Special project" }
        ]
    }
    useEffect(() => {
        if (PkForm !== undefined && PkForm !== '') {
            setIsEdit(false)
            setFormId(PkForm)
        }
    }, [PkForm]);

    useEffect(() => {
        if (mrfId !== undefined && mrfId !== '') {
            setFormId(mrfId)
            setIsEdit(false)
        }
    }, [mrfId]);

    useEffect(() => {
        if (mrUrlid !== undefined && mrUrlid !== null && mrUrlid !== '') {
            console.log("mrUrlid 1", mrUrlid)
            setFormId(mrUrlid)
            setIsEdit(false)
            dispatch({type:'GET_MR_INITIAL_DATA'})
            dispatch(formMrfAction(mrUrlid))
        }
    }, [mrUrlid]);

    useEffect(() => {
        dispatch(getAllActiveCompanyAction())
        dispatch({ type: "CLEAR_EQ_DATA" })
        dispatch({ type: "CLEAR_MR_DATA" })
    }, []);

    useEffect(() => {
        setRegionName(regionData.regionName)
    }, [regionData]);


    const handleMREdit = (mrfId) => {
        setMrfId(mrfId);
        dispatch(formMrfAction(mrfId))
        setPageView('newMrfW')
    }

    useEffect(() => {
        if (FormId === '' && mrfId === undefined) {
            dispatch(formInitialAction())
        }
    }, []);

    useEffect(() => {
        if (isFormInitialLoading || isDetailLoading)
            setIsPageLoading(true)
        else
            setIsPageLoading(false)
    }, [isFormInitialLoading, isDetailLoading]);

    useEffect(() => {
        if (isDeleted) {
            let tmpList = documentList.filter((item) => item.pkFormDocument !== deleteDocId)
            setDocumentList([...tmpList])
        }
    }, [isDeleted]);

    useEffect(() => {
        if (showNotification) {
            setPageView('confirmation')
        }
    }, [showNotification]);
    useEffect(() => {
        if (formUploadData?.pkFormDocument !== undefined) {
            if (documentList.length > 0) {
                let temp = documentList.find(({ pkFormDocument }) => pkFormDocument === formUploadData.pkFormDocument);
                if (temp === undefined)
                    setDocumentList([...documentList, formUploadData])
            }
            else
                setDocumentList([formUploadData])
        }
    }, [formUploadData?.pkFormDocument]);

    const handleDateChange = (evt) => {
        const { name, value } = evt.target;
        setRequestValidityDate(value)
    }

    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(actions.openDrawer({ drawerOpen: !open }));
    };



      const onCompanyChange = (e, value) => {
        setCompany(value);
        let getSelectedRow = companyLists.find((item) => item.pkCompany === value.id)
        setIsTpm(getSelectedRow.isTpm)
        dispatch(getRegionByIdAction(getSelectedRow.fkRegion));
    }
    const onReasonChange = ({ target }) => {
        setReasonForRequest(target.value)
    }
    const onDecisionChange = ({ target }) => {
        setDecision(target.value)
        setIsDisableMrDecision(false)

        const tmpData = [...rowsData]
        tmpData.map((item) => {
            item.mrDecision = target.value === 'Accept' ? 'Accept' : target.value === 'Reject' ? 'Cancel' : ''
            item.mrDecisionDate = new Date()
            return item
        })
        setRowsData([...tmpData])
    }
    const validateSubmit = (type) => {
        let message = ''
        if (company === '') {
            setIsError(true)
            message = `${message}
            <br/>- Requestor company is mandatory`
        }
        if (!requestValidityDate) {
            setIsError(true)
            message = `${message}
            <br/>- Request Request Valid Date is mandatory`
        }
        if (reasonForRequest === '') {
            setIsError(true)
            message = `${message}
            <br/>- Reason For Request is mandatory`
        }
        if (finalNameList.length === 0) {
            setIsError(true)
            message = `${message}
            <br/>- Donor contact(s)  is mandatory`
        }

        rowsData.forEach((asd) => {
            const { pkEquipment, urgencyLevel, deliveryRequiredByDate, mrDecision } = asd
            if (pkEquipment === '') {
                setIsError(true)
                message = `${message}
                <br/>- Requested equipment existing SAM ID is mandatory`
            }
            if (urgencyLevel === '' || urgencyLevel === undefined) {
                setIsError(true)
                message = `${message}
                <br/>- Requested equipment Urgency Level is mandatory`
            }
            if (!deliveryRequiredByDate) {
                setIsError(true)
                message = `${message}
                <br/>- Requested equipment Delivery Required By Date is mandatory`
            }
            if ((type === 'decision' || type === 'update') && (mrDecision === null || mrDecision === '-' || mrDecision === '')) {
                setIsError(true)
                message = `${message}
                <br/>- Requested equipment Decision is mandatory for SAM ID ${pkEquipment}.`
            }
        })
        if (!comment && (type === 'submit' || type === 'resubmit')) {
            setIsError(true)
            message = `${message}\r\n
            <br/>- Justification / Information / Message is mandatory`
        }
        if (type === 'decision' || type === 'update' || type === 'complete') {
            if (decision === '') {
                setIsError(true)
                message = `${message}
                <br/>- Decision is mandatory.`
            }
        }
        if (decision === 'Reject' && samComment === '') {
            setIsError(true)
            message = `${message}
            <br/>- SAM comment is mandatory.`
        }
        return message
    }
    const handleSubmit = () => {
        let message = validateSubmit('submit')
        if (message !== '')
            toggleAlertModal({ open: true, message: message });
        else {
            setIsError(false)
            const eqDTO = rowsData.filter(({ pkEquipment }) => pkEquipment).map((asd) => {
                return {
                    pkEquipment: asd.pkEquipment,
                    deliveryRequiredByDate: asd.deliveryRequiredByDate,
                    urgencyLevel: asd.urgencyLevel,
                    equipmentComment: asd.equipmentComment
                }
            })
            const sidList = []
            finalNameList.forEach((item) => sidList.push(item.emailAddress))
            const docList = []
            documentList.forEach((item) => {
                let tmpObj = {}
                tmpObj = {
                    pkFormDocument: item.pkFormDocument,
                    fkForm: item.fkForm,
                    documentName: item.documentName,
                    documentMimeType: item.documentMimeType,
                    uploadedByDisplayName: item.uploadedByDisplayName,
                    insertDate: item.insertDate
                }
                docList.push(tmpObj)
            })
            const dd = {
                "stepId": 0,
                "stateId": 0,
                "formType": "MR",
                "fkCompany": company.id,
                "requesterCompanyIsTPM": isTpm ? 1 : 0,
                "comment": comment,
                "reasonForRequest": reasonForRequest,
                "requestValidityDate": requestValidityDate,
                "reservationEquipmentDTOs": eqDTO,
                "documentDTOs": [...docList],
                "contactSid": [...sidList],
            }
            dispatch(submitMRAction(dd))
            setNotificationAction('submit')
        }
    };
    const toggleTPM = (evnt) => {
        setIsTpm(evnt.target.checked ? true : false)
    }
    const handleFileUpload = ({ target }) => {
        let name = target.files[0].name
        let filesize = target.files[0].size
        let filesizeKb = filesize / 1024

        let lastIndx = name.lastIndexOf('.')
        let fileExt = name.substring(lastIndx + 1, name.length)
        let acceptableFiles = ['doc', 'docx', 'xlsx', 'pdf', 'msg', 'txt', 'jpg', 'jpeg']
        let showAlert = false
        let message = ''
        if (filesizeKb > 4096) {
            showAlert = true
            message = `Max file size (Kb) :4096`
        }
        if (!acceptableFiles.includes(fileExt)) {
            showAlert = true
            if (message !== '')
                message = `${message} <br/> Only file of type: MS Word(.doc / .docx); MS Excel(.xlsx);
                Adobe PDF(.pdf); MS Outlook Message(.msg); text file(.txt) and Picture(.jpg /.jpeg) are authorized`
            else
                message = `Only file of type: MS Word(.doc / .docx); MS Excel(.xlsx);
           Adobe PDF(.pdf); MS Outlook Message(.msg); text file(.txt) and Picture(.jpg /.jpeg) are authorized`
        }
        if (showAlert)
            toggleAlertModal({ open: true, message: message });
        else {
            const reader = new FileReader()
            reader.readAsBinaryString(target.files[0])
            reader.onloadend = () => {
                setFiles(target.files[0])
            }
        }
    }
    const saveFiles = () => {
        if (files === '')
            toggleAlertModal({ open: true, message: 'Please upload file.' });
        else {
            let fd = new FormData
            fd.append('Source', 'step1Attachement')
            fd.append('Session', 0)
            fd.append('file', files)
            dispatch(formDocumentAction(fd, FormId))
        }
    }
    const toggleTransfer = (evnt) => {
        const tmpData = [...rowsData]
        setTransferall(evnt.target.checked)
        tmpData.map((item) => {
            if (!item.transferredBy || !item.transferredDate)
                item.transfer = evnt.target.checked ? true : false
            return item
        })
        setRowsData([...tmpData])
    }
    const handleTransfer = (index) => {
        const rowsInput = [...rowsData];
        rowsInput[index]['showTransferDetails'] = true
        rowsInput[index]['transferredBy'] = decodedToken.name
        rowsInput[index]['transferredDate'] = new Date()
        setRowsData(rowsInput);
        handleTransferProcess()
    }
    const handleSelectedTransfer = () => {
        const checkTransferrowsData = rowsData.filter((item) => item.transfer)
        if (checkTransferrowsData.length === 0) {
            alert("No Equipment Selected");
        }
        else {
            let result = window.confirm("Do you confirm all selected item transfer?It will not be possible to undo this action")
            if (result) {
                const rowsInput = [...rowsData];
                rowsInput.map((item) => {
                    if (item.transfer) {
                        item.showTransferDetails = true
                        item.transferredBy = decodedToken.name
                        item.transferredDate = new Date()
                    }

                    return item
                })
                setRowsData(rowsInput);
                handleTransferProcess()
            }
        }
    }

    useEffect(() => {
        return () => {
            dispatch({ type: 'DOWNLOAD_DOC_FAILED' });
        }
    }, []);

    const downloadDocClick = (row) => {
        if (FormId === '')
            alert("Please submit Machine Reservation request before dowloading document")
        else {
            if (row.documentContent && row.documentContent !== 'MA==') {
                const a = document.createElement("a");
                a.href = `data:application/octet-stream;base64,${row.documentContent}`;
                a.download = row.documentName;
                a.click();
            } else {
                let downloadObj = {
                    docId: row.pkFormDocument,
                    formId: FormId
                }
                dispatch(downloadDocAction(downloadObj))
            }
        }
    };

    const onDeleteClick = (e, data) => {
        toggleDeleteModal({ open: true, data });
    }
    const deleteConfirm = (data) => {
        toggleDeleteModal({});
        setDeleteDocId(data.pkFormDocument)
        dispatch(deleteDocAction(data.pkFormDocument));
    }

    const toggleSearchModal = (isShow) => {
        setContactShow(!isShow)
    }
    const openSearch = () => {
        toggleSearchModal(true)
        setSelectedNameList([])
    }
    const handleSearch = () => {
        if (contactValue.contact === undefined || contactValue.contact === '')
            toggleAlertModal({ open: true, message: 'A value is needed in the search textbox' });
        else {
            setSelectedNameList([])
            dispatch(searchContactAction(contactValue.contact))
        }
    }
    useEffect(() => {
        if (contactList.length > 0) {
            setSelectedNameList([...contactList])
        }
    }, [contactList]);

    const handleDelete = () => {
        let tmpList = finalNameList.filter((item) => item.value !== selectedContact.value)
        setfinalNameList([...tmpList])
    }

    const handleAdd = () => {
        if (selectedName.length === 0)
            toggleAlertModal({ open: true, message: 'Select a name from the list' });
        else {
            let filterName = [...finalNameList, ...selectedName]
            let newfilterName = filterName.filter((item, index, self) => index === self.findIndex((ele) => ele.value === item.value))
            newfilterName.map((item) => {
                let newFiledName = `isSelected${item.value}`
                item[newFiledName] = false
                return item
            })
            setfinalNameList([...newfilterName])
            setContactShow(true)
        }
    }

    const onContactChange = ({ target }) => {
        setContactValue({ [target.name]: target.value })
    }

    const onNameChange = (name, allSelected) => {
        setSelectedName([...name.name])
    }

    const handleListClick = (selectedItem) => {
        finalNameList.map((item) => {
            if (item.value === selectedItem.value)
                item[`isSelected${item.value}`] = true
            else
                item[`isSelected${item.value}`] = false
            return (item)
        })
        setfinalNameList([...finalNameList])
        setSelectedContact(selectedItem)
    }

    useEffect(() => {
        if (isDocDownloaded) {
            let a = document.createElement('a');
            a.href = docUrl;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    }, [isDocDownloaded]);

    const columnc = [
        { field: 'id', headerName: `Sl No`, width: 10, sortable: false,flex:0.1,height:60,headerClassName: "dgHeader",hide:true},
        { field: 'stepname', headerName: `Current Step`, width: 10, sortable: false,flex:0.12,height:60,headerClassName: "dgHeader"},
        { field: 'statename', headerName: `Current State`, width: 6, sortable: false,flex:0.075,height:60,headerClassName: "dgHeader"},
        { field: 'formtype', headerName: `Form Type`, width: 10, sortable: false,flex:0.12  ,height:60,headerClassName: "dgHeader"},        
        { field: 'pkform', headerName: `Form #`, width: 10, sortable: false,flex:0.05,height:80,headerClassName: "dgHeader"},
        { field: 'requestdate', headerName: `Request Date`, width: 10, sortable: false,flex:0.1,height:60,headerClassName: "dgHeader",hide: true },
        { field: 'lastupdatedate', headerName: `Last Update Date`, width: 10, sortable: false,flex:0.1,height:60,headerClassName: "dgHeader",hide: true },
        { field: 'name', headerName: `Name`, width: 10, sortable: false,flex:0.2,height:60,headerClassName: "dgHeader"},
        { field: 'email', headerName: `Email`, width: 10, sortable: false,flex:0.2,height:60,headerClassName: "dgHeader"},
        { field: 'alias', headerName: `Alias`, width: 10, sortable: false,flex:0.1,height:60,headerClassName: "dgHeader"},        
        { field: 'title', headerName: `Title`, width: 10, sortable: false,flex:0.1,height:60,headerClassName: "dgHeader"},
        { field: 'department', headerName: `Department`, width: 10, sortable: false,flex:0.1,height:60,headerClassName: "dgHeader"},
        { field: 'country', headerName: `Country`, width: 10, sortable: false,flex:0.1,height:60,headerClassName: "dgHeader"},
        { field: 'company', headerName: `Company`, width: 10, sortable: false,flex:0.1,height:60,headerClassName: "dgHeader"}                                                                
    ];


    const column = [
        { field: 'id', headerName: `Sl No`, width: 10, sortable: false,flex:0.1,height:60,headerClassName: "dgHeader",hide: true },
        { field: 'pkequipment', headerName: `SAM ID`, width: 80, sortable: false,headerClassName: "dgHeader",},
        { field: 'drNumber', headerName: `Dr#`, width: 80, headerClassName: "dgHeader", },
        { field: 'x',  renderHeader: () => (
                <Box>
                    {'Company'}{'\n'}{'Location'}
                </Box>
          ),headerName:`Sample`,sortable: false,height:60, width: 120, headerClassName: "dgHeader", 
        renderCell: ({ row }) => (
            <Stack sx={{ mt: 1 }} direction="column" spacing={1}>
                <Grid>{`${row.companyName}`}</Grid>
                <Grid>{`${row.location}`}</Grid>
            </Stack>
        )},
        { field: 'y', headerName: `Area Function OEM Model`, width: 140, headerClassName: "dgHeader", renderCell: ({ row }) => (
            <Stack sx={{ mt: 1 }} direction="column" spacing={1}>
                <Grid>{`${row.area}`}{` `}{`${row.functionName}`}</Grid>
                <Grid>{`${row.oemName}`}{` `}{`${row.modelName}`}</Grid>
            </Stack>
        ) },
        { field: 'z', headerName: `Serial No sapP1Ref`, width: 80, headerClassName: "dgHeader", renderCell: ({ row }) => (
            <Stack sx={{ mt: 1 }} direction="column" spacing={1}>
                <Grid>{`${row.serialNo}`}</Grid>
                <Grid>{`${row.sapP1Ref}`}</Grid>
            </Stack>
        ) },
        { field: 'urgencyLevel', headerName: `Urgency level`, width: 80, headerClassName: "dgHeader", },
        { field: 'deliveryRequiredByDate', headerName: `Delivery required by`, width: 120, headerClassName: "dgHeader", },
        { field: 'a', headerName: `Comment`, width: 160, headerClassName: "dgHeader",renderCell: ({ row }) => (
            <Stack sx={{ mt: 1 }} direction="column" spacing={1}>
                <Grid zeroMinWidth>
                      {`${row.equipmentComment}`}
                </Grid>
            </Stack>
        ) },
        { field: 'mrDecision', headerName: `Decision`, width: 60, headerClassName: "dgHeader", },
        { field: 'mrDecisionDate', headerName: `Decision Date`, width: 120, headerClassName: "dgHeader", },
        { field: 'Status', headerName: `FinalStatus`, width: 80, headerClassName: "dgHeader", },
        { field: 'Rule', headerName: `Rule`, width: 80, headerClassName: "dgHeader", },
    ];

    const columnd = [
        {
            field: 'command',
            headerName: 'Command',
            flex: 1,
            disableReorder: true,
            hideSortIcons: true,
            sortable: false,
            filterable: false,
            headerClassName: "dgHeader",
            renderCell: ({ row }) => (
                <Stack direction="row" spacing={1}>
                    <IconButton color="error" aria-label="Edit">
                        <ClearOutlinedIcon onClick={(e) => onDeleteClick(e, row)} />
                    </IconButton>
                </Stack>
            )
        },
        { field: 'pkFormDocument', headerName: `document Id`, flex: 1, headerClassName: "dgHeader", },
        {
            field: 'documentName', headerName: `Document name`, flex: 2, headerClassName: "dgHeader", renderCell: ({ row }) => (
                <Stack direction="row" spacing={1}>
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => downloadDocClick(row)}
                    >{row.documentName}</Link>
                </Stack>
            )
        },
        {
            field: 'insertDate', headerName: `Uploaded date`, flex: 1, headerClassName: "dgHeader", renderCell: ({ row }) => (
                <Stack direction="row" spacing={1}>
                    {moment(row.insertDate).format('MM/DD/YYYY')}
                </Stack>
            )
        },
        { field: 'uploadedByDisplayName', headerName: `Uploaded by`, flex: 2, headerClassName: "dgHeader", },
    ];

    let initialInfo = {}
    if (FormId === '' && mrfId === undefined)
    {
        if(detailData.formHeader!==undefined)
        {
            initialInfo = { formInfo: { ...detailData.formHeader }, requestorInfo: { ...detailData.formRequester } }
        }
        //initialInfo = { formInfo: { ...initialData.formHeader }, requestorInfo: { ...initialData.formRequester } }
    }
    else
    {
        initialInfo = { formInfo: { ...detailData.formHeader }, requestorInfo: { ...detailData.formRequester } }
    }    

  
    useEffect(() => {
        if ((FormId !== '' || mrfId !== undefined) && !isDetailLoading) {
            if (Object.keys(detailData).length > 0) {
                let role = roles.includes('admin')
                if (!role && detailData.formBody.step10Decision !== 'Reject') {
                    setFormDisabled(true)
                }
                if (detailData.formHeader.stepId === 5)
                    setIsEdit(true)
                    if (role && detailData.formHeader.stepId >=10)
                    {
                        setIsEdit(true)
                        setIsAdminEdit(true)
                    }
                if (detailData?.formHeader?.stepName === 'Closed')
                    setFormDisabled(true)
                setMrData({ ...detailData })
                if (detailData?.formHeader?.stepId === 20)
                    setIsDisableMrDecision(false)

                if (detailData?.formBody?.formCompanyId !== '') {
                    let getSelectedRow = companyLists.find((item) => item.pkCompany === detailData?.formBody?.formCompanyId)
                    let selectedCompany = { id: detailData?.formBody?.formCompanyId, label: getSelectedRow.longCompanyName }
                    setCompany(selectedCompany);
                }

                setComment(detailData?.formBody?.comment);
                setRegionName(detailData.formBody.formCompanyRegionName)
                setReasonForRequest(detailData.formBody.reasonForRequest)
                if (detailData.formBody.requestValidityDate !== undefined && detailData.formBody.requestValidityDate !== null) {
                    let date = detailData.formBody.requestValidityDate
                    date = date.indexOf('Z') === -1 ? date + 'Z' : date
                    setRequestValidityDate(date)
                }


                if (detailData?.formBody?.requesterCompanyIsTPM === 1)
                    setIsTpm(true)
                else
                    setIsTpm(false)

                if (detailData.formDocument?.length > 0) {
                    let tmpList = [...detailData.formDocument]
                    tmpList.map((dt, index) => {
                        dt.id = index
                        return dt
                    })
                    setDocumentList([...tmpList])
                }

                if (detailData.formContact?.length > 0) {
                    let tmpArr = []
                    detailData.formContact.forEach((item) => {
                        item.label = item.displayName || item.name
                        item.emailAddress = item.emailAddress !== undefined ? item.emailAddress : item.email
                        item.value = item.alias ? item.alias : item.email
                        let newFiledName = `isSelected${item.value}`
                        item[newFiledName] = false
                        tmpArr.push(item)
                    })
                    setfinalNameList([...tmpArr])
                }
                if (detailData.formBody.step10Decision !== undefined)
                    setDecision(detailData.formBody.step10Decision)
                if (detailData.formBody.step10Comment !== undefined)
                    setSamComment(detailData.formBody.step10Comment)
                if (detailData.formBody.step20Comment !== undefined)
                    setSamComment(detailData.formBody.step10Comment)
                if (detailData?.formHeader?.stepId === 5)
                    setDecision("")

                if (detailData.reservationEquipmentDTOs?.length > 0) {
                    let machineReservationData = [...detailData.reservationEquipmentDTOs]
                    console.log('machineReservationData',machineReservationData)
                    let chkTransfer = machineReservationData.findIndex((item) => (item.transferredBy === undefined || item.transferredBy === '') && item.mrDecision !=='Cancel')
                    if (chkTransfer <= -1)
                        setShowComplete(true)

                    machineReservationData.map((mrItem) => {
                        mrItem.company = mrItem.companyName
                        mrItem.model = mrItem.modelName
                        mrItem.functionVal = mrItem.functionName
                        mrItem.serialNumber = mrItem.serialNo
                        mrItem.p1idNumber = mrItem.sapP1Ref
                        mrItem.showTransferDetails = false
                        mrItem.samDrfref = mrItem.drNumber

                        if (mrItem.deliveryRequiredByDate !== undefined && mrItem.deliveryRequiredByDate !== null)
                            mrItem.deliveryRequiredByDate = mrItem.deliveryRequiredByDate.indexOf('Z') === -1 ? mrItem.deliveryRequiredByDate + 'Z' : mrItem.deliveryRequiredByDate

                        if (mrItem.transferredBy.trim() !== ''){
                            setIsDisableMrDecision(true)
                            mrItem.showTransferDetails = true
                        }
                            
                    })
                    setRowsData([...machineReservationData])
                    let fromPrintList=[];

                    if (machineReservationData !== undefined && machineReservationData.length>0) {
                        //Changes to be done
                        machineReservationData.forEach((item,index) => {
                            let tmpObj = {}
                            tmpObj = {
                                id:index+1,
                                pkequipment: item.pkEquipment,
                                drNumber:item.drNumber,
                                companyName: item.companyName,
                                location: item.location,
                                area:item.area,
                                functionName:item.functionName,
                                oemName:item.oemName,
                                modelName:item.modelName,
                                serialNo:item.serialNo,
                                sapP1Ref:item.sapP1Ref,
                                urgencyLevel:item.urgencyLevel,
                                deliveryRequiredByDate:item.deliveryRequiredByDate,
                                equipmentComment:item.equipmentComment,
                                mrDecision:item.mrDecision,
                                mrDecisionDate:item.mrDecisionDate    
                            }
                            fromPrintList.push(tmpObj)

                        })
                        if(fromPrintList.length>0)
                        {
                            setFormPrintList(fromPrintList);
                            if(fromPrintList.length>0)
                            {
                               setFormPrintCount10(true)
                            }
                            if(fromPrintList.length>10)
                            {
                                setFormPrintCount10(false);
                                setFormPrintCount20(true);
                            }
                            if(fromPrintList.length>20)
                            {
                                setFormPrintCount10(false);
                                setFormPrintCount20(false);
                                setFormPrintCount40(true);
                            }
                            if(fromPrintList.length>40)
                            {
                                setFormPrintCount10(false);
                                setFormPrintCount20(false);
                                setFormPrintCount40(false);
                                setFormPrintCount60(true);
                            }
                            if(fromPrintList.length>60)
                            {
                                setFormPrintCount10(false);
                                setFormPrintCount20(false);
                                setFormPrintCount40(false);
                                setFormPrintCount60(false);
                                setFormPrintCount80(true);
                            }
                        }
            
                        
                    }
            
                }
                let fromHeaderList=[];
                let fromHdrList=[];
                if (detailData.formRequester!==undefined) 
                {
                    fromHeaderList=detailData.formRequester
                    // fromHeaderList.forEach((item,index) => {
                        let tmpObj1 = {}
                        tmpObj1 = {
                            id: 1,
                            name: fromHeaderList.displayName,
                            email:fromHeaderList.email,
                            alias: fromHeaderList.alias,
                            phonenumber: fromHeaderList.phone,
                            title:fromHeaderList.title,
                            department:fromHeaderList.department,
                            country:fromHeaderList.country,
                            company:fromHeaderList.company,
                            formtype:"",
                            lastupdatedate:"",
                            pkform:"",
                            requestdate:"",
                            statename:"",
                            stepname:""
                        }                        
                         fromHdrList.push(tmpObj1)
                    // })
                    
                    //setFromHeaderList(fromHdrList);
                }
                if (detailData.formHeader!==undefined) 
                {
                    fromHeaderList=detailData.formHeader
                    // fromHeaderList.forEach((item,index) => {
                     let tmpOdjUpd=fromHdrList;
                     if(tmpOdjUpd!==undefined)   
                     {
                        tmpOdjUpd[0].formtype=fromHeaderList.formType;
                        tmpOdjUpd[0].statename=fromHeaderList.stateName;
                        tmpOdjUpd[0].stepname=fromHeaderList.stepName;
                        tmpOdjUpd[0].requestdate=fromHeaderList.requestDate;    
                        tmpOdjUpd[0].lastupdatedate=fromHeaderList.lastUpdateDate;    
                        tmpOdjUpd[0].pkform=fromHeaderList.pkForm;
                     }

                    //  if(tmpOdjUpd!==undefined)
                    //  {
                    //     tmpOdjUpd.formtype=fromHeaderList.formtype
                    //  }
                    
                    //     fromHdrList.push(tmpOdjUpd)
                    // })
                    
                    setFromHeaderList(tmpOdjUpd);
                }

            }
        }
        else
            setMrData({})
    }, [detailData]);

    const handlePrintClick = () => {
        // setMrfId(mrfId);
        // dispatch(formMrfAction(mrfId))
        //setPageViews('confirmation');
        var css = '@page { size: landscape; }',
        head = document.head || document.getElementsByTagName('head')[0],
        style = document.createElement('style');
    
    style.type = 'text/css';
    style.media = 'print';
    
    if (style.styleSheet){
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
    
    head.appendChild(style);
    
    window.print();
    }





    const handleUpdate = () => {
        let message = validateSubmit('update')
        if (message !== '')
            toggleAlertModal({ open: true, message: message });
        else {
            setIsError(false)
            const eqDTO = rowsData.filter(({ pkEquipment }) => pkEquipment).map((asd) => {
                return {
                    pkEquipment: asd.pkEquipment,
                    deliveryRequiredByDate: asd.deliveryRequiredByDate,
                    urgencyLevel: asd.urgencyLevel,
                    equipmentComment: asd.equipmentComment,
                    equipmentSAMComment: asd.equipmentSAMComment,
                    mrDecision: asd.mrDecision,
                    mrDecisionDate: asd.mrDecisionDate,
                    transferredBy: asd.transferredBy,
                    transferredDate: asd.transferredDate,
                }
            })
            const sidList = []
            finalNameList.forEach((item) => sidList.push(item.emailAddress))
            const docList = []
            documentList.forEach((item) => {
                let tmpObj = {}
                tmpObj = {
                    pkFormDocument: item.pkFormDocument,
                    fkForm: item.fkForm,
                    documentName: item.documentName,
                    documentMimeType: item.documentMimeType,
                    uploadedByDisplayName: item.uploadedByDisplayName,
                    insertDate: item.insertDate
                }
                docList.push(tmpObj)
            })

            const dd = {
                "pkForm": FormId,
                "fkCompany": company.id,
                "requesterCompanyIsTPM": isTpm ? 1 : 0,
                "comment": comment,
                "reasonForRequest": reasonForRequest,
                "requestValidityDate": requestValidityDate,
                "step10Decision": decision,
                "step10DecisionComment": samComment,
                "reservationEquipmentDTOs": eqDTO,
                "formDocuments": [...docList],
                "contactSid": [...sidList],
            }
            dispatch(updateAction(dd))
            setNotificationAction('update')
        }
    }
    const handleTransferProcess = () => {
        const eqDTO = rowsData.filter(({ pkEquipment }) => pkEquipment).map((asd) => {
            return {
                pkEquipment: asd.pkEquipment,
                deliveryRequiredByDate: asd.deliveryRequiredByDate,
                urgencyLevel: asd.urgencyLevel,
                equipmentComment: asd.equipmentComment,
                equipmentSAMComment: asd.equipmentSAMComment,
                mrDecision: asd.mrDecision,
                mrDecisionDate: asd.mrDecisionDate,
                transferredBy: asd.transferredBy,
                transferredDate: asd.transferredDate,
            }
        })
        const sidList = []
        finalNameList.forEach((item) => sidList.push(item.emailAddress))
        const docList = []
        documentList.forEach((item) => {
            let tmpObj = {}
            tmpObj = {
                pkFormDocument: item.pkFormDocument,
                fkForm: item.fkForm,
                documentName: item.documentName,
                documentMimeType: item.documentMimeType,
                uploadedByDisplayName: item.uploadedByDisplayName,
                insertDate: item.insertDate
            }
            docList.push(tmpObj)
        })

        const dd = {
            "pkForm": FormId,
            "fkCompany": company.id,
            "requesterCompanyIsTPM": isTpm ? 1 : 0,
            "comment": comment,
            "reasonForRequest": reasonForRequest,
            "requestValidityDate": requestValidityDate,
            "step10Decision": decision,
            "step10DecisionComment": samComment,
            "reservationEquipmentDTOs": eqDTO,
            "formDocuments": [...docList],
            "contactSid": [...sidList],
        }
        dispatch(transferAction(dd))
    }

    const handleReSubmit = () => {
        let message = validateSubmit('resubmit')
        if (message !== '')
            toggleAlertModal({ open: true, message: message });
        else {
            setIsError(false)
            const eqDTO = rowsData.filter(({ pkEquipment }) => pkEquipment).map((asd) => {
                return {
                    pkEquipment: asd.pkEquipment,
                    deliveryRequiredByDate: asd.deliveryRequiredByDate,
                    urgencyLevel: asd.urgencyLevel,
                    equipmentComment: asd.equipmentComment,
                    mrDecision: asd.mrDecision,
                    mrDecisionDate: asd.mrDecisionDate,
                }
            })
            const sidList = []
            finalNameList.forEach((item) => sidList.push(item.emailAddress))
            const docList = []
            documentList.forEach((item) => {
                let tmpObj = {}
                tmpObj = {
                    pkFormDocument: item.pkFormDocument,
                    fkForm: item.fkForm,
                    documentName: item.documentName,
                    documentMimeType: item.documentMimeType,
                    uploadedByDisplayName: item.uploadedByDisplayName,
                    insertDate: item.insertDate
                }
                docList.push(tmpObj)
            })
            const dd = {
                "pkForm": FormId,
                "fkCompany": company.id,
                "requesterCompanyIsTPM": isTpm ? 1 : 0,
                "comment": comment,
                "reasonForRequest": reasonForRequest,
                "requestValidityDate": requestValidityDate,
                "step10Decision": decision,
                "step10DecisionComment": samComment,
                "reservationEquipmentDTOs": eqDTO,
                "formDocuments": [...docList],
                "contactSid": [...sidList],
            }
            dispatch(reSubmitAction(dd))
            setNotificationAction('resubmit')
        }
    }

    const handleComplete = () => {
        let message = validateSubmit('complete')
        if (message !== '')
            toggleAlertModal({ open: true, message: message });
        else {
            setIsError(false)
            const eqDTO = rowsData.filter(({ pkEquipment }) => pkEquipment).map((asd) => {
                return {
                    pkEquipment: asd.pkEquipment,
                    deliveryRequiredByDate: asd.deliveryRequiredByDate,
                    urgencyLevel: asd.urgencyLevel,
                    equipmentComment: asd.equipmentComment,
                    equipmentSAMComment: asd.equipmentSAMComment,
                    mrDecision: asd.mrDecision,
                    mrDecisionDate: asd.mrDecisionDate,
                    transferredBy: asd.transferredBy,
                    transferredDate: asd.transferredDate
                }
            })
            const sidList = []
            finalNameList.forEach((item) => sidList.push(item.emailAddress))
            const docList = []
            documentList.forEach((item) => {
                let tmpObj = {}
                tmpObj = {
                    pkFormDocument: item.pkFormDocument,
                    fkForm: item.fkForm,
                    documentName: item.documentName,
                    documentMimeType: item.documentMimeType,
                    uploadedByDisplayName: item.uploadedByDisplayName,
                    insertDate: item.insertDate
                }
                docList.push(tmpObj)
            })
            const dd = {
                "pkForm": FormId,
                "fkCompany": company.id,
                "requesterCompanyIsTPM": isTpm ? 1 : 0,
                "comment": comment,
                "reasonForRequest": reasonForRequest,
                "requestValidityDate": requestValidityDate,
                "step10Decision": decision,
                "step10DecisionComment": samComment,
                "reservationEquipmentDTOs": eqDTO,
                "formDocuments": [...docList],
                "contactSid": [...sidList],
            }
            dispatch(completeAction(dd))
            setNotificationAction('complete')
        }
    }

    const handleCancel = () => {
        let message = validateSubmit('cancel')
        if (message !== '')
            toggleAlertModal({ open: true, message: message });
        else {
            setIsError(false)
            const eqDTO = rowsData.filter(({ pkEquipment }) => pkEquipment).map((asd) => {
                return {
                    pkEquipment: asd.pkEquipment,
                    deliveryRequiredByDate: asd.deliveryRequiredByDate,
                    urgencyLevel: asd.urgencyLevel,
                    equipmentComment: asd.equipmentComment,
                    equipmentSAMComment: asd.equipmentSAMComment,
                    mrDecision: asd.mrDecision,
                    mrDecisionDate: asd.mrDecisionDate,
                    transferredBy: asd.transferredBy,
                    transferredDate: asd.transferredDate
                }
            })
            const sidList = []
            finalNameList.forEach((item) => sidList.push(item.emailAddress))
            const docList = []
            documentList.forEach((item) => {
                let tmpObj = {}
                tmpObj = {
                    pkFormDocument: item.pkFormDocument,
                    fkForm: item.fkForm,
                    documentName: item.documentName,
                    documentMimeType: item.documentMimeType,
                    uploadedByDisplayName: item.uploadedByDisplayName,
                    insertDate: item.insertDate
                }
                docList.push(tmpObj)
            })
            const dd = {
                "pkForm": FormId,
                "fkCompany": company.id,
                "requesterCompanyIsTPM": isTpm ? 1 : 0,
                "comment": comment,
                "reasonForRequest": reasonForRequest,
                "requestValidityDate": requestValidityDate,
                "step10Decision": decision,
                "step10DecisionComment": samComment,
                "reservationEquipmentDTOs": eqDTO,
                "formDocuments": [...docList],
                "contactSid": [...sidList],
            }
            dispatch(cancelAction(dd))
            setNotificationAction('cancel')
        }
    }

    const handleDecision = () => {
        let message = validateSubmit('decision')
        if (message !== '')
            toggleAlertModal({ open: true, message: message });
        else {
            setIsError(false)
            const eqDTO = rowsData.filter(({ pkEquipment }) => pkEquipment).map((asd) => {
                return {
                    pkEquipment: asd.pkEquipment,
                    deliveryRequiredByDate: asd.deliveryRequiredByDate,
                    urgencyLevel: asd.urgencyLevel,
                    equipmentComment: asd.equipmentComment,
                    equipmentSAMComment: asd.equipmentSAMComment,
                    mrDecision: asd.mrDecision,
                    mrDecisionDate: asd.mrDecisionDate,
                }
            })
            const sidList = []
            finalNameList.forEach((item) => sidList.push(item.emailAddress))
            const docList = []
            documentList.forEach((item) => {
                let tmpObj = {}
                tmpObj = {
                    pkFormDocument: item.pkFormDocument,
                    fkForm: item.fkForm,
                    documentName: item.documentName,
                    documentMimeType: item.documentMimeType,
                    uploadedByDisplayName: item.uploadedByDisplayName,
                    insertDate: item.insertDate
                }
                docList.push(tmpObj)
            })
            const dd = {
                "pkForm": FormId,
                "fkCompany": company.id,
                "requesterCompanyIsTPM": isTpm ? 1 : 0,
                "comment": comment,
                "reasonForRequest": reasonForRequest,
                "requestValidityDate": requestValidityDate,
                "step10Decision": decision,
                "step10DecisionComment": samComment,
                "reservationEquipmentDTOs": eqDTO,
                "formDocuments": [...docList],
                "contactSid": [...sidList],
            }
            dispatch(decisionAction(dd))
            setNotificationAction('update')
        }
    }
    const tooltipMessage = <span><u>Info:</u><br />Max file size (Kb) :4096
        <br />Only file of type: MS Word(.doc / .docx); MS Excel(.xlsx);
        Adobe PDF(.pdf); MS Outlook Message(.msg); text file(.txt) and Picture(.jpg /.jpeg) are authorized</span>

    return <>
        {isPageLoading ? <Box><LinearProgress /></Box>
            :
            <MainCard boxShadow={pageView === 'DETAILS_MR' ? false : true} border={false}>
                <DeleteModal modalData={openDeleteModal} deleteConfirm={deleteConfirm} toggleModal={() => toggleDeleteModal({})} />
                {pageView === 'forPrint'?
                    <Box sx={{ p: 1, display: pageView === 'search' ? 'none' : 'block' }}>
                        {needBack ? <Grid item sx={{ p: 2 }} xs={12} sm={1}>
                            <Box display="flex" justifyContent="flex-end">
                                <Button onClick={handlePrintClick} onMouseEnter={handleDrawerToggle}  variant="text">Print</Button>
                            </Box>
                        </Grid> : ""}
                        <AlertModal modalData={openAlertModal} toggleModal={() => toggleAlertModal({})} />
                        <>
                            <Grid container spacing={1} display="block">
                                {/* <FormIntial formType="MR" initialInfo={initialInfo} />  */}

                                <Grid container sx={{ mt: 0 }}>
                                                    <div style={
                                                            { height: '200px',  width:`1200px`}
                                                    }>
                                                           <StyledDataGrid 
                                                            columns={columnc}
                                                            rows={HeaderList}
                                                            hideCSV={true}
                                                            showQuickFilter={false}
                                                            hideFooterPagination
                                                        />
                                                    </div>
                                                </Grid>

                            </Grid>
                            <Grid container spacing={1} sx={{ mt: 1, pl: 2, pr: 2 }}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ mt: 3 }}>
                                        <Box>
                                            <Badge badgeContent={1} color="primary" size='large'></Badge>
                                            <Typography sx={{ fontSize: '16px', fontWeight: '600', display: 'inline', ml: 2 }}>
                                                REQUEST DETAILS
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ mt: 2 }}>
                                    <Grid item xs={12} sm={12} display='flex'>
                                        <Grid item xs={12} sm={3}>
                                            <Box display="flex">
                                                <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Requestor company:</Typography>
                                                {isError && company === '' ? <span style={{ color: 'red' }}>*</span> : ''}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={9} display="flex">
                                            <Grid item xs={12} sm={6}>
                                                <Box >
                                                    <Autocomplete
                                                        disablePortal
                                                        disableClearable
                                                        size="small"
                                                        id="company"
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        disabled={formDisabled}
                                                        name="company"
                                                        value={company ? { id: company.id, label: company.label } : null}
                                                        options={companyLists.map(({ pkCompany, longCompanyName }) => ({ id: pkCompany, label: longCompanyName }))}
                                                        onChange={onCompanyChange}
                                                        renderInput={(params) => <TextField
                                                            fullWidth
                                                            maxwidth="20px"
                                                            {...params}
                                                            label={"Please select company"}
                                                        />
                                                        }
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Box sx={{ display: 'inline', ml: 3 }}>Requester Region: {regionName !== undefined ? regionName : ''}</Box>
                                            </Grid>
                                            <Grid item xs={12} sm={3} sx={{ ml: 3 }}>
                                                <FormControlLabel labelPlacement="start"
                                                    control={
                                                        <Checkbox
                                                            checked={isTpm}
                                                            name='isTpm'
                                                            id='isTpm'
                                                            onChange={(event) => toggleTPM(event)}
                                                            size="small"
                                                        />
                                                    }
                                                    label={'Is TPM'} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} style={{ display: 'flex' }} sx={{ mt: 2 }}>
                                        <Grid item xs={12} sm={3}>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>SAM MRF Ref:</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={9}>
                                            <Box>{mrData?.formBody?.samMRFRef !== undefined ? mrData.formBody.samMRFRef : 'N/A'}</Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} style={{ display: 'flex' }} sx={{ mt: 2 }}>
                                        <Grid item xs={12} sm={3}>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Request Valid up to:</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={3} display="flex">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    label="Date"
                                                    inputFormat="MM/DD/YYYY"
                                                    size="small"
                                                    fullWidth
                                                    // value={(requestValidityDate===null || requestValidityDate==='' || requestValidityDate===undefined)?null :dayjs(requestValidityDate)}
                                                    value={!requestValidityDate ? null : dayjs(requestValidityDate)}
                                                    onChange={(value) => handleDateChange({ target: { name: 'requestValidityDate', value } })}
                                                    renderInput={(params) => <TextField sx={{ mt: 2 }}
                                                        label="Date" fullWidth size="small" {...params} />}
                                                    disabled={formDisabled}
                                                />
                                            </LocalizationProvider>
                                            {!requestValidityDate && isError ? <span style={{ color: 'red' }}>*</span> : ''}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} style={{ display: 'flex' }} sx={{ mt: 2 }}>
                                        <Grid item xs={12} sm={3}>
                                            <Box display="flex">
                                                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Reason For Request:</Typography>
                                                {reasonForRequest === '' && isError ? <span style={{ color: 'red' }}>*</span> : ''}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <Box>
                                                <FormControl fullWidth>
                                                    <InputLabel id="reason-label">{ReasonList.label}</InputLabel>
                                                    <Select
                                                        labelId="reason-label"
                                                        id="reason"
                                                        name="reasonForRequest"
                                                        value={reasonForRequest}
                                                        label={ReasonList.label}
                                                        onChange={onReasonChange}
                                                        disabled={formDisabled}
                                                    >
                                                        {ReasonList.options.map((a, index) => <MenuItem key={index} value={a.value}>{a.label}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} sx={{ mt: 2 }}>
                                    <Grid item xs={12} sm={12} display="flex">
                                        <Grid item xs={12} sm={3}>
                                            <Box display="flex">
                                                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Donor Contact(s):</Typography>
                                                {finalNameList.length === 0 && isError ? <span style={{ color: 'red' }}>*</span> : ''}
                                            </Box>
                                        </Grid>
                                        {isContactShow ?
                                            <Grid item xs={12} sm={9} display="flex">
                                                <Grid xs={12} sm={6} >
                                                    <div style={{ outline: '1px solid grey', height: "200px", maxHeight: '200px', overflowY: 'auto' }} >
                                                        <List>
                                                            {finalNameList.map((item) => {
                                                                let selectedFieldName = `isSelected${item.value}`
                                                                return <ListItem>
                                                                    <ListItemButton selected={item[selectedFieldName]} onClick={() => handleListClick(item)}>{item.label}</ListItemButton>
                                                                </ListItem>
                                                            }
                                                            )}
                                                        </List>
                                                    </div>
                                                </Grid>
                                                {!formDisabled ?
                                                    <Grid>
                                                        <IconButton color="primary" aria-label="User" onClick={openSearch}>
                                                            <PersonOutlineOutlinedIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    : ''
                                                }
                                                {!formDisabled ?
                                                    <Grid >
                                                        <IconButton color="error" aria-label="Delete" onClick={handleDelete}>
                                                            <ClearOutlinedIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    : ''
                                                }
                                            </Grid>
                                            :
                                            <Grid item xs={12} sm={9} >
                                                <Grid item xs={12} sm={12} display="flex">
                                                    <Grid item xs={12} sm={2}>
                                                        <Box>
                                                            <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Step 1: Search:</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid  sx={{pr: 2}} item xs={12} sm={6}>
                                                        <TextField
                                                        label="For best results use Last Name"
                                                            id="contact"
                                                            fullWidth
                                                            size="small"
                                                            name="contact"
                                                            onChange={onContactChange}
                                                        />
                                                    </Grid>
                                                    <Grid>
                                                        <IconButton color="primary" aria-label="User" onClick={handleSearch}>
                                                            <SearchOutlinedIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid >
                                                        <IconButton color="error" aria-label="Cancel" onClick={() => toggleSearchModal(false)}>
                                                            <RemoveCircleIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12} display="flex">
                                                    <Grid item xs={12} sm={2}>
                                                        <Box>
                                                            <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Step 2: Select:</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid xs={12} sm={6}>
                                                        <Box sx={{ my: 0.25}}>
                                                            {isContactLoading ? <CircularProgress size={30} /> :
                                                                <MultiSelect
                                                                    error=""
                                                                    helperText=""
                                                                    onChange={onNameChange}
                                                                    name='name'
                                                                    data={{ label: "Contacts", options: selectedNameList }}
                                                                />
                                                            }
                                                        </Box>
                                                    </Grid>
                                                    <Grid>
                                                        <Tooltip title="Please select searched contact(s) and click on add icon">
                                                            <IconButton color="primary" aria-label="User" onClick={handleAdd}>
                                                                <AddCircleIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Box>
                                            <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Requested Equipment(s):</Typography>
                                        </Box>
                                        <Box>
                                            Use the <Link onClick={() => { setPageView('search') }} component="button" variant="body2" style={{ fontSize: '16px' }}> Search Eqipment</Link> page from the SAM database to find the SAM ID of the equipment
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ mt: 3 }}>
                                                {FormPrint10?
                                                <Grid container sx={{ mt: 0 }}>
                                                    <div style={
                                                            { height: '800px',  width:`1250px`}
                                                    }>
                                                           <StyledDataGrid 
                                                            columns={column}
                                                            rows={FormPrintList}
                                                        />
                                                    </div>
                                                </Grid>
                                                :''}
                                                {FormPrint20?
                                                <Grid container sx={{ mt: 0 }}>
                                                    <div style={
                                                            { height: '1300px',  width:`1250px`}
                                                    }>
                                                           <StyledDataGrid
                                                            columns={column}
                                                            rows={FormPrintList}
                                                        />
                                                    </div>
                                                </Grid>
                                                :''}
                                                {FormPrint40?
                                                <Grid container sx={{ mt: 0 }}>
                                                    <div style={
                                                            { height: '2400px',  width:`1250px`}
                                                    }>
                                                           <StyledDataGrid
                                                            columns={column}
                                                            rows={FormPrintList}
                                                        />
                                                    </div>
                                                </Grid>
                                                :''}
                                               {FormPrint60?
                                                <Grid container sx={{ mt: 0 }}>
                                                    <div style={
                                                            { height: '3400px',  width:`1250px`}
                                                    }>
                                                           <StyledDataGrid
                                                            columns={column}
                                                            rows={FormPrintList}
                                                        />
                                                    </div>
                                                </Grid>
                                                :''}
                                               {FormPrint80?
                                                <Grid container sx={{ mt: 0 }}>
                                                    <div style={
                                                            { height: '4400px',  width:`1250px`}
                                                    }>
                                                           <StyledDataGrid
                                                            columns={column}
                                                            rows={FormPrintList}
                                                        />
                                                    </div>
                                                </Grid>
                                                :''}                                                
                                </Grid>
                                <Grid container spacing={1} sx={{ mt: 3 }}>
                                    <Grid item xs={12} sm={12} display="flex">
                                        <TextField
                                            id="justification-information-message"
                                            label="Justification/Information/Message"
                                            name="comment"
                                            multiline
                                            value={comment}
                                            onChange={(e) => { setComment(e.target.value) }}
                                            rows={4}
                                            sx={{ width: '100%' }}
                                            variant="outlined"
                                            disabled={(initialInfo?.formInfo?.stepId === 0 || initialInfo?.formInfo?.stepId === 5) ? false : true}
                                        />
                                        {(isError && comment === '') ? <span style={{ color: 'red' }}>*</span> : ''}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} sx={{ mt: 3 }}>
                                    <Grid item xs={12} sm={3}>
                                        <Box>
                                            <Typography>File attachment(s):</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        {!formDisabled ?
                                            <>
                                                <Box sx={{ mt: 1 }}>
                                                    <Tooltip title={tooltipMessage}
                                                        componentsProps={{
                                                            tooltip: {
                                                                sx: {
                                                                    backgroundColor: '#ced4da',
                                                                    color: 'black',
                                                                    fontSize: '12px'
                                                                }
                                                            }
                                                        }}><InfoIcon color='primary' /></Tooltip><input multiple type="file" onChange={handleFileUpload} />
                                                </Box>
                                                <Box sx={{ mt: 1 }}>
                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        onClick={saveFiles}>
                                                        + Add File
                                                    </Button>
                                                </Box>
                                            </>
                                            : ''
                                        }
                                        {documentList.length === 0 ?
                                            <Box sx={{ mt: 1 }}>
                                                <Typography>Current uploaded files: None</Typography>
                                            </Box>
                                            : ''}
                                    </Grid>
                                </Grid>
                                {documentList.length === 0 ? '' :
                                    <Grid container spacing={1} display="flex" justifyContent="center">
                                        <Grid item xs={12} sm={8}>
                                            <Box sx={{ mt: 1 }}>
                                                <Grid container sx={{ mt: 3 }}>
                                                    <div style={{
                                                        height: '300px',
                                                        width: '100%'
                                                    }}>
                                                        <StyledDataGrid
                                                            columns={columnd}
                                                            rows={documentList}
                                                            loading={isDocLoading}
                                                            csvOptions={{
                                                                fileName: 'Documents'
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                {initialInfo?.formInfo?.stepId === 0 ?
                                    <Grid container spacing={1}>
                                        <Grid item pt={3} sm={12}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <Button
                                                    variant="contained"
                                                    startIcon={<CheckIcon />}
                                                    sx={{ "marginRight": "1rem" }}
                                                    size="small"
                                                    onClick={handleSubmit}
                                                >
                                                    Submit
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    : ''
                                }
                                {(initialInfo?.formInfo?.stepId === 5 && !formDisabled) ?
                                    <Grid container spacing={1} sx={{ mt: 2 }}>
                                        <Grid item pt={3} sm={12} >
                                            <Box display="flex" justifyContent="flex-end">
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    startIcon={<CheckIcon />}
                                                    sx={{ "marginRight": "1rem" }}
                                                    onClick={handleReSubmit}
                                                >
                                                    Re-Submit
                                                </Button>
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    startIcon={<CancelIcon />}
                                                    sx={{ "marginRight": "1rem" }}
                                                    onClick={handleCancel}
                                                >
                                                    Cancel the Request
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    : ''
                                }
                            </Grid>
                            {(initialInfo?.formInfo?.stepId !== 0) ?
                                <Grid container spacing={1} sx={{ pl: 2, pr: 2 }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sx={{ mt: 3 }}>
                                            <Box>
                                                <Badge badgeContent={2} color="primary" size='large'></Badge>
                                                <Typography sx={{ fontSize: '16px', fontWeight: '600', display: 'inline', ml: 2 }}>
                                                    SAM Team Decision
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} sx={{ mt: 2 }}>
                                        <Grid item xs={12} sm={12} display='flex'>
                                            <Grid item xs={12} sm={2}>
                                                <Box>
                                                    <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Decision:{isError && decision === '' ? <span style={{ color: 'red' }}>*</span> : ''}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={9} display="flex">
                                                <Grid item xs={12} sm={3}>
                                                    <Box >
                                                        <FormControl fullWidth>
                                                            <InputLabel id="decision-label">{decisionList.label}</InputLabel>
                                                            <Select
                                                                labelId="decision-label"
                                                                id="decision"
                                                                name="decision"
                                                                value={decision}
                                                                label={decisionList.label}
                                                                onChange={onDecisionChange}
                                                                disabled={formDisabled || (initialInfo?.formInfo?.stepId >= 20)}
                                                            >
                                                                {decisionList.options.map((a, index) => <MenuItem key={index} value={a.value}>{a.label}</MenuItem>)}
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} sx={{ mt: 3 }}>
                                        <Grid item xs={12} sm={5}>
                                            <TextField
                                                id="sam-comment"
                                                label="Sam Comment"
                                                name="samComment"
                                                multiline
                                                value={samComment}
                                                onChange={(e) => { setSamComment(e.target.value) }}
                                                rows={4}
                                                sx={{ width: '100%' }}
                                                variant="outlined"
                                                disabled={formDisabled}
                                            />
                                        </Grid>
                                    </Grid>
                                    {(initialInfo?.formInfo?.stepId === 10 && !formDisabled) ?
                                        <Grid container spacing={1}>
                                            <Grid item pt={3} sm={12}>
                                                <Box display="flex" justifyContent="flex-end">
                                                    <Button
                                                        variant="contained"
                                                        startIcon={<CheckIcon />}
                                                        sx={{ "marginRight": "1rem" }}
                                                        size="small"
                                                        onClick={handleDecision}
                                                    >
                                                        Submit
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        : ''}
                                </Grid>
                                : ''
                            }
                            {(initialInfo?.formInfo?.stepId === 20) ?
                                <Grid container spacing={1} sx={{ mt: 3 }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sx={{ mt: 3 }}>
                                            <Box>
                                                <Badge badgeContent={3} color="primary" size='large'></Badge>
                                                <Typography sx={{ fontSize: '16px', fontWeight: '600', display: 'inline', ml: 2 }}>
                                                    SAM Team Execution
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {!formDisabled ?
                                        <Grid container>
                                            <Grid item pt={3} sm={12}>
                                                <Box display="flex" justifyContent="flex-end">
                                                    <Button
                                                        variant="contained"
                                                        startIcon={<CheckIcon />}
                                                        sx={{ "marginRight": "1rem" }}
                                                        size="small"
                                                        onClick={handleUpdate}
                                                    >
                                                        Update
                                                    </Button>
                                                    {
                                                        showComplete ?
                                                            <Button
                                                                variant="contained"
                                                                startIcon={<CheckIcon />}
                                                                sx={{ "marginRight": "1rem" }}
                                                                size="small"
                                                                onClick={handleComplete}
                                                            >
                                                                Complete
                                                            </Button>
                                                            : ''
                                                    }
                                                    <Button
                                                        size="small"
                                                        variant="outlined"
                                                        startIcon={<CancelIcon />}
                                                        sx={{ "marginRight": "1rem" }}
                                                        onClick={handleCancel}
                                                    >
                                                        Cancel Request
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        : ''}
                                </Grid>
                                : ''}
                            <Accordion expanded={historyExpanded} onChange={() => setHistoryExpanded(!historyExpanded)} sx={{ boxShadow: 'none' }}>
                                <AccordionSummaryRev
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Box sx={{ fontWeight: 600, display: 'block' }}>Form History Log</Box>
                                        </Grid>
                                    </Grid>
                                </AccordionSummaryRev>
                                <AccordionDetails>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={2}>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Date:</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>By:</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Action:</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Step:</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Comment:</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {mrData?.formLog?.length > 0 ?
                                        mrData.formLog.map((item) =>
                                            <Grid container>
                                                <Divider sx={{ my: 2, borderBottomWidth: 2, width: '100%' }} />
                                                <Grid item xs={12} sm={2}>
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px' }}>{moment(item.actionDate).format('MM/DD/YYYY')}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px' }}>{item.actionBy}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px' }}>{item.action}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px' }}>{item.step}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px' }}>{item.comment}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        )
                                        :
                                        <Grid item xs={12} sm={12}>
                                            <Divider sx={{ my: 2, borderBottomWidth: 2, width: '100%' }} />
                                            <Box>No history</Box>
                                        </Grid>
                                    }
                                </AccordionDetails>
                            </Accordion>
                        </>
                    </Box>
                    : ''}
                {pageView === 'search' ? <SearchEquipment setPageView={() => { setPageView('newMrf') }} needBack={false} /> : ''}
                {pageView === 'confirmation' ? <ConfirmationPage formType="CR" notificationAction={notificationAction} formId={mrfId} setPageView={setPageViews} /> : ''}
                {/* {pageView === 'newMrf' ? <NewMRRequest setPageView={() => { setPageView('newMrf') }} needBack={true}  mrfId={FormId} /> : ''} */}
                {pageView === 'DETAILS_MR' ? <NewMRRequest mrfId={mrfId} setView={() => { setMrfId(''); setView('dashboard') }} needBack={true} /> : ''}
            </MainCard>
        }
    </>
}