
import { createAreaAPI, updateAreaAPI, deleteAreaAPI, getAreaListAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';
import { gettFnstrAction } from '../../state/actions';

export const actionTypes = {
    AREA_GET_REQUEST: "AREA_GET_REQUEST",
    AREA_GET_RECEIVE: "AREA_GET_RECEIVE",
    AREA_GET_FAIL: "AREA_GET_FAIL"
};

const requestArea = () => ({
    type: actionTypes.AREA_GET_REQUEST,
});
const receiveArea = (payload) => ({
    type: actionTypes.AREA_GET_RECEIVE,
    payload,
});
const failureArea = (payload) => ({
    type: actionTypes.AREA_GET_FAIL,
    payload,
});


export const getAreaAction = () => {
    return async (dispatch) => {
        dispatch(requestArea());
        try {
            const response = await getAreaListAPI();
            if (response?.status === 200) {
                dispatch(receiveArea(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureArea(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const createAreaAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestArea());
        try {
            const response = await createAreaAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Area added successfully"));
            }
            dispatch(gettFnstrAction());
            dispatch(getAreaAction());
        } catch (error) {
            dispatch(failureArea(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateAreaAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestArea());
        try {
            const response = await updateAreaAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Area updated successfully"));
            }
            dispatch(gettFnstrAction());
            dispatch(getAreaAction());
        } catch (error) {
            dispatch(failureArea(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deleteAreaAPIAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestArea());
        try {
            const response = await deleteAreaAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Area deleted successfully"));
            }
            dispatch(gettFnstrAction());

        } catch (error) {
            dispatch(failureArea(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
