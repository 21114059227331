import { actionTypes } from "./actions";

export const initialState = {
  templateUploadSuccess: false,
  isTemplateLoading: false,
};

// ===========================|| COMMON REDUCER ||=========================== //

const updateTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.UPDATE_TEMPLATE_PENDING:
      return {
        ...state,
        isTemplateLoading: true,
      };
      case actionTypes.UPDATE_TEMPLATE_RECEIVED:
      return {
        ...state,
        templateUploadSuccess:action.payload,
        isTemplateLoading: false,
      };
      case actionTypes.UPDATE_TEMPLATE_FAILED:
      return {
        ...state,
        isTemplateLoading: false,
      };
    default:
      return state;
  }
};

export default updateTemplateReducer;
