import { actionTypes } from "./actions";

export const initialState = {  
  statusList: [],
  isStatusLoading: false,
  addStatus:'',
  updateStatus:'',
  deleteStatus:'',
};
  const statusReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.STATUS_REQUEST:
        return {
          ...state,
          isStatusLoading: true,
        };
      case actionTypes.STATUS_RECIEVE:
        return {
          ...state,
          statusList: action.payload.map((dt, index) => ({ ...dt, id: index })),
          isStatusLoading: false,
        };
       
      case actionTypes.STATUS_FAIL:
        return {
          ...state,        
          isStatusLoading: false
        };
      case actionTypes.CREATE_STATUS_PENDING:
        return {
          ...state,
        };
      case actionTypes.CREATE_STATUS_RECEIVED:
        return {
          ...state,
          addStatus: action.payload,
        };
      case actionTypes.CREATE_STATUS_FAILED:
        return {
          ...state,        
        };
      case actionTypes.UPDATE_STATUS_PENDING:
        return {
          ...state,
        };
      case actionTypes.UPDATE_STATUS_RECEIVED:
        return {
          ...state,
          updateStatus: action.payload,
        };
      case actionTypes.UPDATE_STATUS_FAILED:
        return {
          ...state,        
        };
      case actionTypes.DELETE_STATUS_PENDING:
        return {
          ...state,
        };
      case actionTypes.DELETE_STATUS_RECEIVED:
        return {
          ...state,
          deleteStatus: action.payload,
        };
      case actionTypes.DELETE_STATUS_FAILED:
        return {
          ...state,        
        };
      case 'CLEAR_STATUS':
        return {
          ...state,
          statusList: []
        };
      default:
        return state;
    }
  };

export default statusReducer