import { getAllMachineTypeAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    MACHINE_TYPE_GET_REQUEST: "MACHINE_TYPE_GET_REQUEST",
    MACHINE_TYPE_GET_RECEIVE: "MACHINE_TYPE_GET_RECEIVE",
    MACHINE_TYPE_GET_FAIL: "MACHINE_TYPE_GET_FAIL",
    MACHINE_TYPE_STOP_LOADING: "MACHINE_TYPE_STOP_LOADING"
  };

const requestMachineType= () => ({
    type: actionTypes.MACHINE_TYPE_GET_REQUEST,
});
const receiveMachineType = (payload) => ({
    type: actionTypes.MACHINE_TYPE_GET_RECEIVE,
    payload,
});
const failureMachineType = (payload) => ({
    type: actionTypes.MACHINE_TYPE_GET_FAIL,
    payload,
});
export const getMachineTypeAction = (filterdata) => {
    return async (dispatch) => {
        dispatch(requestMachineType());
        try {
            const response = await getAllMachineTypeAPI(filterdata);
            if (response?.status === 200) {
                dispatch(receiveMachineType( response?.data?.data));
            }
        } catch (error) {
            dispatch(failureMachineType(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};