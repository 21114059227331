
// import { actionTypes } from "./actions";
import { searchEqByDispAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    EQ_SEARCH_REQUEST: "EQ_SEARCH_REQUEST",
    EQ_SEARCH_RECEIVE: "EQ_SEARCH_RECEIVE",
    EQ_SEARCH_FAIL: "EQ_SEARCH_FAIL",
    EQ_BYUSER_REQUEST: "EQ_BYUSER_REQUEST",
    EQ_BYUSER_RECEIVE: "EQ_BYUSER_RECEIVE",
    EQ_BYUSER_FAIL: "EQ_BYUSER_FAIL"
  };

const requestEqForDisposal= () => ({
    type: actionTypes.EQ_SEARCH_REQUEST,
});
const receiveEqForDisposal = (payload) => ({
    type: actionTypes.EQ_SEARCH_RECEIVE,
    payload,
});
const failureEqForDisposal = (payload) => ({
    type: actionTypes.EQ_SEARCH_FAIL,
    payload,
});

export const searchEqForDisposal = (payload, page, size, withoutEquipment = false) => {
    return async (dispatch) => {
        dispatch(requestEqForDisposal());
        try {
            const response = await searchEqByDispAPI(payload, page, size, withoutEquipment);
            if (response?.status === 200) {
                dispatch(receiveEqForDisposal(response?.data));
            }
        } catch (error){
            dispatch(failureEqForDisposal(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}



const requestEqByUserDisposal= () => ({
    type: actionTypes.EQ_BYUSER_REQUEST,
});
const receiveEqByUserDisposal = (payload) => ({
    type: actionTypes.EQ_BYUSER_RECEIVE,
    payload,
});
const failureEqByUserDisposal = (payload) => ({
    type: actionTypes.EQ_BYUSER_FAIL,
    payload,
});

export const getEqByUserDisposal = (payload, page, size, withoutEquipment = false) => {
    return async (dispatch) => {
        dispatch(requestEqByUserDisposal());
        try {
            const response = await searchEqByDispAPI(payload, page, size, withoutEquipment);
            if (response?.status === 200) {
                dispatch(receiveEqByUserDisposal(response?.data));
            }
        } catch (error){
            dispatch(failureEqByUserDisposal(error?.response?.data?.data));
            if (error?.response) {
               // dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                //  dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

