import { useEffect, useState } from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Checkbox, CircularProgress, Divider,LinearProgress,FormControlLabel, FormControl, InputLabel, MenuItem, 
    Select,Stack, Link, Box, TextField } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip  from '@mui/material/Tooltip';
import moment from 'moment'
import dayjs from 'dayjs';

import { getEqbyIdAction,hasChildrenAction, getChildrenAction } from '../../SearchViews/EquipmentByCompany/state/actions';
import { getFlbyIdAction } from "../DisposalRequest/stateNew/actions";
import { getAllActiveCompanyAction } from "containers/Management/Companies/state/actions";
import { getAreaAction } from "containers/Management/FunctionalStructure/Area/state/actions";
import { getFunctionAction } from "containers/Management/FunctionalStructure/Function/state/actions";
import { getModelAction } from "containers/Management/Models/state/actions";
import { getOemAction } from 'containers/Management/OEM/state/actions';

function TableRows({ loading, rowsData, isadminEdit,deleteTableRows, handleChange, onSamIdBlur, handlekeyPress,
    isEdit,mrDecisionList, formDisabled,isDisableMrDecision, isError,urgencyLevels, stepId, handleTransfer,getChildren}) {
        return (
        rowsData.map((data, index) => {
            const { p1idNumber, serialNumber, pkEquipment, equipmentComment, deliveryRequiredByDate,
                location, company, area, functionVal,oemName, model, mrDecisionDate, mrDecision,transfer, urgencyLevel, 
                transferredDate, transferredBy, showTransferDetails, samDrfref, equipmentSAMComment,showChildren} = data;
                return (
                <tr valine="top" style={{ borderBottom: '1px solid #aab2b34f', backgroundColor: '#eaf2f3f0', width: '100%' }} key={index}>
                    <td className="align-top" style={{ width: '5%' }}>{index !== 0 && isEdit? <button className="btn btn-outline-danger btn-sm " onClick={() => (deleteTableRows(index))}>x</button> : ''}</td>
                    <td className="align-top">
                        {!isEdit?
                            <TextField
                            id={'pkEquipment'}
                            fullWidth
                            size="small"
                            disabled={true}
                            label=""
                            name="pkEquipment"
                            value={pkEquipment}
                            error={false}
                        />
                        :
                            loading ? <Box sx={{ display: 'flex' }}>
                            <CircularProgress />
                            </Box> 
                        :
                        <>
                        <TextField
                            id='pkEquipment'
                            fullWidth
                            size="small"
                            label=""
                            name="pkEquipment"
                            value={pkEquipment}
                            onBlur={onSamIdBlur}
                            onChange={(evnt) => (handleChange(index, evnt))}
                            error={false}
                            onKeyUp={(event) => handlekeyPress(event)}
                        />
                        {showChildren?
                            <Link onClick={()=>getChildren(pkEquipment,index)}  component="button"
                                variant="body2" style={{fontSize:'16px'}}> Add children</Link>
                            :''
                        }
                        </>
                        }
                    </td>
                    <td className="align-top" style={{ width: '12%' }}>
                        <label>{samDrfref}</label>
                    </td>
                    <td className="align-top" style={{ width: '12%' }}>
                        <label>{company}<br/> {location}</label>
                    </td>
                    <td className="align-top" style={{ width: '12%' }}>
                        <label>{area}<br/> {functionVal} <br/>{oemName} &nbsp; {model}</label>
                    </td>
                    <td className="align-top" style={{ width: '12%' }}>
                        <div >{serialNumber}  </div>
                        <div >{p1idNumber}  </div>
                    </td> 
                    <td className="align-top" style={{ width: '13%' }}>
                        <div style={{display: "flex" }}>
                            <FormControl fullWidth>
                                <InputLabel id="urgency-label">{urgencyLevels.label}</InputLabel>
                                <Select
                                    labelId="urgency-label"
                                    id="urgencyLevel"
                                    name="urgencyLevel"
                                    size= "small"
                                    value ={(urgencyLevel===null || urgencyLevel===undefined || urgencyLevel.trim() === '')?'-' :urgencyLevel.trim()}
                                    label={urgencyLevels.label}
                                    onChange={(evnt) => (handleChange(index, evnt))}
                                    disabled={(formDisabled || stepId >=10) && isadminEdit}
                                >
                                    {urgencyLevels.map((a,index) => <MenuItem key={index} value={a.label}>{a.label}</MenuItem>)}
                                </Select>
                            </FormControl>
                            {isError && (urgencyLevel ===undefined || urgencyLevel === null || urgencyLevel === '')? <span style={{ color: 'red' }}>*</span> :''}
                        </div>
                    </td>
                    <td className="align-top">
                        <span style={{display: "flex" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    label="Date"
                                    inputFormat="MM/DD/YYYY"
                                    size="small"
                                    fullWidth
                                    value={(deliveryRequiredByDate===null || deliveryRequiredByDate==='' || deliveryRequiredByDate===undefined)?null :dayjs(deliveryRequiredByDate)}
                                    onChange={(value) => handleChange(index, { target: { name: 'deliveryRequiredByDate', value } })}
                                    renderInput={(params) => <TextField sx={{ mt: 2 }}
                                    label="Date" fullWidth size="small" {...params} />}
                                    disabled={(formDisabled || stepId >=10) && isadminEdit}
                                />
                            </LocalizationProvider>
                            {(isError && (deliveryRequiredByDate === '' || deliveryRequiredByDate === undefined))? <span style={{ color: 'red' }}>*</span> :''}
                        </span>
                    </td>
                    <td className="align-top" style={{ width: '12%' }}>
                        <TextField
                            id="comment-static"
                            label=""
                            multiline
                            value={equipmentComment}
                            InputLabelProps={{ shrink: true }}
                            name="equipmentComment"
                            onChange={(evnt) => (handleChange(index, evnt))}
                            sx={{ width: '100%' }}
                            variant="outlined"
                            disabled={formDisabled}
                        />
                        {!isEdit?
                            <div style={{paddingTop:'10px'}}>
                                <TextField
                                    id="comment-static"
                                    label=""
                                    multiline
                                    value={equipmentSAMComment}
                                    InputLabelProps={{ shrink: true }}
                                    name="equipmentSAMComment"
                                    onChange={(evnt) => (handleChange(index, evnt))}
                                    sx={{ width: '100%' }}
                                    variant="outlined"
                                    disabled={formDisabled}
                                />
                            </div>
                        :''}
                    </td>
                    {stepId>=10?
                    <>
                        <td>
                            <div>
                                <div style={{display:'flex'}}>
                                <FormControl size="small" sm={{ width: '100%' }} fullWidth>
                                    <InputLabel id="mrDecisionList-label">{mrDecisionList.value}</InputLabel>
                                    <Select
                                        labelId="mrDecisionList-label"
                                        id="drDecision"
                                        name="mrDecision"
                                        value ={(mrDecision=== null || mrDecision=== undefined || mrDecision=== '')?'-' : mrDecision}
                                        label={mrDecisionList.value}
                                        onChange={(evnt) => (handleChange(index, evnt))}
                                        disabled={(formDisabled || isDisableMrDecision)&& isadminEdit}
                                    >
                                        {mrDecisionList.map((a,index) => <MenuItem key={index} value={a.value}>{a.value}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                    {isError && (mrDecision === '-' || mrDecision ===null || mrDecision ==='' )? <span style={{ color: 'red' }}>*</span> :''}
                                </div>
                                <div  style={{paddingTop:'10px'}}>
                                    <TextField
                                        id={'mrDecisionDate'}
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                        label=""
                                        name="mrDecisionDate"
                                        value={(mrDecisionDate!=='' && mrDecisionDate !==null) ?moment(mrDecisionDate).format('DD/MM/YYYY') :''}
                                        error={false}
                                    />
                                </div>
                            </div>
                        </td>
                        {stepId >=20?
                        <td>
                            <div>
                                {
                                    !showTransferDetails?
                                    <div style={{display:"flex"}}>
                                        <Stack direction="row" spacing={1}>
                                            <Link
                                                component="button"
                                                variant="body2"
                                                style={{fontSize:'16px'}}
                                                onClick={() => handleTransfer(index)}
                                            >
                                            Transfer
                                            </Link>
                                        </Stack>
                                        <FormControlLabel labelPlacement="start"
                                            control={
                                                <Checkbox
                                                    checked={transfer? true : false}
                                                    name='transfer'
                                                    id='transferid'
                                                    onChange={(event) => {
                                                        handleChange(index, event)
                                                    }}
                                                    size="small"
                                                    disabled={formDisabled}
                                                />
                                            }
                                            label={''} />
                                    </div>
                                    
                                :
                                <>
                                    <span>{(transferredBy!== '' && transferredBy !==undefined)? transferredBy :''}</span>
                                    <TextField
                                        id={'transferredDate'}
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                        label=""
                                        name="transferredDate"
                                        value={(transferredDate!=='' && transferredDate !==null && transferredDate !==undefined)?moment(transferredDate).format('DD/MM/YYYY') :''}
                                        error={false}
                                    />
                                </>
                                }
                            </div>
                        </td>
                        :''}
                    </>
                    :''}
                </tr>
            )
        })
    )
}

function DynamicTable({ rowsData, setRowsData, isEdit,formDisabled, isDisableMrDecision,isError, 
    transferall, setTransferall,toggleTransfer, stepId, handleTransfer, handleSelectedTransfer }) {
        const menu = useSelector((state) => state.common);
        const { drawerOpen } = menu;
    

    const { equipmentWithId = {}, getEqLoading,hasChildren, checkChildrenLoading,isChildrenLoading,
    childEquipmentList=[]   } = useSelector((state) => state.equipment);
    const { flByIdList = {}, isflByIDLoading } = useSelector((state) => state.flByID);
    const { companyList = [] } = useSelector((state) => state.company);
    const { areaList = [], isAreaLoading } = useSelector((state) => state.area);
    const { functionListNoD : functionList = [] } = useSelector((state) => state.function);
    const {modelListNoD: modelList = [] } = useSelector((state) => state.model);
    const { oemList = [], isOemLoading } = useSelector((state) => state.oem);
   
    const urgencyLevels =[
        {id:1, label: 'Low'},
        {id:2, label: 'Medium'},
        {id:3, label: 'Immediate'},
    ]

    const mrDecisionList =[
        {value: '-'},
        {value: 'Accept'},
        {value: 'On Hold'},
        {value: 'Cancel'},
    ]

    useEffect(() => {
        dispatch(getAllActiveCompanyAction());
        dispatch(getAreaAction());
        dispatch(getFunctionAction());
        dispatch(getModelAction());
        dispatch(getOemAction());
    }, [])
    
    useEffect(() => {
        if (equipmentWithId.fkFunctionalLocation && !getEqLoading) {
            dispatch(getFlbyIdAction(equipmentWithId.fkFunctionalLocation));
        }
    }, [equipmentWithId.fkFunctionalLocation, getEqLoading])

    useEffect(() => {
        if (equipmentWithId.pkEquipment && !getEqLoading && !isOemLoading && isEdit && !checkChildrenLoading) {
            const location = flByIdList.functionalLocationName;
            const dt = JSON.parse(JSON.stringify(rowsData));
            const { p1idNumber, serialNumber, pkEquipment,
                fkCompany, fkArea, fkFunction, fkModel, comment,fkOem} = equipmentWithId;
            const company = fkCompany && companyList.length ? companyList.find(({ pkCompany }) => fkCompany === pkCompany).companyName : "";
            const area = fkArea && areaList.length ? areaList.find(({ pkArea }) => pkArea === fkArea).areaName : "";
        
            const functionVal = fkFunction && functionList.length ? functionList.find(({ pkFunction }) => pkFunction === fkFunction)?.functionName : "";
            const model = fkModel && modelList.length ? modelList.find(({ pkModel }) => pkModel === fkModel).modelName : "";
            const oemName = fkOem && oemList.length ? oemList.find(({ pkOem }) => pkOem === fkOem).oemName : "";
            const equipmentComment = comment !== null? comment : '';
            
            let samDrfref = ''
            
            if(equipmentWithId.equipmentForDisposalResponseDTO !== null)
                samDrfref = equipmentWithId.equipmentForDisposalResponseDTO.samDrfref
            const showChildren = hasChildren?true: false
            if(dt[dt.length - 1].company==null)
            {
                dt[dt.length - 1] = { ...dt[dt.length - 1], equipmentComment, serialNumber, location, pkEquipment, company, samDrfref,area, functionVal, model, oemName, p1idNumber,showChildren,hasChildren};                
                setRowsData([...dt]);
            }    

        }
    }, [equipmentWithId.pkEquipment, getEqLoading, flByIdList.functionalLocationName, isOemLoading,checkChildrenLoading])
    
    useEffect(() => {
        if(childEquipmentList.length >0 && !isChildrenLoading){
            childEquipmentList.forEach((item)=>{
                dispatch(getFlbyIdAction(item.fkFunctionalLocation));
            })
        }
    }, [childEquipmentList, isChildrenLoading])

    useEffect(() => {
        if (childEquipmentList.length>0 && !isChildrenLoading) {
            let dt = [...rowsData]
            childEquipmentList.forEach((item)=>{
                const location = flByIdList.functionalLocationName;
            const { p1idNumber, serialNumber, pkEquipment,
                fkCompany, fkArea, fkFunction, fkModel, comment,fkOem} = item;
            const company = fkCompany && companyList.length ? companyList.find(({ pkCompany }) => fkCompany === pkCompany).companyName : "";
            const area = fkArea && areaList.length ? areaList.find(({ pkArea }) => pkArea === fkArea).areaName : "";
            const functionVal = fkFunction && functionList.length ? functionList.find(({ pkFunction }) => pkFunction === fkFunction)?.functionName : "";
            const model = fkModel && modelList.length ? modelList.find(({ pkModel }) => pkModel === fkModel).modelName : "";
            const oemName = fkOem && oemList.length ? oemList.find(({ pkOem }) => pkOem === fkOem).oemName : "";
            const equipmentComment = `Child of equipment with SAM ID ${item.fkEquipment}`

            let samDrfref = ''
            if(item.equipmentForDisposalResponseDTO !== null)
                samDrfref = item.equipmentForDisposalResponseDTO.samDrfref
            const showChildren = false
            
                let tmpObj = {
                    equipmentComment, serialNumber, location, pkEquipment, company, samDrfref,area, 
                functionVal, model, oemName, p1idNumber,showChildren
                };
                dt.push(tmpObj)
            })
            setRowsData(dt);
        }
    }, [childEquipmentList, isChildrenLoading])

    const deleteTableRows = (index) => {
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
    }

    const addTableRows = () => {
        const rowsInput = {
            pkEquipment: '',
            emailAddress: '',
            salary: '',
            netBookValue: 0,
            location: ''
        }
        setRowsData([...rowsData, rowsInput]);
    }
    const dispatch = useDispatch();
    const handlekeyPress =(e)=>{
        if (e.key== 'Enter'){
            if (e.target.value){
                dispatch(getEqbyIdAction(e.target.value));
                dispatch(hasChildrenAction(e.target.value,1))
            }
        }
    }
    const onSamIdBlur = ({ target }) => {
        if (target.value){
            dispatch(getEqbyIdAction(target.value));
            dispatch(hasChildrenAction(target.value,1))
        }
    };
    
    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const regex = /^[0-9\b]+$/;
        if (name === 'pkEquipment' && value && !regex.test(value)) {
            return;
        } 
        else if(name === 'transfer'){
            const rowsInput = [...rowsData];
            rowsInput[index][name] = evnt.target.checked? true : false;
            setRowsData(rowsInput);
            if(!evnt.target.checked)
                setTransferall(false)
            else{
                let checkTransferData = rowsInput.filter((item)=>!item.transfer)
                if(checkTransferData.length >0)
                    setTransferall(false)
                else
                    setTransferall(true)
            }
        }     
        else {
            const rowsInput = [...rowsData];
            rowsInput[index][name] = value;
            setRowsData(rowsInput);
        }
    }
    const getChildren =(samid,indx)=>{
        dispatch(getChildrenAction(samid,0 || 1))
        const rowsInput = [...rowsData];
        rowsInput[indx]['showChildren'] = false;
        setRowsData(rowsInput);
    }
  
    const thClass = {
        borderRight: '1px solid #ada0a0',
        minWidth: '150px'
    }
    const tooltipMessage =<span><u>Info:</u><br/>If equipment is currently ready for disposal</span>
    return (
        <div className="">
            <div className="row">
                <div style={{overflowX: 'auto', overflowY: 'auto', height: '400px', width: drawerOpen ? `${window.innerWidth - 375}px` : `${window.innerWidth - 220}px`}} className="col-sm-12">
                    {isflByIDLoading || getEqLoading ? <tr style={{ width: '100%' }}>
                        <Box sx={{ width: '100%', mb: 1 }}>
                            <LinearProgress />
                        </Box></tr> : ''}
                    <table className="table">
                        <thead style={{ color: '#1179b7', height: '7px' }}>
                            <tr>
                                <th></th>
                                <th style={thClass}>SAM ID</th>
                                <th style={thClass}>Dr# <Tooltip title={tooltipMessage} componentsProps={{
                                                tooltip:{
                                                    sx: {
                                                        backgroundColor: '#ced4da',
                                                        color: 'black',
                                                        fontSize:'12px'
                                                    }
                                                }
                                            }}><InfoIcon/></Tooltip></th>
                                <th style={thClass}>Company <br/> Location</th>
                                <th style={thClass}>Area <br/> Function<br/>OEM Model</th>
                                <th style={thClass}>Serial Number <br/> SAP/P1 Ref</th>
                                <th style={thClass}>Urgency Level</th>
                                <th style={thClass}>Delivery required by</th>
                                <th style={thClass}>Comment
                                    {!isEdit?<span><br/>SAM Comment</span>:''}
                                </th>
                                {stepId>=10?
                                    <>
                                        <th style={thClass}>Decision <br/> and Date</th>
                                        {stepId>=20?
                                        <th style={thClass}>Transfer and Date
                                            <div style={{display:'flex'}}>
                                                    <FormControlLabel labelPlacement="start"
                                                    control={
                                                    <Checkbox
                                                        checked={transferall}
                                                        name='transferall'
                                                        id='transferall'
                                                        onChange={(event) => {
                                                            toggleTransfer(event)
                                                        }}
                                                        size="small"
                                                    />
                                                    }
                                                    style={{whiteSpace:'nowrap',fontSize:'16px', fontWeight: '600'}}
                                                    label="Select / Deselect All" />
                                                </div>
                                            <Link onClick={handleSelectedTransfer}  
                                                component="button"
                                                variant="body2" style={{fontSize:'16px', fontWeight: '600'}}> Transfer Selected</Link> 
                                        </th>
                                        :''}
                                    </>
                                 :''
                                }
                            </tr>
                            <Divider />
                        </thead>
                        <tbody style={{ borderTop: '1px solid #85d0e1', backgroundColor: '#eaf2f3f0', height: '7px' }}>
                            <TableRows loading={getEqLoading || isflByIDLoading} 
                                onSamIdBlur={onSamIdBlur} rowsData={rowsData} deleteTableRows={deleteTableRows} 
                                getChildren={getChildren}
                                mrDecisionList={mrDecisionList}
                                handleChange={handleChange} 
                                handleTransfer={handleTransfer} 
                                handleSelectedTransfer ={handleSelectedTransfer}
                                isEdit={isEdit}
                                formDisabled={formDisabled}
                                isDisableMrDecision={isDisableMrDecision}
                                isError={isError}
                                urgencyLevels= {urgencyLevels}
                                transferall={transferall}
                                stepId={stepId}
                                handlekeyPress={handlekeyPress}
                                />
                        </tbody>
                        {(rowsData.find(({ pkEquipment }) => !pkEquipment) || getEqLoading || isflByIDLoading) ? "" :
                            <button className="btn btn-outline-primary btn-sm m-2" onClick={addTableRows} >+</button>}
                    </table>
                </div>
            </div>
        </div>
    )
}
export default DynamicTable;