
// import { actionTypes } from "./actions";
import { createRegionAPI, deleteRegionAPI, getRegionsListAPI, updateRegionAPI, getAllActiveRegionListAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    REGION_GET_REQUEST: "REGION_GET_REQUEST",
    REGION_GET_RECEIVE: "REGION_GET_RECEIVE",
    REGION_GET_FAIL: "REGION_GET_FAIL"
  };

const requestRegions= () => ({
    type: actionTypes.REGION_GET_REQUEST,
});
const receiveRegions = (payload) => ({
    type: actionTypes.REGION_GET_RECEIVE,
    payload,
});
const failureRegions = (payload) => ({
    type: actionTypes.REGION_GET_FAIL,
    payload,
});
export const getRegionAction = () => {
    return async (dispatch) => {
        dispatch(requestRegions());
        try {
            const response = await getRegionsListAPI();
            if (response?.status === 200) {
                dispatch(receiveRegions(response?.data?.data));
               // dispatch(actions.successAlert("Coutries fetched successfully"));
            }
        } catch (error) {
            dispatch(failureRegions(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getAllActiveRegionAction = () => {
    return async (dispatch) => {
        dispatch(requestRegions());
        try {
            const response = await getAllActiveRegionListAPI();
            if (response?.status === 200) {
                dispatch(receiveRegions(response?.data?.data));
               // dispatch(actions.successAlert("Coutries fetched successfully"));
            }
        } catch (error) {
            dispatch(failureRegions(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const createRegionAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestRegions());
        try {
            const response = await createRegionAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Region added successfully"));
            }
            dispatch(getRegionAction());
        } catch (error){
            dispatch(failureRegions(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateRegionAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestRegions());
        try {
            const response = await updateRegionAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Region updated successfully"));
            }
            dispatch(getRegionAction());
        } catch (error) {
            dispatch(failureRegions(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deleteRegionAPIAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestRegions());
        try {
            const response = await deleteRegionAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Region deleted successfully"));
            }
            dispatch(getRegionAction());
        } catch (error) {
            dispatch(failureRegions(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
