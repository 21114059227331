import { equipmentAPI } from "api";

export const getRequestHistoryAPI = (id) => {
    return equipmentAPI.get(`api/machinedetail/getallstgmachinetypehdrdetailbyeqmtid/${id}`)
        .then((res) => res);
};

export const getMachineDetailByIdAPI = (pkMachineTypeDetail) => {
      return equipmentAPI.get(`/api/machinedetail/gemachinedetailbymachinetypedetailid/${pkMachineTypeDetail}`)
        .then((res) => res);
}

export const getAffiliateAPI = () => {
    return equipmentAPI.get("api/dshistory/getalldsaffilate")
        .then((res) => res);
};