
import { actionTypes } from "./actions";

export const initialState = {
  eqByCompanyList: [],
};

// ===========================|| COMMON REDUCER ||=========================== //

const eqCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EQ_COMPANY_GET_REQUEST:
      return {
        ...state,
        isCompanyLoading: true,
        getEqLoading: true,
      };
    case actionTypes.EQ_COMPANY_GET_RECEIVE:
      return {
        ...state,
        eqByCompanyList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isCompanyLoading: false,
        getEqLoading: false
      };
    case actionTypes.EQ_COMPANY_GET_FAIL:
      return {
        ...state,
        isCompanyLoading: false,
        isCompanyFail: false,
        getEqLoading: false
      };
    default:
      return state;
  }
};

export default eqCompanyReducer;



export const initial = {
  FLocationList: []
};

// ===========================|| COMMON REDUCER ||=========================== //

export const FLocationReducer = (state = initial, action) => {
  switch (action.type) {
    case actionTypes.FL_GET_REQUEST:
      return {
        ...state,
        isFLLoading: true,
      };
    case actionTypes.FL_GET_RECEIVE:
      return {
        ...state,
        FLocationList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isFLLoading: false,
      };
    case actionTypes.FL_GET_FAIL:
      return {
        ...state,
        isFLLoading: false,
        isFLFail: false,
      };
    default:
      return state;
  }
};


export const SpeedUnitReducer = (state = {SpeedUnitList: []}, action) => {
  switch (action.type) {
    case actionTypes.SPUNIT_REQUEST:
      return {
        ...state,
        isSULoading: true,
      };
    case actionTypes.SPUNIT_RECEIVE:
      return {
        ...state,
        SpeedUnitList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isSULoading: false,
      };
    case actionTypes.SPUNIT_FAIL:
      return {
        ...state,
        isSULoading: false,
        isSUFail: false,
      };
    default:
      return state;
  }
};

//export FLocationReducer;

export const StatusReducer = (state = {statusList: []}, action) => {
  switch (action.type) {
    case actionTypes.GET_STATUS_RECEIVE:
      return {
        ...state,
        statusList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isStatusLoading: false
      };
      case actionTypes.GET_STATUS_REQUEST:
        return {
          ...state,
          isStatusLoading: true
        };
        case actionTypes.GET_STATUS_FAIL:
          return {
            ...state,
            isStatusLoading: false
          };
    default:
      return state;
  }
};


export const EqReducer = (state = {EqAdded: '', samIdForAddEq:'',childEquipmentList:[]}, action) => {
  switch (action.type) { 
    case 'CLEAR_EQ_ADDED':
      return {
        ...state,
        EqAdded: false,
        addEqLoading: false
      };
    case actionTypes.ADD_EQ_RECEIVE:
      return {
        ...state,
        EqAdded: true,
        addEqLoading: false
      };
      
      case actionTypes.SAM_EQ_RECEIVE:
      return {
        ...state,
        EqAdded: true,
        addEqLoading: false,
        samIdForAddEq:action.payload
      };
      case actionTypes.CHECK_CHILDREN_PENDING:
        return {
          ...state,
          checkChildrenLoading: true
        };
      case actionTypes.CHECK_CHILDREN_RECEIVED:
        return {
          ...state,
          hasChildren: action.payload,
          checkChildrenLoading: false
        };
      case actionTypes.CHECK_CHILDREN_FAILED:
        return {
          ...state,
          checkChildrenLoading: false
        };
        case actionTypes.GET_CHILDREN_PENDING:
          return {
            ...state,
            isChildrenLoading: true
          };
        case actionTypes.GET_CHILDREN_RECEIVED:
          return {
            ...state,
            childEquipmentList: action.payload,
            isChildrenLoading: false
          };
        case actionTypes.GET_CHILDREN_FAILED:
          return {
            ...state,
            isChildrenLoading: false
          };
      case  "CLEAR_EQ_DATA":
        return {
          ...state,
          addEqLoading: false,
          equipmentWithId: {},
          isShowFunctionalLocationAlert:false,
          hasChildren: false,
          childEquipmentList:[]
        };
        case  "CLEAR_FNLOC_DATA":
          return {
            isShowFunctionalLocationAlert:false
          }; 
      case actionTypes.ADD_EQ_REQUEST:
        return {
          ...state,
          addEqLoading: true,
        };
        case actionTypes.ADD_EQ_FAIL:
          return {
            ...state,
            addEqLoading: false,
          };
          case actionTypes.GET_EQ_ID_RECEIVE:
            return {
              ...state,
              equipmentWithId: action.payload,
              EqAdded: false,
              getEqLoading: false
            };
          case actionTypes.GET_EQ_ID_REQUEST:
            return {
              ...state,
              EqAdded: false,
              getEqLoading: true
            };
          case actionTypes.GET_EQ_ID_FAIL:
            return {
              ...state,
              getEqLoading: false
            };
            case actionTypes.GET_FL_ID_RECEIVE:
              return {
                ...state,
                isShowFunctionalLocationAlert: action.payload
              };
            case actionTypes.GET_FL_ID_REQUEST:
              return {
                ...state,
              };
            case actionTypes.GET_FL_ID_FAIL:
              return {
                ...state,
              };
              
    default:
      return state;
  }
};
export const initialparentState = {
  parentEquipmentList: []
};
export const parentEquipmentReducer = (state = initialparentState, action) => {
  switch (action.type) {
    case actionTypes.PARENT_EQUIPMENT_PENDING:
      return {
        ...state, 
        isParEquipmentsLoading: true,
      };
      case actionTypes.PARENT_EQUIPMENT_RECEIVED:
      return {
        ...state,
        parentEquipmentList: action.payload.map((dt, index)=>({...dt, id:index})),
        isParEquipmentsLoading : false
      };
      case actionTypes.PARENT_EQUIPMENT_FAILED:
      return {
        ...state,
        parentEquipmentList: [],
        isParEquipmentsLoading: false,
      };
      case 'CLEAR_PARENT':
        return {
          ...state,
          parentEquipmentList :[]
        };

      
      default: return{...state}
  }
};