import { API } from "api";

export const getCompanyCoordinatorListAPI = (payload) => {        
    return API.get(`/api/companycoordinator/getallbyref?FkCompany=${payload.fkCompany}&FkCountry=${payload.fkCountry}&IsMainCoordinator=${payload.isMainCoordinator}&page=0&size=0`)             
    .then((res) => res);
}

export const getAllCompanyCoordinatorListAPI = () => {        
    return API.get(`api/security/coordinator/getall/true`)               
    .then((res) => res);
}