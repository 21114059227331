import { actionTypes } from "./actions";

export const initialState = {
  detailEquipment: {},
  isDetailLoading: false,
  equipmentHistory : [],
  productFormatFromData: [],
  isPFFromLoading: false,
  productFormatToData: [],
  isPFToLoading: false,
  childData: [],
  isChildLoading: false,
  updatePFData: '',
  isPFUpdated : false,
  updatePFKitData: '',
  isPFKitUpdated : false
};

// ===========================|| COMMON REDUCER ||=========================== //

const detailEquipmentReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.EQUIPMENT_REQUEST_PENDING:
      return {
        ...state,
        isDetailLoading: true,
      };
      case actionTypes.EQUIPMENT_REQUEST_RECEIVED:
      return {
        ...state,
        detailEquipment:action.payload,
        equipmentHistory:action.payload.equipmentHistoryDTO.map((dt, index)=>({...dt, id:index})),
        isDetailLoading: false,
      };
      case actionTypes.EQUIPMENT_REQUEST_FAILED:
      return {
        ...state,
        isDetailLoading: false,
      };
    case actionTypes.GET_PF_FROM_PENDING:
      return {
          ...state,
          isPFFromLoading : true
      };
    case actionTypes.GET_PF_FROM_RECEIVED:
      return {
          ...state,
          productFormatFromData:action.payload,
          isPFFromLoading : false
      };
    case actionTypes.GET_PF_FROM_FAILED:
        return {
          ...state,
          isPFFromLoading : false
      };
    case actionTypes.GET_PF_TO_PENDING:
      return {
          ...state,
          isPFToLoading : true
      };
    case actionTypes.GET_PF_TO_RECEIVED:
      return {
          ...state,
          productFormatToData:action.payload,
          isPFToLoading : false
      };
    case actionTypes.GET_PF_TO_FAILED:
        return {
          ...state,
          isPFToLoading : false
      };
    case actionTypes.GET_FUNCTION_LOCATION_CHILD_PENDING:
      return {
          ...state,
          isChildLoading : true
      };
    case actionTypes.GET_FUNCTION_LOCATION_CHILD_RECEIVED:
      let sortedData = action.payload.sort((a,b)=>a.productFormatTypeValue>b.productFormatTypeValue?1:-1)
      return {
          ...state,
          childData: sortedData.map((dt) => ({ ...dt, label:dt.productFormatTypeValue, value:dt.pkProductFormatTypeValue})),
          isChildLoading : false
      };
    case actionTypes.GET_FUNCTION_LOCATION_CHILD_FAILED:
        return {
          ...state,
          isChildLoading : false
      };
    case actionTypes.UPDATE_PF_PENDING:
      return {
        ...state,
        isPFUpdated : true
      };
    case actionTypes.UPDATE_PF_RECEIVED:
      return {
          ...state,
          updatePFData: action.payload,
          isPFUpdated : false
      };
    case actionTypes.UPDATE_PF_FAILED:
      return {
        ...state,
        isPFUpdated : false
      };
    case actionTypes.UPDATE_PF_KIT_PENDING:
      return {
        ...state,
        isPFKitUpdated : true
      };
    case actionTypes.UPDATE_PF_KIT_RECEIVED:
      return {
          ...state,
          updatePFKitData: action.payload,
          isPFKitUpdated : false
      };
    case actionTypes.UPDATE_PF_KIT_FAILED:
      return {
        ...state,
        isPFKitUpdated : false
      };
    default:
      return state;
  }
};

export default detailEquipmentReducer;
