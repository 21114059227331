import { API,equipmentAPI } from "api";
import axios from 'axios';

export const getAllActiveStatusAPI = () => {
    return API.get("/api/status/getallactive?page=0&size=0")
                .then((res) => res);
};
export const getAllActiveFunctionAPI = () => {
    return API.get("/api/function/getallactive?page=0&size=0")
                .then((res) => res);
};
export const getAllFunctionalLoacationAPI = () => {
    return API.get("/api/functionallocation/getall?page=0&size=0")
                .then((res) => res);
};
export const getsearchEquipmentAPI = (payload, p, s) => {
    return equipmentAPI.post(`/api/equipmentcustomsearch/getflfiltered?PageNo=${p}&PageSize=${s}`, payload).then((res) => res);
};

export const getEquipmentALLExportAPI = (payload) => {
    return equipmentAPI.post(`/api/equipmentcustomsearch/export`, payload).then((res) => res);
};
 
export const getEquipmentByIdAPI = (payload) => {
    let apiUrl = `/api/equipment/getequipment/${payload}`
    return equipmentAPI.get(apiUrl).then((res) => res);
}
export const getAllDocIdAPI = (payload) => {
    let apiUrl = `/api/equipmentdocument/${payload}`
    return equipmentAPI.get(apiUrl).then((res) => res);
}
export const uploadDocAPI = (payload) => {
    let  apiUrl= `${process.env.REACT_APP_EQUIPMENT_BASE_URL}/api/equipmentdocument`
      return axios.post(apiUrl,payload,{
            headers: {"Content-Type": "multipart/form-data",
            authorization: `Bearer ${localStorage.getItem('idToken')}`
        },
        }).then(resp=>resp)
}
export const deleteDocAPI = (payload) => {
    let apiUrl = `/api/equipmentdocument/${payload.eqId}/${payload.docId}`
    return equipmentAPI.delete(apiUrl).then((res) => res);
}
export const downloadFileAPI = (payload) => {
let apiUrl = `api/equipmentdocument/${payload.eqId}/${payload.docId}`
return equipmentAPI.get(apiUrl).then((res) => res);
}
export const updateDocumentAPI = (payload) => {
    let apiUrl = `api/equipmentdocument/${payload.docId}/${payload.docName}`
    return equipmentAPI.put(apiUrl).then((res) => res);
}
export const getProductFormatAssignmentFromAPI = (id) => {
    return API.get(`/api/pfadetail/getallbyequipment/${id}`)
    .then((res) => res
    );
};
export const getProductFormatAssignmentToAPI = (id) => {
    return API.get(`/api/pfadetail/getallbykit/${id}`)
    .then((res) => res
    );
};
export const getAllProductFormatTypesAPI = (id) => {
    return API.get(`/api/productformattypevalue/getallactive?page=0&size=0`)
    .then((res) => res);
}
export const updateAPI = (id,payload) => {
    return API.put(`/api/pfa/updateforequipment/${id}`, payload).then((res) => res);
};
export const updateKitAPI = (id,payload) => {
    return API.put(`/api/pfa/updateforkit/${id}`, payload).then((res) => res);
};