import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import GeneralEditTab from './GeneralEditTab';
import EquipmentDocument from './EquipmentDocument';
import EquipmentHistory from './EquipmentHistory';
import EquipmentDisposal from './EquipmentDisposal';
import ProductFormat from './ProductFormat';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const BoxU = styled(Box)({
    color: 'darkslategray'
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function EquipmentEditTabs({parent,setEqId, company, setView,samid, onClose }) {
    const [value, setValue] = React.useState(0);
    const [isChecked, setDisposalVal] = useState(true);
    const [eq, setEq] = React.useState('');

    const setEqFromChild = (id) =>{
        setEq(id)
    }
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const theme = useTheme();

    return (
        <Card
            sx={{
                border: 'none',
                borderRadius: 2,
                borderColor: theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.grey.A800,
                boxShadow: theme.customShadows.z1 || 'inherit',
                ':hover': {
                    boxShadow: 'inherit'
                },
                '& pre': {
                    m: 0,
                    p: '16px !important',
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '0.75rem'
                }
            }}
        >
            <Box sx={{ width: '100%' }}>
            <Grid item sx={{mt:2, pl:2}}>
                       <Typography variant="h3">{(samid || eq) ?"Edit Equipment":" Create new Equipment"}</Typography>
              </Grid>
              <Grid item xs={12} sm={4} sx={{pl:2}}>
                        <Box>{(samid || eq)?`(SAM ID: ${samid || eq})`:" "}</Box>
                    </Grid>
                <Grid sx={{ pr: 2 }} container>
                    <Grid item xs={12} sm={10}></Grid>
                    {setView ? <Grid item xs={12} sm={2}>
                        <Box display="flex" justifyContent="flex-end">
                            <Button onClick={() => setView('main')} variant="outlined">Back</Button>
                        </Box>
                    </Grid> : 
                    <Grid item xs={12} sm={2}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button startIcon={<ArrowBackOutlinedIcon />} onClick={onClose} variant="outlined">Close</Button>
                    </Box>
                </Grid>
                    }
                </Grid>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="General" {...a11yProps(0)} />
                       {(samid || eq) && <Tab label="Product Format" {...a11yProps(1)} />}
                        {(samid || eq) && <Tab label="Disposal & Reservation" {...a11yProps(2)} />}
                        {(samid || eq) && <Tab label="Document" {...a11yProps(3)} />}
                        {(samid || eq) && <Tab label="History" {...a11yProps(4)} />}
                    
        
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <GeneralEditTab parent={parent} setEqId={setEqId ? setEqId : setEqFromChild} company={company} setView={setView} samid={samid || eq}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ProductFormat samid={samid || eq} />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <EquipmentHistory setView={setView} samid={samid || eq} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <EquipmentDisposal samid={samid || eq} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <EquipmentDocument setView={setView} samid={samid || eq}/>
                </TabPanel>
            </Box>
        </Card>
    );
}