import PropTypes from 'prop-types';
import { useMemo } from 'react';
// material-ui
import { useTheme, styled } from '@mui/material/styles';

import { Box } from '@mui/material';
// import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';


// project import
import DrawerHeader from './DrawerHeader';
import DrawerContent from './DrawerContent';
import CssBaseline from '@mui/material/CssBaseline';

// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import SimpleBarScroll from 'components/third-party/SimpleBar';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
// import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
// import { FindInPageOutlined } from '../../../../node_modules/@mui/icons-material/index';
// import Badge from '@mui/material/Badge';



// import { drawerWidth } from 'config';

const drawerWidth = 260;
// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

const MainDrawer = ({ open, setOpen, handleDrawerToggle, window }) => {
    const theme = useTheme();
    // const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

    // responsive drawer container
    // const container = window !== undefined ? () => window().document.body : undefined;



    const openedMixin = () => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });

    const closedMixin = () => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
    });



    // const AppBar = styled(MuiAppBar, {
    //     shouldForwardProp: (prop) => prop !== 'open',
    // })(({ theme, open }) => ({
    //     zIndex: theme.zIndex.drawer + 1,
    //     transition: theme.transitions.create(['width', 'margin'], {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.leavingScreen,
    //     }),
    //     ...(open && {
    //         marginLeft: drawerWidth,
    //         width: `calc(100% - ${drawerWidth}px)`,
    //         transition: theme.transitions.create(['width', 'margin'], {
    //             easing: theme.transitions.easing.sharp,
    //             duration: theme.transitions.duration.enteringScreen,
    //         }),
    //     }),
    // }));


    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );

    // header content
    const drawerContent = useMemo(() => <DrawerContent />, []);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Box className="dcsaca" >
                <Drawer
                    transitionDuration={1000}
                    PaperProps={{
                        sx: {
                            backgroundColor: "#263742",
                            color: "#000"
                        }
                    }} variant="permanent" open={open}>
                    <DrawerHeader handleDrawerToggle={handleDrawerToggle} open={open} />
                    <Divider />
                    <SimpleBarScroll>{drawerContent}</SimpleBarScroll>
                    
                </Drawer>
            </Box>
        </Box>
    );
};

MainDrawer.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default MainDrawer;

