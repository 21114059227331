
import { getAllConditionAPI, createConditionAPI, updateConditionAPI, deleteConditionAPI, getAllActiveConditionAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    CONDITION_PENDING: "CONDITION_PENDING",
    CONDITION_RECEIVED: "CONDITION_RECEIVED",
    CONDITION_FAILED: "CONDITION_FAILED"
  };

const requestConditions= () => ({
    type: actionTypes.CONDITION_PENDING,
});
const receiveConditions = (payload) => ({
    type: actionTypes.CONDITION_RECEIVED,
    payload,
});
const failureConditions = (payload) => ({
    type: actionTypes.CONDITION_FAILED,
    payload,
});
export const getAllConditionAction = () => {
    return async (dispatch) => {
        dispatch(requestConditions());
        try {
            const response = await getAllConditionAPI();
            if (response?.status === 200) {
                dispatch(receiveConditions(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureConditions(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getAllActiveConditionAction = () => {
    return async (dispatch) => {
        dispatch(requestConditions());
        try {
            const response = await getAllActiveConditionAPI();
            if (response?.status === 200) {
                dispatch(receiveConditions(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureConditions(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const createConditionAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestConditions());
        try {
            const response = await createConditionAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Condition added successfully"));
            }
            dispatch(getAllConditionAction());
        } catch (error) {
            dispatch(failureConditions(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateConditionAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestConditions());
        try {
            const response = await updateConditionAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Condition updated successfully"));
            }
            dispatch(getAllConditionAction());
        } catch (error) {
            dispatch(failureConditions(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deleteConditionAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestConditions());
        try {
            const response = await deleteConditionAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Condition deleted successfully"));
            }
            dispatch(getAllConditionAction());
        } catch (error) {
            dispatch(failureConditions(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
