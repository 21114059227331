import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EquipmentDetails from './EquipmentDetails';
import EquipmentHistory from './EquipmentHistory';
import EquipmentDocuments from './EquipmentDocuments';

const BoxU = styled(Box)({
    color: 'darkslategray'
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function EquipmentTabs({ setView,samid,setEqId }) {
    console.log("setView",setView)
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const theme = useTheme();

    return (
        <Card
            sx={{
                border: 'none',
                borderRadius: 2,
                borderColor: theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.grey.A800,
                boxShadow: theme.customShadows.z1 || 'inherit',
                ':hover': {
                    boxShadow: 'inherit'
                },
                '& pre': {
                    m: 0,
                    p: '16px !important',
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '0.75rem'
                }
            }}
        >
            <Box sx={{ width: '100%' }}>
                <Grid sx={{ p: 2 }} container>
                    <Grid item xs={12} sm={4}>
                        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>{setView!== undefined?'Search Equipment':'Detail Equipment'}</Typography>
                        <Box>{`(SAM ID: ${samid})`}</Box>
                    </Grid>
                    <Grid item xs={12} sm={7}></Grid>
                    <Grid item xs={12} sm={1}>
                        {setView!== undefined?
                        <Box display="flex" justifyContent="flex-end">
                            <Button onClick={() => setView('main')} variant="outlined">Back</Button>
                        </Box>
                        :''
                        }
                    </Grid>
                </Grid>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Equipment Details" {...a11yProps(0)} />
                        <Tab label="Equipment History" {...a11yProps(1)} />
                        <Tab label="Equipment Document" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <EquipmentDetails setView={setView} samid={samid}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <EquipmentHistory setView={setView} samid={samid}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <EquipmentDocuments samid={samid}/>
                </TabPanel>
            </Box>
        </Card>
    );
}