import { Box, Typography } from "@mui/material/index";



export const drRule = [
    { id: 'Cancel', label: 'Cancel' },
    { id: 'Scrap Immediately', label: 'Scrap Immediately' },
    { id: '3 Months Publishing', label: '3 Months Publishing' }
];

export const drDecision = [
    { id: 'Cancelled', label: 'Cancelled' },
    { id: 'Scrap', label: 'Scrap' },
    { id: 'Reserved', label: 'Reserved' }
];

export const requestReason = [
    { id: 'Capacity increase', label: 'Capacity increase' },
    { id: 'Replacement', label: 'Replacement' },
    { id: 'Special project', label: 'Special project' }
];

export const eqDecision = [
    { id: 'Accept', label: 'Accept' },
    { id: 'On Hold', label: 'On Hold' },
    { id: 'Cancel', label: 'Cancel' }
];


export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export function getTreeData(data) {
    const abc = [];
    data.forEach((next) => {
        const { equipmentId,
            drRule,
            equipmentOemname,
            equipmentModelName,
            serialNumber,
            sapP1ref,
            equipmentFunctionName,
            drDecision,
            requesterCompany,
            drDecisionDate,
            status,
            requesterDisplayName,
            requesterCompanyName,
            condition,
            stateId,
            creationDate,
            stepId,
            lastUpdateDate,
            equipmentCompanyName,
            equipmentOemmodel,
            reasonForRequest,
            decision,
            equipmentStatusName,
            equipmentFunction,
            requestValidityDate,
            lastUpdateBy,
            requesterCountry,
            conditionId } = next;
        if (abc.find(({ samMrfref }) => next.samMrfref === samMrfref)) {
            abc.map((a) => {
                if (a.samMrfref === next.samMrfref) {
                    const pqr = abc.findIndex((f) => next.samMrfref === f.samMrfref)
                    abc[pqr].children = [...abc[pqr].children, {
                        equipmentId,
                        equipmentFunctionName,
                        equipmentOemname,
                        equipmentModelName,
                        serialNumber,
                        sapP1ref,
                        decision,
                        drDecision,
                        drDecisionDate,
                        status,
                        equipmentOemname,
                        equipmentModelName,
                        condition,
                        drRule,
                        equipmentOemmodel,
                        equipmentCompanyName,
                        equipmentFunction,
                        conditionId, key: `${next.samMrfref}.${equipmentId}`
                    }]
                } else {
                    return a;
                }
            })
        } else {
            const children = [{
                equipmentId,
                equipmentFunctionName,
                equipmentOemname,
                equipmentFunction,
                equipmentModelName,
                serialNumber,
                sapP1ref,
                decision,
                drDecision,
                equipmentOemmodel,
                drDecisionDate,
                status,
                drRule,
                equipmentOemname,
                equipmentModelName,
                equipmentCompanyName,
                condition,
                conditionId, equipmentId, key: `${next.samMrfref}.${next.equipmentId}`
            }];
            abc.push({ equipmentStatusName, requestValidityDate,reasonForRequest, creationDate, lastUpdateBy, lastUpdateDate, requesterCompanyName, requesterDisplayName, requesterCompany, requesterCountry, stateId, stepId, samMrfref: next.samMrfref, key: next.samMrfref, children })
        }
    });
    return abc;
}