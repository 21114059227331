import { actionTypes } from "./actions";

export const initialState = {
    activeListType: [],
};

const listTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_TYPE_GET_REQUEST:
      return {
        ...state,
        isListTypeLoading: true,
      };
    case actionTypes.LIST_TYPE_GET_RECEIVE:
      return {
        ...state,
        activeListType: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isListTypeLoading: false,
      };
    case actionTypes.LIST_TYPE_GET_FAIL:
      return {
        ...state,
        isListTypeLoading: false,
    
      };
    default:
      return state;
  }
};

export default listTypeReducer;
