import { actionTypes } from "./actions";

export const initialState = {
  docUpload: {},
  isDocLoading: false,
  docList: [],
  docDelete: {},
  docEdit: {},
  isDocLoaded : false,
  docUrl: ''
};

// ===========================|| COMMON REDUCER ||=========================== //

const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DOC_UPLOAD_PENDING:
      return {
        ...state,
        isDocLoading: true,
      };
    case actionTypes.DOC_UPLOAD_RECEIVED:
      return {
        ...state,
        docUpload:action.payload,
        isDocLoading: false,
      };
    case actionTypes.DOC_UPLOAD_FAILED:
      return {
        ...state,
        isDocLoading: false,
      };
    case actionTypes.EQUIPMENT_DOCUMENTSID_PENDING:
        return {
          ...state,
          isDocLodaing: true
        };
    case actionTypes.EQUIPMENT_DOCUMENTSID_RECEIVED:
      return {
        ...state,
        isDocLodaing: false,
        docList:action.payload.map((item,index)=>{
          let tmpLength = item.pkDocument.toString()
          tmpLength = tmpLength.length
          let newName = item.documentName
          let tmpName =newName.substr(tmpLength+1,item.documentName.length)
          item.id = index
          item.fullName=`${tmpName}.${item.fileExtension}`
          return item}),
      };
    case actionTypes.EQUIPMENT_DOCUMENTSID_FAILED:
      return {
        ...state,
        isDocLodaing: false
      };
    case actionTypes.DOC_DELETE_PENDING:
      return {
        ...state,
      };
    case actionTypes.DOC_DELETE_RECEIVED:
      return {
        ...state,
        docDelete:action.payload,
      };
    case actionTypes.DOC_DELETE_FAILED:
      return {
        ...state,
      };
    case actionTypes.DOWNLOAD_FILE_PENDING:
      return {
        ...state,
        isDocLoaded: false,
      };
    case actionTypes.DOWNLOAD_FILE_RECEIVED:
      return {
        ...state,
        docUrl:action.payload,
        isDocLoaded: true,
      };
    case actionTypes.DOWNLOAD_FILE_FAILED:
      return {
        ...state,
        isDocLoaded: false,
      };
    case actionTypes.DOC_EDIT_PENDING:
      return {
        ...state,
      };
    case actionTypes.DOC_EDIT_RECEIVED:
      return {
        ...state,
        docDelete:action.payload,
      };
    case actionTypes.DOC_EDIT_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default documentReducer;
