import { actionTypes } from "./actions";

export const initialState = {
  formStateList: []
};

// ===========================|| COMMON REDUCER ||=========================== //

const formStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FORMSTATE_GET_REQUEST:
      return {
        ...state,
        isFormStateLoading: true,
      };
    case actionTypes.FORMSTATE_GET_RECEIVE:
      return {
        ...state,
        formStateList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isFormStateLoading: false,
      };
    case actionTypes.FORMSTATE_GET_FAIL:
      return {
        ...state,
        isFormStateLoading: false,
        isFormStateFail: false,
      };
    default:
      return state;
  }
};

export default formStateReducer;
