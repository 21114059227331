import React from 'react';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { useTheme, alpha } from '@mui/material/styles';
import CustomPagination from './CustomPagination';
import CustomToolbar from './CustomToolbar';

const ODD_OPACITY = 0.2;
export default function StyledDataGrid(props) {
    const theme = useTheme();
    return <DataGrid
                disableSelectionOnClick
                disableColumnSelector={true}
                disableColumnFilter={true}
                disableColumnMenu={true}

                disableDensitySelector
                pagination
                components={{
                    Toolbar: CustomToolbar,
                    Pagination: CustomPagination
                }}
                paginationMode="server"
                componentsProps={{
                    toolbar: {
                        hideCSV:props.hideCSV,
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                      csvOptions: props.csvOptions
                    },
                    pagination:{
                        pageSize1: props.pageSize1,
                        setPageSize: props.setPageSize,
                        page: props.page,
                        setPage: props.setPage,
                        totalRow: props.totalRow
                    }
                }}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                sx={{
                    '& .dgHeader': {
                        fontWeight: 600,
                        color: `${theme.palette.common.dgHeader}`,
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 600,
                    },
                    [`& .${gridClasses.row}.even`]: {
                        backgroundColor: theme.palette.common.evenCell,
                        '&:hover, &.Mui-hovered': {
                            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
                            '@media (hover: none)': {
                                backgroundColor: 'transparent',
                            },
                        },
                        '&.Mui-selected': {
                            backgroundColor: alpha(
                                theme.palette.primary.main,
                                ODD_OPACITY + theme.palette.action.selectedOpacity,
                            ),
                            '&:hover, &.Mui-hovered': {
                                backgroundColor: alpha(
                                    theme.palette.primary.main,
                                    ODD_OPACITY +
                                    theme.palette.action.selectedOpacity +
                                    theme.palette.action.hoverOpacity,
                                ),
                                // Reset on touch devices, it doesn't add specificity
                                '@media (hover: none)': {
                                    backgroundColor: alpha(
                                        theme.palette.primary.main,
                                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                                    ),
                                },
                            },
                        },
                    }
                }}
                {...props}
            />
}