
// import { actionTypes } from "./actions";
import { deleteModelFunctionAPI } from 'containers/Management/FunctionalStructure/Model/state/endpoints';
import { createModelAPI, deleteModelAPI, getModelListAPI, updateModelAPI, getAllActiveModelListAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';
import { gettFnstrAction } from 'containers/Management/FunctionalStructure/state/actions';

export const actionTypes = {
    MODEL_GET_REQUEST: "MODEL_GET_REQUEST",
    MODEL_GET_RECEIVE: "MODEL_GET_RECEIVE",
    MODEL_GET_FAIL: "MODEL_GET_FAIL"
};

const requestModel = () => ({
    type: actionTypes.MODEL_GET_REQUEST,
});
const receiveModel = (payload) => ({
    type: actionTypes.MODEL_GET_RECEIVE,
    payload,
});
const failureModel = (payload) => ({
    type: actionTypes.MODEL_GET_FAIL,
    payload,
});
export const getModelAction = () => {
    return async (dispatch) => {
        dispatch(requestModel());
        try {
            const response = await getModelListAPI();
            if (response?.status === 200) {
                dispatch(receiveModel(response?.data?.data));
                // dispatch(actions.successAlert("Coutries fetched successfully"));
            }
        } catch (error) {
            dispatch(failureModel(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getAllActiveModelAction = () => {
    return async (dispatch) => {
        dispatch(requestModel());
        try {
            const response = await getAllActiveModelListAPI();
            if (response?.status === 200) {
                dispatch(receiveModel(response?.data?.data));
                // dispatch(actions.successAlert("Coutries fetched successfully"));
            }
        } catch (error) {
            dispatch(failureModel(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const createModelAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestModel());
        try {
            const response = await createModelAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Model added successfully"));
            }
            dispatch(getModelAction());
        } catch (error) {
            dispatch(failureModel(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateModelAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestModel());
        try {
            const response = await updateModelAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Model updated successfully"));
            }
            dispatch(getModelAction());
        } catch (error) {
            dispatch(failureModel(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deleteModelAPIAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestModel());
        try {
            const response = await deleteModelAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Model deleted successfully"));
            }
            dispatch(getModelAction());
            dispatch(gettFnstrAction());
        } catch (error) {
            dispatch(failureModel(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));

            }
        }
    };
}

export const deleteModelFunctionAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestModel());
        try {
            const response = await deleteModelFunctionAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Model deleted successfully"));
            }
            dispatch(getModelAction());
            dispatch(gettFnstrAction());
        } catch (error) {
            dispatch(failureModel(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));

            }
        }
    };
}



