import { actionTypes } from "./actions";

export const initialState = {
  allMakerPackerList:[],
  parentDictUIColMappings: []  
};


const teamFocusReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MAKERS_GET_REQUEST:
      return {
        ...state,
        isMakerLoading: true,
      };
    case actionTypes.MAKERS_STOP_LOADING:
      return {
        ...state,
        isMakerLoading: false,
      };
    case actionTypes.MAKERS_GET_RECEIVE:
      let makerData = action.payload.makerPackerdata
      return {
        ...state,
        allMakerPackerList: makerData.map((dt, index) => ({ ...dt, id: index })),
        parentDictUIColMappings: action.payload.parentDictUIColMappings,
        isMakerLoading: false,
      };
    case actionTypes.MAKERS_GET_FAIL:
      return {
        ...state,
        isMakerLoading: false,
        isMakerFail: false,
      };
    case  "CLEAR_MAKER_DATA":
        return {
          ...state,
          allMakerPackerList: [],
          parentDictUIColMappings:[]
        };
    default:
      return state;
  }
};

export default teamFocusReducer;
