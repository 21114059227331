import React from "react";
import { GridToolbarQuickFilter, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

export default function CustomToolbar(props) {
      return (<>
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: !props.hideCSV ? 'space-between' : 'flex-end' }}>
                  {props.hideCSV ? "" : <GridToolbarExport printOptions={{ disableToolbarButton: true }} csvOptions={props.csvOptions} />}
                  <GridToolbarQuickFilter />
            </GridToolbarContainer>

      </>
      );
}
