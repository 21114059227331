
import {getAllEquipmentTypeAPI, updateEquipmentColumnByIdAPI, createEquipmentTypeAPI, deleteEquipmentTypeAPI, getEquipmentColumnByIdAPI,getAllColumnMapperActionAPI,AddEquipmentColumnByIdAPI} from '../State/endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    EQUIPMENT_TYPE_GET_REQUEST: "EQUIPMENT_TYPE_GET_REQUEST",
    EQUIPMENT_TYPE_GET_RECEIVE: "EQUIPMENT_TYPE_GET_RECEIVE",
    EQUIPMENT_TYPE_GET_FAIL: "EQUIPMENT_TYPE_GET_FAIL",
    EQUIPMENT_COLUMN_BY_ID_TYPE_GET_REQUEST: "EQUIPMENT_COLUMN_BY_ID_TYPE_GET_REQUEST",
    EQUIPMENT_COLUMN_BY_ID_TYPE_GET_RECEIVE: "EQUIPMENT_COLUMN_BY_ID_TYPE_GET_RECEIVE",
    EQUIPMENT_COLUMN_BY_ID_TYPE_GET_FAIL: "EQUIPMENT_COLUMN_BY_ID_TYPE_GET_FAIL",
    COLUMN_TYPE_GET_REQUEST: "COLUMN_TYPE_GET_REQUEST",
    COLUMN_TYPE_GET_RECEIVE: "COLUMN_TYPE_GET_RECEIVE",
    COLUMN_TYPE_GET_FAIL: "COLUMN_TYPE_GET_FAIL"
  };
  const requestEquColbyId =()=>({
    type: actionTypes.EQUIPMENT_COLUMN_BY_ID_TYPE_GET_REQUEST
  });
  const receiveEquColbyId =(payload)=>({
    type: actionTypes.EQUIPMENT_COLUMN_BY_ID_TYPE_GET_RECEIVE,
    payload
  });
  const failureEquColbyId =()=>({
    type: actionTypes.EQUIPMENT_COLUMN_BY_ID_TYPE_GET_FAIL
  })
const requestEquipmentTypeValue= () => ({
    type: actionTypes.EQUIPMENT_TYPE_GET_REQUEST,
});
const receiveEquipmentTypeValue = (payload) => ({
    type: actionTypes.EQUIPMENT_TYPE_GET_RECEIVE,
    payload,
});
const failureEquipmentTypeValue = (payload) => ({
    type: actionTypes.EQUIPMENT_TYPE_GET_FAIL,
    payload,
});

const requestColumnType= () => ({
    type: actionTypes.COLUMN_TYPE_GET_REQUEST,
});
const receiveColumnType = (payload) => ({
    type: actionTypes.COLUMN_TYPE_GET_RECEIVE,
    payload,
});
const failureColumnType = (payload) => ({
    type: actionTypes.COLUMN_TYPE_GET_FAIL,
    payload,
});

export const getAllColumnMapperAction = () => {
    return async (dispatch) => {
        dispatch(requestColumnType());
        try {
            const response = await getAllColumnMapperActionAPI();
            if (response?.status === 200) {
                dispatch(receiveColumnType(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureColumnType(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};


export const getAllEquipmentTypeAction = () => {
    return async (dispatch) => {
        dispatch(requestEquipmentTypeValue());
        try {
            const response = await getAllEquipmentTypeAPI();
            if (response?.status === 200) {
                console.log("equ action",response)
                dispatch(receiveEquipmentTypeValue(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureEquipmentTypeValue(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const updateEquipmentTypeAction = ( payload) => {
    return async (dispatch) => {
        dispatch(requestEquipmentTypeValue());
        try {
            const response = await updateEquipmentColumnByIdAPI(payload.pkDSEquipmentTypeCols, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Equipment Type updated successfully"));
            }
            dispatch(getAllEquipmentTypeAction());
        } catch (error) {
            dispatch(failureEquipmentTypeValue(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const UpdateEquipmentColumnByIdAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestEquColbyId());
        try {
            const response = await updateEquipmentColumnByIdAPI(payload.pkDSEquipmentTypeCol, payload);;
            if (response?.status === 200) {
                console.log("responsecolbyid", response)
                dispatch(actions.successAlert("Equipment Type updated successfully")); 
               dispatch(getEquipmentColumnByIdAction(payload.fkDSEquipmentType));
            }
        } catch (error) {
            dispatch(failureEquColbyId(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};


export const createEquipmentTypeAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestEquipmentTypeValue());
        try {
            const response = await createEquipmentTypeAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Equipment Type added successfully"));
            }
            dispatch(getAllEquipmentTypeAction());
        } catch (error) {
            dispatch(failureEquipmentTypeValue(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const AddEquipmentColumnByIdAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestEquColbyId());
        try {
            const response = await AddEquipmentColumnByIdAPI(payload);
            if (response?.status === 200) {
                console.log("responsecolbyid", response)
                if(payload.equipmentTypeColRequest.length>0)
                {
                    dispatch(getEquipmentColumnByIdAction(payload.equipmentTypeColRequest[0].fkDSEquipmentType));
                }
            }
        } catch (error) {
            dispatch(failureEquColbyId(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const deleteEquipmentTypeAction = (id) => {
    return async (dispatch) => {
        dispatch(requestEquipmentTypeValue());
        try {
            const response = await deleteEquipmentTypeAPI(id);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Equipment Type deleted successfully"));
            }
            dispatch(getAllEquipmentTypeAction());
        } catch (error) {
            dispatch(failureEquipmentTypeValue(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const getEquipmentColumnByIdAction = (id) => {
    return async (dispatch) => {
        dispatch(requestEquColbyId());
        try {
            const response = await getEquipmentColumnByIdAPI(id);
            if (response?.status === 200) {
                console.log("responsecolbyid", response)
                dispatch(receiveEquColbyId(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureEquColbyId(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

