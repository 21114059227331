import * as React from 'react';
import { createFilterOptions } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


export default function MultiSelect({ data, onChange, name, resetFilter, selectAll }) {

  const { options, label } = data;

  const filter = createFilterOptions();
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  React.useEffect(() => {    
    handleClearOptions && handleClearOptions();
  }, [resetFilter])


  const allSelected = options.length === selectedOptions.length;

  const handleToggleOption = selectedOptions =>
    setSelectedOptions(selectedOptions);
  const handleClearOptions = () => setSelectedOptions([]);

  const handleSelectAll = isSelected => {
    if (isSelected) {
      setSelectedOptions(options);
    } else {
      handleClearOptions();
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOptions.find(option => option.value === "select-all")) {
        handleToggleSelectAll();
        let result = [];
        result = options.filter(el => el.value !== "select-all");
        if(selectAll == 'N') {
        return onChange({[name]: []});
        } else {
          return onChange({ [name]: result }, 'All');          
        }
        
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
        return onChange({ [name]: selectedOptions }, 'Option');
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
      return onChange({ [name]: [] }, 'Clear');
    }
  };


  const optionRenderer = (props, option, { selected }) => {
    const selectAllProps =
      option.value === "select-all" // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};
    return (
      <li {...props}>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {option.label}
      </li>
    );
  };
  const getOptionSelected = (option, anotherOption) => {
    return option.value === anotherOption.value;
  }
  return (
    <Autocomplete
      sx={{ my: 0.25, mr: 2 }}
      multiple
      id="checkboxes-tags-demo"
      options={options}
      size="small"
      limitTags={2}
      value={selectedOptions}
      noOptionsText={'Empty'}
      onChange={handleChange}
      name={name}
      getOptionSelected={getOptionSelected}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      filterOptions={(options, params) => {
        const filtered = filter(options, params).slice(0, 300);
        if(selectAll == 'N') {
        return [...filtered];
        } else{
        return [{ label: "All", value: "select-all" }, ...filtered];

        }
      }}
      renderOption={optionRenderer}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={label} />
      )}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const options = [
  { label: "foo", value: "foo" },
  { label: "bar", value: "bar" },
  { label: "jar", value: "jar" },
  { label: "nar", value: "nar" }
];