import React from 'react';
import jwt from 'jwt-decode'
import { connect } from "react-redux";
import ThemeCustomization from './themes';
import Routes from './routes';
import ScrollTop from './components/ScrollTop/ScrollTop';
import './assets/css/styles.css';

import { getTokens, login, loginUrl, logout, logoutUrl, refreshTokens } from './components/auth';
import {getTokenAction} from './store/Reducer/actions'
const mapDispatchToProps = (dispatch)=>({
    getTokenAction:(decodedIdToken) => dispatch(getTokenAction(decodedIdToken))
})
class App extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            code: '',
            bearerToken: '',
            refreshToken: '',
            tokenExpiry: '',
            authenticated: false,
            authenticating: false
        }
        console.log("location",window.location.href)
        window.localStorage.setItem('replaceURL', window.location.href);
    }
    updateTokens = async () => {
        console.log("Step 0")
        const params = (new URL(document.location)).searchParams;
        const code = params.get('code');
        const tokens = getTokens();
        console.log('tokens updateTokens',tokens)
        const bearer = (tokens !== null) ? 'Bearer ' + tokens.idToken : '';
        const refresh = (tokens !== null) ? tokens.refreshToken : ''; 
        const modifiedURL = (tokens !== null) ? tokens.newURL : ''; 
        if(tokens !== null){
            const decodedIdToken = jwt(tokens.idToken)
            console.log('decodedIdToken',decodedIdToken)
            this.props.getTokenAction(decodedIdToken)
        }
        this.setState({
            code: code,
            bearerToken: bearer,
            refreshToken: refresh,
            modifiedURL: modifiedURL
        });
    }
    
    executeLogin = async () => {
        console.log("Signing in...")
        window.history.replaceState({}, document.title, '/');
        try {
            await login(this.state.code).then(() => {
                const expirtyDateTime = new Date();
                expirtyDateTime.setMinutes(expirtyDateTime.getMinutes() + 10);
                console.log('inside execute login')
                this.setState({
                    tokenExpiry: expirtyDateTime,
                    authenticated: true
                },()=>{
                    console.log("Updated authentication function")
                    this.afterAuthentication()
                });
            });
        } catch (err) {
            console.log(err);
        }
        console.log('outside execute login')
        this.setState({ authenticating: false });
    };

    componentDidMount() {
        // document.body.style.zoom = "75%";
        console.log("Calling componentDidMount")
       this.updateTokens().then(() => {
            console.log("Step 1")
           this.setState(prevState => ({
               authenticated: (prevState.bearerToken !== "") ? true : false,
               authenticating: (prevState.code !== null) ? true : false
           }), () => {
               console.log("Application loading...")
               console.log("Bearer Token : " + this.state.bearerToken)
               console.log("Authenticated 1: " + this.state.authenticated)
               console.log("Authenticating 1: " + this.state.authenticating)
               console.log('this.state',this.state)
               if (this.state.code !== null) {
                this.executeLogin()
               }
               else if (this.state.authenticated === true) {
                console.log("Already authenticated")
                console.log("modifiedURL",this.state.modifiedURL)
                this.updateTokens().then(() => {
                });
                
                }
               else{
                console.log("Inside else part")
                window.location.replace(loginUrl)
               }
           });
       });
   }
   afterAuthentication(){
        console.log("After signing in...");
        console.log("Authenticated 2: " + this.state.authenticated)
        console.log("Authenticating 2: " + this.state.authenticating)
        if (this.state.authenticated === true) {
            this.updateTokens().then(() => {
                console.log("modifiedURL after authentication",this.state.modifiedURL)
                let homeUrl = `${process.env.REACT_APP_REDIRECT_URL}/`
                console.log("homeUrl",homeUrl)
                if(this.state.modifiedURL !== homeUrl) {
                    window.location.replace(this.state.modifiedURL)
                }
            })
        }
   }
    render(){
        let {authenticated, authenticating} = this.state
        if(authenticated && !authenticating){
            return(
                <ThemeCustomization>
                    <ScrollTop>
                        <Routes />
                    </ScrollTop>
                </ThemeCustomization>
            )
        }
    }
}
export default connect(null,mapDispatchToProps)(App);