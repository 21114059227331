import { actionTypes } from "./actions";

export const initialState = {
  isFileLoading: false,
  uploadList: [],
  fileUrl: '',
  isFileLoaded: false,
};
const fileUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPLAOD_STATUS_PENDING:
        return {
            ...state,
            isFileLoading : true
        };
    case actionTypes.UPLAOD_STATUS_RECEIVED:
      return {
          ...state,
          uploadList:action.payload.map((dt, index)=>({...dt, id:index})),
          isFileLoading : false
      };
    case actionTypes.UPLAOD_STATUS_FAILED:
        return {
          ...state,
          isFileLoading : false
      };
    case actionTypes.DOWNLOAD_FILE_PENDING:
        return {
          ...state,
          isFileLoaded: false,
        };
      case actionTypes.DOWNLOAD_FILE_RECEIVED:
        return {
          ...state,
          fileUrl:action.payload,
          isFileLoaded: true,
        };
      case actionTypes.DOWNLOAD_FILE_FAILED:
        return {
          ...state,
          isFileLoaded: false,
        };
    default:
      return state;
  }
};

export default fileUploadReducer;
