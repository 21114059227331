import { updateTemplateAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    UPDATE_TEMPLATE_PENDING: "UPDATE_TEMPLATE_PENDING",
    UPDATE_TEMPLATE_RECEIVED: "UPDATE_TEMPLATE_RECEIVED",
    UPDATE_TEMPLATE_FAILED: "UPDATE_TEMPLATE_FAILED",
};
const updatePending= () => ({
    type: actionTypes.UPDATE_TEMPLATE_PENDING,
});
const updateReceived = (payload) => ({
    type: actionTypes.UPDATE_TEMPLATE_RECEIVED,
    payload,
});
const updateFailed = (payload) => ({
    type: actionTypes.UPDATE_TEMPLATE_FAILED,
    payload,
})

export const updateTemplateAction = (payload) => { 
    return async (dispatch) => {
        dispatch(updatePending());
        try {
            const response = await updateTemplateAPI(payload);                      
            if (response?.status === 200) {
                dispatch(updateReceived(response.data));
            }
        } catch (error) {    
            dispatch(updateFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}