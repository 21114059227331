import { API } from "api";

export const getFormTypeListAPI = (id) => {
    return API.get(`api/formworkflow/getallbyref?FormType=${id}&page=0&size=0`)
                .then((res) => res);
};
export const getFormStateAPI = () => {
    return API.get(`api/formstate/getall?page=0&size=0`)
                .then((res) => res);
};
export const getFormWorkFlowListAPI = () => {
    return API.get(`api/formworkflow/getall?page=0&size=0`)
                .then((res) => res);
};
export const getFormStepAPI = () => {
    return API.get(`api/formstep/getall?page=0&size=0`)
                .then((res) => res);
                
};
    export const createProductFormatValueAPI = (payload) => {
        return API.post("/api/productformattypevalue/add", payload)
                    .then((res) => res);
    }
    
    export const updateProductFormatValueAPI = (id, payload) => {
        return API.put(`/api/productformattypevalue/update/${id}`, payload)
                    .then((res) => res);
    }
    
    export const deleteProductFormatValueAPI = (id) => {
        return API.delete(`/api/productformattypevalue/delete/${id}`)
                    .then((res) => res);
    }
export const deleteFormWorkFlowAPI = (id) => {
    return API.delete(`/api/formworkflow/delete/${id}`)
                .then((res) => res);
}
export const updateFormWorkFlowAPI = (id, payload) => {
    return API.put(`/api/formworkflow/update/${id}`, payload)
                .then((res) => res);
}
export const createFormWorkFlowAPI = (payload) => {
    return API.post(`/api/formworkflow/add`, payload)
                .then((res) => res);
}
