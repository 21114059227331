import axios from "axios";

export const API = axios.create({
  baseURL: `${process.env.REACT_APP_MDM_BASE_URL}`,
});
export const equipmentAPI = axios.create({
  baseURL: `${process.env.REACT_APP_EQUIPMENT_BASE_URL}`,
});
export const notificationAPI = axios.create({
  baseURL: `${process.env.REACT_APP_NOTIFICATION_BASE_URL}`,
});
API.interceptors.request.use(
  async (config) => {
    config.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem('idToken')}`      
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  async (config) => {
    return config;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("isAuthorized");
      localStorage.clear();
      window.location.href = "/";
      return;
    }
    return Promise.reject(error);
  }
);
equipmentAPI.interceptors.request.use(
  async (config) => {
    config.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem('idToken')}`


    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

equipmentAPI.interceptors.response.use(
  async (config) => {
    return config;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("isAuthorized");
      localStorage.clear();
      window.location.href = "/";
      return;
    }
    return Promise.reject(error);
  }
);

// Notification

notificationAPI.interceptors.request.use(
  async (config) => {
    config.headers = {
      "Content-Type": "application/json",      
      // Authorization: `Bearer ${localStorage.getItem('idToken')}`
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

notificationAPI.interceptors.response.use(
  async (config) => {
    return config;
  },
  (error) => {    
    // if (error.response.status === 401) {
    //   localStorage.removeItem("isAuthorized");
    //   window.location.href = "/";
    //   return;
    // }
    return Promise.reject(error);
  }
);
