
// import { actionTypes } from "./actions";
import { createOemAPI, deleteOemAPI, getOemListAPI, updateOemAPI, getAllActiveOemListAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    OEM_GET_REQUEST: "OEM_GET_REQUEST",
    OEM_GET_RECEIVE: "OEM_GET_RECEIVE",
    OEM_GET_FAIL: "OEM_GET_FAIL"
};

const requestOem = () => ({
    type: actionTypes.OEM_GET_REQUEST,
});
const receiveOem = (payload) => ({
    type: actionTypes.OEM_GET_RECEIVE,
    payload,
});
const failureOem = (payload) => ({
    type: actionTypes.OEM_GET_FAIL,
    payload,
});
export const getOemAction = () => {
    return async (dispatch) => {
        dispatch(requestOem());
        try {
            const response = await getOemListAPI();
            if (response?.status === 200) {
                dispatch(receiveOem(response?.data?.data));
                // dispatch(actions.successAlert("Coutries fetched successfully"));
            }
        } catch (error) {
            dispatch(failureOem(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getAllActiveOemAction = () => {
    return async (dispatch) => {
        dispatch(requestOem());
        try {
            const response = await getAllActiveOemListAPI();
            if (response?.status === 200) {
                dispatch(receiveOem(response?.data?.data));
                // dispatch(actions.successAlert("Coutries fetched successfully"));
            }
        } catch (error) {
            dispatch(failureOem(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const createOemAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestOem());
        try {
            const response = await createOemAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("OEM added successfully"));
            }
            dispatch(getOemAction());
        } catch (error) {
            dispatch(failureOem(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateOemAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestOem());
        try {
            const response = await updateOemAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("OEM updated successfully"));
            }
            dispatch(getOemAction());
        } catch (error) {
            dispatch(failureOem(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deleteOemAPIAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestOem());
        try {
            const response = await deleteOemAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("OEM deleted successfully"));
            }
            dispatch(getOemAction());
        } catch (error) {
            dispatch(failureOem(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));

            }
        }
    };
}
