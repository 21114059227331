
// import { actionTypes } from "./actions";
import { createCountryAPI, deleteCountryAPI, getCountriesListAPI, getCountriesListAWithFilterPI, updateCountryAPI, getActiveCountriesListAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    COUNTRY_GET_REQUEST: "COUNTRY_GET_REQUEST",
    COUNTRY_GET_RECEIVE: "COUNTRY_GET_RECEIVE",
    COUNTRY_GET_FAIL: "COUNTRY_GET_FAIL"
  };

const requestCountries= () => ({
    type: actionTypes.COUNTRY_GET_REQUEST,
});
const receiveCountries = (payload) => ({
    type: actionTypes.COUNTRY_GET_RECEIVE,
    payload,
});
const failureCountries = (payload) => ({
    type: actionTypes.COUNTRY_GET_FAIL,
    payload,
});
export const getCountryAction = () => {
    return async (dispatch) => {
        dispatch(requestCountries());
        try {
            const response = await getCountriesListAPI();
            if (response?.status === 200) {
                dispatch(receiveCountries(response?.data?.data));
               // dispatch(actions.successAlert("Coutries fetched successfully"));
            }
        } catch (error) {
            dispatch(failureCountries(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const createCountryAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestCountries());
        try {
            const response = await createCountryAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Countries added successfully"));
            }
            dispatch(getCountryAction());
        } catch (error){
            dispatch(failureCountries(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateCountryAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestCountries());
        try {
            const response = await updateCountryAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Countries updated successfully"));
            }
            dispatch(getCountryAction());
        } catch (error) {
            dispatch(failureCountries(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deleteCountryAPIAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestCountries());
        try {
            const response = await deleteCountryAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Countries deleted successfully"));
            }
            dispatch(getCountryAction());
        } catch (error) {
            dispatch(failureCountries(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const getAllActiveCountryAction = () => {
    return async (dispatch) => {
        dispatch(actions.requestActiveCountries());
        try {
            const response = await getActiveCountriesListAPI();
            if (response?.status === 200) {
                dispatch(actions.receiveActiveCountries(response?.data?.data));
               // dispatch(actions.successAlert("Coutries fetched successfully"));
            }
        } catch (error) {
            dispatch(actions.failureActiveCountries(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
