import { API } from "api";

export const getSubProListAPI = (req) => {
    return API.get("api/subprocess/getallactive?page=0&size=0&sortOrder=1")
        .then((res) => res);
};


export const createSubProAPI = (payload) => {
    return API.post("/api/subprocess/add", payload)
        .then((res) => res);
}

export const updateSubProAPI = (id, payload) => {
    return API.put(`/api/subprocess/update/${id}`, payload)
        .then((res) => res);
}

export const deleteSubProAPI = (id) => {
    return API.delete(`/api/subprocess/delete/${id}`)
        .then((res) => res);
}
