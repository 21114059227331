import { actions } from 'store/Reducer/actions';
import { getDSSysOwnerRolesAPI,getDSEquipmentRolesAPI,GetAllDSEquipmentOwnerAPI,getDSUsersRolesAPI,AddDSEquipmentOwnerAPI,GetDSEquipmentOwnerAPI,deleteDSEquipmentOwnerAPI } from './endpoints';
export const actionTypes = {
    DSSYSOWN_ROLES_PENDING: "DSSYSOWN_ROLES_PENDING",
    DSSYSOWN_ROLES_RECEIVED: "DSSYSOWN_ROLES_RECEIVED",
    DSSYSOWN_ROLES_FAILED: "DSSYSOWN_ROLES_FAILED",
    DSEQOWN_ALLROLES_PENDING: "DSEQOWN_ALLROLES_PENDING",
    DSEQOWN_ALLROLES_RECEIVED: "DSEQOWN_ALLROLES_RECEIVED",
    DSEQOWN_ALLROLES_FAILED: "DSEQOWN_ALLROLES_FAILED",
    DSEQOWN_DATA_PENDING: "DSEQOWN_ROLES_PENDING",
    DSEQOWN_DATA_RECEIVED: "DSEQOWN_ROLES_RECEIVED",
    DSEQOWN_DATA_FAILED: "DSEQOWN_ROLES_FAILED",
    DSEQUSERS_ROLES_PENDING: "DSEQOWN_ROLES_PENDING",
    DSEQUSERS_ROLES_RECEIVED: "DSEQOWN_ROLES_RECEIVED",
    DSEQUSERS_ROLES_FAILED: "DSEQOWN_ROLES_FAILED",
};
const requestSysOwnerRoles= () => ({
    type: actionTypes.DSSYSOWN_ROLES_PENDING,
});
const receiveSysOwnerRoles = (payload) => ({
    type: actionTypes.DSSYSOWN_ROLES_RECEIVED,
    payload,
});
const failureSysOwnerRoles = (payload) => ({
    type: actionTypes.DSSYSOWN_ROLES_FAILED,
    payload,
});
const requestEqmntOwnerRoles= () => ({
    type: actionTypes.DSEQOWN_ALLROLES_PENDING,
});
const receiveEqmntOwnerRoles = (payload) => ({
    type: actionTypes.DSEQOWN_ALLROLES_RECEIVED,
    payload,
});
const requestEqmntOwnerData= () => ({
    type: actionTypes.DSEQOWN_DATA_PENDING,
});
const receiveEqmntOwnerData = (payload) => ({
    type: actionTypes.DSEQOWN_DATA_RECEIVED,
    payload,
});
const failureEqmntOwnerRoles = (payload) => ({
    type: actionTypes.DSEQOWN_ALLROLES_FAILED,
    payload,
});
const failureEqmntOwnerData = (payload) => ({
    type: actionTypes.DSEQOWN_ROLES_FAILED,
    payload,
});

const requestDSUsersRoles= () => ({
    type: actionTypes.DSEQUSERS_ROLES_PENDING,
});
const receiveDSUsersRoles = (payload) => ({
    type: actionTypes.DSEQUSERS_ROLES_RECEIVED,
    payload,
});
const failureDSUsersRoles = (payload) => ({
    type: actionTypes.DSEQUSERS_ROLES_FAILED,
    payload,
});



export const getDSSystemOwnerRolesAction = () => {   
    return async (dispatch) => {
        dispatch(requestSysOwnerRoles());
        try {
            const response = await getDSSysOwnerRolesAPI();
            if (response?.status === 200) {
                dispatch(receiveSysOwnerRoles(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureSysOwnerRoles(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getDSEquipmentOwnerDataAction = () => {   
    return async (dispatch) => {
        dispatch(requestEqmntOwnerData());
        try {
            const response = await GetDSEquipmentOwnerAPI();
            if (response?.status === 200) {
                dispatch(receiveEqmntOwnerData(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureEqmntOwnerData(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};


export const getDSEquipmentOwnerRolesAction = () => {   
    return async (dispatch) => {
        dispatch(requestEqmntOwnerRoles());
        try {
            const response = await GetAllDSEquipmentOwnerAPI();
            if (response?.status === 200) {
                dispatch(receiveEqmntOwnerRoles(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureEqmntOwnerRoles(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getDSUsersRolesAction = () => {   
    return async (dispatch) => {
        dispatch(requestDSUsersRoles());
        try {
            const response = await getDSEquipmentRolesAPI();
            if (response?.status === 200) {
                dispatch(receiveDSUsersRoles(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureDSUsersRoles(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const createEquipmentOwnerAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestEqmntOwnerData());
        try {
            const response = await AddDSEquipmentOwnerAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Equipment Owner added to Equipment successfully"));
            }
            dispatch(receiveEqmntOwnerData());
        } catch (error){
            dispatch(failureEqmntOwnerData(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deleteEquipmentOwnerTypeAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestEqmntOwnerData());
        try {
            const response = await deleteDSEquipmentOwnerAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("User removed from EquipmentType successfully"));
            }
            dispatch(getDSSystemOwnerRolesAction());
        } catch (error) {
            dispatch(failureEqmntOwnerData(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}