import { API } from "api";

export const getFunctionListAPI = (req) => {
    return API.get("api/function/getallactive?page=0&size=0")
        .then((res) => res);
};


export const createFunctionAPI = (payload) => {
    return API.post("/api/function/add", payload)
        .then((res) => res);
}

export const updateFunctionAPI = (id, payload) => {
    return API.put(`/api/function/update/${id}`, payload)
        .then((res) => res);
}

export const deleteFunctionAPI = (id) => {
    return API.delete(`/api/function/delete/${id}`)
        .then((res) => res);
}
