import { API } from "api";

export const getSecurityCompanyCoordinatorListAPI = (payload) => { 
    let apiUrl = `api/companycoordinator/getcompanycoordinators?page=0&size=0`
    console.log('payload',payload)
    if (payload.LongCompanyName === undefined && payload.CoordinatorDisplayName === undefined )
        apiUrl=apiUrl
    else if(payload.LongCompanyName !== undefined && payload.CoordinatorDisplayName === undefined){
        apiUrl = `api/companycoordinator/getcompanycoordinators?LongCompanyName=${payload.LongCompanyName}&page=0&size=0`
    } 
    else if(payload.CoordinatorDisplayName !== undefined && payload.LongCompanyName === undefined){
        apiUrl = `api/companycoordinator/getcompanycoordinators?CoordinatorDisplayName=${payload.CoordinatorDisplayName}&page=0&size=0`
    }
    else{
        apiUrl = `api/companycoordinator/getcompanycoordinators?CoordinatorDisplayName=${payload.CoordinatorDisplayName}&LongCompanyName=${payload.LongCompanyName}&page=0&size=0`
    }
   
    return API.get(apiUrl).then((res) => res);
}
export const getSecurityCoordinatorAPI = () => {
    return API.get("api/security/coordinator/getall/true")
        .then((res) => res);
}
export const createCompanyCoordinatorAPI = (payload) => {
    return API.post("/api/companycoordinator/add", payload)
        .then((res) => res);
}
export const updateCompanyCoordinatorAPI = (payload,id) => {
    return API.put(`/api/companycoordinator/update/${id}`, payload)
        .then((res) => res);
}
export const getCompanyCoordinatorAPI = (id) => {
    return API.get(`/api/companycoordinator/${id}`)
        .then((res) => res);
}
export const deleteSecurityCompanyCoordinatorAPI = (id) => {
    return API.delete(`/api/companycoordinator/delete/${id}`)
                .then((res) => res);
}