import MainCard from 'components/MainCard';
import React, { useState, useEffect } from 'react';
import { Grid, Typography, Checkbox, Button } from "@mui/material";
import StyledDataGrid from 'components/DataGrid/index';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Autocomplete, TextField } from '@mui/material/index';
import { useDispatch, useSelector } from 'react-redux';
import { getAreaAction } from 'containers/Management/FunctionalStructure/Area/state/actions';
import { getProcessAction } from 'containers/Management/FunctionalStructure/Process/state/actions';
import { getFunctionAction } from 'containers/Management/FunctionalStructure/Function/state/actions';
import { getSubPrAction } from 'containers/Management/FunctionalStructure/SubProcess/state/actions';
import { getModelAction } from 'containers/Management/FunctionalStructure/Model/state/actions';
import { getOemAction } from 'containers/Management/OEM/state/actions';
import { getModelFunctionAction } from './state/actions';
import manualExport from '../../GenericExcelExport/index';

export default function FunctionalStructureRef() {
    const [values, setValues] = useState({});
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [process, setProcess] = useState('');
    const [area, setArea] = useState('');
    const [newModelList, setNewModelList] = useState([]);
    const [subprocess, setSubprocess] = useState('');
    const [functionVal, setFunction] = useState('');
    const [model, setModel] = useState('');
    const { areaList = [] } = useSelector((state) => state.area);
    const { processListNoD : processList } = useSelector((state) => state.process);
    const { subProcessListNew:subProcessList } = useSelector((state) => state.subProcess);
    const { modelListNoD: modelList } = useSelector((state) => state.model);
    const { functionListNoD: functionList } = useSelector((state) => state.function);
    const { modelFunctionList = [] } = useSelector((state) => state.modelFunctiolRel);
    const { oemList } = useSelector((state) => state.oem);


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAreaAction());
        dispatch(getProcessAction());
        dispatch(getFunctionAction());
        dispatch(getSubPrAction());
        dispatch(getModelAction());
        dispatch(getOemAction());
        dispatch(getModelFunctionAction());
    }, []);

    useEffect(() => {
        const tempmdl = [];
        const modelOem = [];
        if (modelList && modelList.length && modelFunctionList && modelFunctionList.length) {
            modelList.forEach((a) => {
                const fkFunction = modelFunctionList.filter(({ fkModel }) => (
                    a.pkModel === fkModel
                ));
                fkFunction.forEach((mdl) => {
                    tempmdl.push({ ...a, fkFunction: mdl?.fkFunction });
                });
            })
            tempmdl.forEach((item1)=>{
                oemList.forEach((item2)=>{
                   if (item2.pkOem === item1.fkOem){
                    let tmpObj = {}
                    tmpObj = {
                      id:item1.pkModel,
                      pkModel:item1.pkModel,
                      modelName:item1.modelName +"  (" + item2.oemName +") ",
                      fkFunction: item1?.fkFunction
                    }
                    //modelOem.push(`${item1.modelName} ${"  "}-${"  "}${item2.oemName}`)
                    modelOem.push(tmpObj)
                  }
                })
              })
        }
        setNewModelList(modelOem);
    }, [modelFunctionList, functionList, modelList])

    const getGridData = () => {
        let areaT = areaList.filter(({pkArea})=> area ? pkArea === area.id : true ).sort((a, b) => a.orderBy > b.orderBy ? 1 : -1);
        let processT = processList.filter(({pkProcess})=> process ? pkProcess === process.id : true ).sort((a, b) => a.orderBy > b.orderBy ? 1 : -1);
        let subProcessT = subProcessList.filter(({pkSubProcess})=> subprocess ? pkSubProcess === subprocess.id : true ).sort((a, b) => a.orderBy > b.orderBy ? 1 : -1);
        let functionT = functionList.filter(({pkFunction})=> functionVal ? pkFunction === functionVal.id : true ).sort((a, b) => a.orderBy > b.orderBy ? 1 : -1);
        let modelT = newModelList.filter(({pkModel})=> model ? pkModel === model.id : true );

        //areaT = areaT.sort((a, b) => a.orderBy > b.orderBy ? 1 : -1);
        processT = processT.sort((a, b) => a.orderBy > b.orderBy ? 1 : -1);
        subProcessT = subProcessT.sort((a, b) => a.orderBy > b.orderBy ? 1 : -1);
        functionT = functionT.sort((a, b) => a.orderBy > b.orderBy ? 1 : -1);




        if (area || process || subprocess || functionVal || model) {
            setError(false);
            let abc = [];
            modelT.forEach((mdl, index) => {
                functionT.filter((x) => mdl.fkFunction === x.pkFunction).forEach((fn) => {
                    subProcessT.filter((b) =>
                        fn.fkSubProcess == b.pkSubProcess).forEach(sb => {
                            processT.filter((a) => (sb.fkProcess === a.pkProcess)).forEach((pr) => (
                                areaT.filter((ar) => (ar.pkArea === pr.fkArea)).forEach((ar) => {
                                    abc.push({
                                        id: index,
                                        area: ar.areaName,
                                        areaOrder: ar.orderBy,
                                        subOrder: sb.orderBy,
                                        functionOrder: fn.orderBy,
                                        processOrder: pr.orderBy,
                                        process: pr.processName,
                                        subprocess: sb.subProcessName,
                                        function: fn.functionName,
                                        model: mdl.modelName
                                    })
                                })
                            ))
                        })
                })
            });
            // abc.sort(sort_by('processOrder', 'subOrder', 'functionOrder'));
            abc.sort((a,b)=> (a.processOrder - b.processOrder || a.subOrder - b.subOrder || a.functionOrder - b.functionOrder));
            let perter = abc.map((a)=>{
                return {...a,
                area: a.areaOrder + ' ' +a.area,
                process: a.processOrder + ' ' +a.process,
                subprocess: a.subOrder + ' ' +a.subprocess,
                function: a.functionOrder + ' ' +a.function,
                model: a.model
            }
            })
            setData(perter);
        } else {
            setError(true);
        }
    }

    const onAreaChange = (e, value) => {
        setProcess('');
        setFunction('');
        setError(false);
        setModel('');
        setSubprocess('');
        setArea(value);
    }
    const onProcessChange = (e, value) => {
        setProcess(value);
        setFunction('');
        setError(false);
        setModel('');
        setSubprocess('');
        setValues({ ...values, 'process': value, subprocess: '', function: '' })
    }
    const onSubStatusChange = (e, value) => {
        setSubprocess(value);
        setFunction('');
        setModel('');
        setError(false);
    }
    const onModelChange = (e, value) => {
        setModel(value);
        setError(false);
    }
    const onFunctionChange = (e, value) => {
        setFunction(value);
        setError(false);
        setModel('');
    }

    const columns = [
        { field: 'area',  sortable: false, renderCell: ({ row }) => {
            console.log("kalp", row)
           return row.area 
        }, headerName: `Area`, flex: 1, headerClassName: "dgHeader" },
        { field: 'process',  sortable: false,
        renderCell: ({ row }) => (
             row.process 
        ), headerName: `SO Process`, flex: 1, headerClassName: "dgHeader" },
        { field: 'subprocess',  sortable: false, renderCell: ({ row }) => (
             row.subprocess 
        ), headerName: `SO Subprocess`, flex: 1, headerClassName: "dgHeader" },
        { field: 'function',  sortable: false,
        renderCell: ({ row }) => (
             row.function 
        ), headerName: `SO function`, flex: 1, headerClassName: "dgHeader" },
        { field: 'model',  sortable: false,
        renderCell: ({ row }) => (
             row.model 
        ), headerName: `Model (OEM)`, flex: 1, headerClassName: "dgHeader" }
    ];
    const column = [
        {
            title: 'Area',
            dataIndex: "area",
            key: "area"
        },
        {
            title: ' SO Process',
            dataIndex: "process",
            key: "process"
        },
        {
            title: 'SO Subprocess',
            dataIndex: "subprocess",
            key: "subprocess"
        },
        {
            title: 'SO function',
            dataIndex: 'function',
            key: 'function',
        },
        {
            title: 'Model (OEM)',
            dataIndex: "model",
            key: "model"
        }
    ];
    const handleClick = (data, fileName, column) => {
        manualExport(data, fileName, column);
    };



    return <MainCard boxShadow={true} border={false}>
        <Grid container justifyContent="space-between">
            <Grid item>
                <Typography variant="h3">Functional structure</Typography>
            </Grid>
        </Grid>

        <Grid container sx={{ mt: 1 }}>
            <Grid sx={{ pr: 1 }} sm={2} item>
                <Autocomplete
                    disablePortal
                    disableClearable
                    size="small"
                    id="area"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    name="area"
                    value={values.area}
                    options={areaList.map(({ pkArea, areaName }) => ({ id: pkArea, label: areaName }))}
                    onChange={onAreaChange}
                    renderInput={(params) => <TextField
                        fullWidth
                        maxwidth="20px"
                        {...params}
                        label={"Area"}
                    />
                    }
                />
            </Grid>
            <Grid sx={{ pr: 1 }} sm={2} item>
                {console.log("avinash",processList, area )}
                <Autocomplete
                    value={process}
                    onChange={onProcessChange}
                    size="small"
                    disabled={!area}
                    id="process"
                    options={processList.filter(({ fkArea }) =>
                        area ? fkArea == area.id :
                            true).map(({ pkProcess, processName }) =>
                                ({ id: pkProcess, label: processName }))}
                    renderInput={(params) => <TextField maxwidth="20px" fullWidth {...params} label="Process" />}
                />
            </Grid>
            <Grid sx={{ pr: 1 }} sm={2} item>
                <Autocomplete
                    value={subprocess}
                    onChange={onSubStatusChange}
                    size="small"
                    id="process"
                    disabled={!process}
                    options={subProcessList.filter(({ fkProcess }) =>
                        process ? fkProcess === process.id : true).map(({ pkSubProcess, subProcessName }) =>
                            ({ id: pkSubProcess, label: subProcessName }))}
                    renderInput={(params) => <TextField maxwidth="20px" fullWidth {...params} label="Subprocess" />}
                />

            </Grid>
            <Grid sx={{ pr: 1 }} sm={2} item>
                <Autocomplete
                    disablePortal
                    filterOptions={(options, state) => options}
                    disableClearable
                    size="small"
                    id="function"
                    name="function"
                    disabled={!subprocess}
                    value={functionVal}
                    options={functionList.filter(({ fkSubProcess }) =>
                        subprocess ? fkSubProcess === subprocess.id : true).map(({ pkFunction, functionName }) =>
                            ({ id: pkFunction, label: functionName }))}
                    onChange={onFunctionChange}
                    renderInput={(params) => <TextField
                        // error={showError && !dropDownVal}
                        fullWidth
                        // helperText={showError && !dropDownVal}
                        maxwidth="20px"
                        {...params}
                        label={"Function"}
                    />
                    }
                />
            </Grid>
            <Grid sx={{ pr: 1 }} sm={2} item>
                <Autocomplete
                    disablePortal
                    disableClearable
                    size="small"
                    id="model"
                    name="model"
                    value={model}
                    disabled={!functionVal}
                    options={newModelList.filter(({ fkFunction }) =>
                        functionVal ? fkFunction === functionVal.id : true).map(({ pkModel, modelName }) => ({ id: pkModel, label: modelName }))}
                    onChange={onModelChange}
                    renderInput={(params) => <TextField
                        //error={showError && !dropDownVal}
                        fullWidth
                        // helperText={showError && !dropDownVal}
                        maxwidth="20px"
                        {...params}
                        label={"Model"}
                    />
                    }
                />
            </Grid>
            <Grid sx={{ pr: 1 }} sm={2} item>
                <Button onClick={getGridData} variant="outlined" startIcon={<SearchOutlinedIcon />}>Search</Button>
            </Grid>
        </Grid>
        {error && <Grid container sx={{ mt: 1 }}>
            <Typography variant='h6' color='#fe3939'>
                Please select atleast one filter criteria
            </Typography>
        </Grid>}
        <Grid xs={12} sx={{mt:2}}>
                <Button variant="outlined" onClick={() => handleClick(data, 'Functional Structure.xlsx', column)}>
                    Export
                </Button>
        </Grid>
        <Grid container sx={{ mt: 3 }}>
            <div style={{
                height: '500px',
                width: '100%'
            }}>
                <StyledDataGrid
                    columns={columns}
                    // loading={isCountryLoading}
                    rows={data}
                    csvOptions={{
                        fileName: 'Functional Structure'
                    }}
                    hideCSV= {true}
                />
            </div>
        </Grid>
        <></>
    </MainCard>
}
