import { createSmartFilterAPI, getSmartFilterListAPI, deleteSamartFilterAPI, updateSmartFilterAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    SFILTER_GET_REQUEST: "SFILTER_GET_REQUEST",
    SFILTER_GET_RECEIVE: "SFILTER_GET_RECEIVE",
    SFILTER_GET_FAIL: "SFILTER_GET_FAIL"
  };

const requestSmartFilter= () => ({
    type: actionTypes.SFILTER_GET_REQUEST,
});
const receiveSmartFilter = (payload) => ({
    type: actionTypes.SFILTER_GET_RECEIVE,
    payload,
});
const failureSmartFilter = (payload) => ({
    type: actionTypes.SFILTER_GET_FAIL,
    payload,
});

export const getSmartFilterAction = () => {
    return async (dispatch) => {
        dispatch(requestSmartFilter());
        try {
            const response = await getSmartFilterListAPI();
            if (response?.status === 200) {
                dispatch(receiveSmartFilter(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureSmartFilter(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const createSmartfilterAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestSmartFilter());
        try {
            const response = await createSmartFilterAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Filter saved successfully"));
            }
            dispatch(getSmartFilterAction());
            dispatch({type: 'SFILTER_SET_FLAG'});
        } catch (error){
            dispatch(failureSmartFilter(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateSmartFIlterAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestSmartFilter());
        try {
            const response = await updateSmartFilterAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Filter updated successfully"));
            }
            dispatch(getSmartFilterAction());
            dispatch({type: 'SFILTER_SET_FLAG'});
        } catch (error) {
            dispatch(failureSmartFilter(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deleteSmartFilterAPIAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestSmartFilter());
        try {
            const response = await deleteSamartFilterAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("FIlter deleted successfully"));
            }
            dispatch(getSmartFilterAction());
        } catch (error) {
            dispatch(failureSmartFilter(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}


