
import { getNotificationById, updateReadNotificationStatusAPI, archiveNotificationAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    NOTIFICATION_PENDING: "NOTIFICATION_PENDING",
    NOTIFICATION_RECEIVED: "NOTIFICATION_RECEIVED",
    NOTIFICATION_FAILED: "NOTIFICATION_FAILED"
  };

const requestNotification= () => ({
    type: actionTypes.NOTIFICATION_PENDING,
});
const recieveNotification = (payload) => ({
    type: actionTypes.NOTIFICATION_RECEIVED,
    payload,
});
const faliureNotification = (payload) => ({
    type: actionTypes.NOTIFICATION_FAILED,
    payload,
});

export const getNotificationByIdAction = (email) => {
    return async (dispatch) => {             
         dispatch(requestNotification());
        try {
            const response = await getNotificationById(email);            
            if (response?.status === 200) {                
                if (response?.data !== 'No Notifications'){
                    dispatch(recieveNotification(response?.data));
                } else {
                dispatch(recieveNotification([]));
                }
            }
        } catch (error) {
            dispatch(faliureNotification(error?.response?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const updateReadNotificationAction = (notificationIdFilter, userIdFilter) => {
    return async (dispatch) => {
        dispatch(requestNotification());
        try {
            const response = await updateReadNotificationStatusAPI(notificationIdFilter, userIdFilter);
            if (response?.status === 201) {
                // dispatch(actions.successAlert("Condition added successfully"));
            }
            dispatch(getNotificationByIdAction(userIdFilter));
        } catch (error) {
            dispatch(faliureNotification(error?.response?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const archiveNotificationAction = (notificationIdFilter, userIdFilter) => {
    return async (dispatch) => {
        dispatch(requestNotification());
        try {
            const response = await archiveNotificationAPI(notificationIdFilter, userIdFilter);
            if (response?.status === 201) {
                // dispatch(actions.successAlert("Notification archived successfully"));
            }
            dispatch(getNotificationByIdAction(userIdFilter));
        } catch (error) {
            dispatch(faliureNotification(error?.response?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
