// material-ui
import { createTheme } from '@mui/material/styles';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode) => {

    return createTheme({
        palette: {
            mode,
            common: {
                black: '#000',
                white: '#fff',
                lightGray: "#6d7d88",
                dgHeader: '#0F5689',
                evenCell: '#f4f8fb'
            },
            background: {
                default: '#f2f2f2'
            }
        }
    });
};

export default Palette;
