import { getAllRequestHistoryAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    REQUEST_ALL_HISTORY: "REQUEST_ALL_HISTORY",
    RECEIVE_ALL_HISTORY: "RECEIVE_ALL_HISTORY",
    FAIL_ALL_HISTORY: "FAIL_ALL_HISTORY"
  };


const requestAllHistory= () => ({
    type: actionTypes.REQUEST_ALL_HISTORY,
});
const receiveAllHistory = (payload) => ({
    type: actionTypes.RECEIVE_ALL_HISTORY,
    payload,
});
const failureAllHistory = (payload) => ({
    type: actionTypes.FAIL_ALL_HISTORY,
    payload,
});

export const getAllRequestHistoryAction = () => {
    return async (dispatch) => {
        dispatch(requestAllHistory());
        try {
            const response = await getAllRequestHistoryAPI();
            if (response?.status === 200) {
                dispatch(receiveAllHistory( response?.data?.data));
            }
        } catch (error) {
            dispatch(failureAllHistory(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};