import { actionTypes } from "./actions";

export const initialState = {
    validateCompanyValueList: []
};

// ===========================|| COMMON REDUCER ||=========================== //

const validateCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VALIDATE_COMPANY_GET_REQUEST:
      return {
        ...state,
        VCLoading: false
      };
    case actionTypes.VALIDATE_COMPANY_GET_RECEIVE:
      return {
        ...state,
        validateCompanyValueList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        VCLoading: false,
      };
    case actionTypes.VALIDATE_COMPANY_GET_FAILURE:
      return {
        ...state,
        VCLoading: false
      };
    default:
      return state;
  }
};

export default validateCompanyReducer;