import { API } from "api";

export const getModelListAPI = (req) => {
    return API.get("api/model/getall?page=0&size=0")
        .then((res) => res);
};

export const getAllActiveModelListAPI = (req) => {
    return API.get("api/model/getallactive?page=0&size=0")
        .then((res) => res);
};

export const createModelAPI = (payload) => {
    return API.post("/api/model/add", payload)
        .then((res) => res);
}

export const updateModelAPI = (id, payload) => {
    return API.put(`/api/model/update/${id}`, payload)
        .then((res) => res);
}

export const deleteModelAPI = (id) => {
    return API.delete(`/api/model/delete/${id}`)
        .then((res) => res);
}
