
// import { actionTypes } from "./actions";
import { searchEqReserAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    EQ_RESER_REQUEST: "EQ_RESER_REQUEST",
    EQ_RESER_RECEIVE: "EQ_RESER_RECEIVE",
    EQ_RESER_FAIL: "EQ_RESER_FAIL",
    EQ_RESER_BYUSER_REQUEST: "EQ_RESER_BYUSER_REQUEST",
    EQ_RESER_BYUSER_RECEIVE: "EQ_RESER_BYUSER_RECEIVE",
    EQ_RESER_BYUSER_FAIL: "EQ_RESER_BYUSER_FAIL"
  };

const requestMachineReser= () => ({
    type: actionTypes.EQ_RESER_REQUEST,
});
const receiveMachineReser = (payload) => ({
    type: actionTypes.EQ_RESER_RECEIVE,
    payload,
});
const failureMachineReser = (payload) => ({
    type: actionTypes.EQ_RESER_FAIL,
    payload,
});

export const searchMachineReser = (payload, page = 0, size = 0, withoutEquipment = false) => {
    return async (dispatch) => {
        dispatch(requestMachineReser());
        try {
            const response = await searchEqReserAPI(payload, page, size, withoutEquipment);
            if (response?.status === 200) {
                dispatch(receiveMachineReser(response?.data));
            }
        } catch (error){
            dispatch(failureMachineReser(error?.response?.data?.data));
            if (error?.response) {
               // dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                //  dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}



const requestMachineReserByUser= () => ({
    type: actionTypes.EQ_RESER_BYUSER_REQUEST,
});
const receiveMachineReserByUser = (payload) => ({
    type: actionTypes.EQ_RESER_BYUSER_RECEIVE,
    payload,
});
const failureMachineReserByUser = (payload) => ({
    type: actionTypes.EQ_RESER_BYUSER_FAIL,
    payload,
});

export const searchMachineReserByUser = (payload, page = 0, size = 0, withoutEquipment=false) => {
    return async (dispatch) => {
        dispatch(requestMachineReserByUser());
        try {
            const response = await searchEqReserAPI(payload, page, size, withoutEquipment);
            if (response?.status === 200) {
                dispatch(receiveMachineReserByUser(response?.data));
            }
        } catch (error){
            dispatch(failureMachineReserByUser(error?.response?.data?.data));
            if (error?.response) {
               // dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
             //    dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
