
import * as React from 'react';
import EquipmentEditTabs from './EquipmentEditTabs';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import GeneralEditTab from './GeneralEditTab';
import { Grid } from '../../../node_modules/@mui/material/index';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ReloadAddSamid({ modalData, okConfirm }) {
    const [seed, setSeed] = React.useState(1);
    const reset = () => {
        setSeed(Math.random());
    }


    return (
        // <EquipmentEditTabs onClose={reset} key={seed} />
        <Box>
            <Grid item sx={{ mt: 2, pl: 2 }}>
                <Typography variant="h3">{" Create new Equipment"}</Typography>
            </Grid>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={0} aria-label="basic tabs example">
                    <Tab label="General" {...a11yProps(0)} />
                </Tabs>
            </Box>
            <TabPanel value={0} index={0}>
                <GeneralEditTab showOnlyOneSave={true} key={seed} setEqId={reset} />
            </TabPanel>
        </Box>

    );
}



