import { API } from "api";

export const getAreaListAPI = (req) => {
    return API.get("api/area/getall?page=0&size=0")
        .then((res) => res);
};


export const createAreaAPI = (payload) => {
    return API.post("/api/area/add", payload)
        .then((res) => res);
}

export const updateAreaAPI = (id, payload) => {
    return API.put(`/api/area/update/${id}`, payload)
        .then((res) => res);
}

export const deleteAreaAPI = (id) => {
    return API.delete(`/api/area/delete/${id}`)
        .then((res) => res);
}
