import { API } from "api";

export const getCountriesListAPI = (req) => {
    return API.get("api/country/getall?page=0&size=0")
                .then((res) => res);
};


    export const createCountryAPI = (payload) => {
        return API.post("/api/country/add", payload)
                    .then((res) => res);
    }
    
    export const updateCountryAPI = (id, payload) => {
        return API.put(`/api/country/update/${id}`, payload)
                    .then((res) => res);
    }
    
    export const deleteCountryAPI = (id) => {
        return API.delete(`/api/country/delete/${id}`)
                    .then((res) => res);
    }

    export const getActiveCountriesListAPI = (req) => {
        return API.get("api/country/getallactive?page=0&size=0")
                    .then((res) => res);
    };
