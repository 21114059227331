import cryptoBrowserify from 'crypto-browserify';
const SCOPE = 'email+openid+profile+aws.cognito.signin.user.admin';
const accessToken = window.localStorage.getItem('accessToken');
const idToken = window.localStorage.getItem('idToken');
const refreshToken = window.localStorage.getItem('refreshToken');
const tokenExpiry = window.localStorage.getItem('tokenExpiry');
const newURL = window.localStorage.getItem('replaceURL');
let tokens = accessToken === null ? null : {
    accessToken,
    idToken,
    refreshToken,
    tokenExpiry,
    newURL
};
const base64URLEncode = str => {
    return str.toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
};
let storedVerifier = window.localStorage.getItem('verifier');
if (storedVerifier === null) {
    const newVerifier = base64URLEncode(cryptoBrowserify.randomBytes(32));
    window.localStorage.setItem('verifier', newVerifier);
    storedVerifier = newVerifier;
}

const verifier = storedVerifier;
let redirectUri = process.env.REACT_APP_REDIRECT_URL
const baseUrl = process.env.REACT_APP_COGNITO_BASE_URL
const clientId = process.env.REACT_APP_CLIENT_ID
const tokenUrl = `${baseUrl}/oauth2/token`;
export const loginUrl = `${baseUrl}/oauth2/authorize?scope=${SCOPE}&response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
export const logoutUrl = `${baseUrl}/logout?client_id=${clientId}&logout_uri=${redirectUri}`;
export const userInfoUrl = `${baseUrl}/oauth2/userInfo`;
 
export const refreshTokens = async refreshToken => {
    const body = `grant_type=refresh_token&client_id=${clientId}&refresh_token=${refreshToken}`;
    const response = await fetch(tokenUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body,
    });
    if (!response.ok) {
        throw Error();
    }   
    window.localStorage.clear();
    const { access_token, id_token } = await response.json();
    window.localStorage.setItem('accessToken', access_token);
    window.localStorage.setItem('idToken', id_token);
    window.localStorage.setItem('refreshToken', refreshToken);
    const expiryDateTime = new Date();
    expiryDateTime.setMinutes(expiryDateTime.getMinutes() + 10);
    window.localStorage.setItem('tokenExpiry', expiryDateTime);
    tokens = {
        accessToken: access_token,
        idToken: id_token,
        refreshToken: refreshToken,
        tokenExpiry: expiryDateTime,
        newURL:newURL
    };
};
export const login = async code => {
    const body = `grant_type=authorization_code&client_id=${clientId}&code_verifier=${verifier}&code=${code}&redirect_uri=${redirectUri}`;
    const response = await fetch(tokenUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body,
    });

    if (!response.ok) {
        throw Error();
    }

    const { access_token, id_token, refresh_token } = await response.json();
    window.localStorage.setItem('accessToken', access_token);
    window.localStorage.setItem('idToken', id_token);
    window.localStorage.setItem('refreshToken', refresh_token);
    const expiryDateTime = new Date();
    expiryDateTime.setMinutes(expiryDateTime.getMinutes() + 10);
    window.localStorage.setItem('tokenExpiry', expiryDateTime);
    tokens = {
        accessToken: access_token,
        idToken: id_token,
        refreshToken: refresh_token,
        tokenExpiry: expiryDateTime,
        newURL:newURL
    };
};
export const logout = async () => {
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('idToken');
    window.localStorage.removeItem('refreshToken');
    window.localStorage.removeItem('tokenExpiry');
    tokens = null;
    console.log('tokens inn logout',tokens);
};

export const getTokens = () => tokens;