
import { getAllProductFormatAPI, createProductFormatAPI, updateProductFormatAPI, deleteProductFormatAPI, getAllActiveAreaAPI, getAllProductFormatTypeAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    PRODUCT_FORMAT_PENDING: "PRODUCT_FORMAT_PENDING",
    PRODUCT_FORMAT_RECEIVED: "PRODUCT_FORMAT_RECEIVED",
    PRODUCT_FORMAT_FAILED: "PRODUCT_FORMAT_FAILED",

    // AREA DROPDOWN
    // AREA_PENDING: "AREA_PENDING",
    // AREA_RECEIVED: "AREA_RECEIVED",
    // AREA_FAILED: "AREA_FAILED"
};

const requestProductFormats= () => ({
    type: actionTypes.PRODUCT_FORMAT_PENDING,
});
const receiveProductFormats = (payload) => ({
    type: actionTypes.PRODUCT_FORMAT_RECEIVED,
    payload,
});
const failureProductFormats = (payload) => ({
    type: actionTypes.PRODUCT_FORMAT_FAILED,
    payload,
});
  //AREA DROPDOWN
// const requestArea= () => ({
//     type: actionTypes.AREA_PENDING,
// });
// const receiveArea = (payload) => ({
//     type: actionTypes.AREA_RECEIVED,
//     payload,
// });
// const failureArea = (payload) => ({
//     type: actionTypes.AREA_FAILED,
//     payload,
// });

export const getAllProductFormatAction = () => {
    return async (dispatch) => {
        dispatch(requestProductFormats());
        try {
            const response = await getAllProductFormatAPI();
            if (response?.status === 200) {
                dispatch(receiveProductFormats(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureProductFormats(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const createProductFormatAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestProductFormats());
        try {
            const response = await createProductFormatAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Product Format added successfully"));
            }
            dispatch(getAllProductFormatAction());
        } catch (error) {
            dispatch(failureProductFormats(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateProductFormatAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestProductFormats());
        try {
            const response = await updateProductFormatAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Product Format updated successfully"));
            }
            dispatch(getAllProductFormatAction());
        } catch (error) {
            dispatch(failureProductFormats(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deleteProductFormatAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestProductFormats());
        try {
            const response = await deleteProductFormatAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Product Format deleted successfully"));
            }
            dispatch(getAllProductFormatAction());
        } catch (error) {
            dispatch(failureProductFormats(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

//AREA DROPDOWN
export const getAllActiveAreaAction = () => {
    return async (dispatch) => {
        dispatch(actions.requestArea());
        try {
            const response = await getAllActiveAreaAPI();
            if (response?.status === 200) {
                dispatch(actions.receiveArea(response?.data?.data));
            }
        } catch (error) {
            dispatch(actions.failureArea(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getAllProductFormatTypeAction = () => {
    return async (dispatch) => {
        dispatch(actions.requestProductFormatType());
        try {
            const response = await getAllProductFormatTypeAPI();
            if (response?.status === 200) {
                dispatch(actions.receiveProductFormatType(response?.data?.data));
            }
        } catch (error) {
            dispatch(actions.failureProductFormatType(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
