
// import { actionTypes } from "./actions";
import { createProcessAPI, updateProcessAPI, deleteProcessAPI, getProcessListAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';
import { gettFnstrAction } from '../../state/actions';

export const actionTypes = {
    PROCESS_GET_REQUEST: "PROCESS_GET_REQUEST",
    PROCESS_GET_RECEIVE: "PROCESS_GET_RECEIVE",
    PROCESS_GET_FAIL: "PROCESS_GET_FAIL"
};

const requestProcess = () => ({
    type: actionTypes.PROCESS_GET_REQUEST,
});
const receiveProcess = (payload) => ({
    type: actionTypes.PROCESS_GET_RECEIVE,
    payload,
});
const failureProcess = (payload) => ({
    type: actionTypes.PROCESS_GET_FAIL,
    payload,
});



export const getProcessAction = () => {
    return async (dispatch) => {
        dispatch(requestProcess());
        try {
            const response = await getProcessListAPI();
            if (response?.status === 200) {
                dispatch(receiveProcess(response?.data?.data));
                // dispatch(actions.successAlert("Coutries fetched successfully"));
            }
        } catch (error) {
            dispatch(failureProcess(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const createProcessAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestProcess());
        try {
            const response = await createProcessAPI(payload);
            if (response?.status === 201) {
               // dispatch(receiveProcess(response?.data?.data));
                dispatch(actions.successAlert("Process added successfully"));
            }
            dispatch(gettFnstrAction());
            dispatch(getProcessAction());

        } catch (error) {
            dispatch(failureProcess(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateProcessAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestProcess());
        try {
            const response = await updateProcessAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Process updated successfully"));
            }
            dispatch(gettFnstrAction());

        } catch (error) {
            dispatch(failureProcess(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deleteProcessAPIAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestProcess());
        try {
            const response = await deleteProcessAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Process deleted successfully"));
            }
            dispatch(gettFnstrAction());

        } catch (error) {
            dispatch(failureProcess(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
