import { equipmentAPI } from "api";

export const getRuleGroupConfigAPI = () => {
    return equipmentAPI.post("api/machinedetail/getallmachinetypehdrdetails")
        .then((res) => res);
};

export const getEquipmentTypeConfigAPI = (id) => {
    return equipmentAPI.post(`api/machinedetail/getallmachinetypehdrdetailbyeqmtid/${id}`)
        .then((res) => res);
};


export const getRuleGroupConfigByIDAPI = (id) => {
    return equipmentAPI.post(`api/machinedetail/getallmachinetypehdrdtlsbyrulegroup/${id}`)
        .then((res) => res);
};

export const getRuleGroupConfigByEqmtIDAPI = (id) => {
    return equipmentAPI.post(`api/machinedetail/GetAllMachineTypeHdrDetailByEqmtID/${id}`)
        .then((res) => res);
};


export const updateRuleGroupConfigAPI = (id,payload) => {
    return equipmentAPI.post(`api/machinedetail/updatemachinetypeheader/${id}`,payload)
        .then((res) => res);
};

export const addRuleGroupConfigAPI = (payload) => {   
    return equipmentAPI.post(`/api/machinedetail/addmachinetypeheader`, payload)
        .then((res) => res);
}

export const deleteRuleGroupConfigAPI = (id) => {
    return equipmentAPI.delete(`/api/machinedetail/DeleteMachineTypeHeader/${id}`)
        .then((res) => res);
};

