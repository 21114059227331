import { actions } from 'store/Reducer/actions';
import { getRegionByIdAPI, formDocumentAPI, submitMRAPI,decisionAPI, updateAPI,completeAPI,cancelAPI, 
    downloadDocAPI, deleteDocAPI, searchContactAPI, formDetailAPI, formInitialAPI, reSubmitAPI } from './endpoints';
export const actionTypes = {
    GET_REGION_PENDING: "GET_REGION_PENDING",
    GET_REGION_RECEIVED: "GET_REGION_RECEIVED",
    GET_REGION_FAILED: "GET_REGION_FAILED",
    MR_UPLOAD_PENDING: "MR_UPLOAD_PENDING",
    MR_UPLOAD_RECEIVED: "MR_UPLOAD_RECEIVED",
    MR_UPLOAD_FAILED: "MR_UPLOAD_FAILED",
    MR_SUBMIT_PENDING: "MR_SUBMIT_PENDING",
    MR_SUBMIT_RECEIVED: "MR_SUBMIT_RECEIVED",
    MR_SUBMIT_FAILED: "MR_SUBMIT_FAILED",
    MR_RESUBMIT_PENDING: "MR_RESUBMIT_PENDING",
    MR_RESUBMIT_RECEIVED: "MR_RESUBMIT_RECEIVED",
    MR_RESUBMIT_FAILED: "MR_RESUBMIT_FAILED",
    MR_DECISION_PENDING: "MR_DECISION_PENDING",
    MR_DECISION_RECEIVED: "MR_DECISION_RECEIVED",
    MR_DECISION_FAILED: "MR_DECISION_FAILED",
    MR_UPDATE_PENDING: "MR_UPDATE_PENDING",
    MR_UPDATE_RECEIVED: "MR_UPDATE_RECEIVED",
    MR_UPDATE_FAILED: "MR_UPDATE_FAILED",
    MR_TRANSFER_RECEIVED: "MR_TRANSFER_RECEIVED",
    MR_COMPLETE_PENDING: "MR_COMPLETE_PENDING",
    MR_COMPLETE_RECEIVED: "MR_COMPLETE_RECEIVED",
    MR_COMPLETE_FAILED: "MR_COMPLETE_FAILED",
    MR_CANCEL_PENDING: "MR_CANCEL_PENDING",
    MR_CANCEL_RECEIVED: "MR_CANCEL_RECEIVED",
    MR_CANCEL_FAILED: "MR_CANCEL_FAILED",
    GET_DOCUMENT_PENDING: "GET_DOCUMENT_PENDING",
    GET_DOCUMENT_RECEIVED: "GET_DOCUMENT_RECEIVED",
    GET_DOCUMENT_FAILED: "GET_DOCUMENT_FAILED",
    DOWNLOAD_DOC_PENDING: "DOWNLOAD_DOC_PENDING",
    DOWNLOAD_DOC_RECEIVED: "DOWNLOAD_DOC_RECEIVED",
    DOWNLOAD_DOC_FAILED: "DOWNLOAD_DOC_FAILED",
    DELETE_DOC_PENDING: "DELETE_DOC_PENDING",
    DELETE_DOC_RECEIVED: "DELETE_DOC_RECEIVED",
    DELETE_DOC_FAILED: "DELETE_DOC_FAILED",
    GET_CONTACTS_PENDING: "GET_CONTACTS_PENDING",
    GET_CONTACTS_RECEIVED: "GET_CONTACTS_RECEIVED",
    GET_CONTACTS_FAILED: "GET_CONTACTS_FAILED",
    GET_DETAIL_PENDING: "GET_DETAIL_PENDING",
    GET_DETAIL_RECEIVED: "GET_DETAIL_RECEIVED",
    GET_DETAIL_FAILED: "GET_DETAIL_FAILED",
    GET_MR_INITIAL_PENDING: "GET_MR_INITIAL_PENDING",
    GET_MR_INITIAL_RECEIVED: "GET_MR_INITIAL_RECEIVED",
    GET_MR_INITIAL_FAILED: "GET_MR_INITIAL_FAILED",
};
const regionRequestPending= () => ({
    type: actionTypes.GET_REGION_PENDING,
});
const regionRequestReceived = (payload) => ({
    type: actionTypes.GET_REGION_RECEIVED,
    payload,
});
const regionRequestFailed = (payload) => ({
    type: actionTypes.GET_REGION_FAILED,
    payload,
});

const formUploadPending= () => ({
    type: actionTypes.MR_UPLOAD_PENDING,
});
const formUploadReceived = (payload) => ({
    type: actionTypes.MR_UPLOAD_RECEIVED,
    payload,
});
const formUploadFailed = (payload) => ({
    type: actionTypes.MR_UPLOAD_FAILED,
    payload,
})
const mrSubmitPending= () => ({
    type: actionTypes.MR_SUBMIT_PENDING,
});
const mrSubmitReceived = (payload) => ({
    type: actionTypes.MR_SUBMIT_RECEIVED,
    payload,
});
const mrSubmitFailed = (payload) => ({
    type: actionTypes.MR_SUBMIT_FAILED,
    payload,
})
const mrReSubmitPending= () => ({
    type: actionTypes.MR_RESUBMIT_PENDING,
});
const mrReSubmitReceived = (payload) => ({
    type: actionTypes.MR_RESUBMIT_RECEIVED,
    payload,
});
const mrReSubmitFailed = (payload) => ({
    type: actionTypes.MR_RESUBMIT_FAILED,
    payload,
})
const decisionPending= () => ({
    type: actionTypes.MR_DECISION_PENDING,
});
const decisionReceived = (payload) => ({
    type: actionTypes.MR_DECISION_RECEIVED,
    payload,
});
const decisionFailed = (payload) => ({
    type: actionTypes.MR_DECISION_FAILED,
    payload,
})
const updatePending= () => ({
    type: actionTypes.MR_UPDATE_PENDING,
});
const updateReceived = (payload) => ({
    type: actionTypes.MR_UPDATE_RECEIVED,
    payload,
});
const transferReceived = (payload) => ({
    type: actionTypes.MR_TRANSFER_RECEIVED,
    payload,
});
const updateFailed = (payload) => ({
    type: actionTypes.MR_UPDATE_FAILED,
    payload,
})

const completePending= () => ({
    type: actionTypes.MR_COMPLETE_PENDING,
});
const completeReceived = (payload) => ({
    type: actionTypes.MR_COMPLETE_RECEIVED,
    payload,
});
const completeFailed = (payload) => ({
    type: actionTypes.MR_COMPLETE_FAILED,
    payload,
})

const cancelPending= () => ({
    type: actionTypes.MR_CANCEL_PENDING,
});
const cancelReceived = (payload) => ({
    type: actionTypes.MR_CANCEL_RECEIVED,
    payload,
});
const cancelFailed = (payload) => ({
    type: actionTypes.MR_CANCEL_FAILED,
    payload,
})
const downloadDocPending= () => ({
    type: actionTypes.DOWNLOAD_DOC_PENDING,
});
const downloadDocReceived = (payload) => ({
    type: actionTypes.DOWNLOAD_DOC_RECEIVED,
    payload,
});
const downloadDocFailed = (payload) => ({
    type: actionTypes.DOWNLOAD_DOC_FAILED,
    payload,
})
const deletedDocPending= () => ({
    type: actionTypes.DELETE_DOC_PENDING,
});
const deletedDocReceived = (payload) => ({
    type: actionTypes.DELETE_DOC_RECEIVED,
    payload,
});
const deletedDocFailed = (payload) => ({
    type: actionTypes.DELETE_DOC_FAILED,
    payload,
})
const requestContactsPending= () => ({
    type: actionTypes.GET_CONTACTS_PENDING,
});
const requestContactsReceived = (payload) => ({
    type: actionTypes.GET_CONTACTS_RECEIVED,
    payload,
});
const requestContactsFailed = (payload) => ({
    type: actionTypes.GET_CONTACTS_FAILED,
    payload,
})
const requestFormDetailPending= () => ({
    type: actionTypes.GET_DETAIL_PENDING,
});
const requestFormDetailReceived = (payload) => ({
    type: actionTypes.GET_DETAIL_RECEIVED,
    payload,
});
const requestFormDetailFailed = (payload) => ({
    type: actionTypes.GET_DETAIL_FAILED,
    payload,
})
const requestFormInitialPending= () => ({
    type: actionTypes.GET_MR_INITIAL_PENDING,
});
const requestFormInitialReceived = (payload) => ({
    type: actionTypes.GET_MR_INITIAL_RECEIVED,
    payload,
});
const requestFormInitialFailed = (payload) => ({
    type: actionTypes.GET_MR_INITIAL_FAILED,
    payload,
})

export const getRegionByIdAction = (payload) => {   
    return async (dispatch) => {
        dispatch(regionRequestPending());
        try {
            const response = await getRegionByIdAPI(payload);
            if (response?.status === 200) {
                dispatch(regionRequestReceived(response?.data?.data));
            }
        } catch (error) {
            dispatch(regionRequestFailed(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const formDocumentAction = (payload) => { 
    return async (dispatch) => {
        dispatch(formUploadPending());
        try {
            const response = await formDocumentAPI(payload);                      
            if (response?.status === 201) {
                dispatch(formUploadReceived(response?.data?.data));
                dispatch(actions.successAlert("Document uploaded successfully"));
            }
        } catch (error) {    
            dispatch(formUploadFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const submitMRAction = (payload) => { 
    return async (dispatch) => {
        dispatch(mrSubmitPending());
        try {
            const response = await submitMRAPI(payload);    
            if (response?.status === 201) {
                dispatch(mrSubmitReceived(response?.data?.data));
                dispatch(actions.successAlert("Machine Reservation request added successfully"));
            }
        } catch (error) {  
            console.log("error",error)    
            dispatch(mrSubmitFailed(error?.response?.data));        
            if (error?.response) {
                if(error?.response?.data?.errorMessage !== undefined)
                    dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
                else
                    dispatch(actions.errorAlert(error?.response?.data?.title));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const reSubmitAction = (payload) => { 
    return async (dispatch) => {
        dispatch(mrReSubmitPending());
        try {
            const response = await reSubmitAPI(payload);    
            if (response?.status === 200) {
                dispatch(mrReSubmitReceived(response?.data?.data));
                dispatch(actions.successAlert("Machine Reservation request re-submitted successfully"));
            }
        } catch (error) {  
            console.log("error",error)    
            dispatch(mrReSubmitFailed(error?.response?.data));        
            if (error?.response) {
                if(error?.response?.data?.errorMessage !== undefined)
                    dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
                else
                    dispatch(actions.errorAlert(error?.response?.data?.title));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const decisionAction = (payload) => { 
    return async (dispatch) => {
        dispatch(decisionPending());
        try {
            const response = await decisionAPI(payload);   
            if (response?.status === 200) {
                dispatch(decisionReceived(response.data));
                dispatch(actions.successAlert("Machine Reservation request updated successfully"));
            }
        } catch (error) {    
            dispatch(decisionFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const transferAction = (payload) => { 
    return async (dispatch) => {
        dispatch(updatePending());
        try {
            const response = await updateAPI(payload,0);   
            if (response?.status === 201) {
                dispatch(transferReceived(response.data));
                // dispatch(formMrfAction(payload.pkForm))
            }
        } catch (error) {    
            dispatch(updateFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateAction = (payload) => { 
    return async (dispatch) => {
        dispatch(updatePending());
        try {
            const response = await updateAPI(payload,1);   
            if (response?.status === 201) {
                dispatch(updateReceived(response.data));
                dispatch(actions.successAlert("Machine Reservation request updated successfully"));
            }
        } catch (error) {    
            dispatch(updateFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const completeAction = (payload) => { 
    return async (dispatch) => {
        dispatch(completePending());
        try {
            const response = await completeAPI(payload);                      
            if (response?.status === 200) {
                 dispatch(completeReceived(response.data));
                dispatch(actions.successAlert("Machine Reservation request completed successfully"));
            }
        } catch (error) {    
            dispatch(completeFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const cancelAction = (payload) => { 
    return async (dispatch) => {
        dispatch(cancelPending());
        try {
            const response = await cancelAPI(payload);                      
            if (response?.status === 200) {
                dispatch(cancelReceived(response.data));
                dispatch(actions.successAlert("Machine Reservation request cancelled"));
            }
        } catch (error) {    
            dispatch(cancelFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const downloadDocAction = (payload) => { 
    return async (dispatch) => {
        dispatch(downloadDocPending());
        try {
            const response = await downloadDocAPI(payload);                      
            if (response?.status === 200) {
                dispatch(downloadDocReceived(response?.data?.data));
            }
        } catch (error) {    
            dispatch(downloadDocFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const deleteDocAction = (payload) => { 
    return async (dispatch) => {
        dispatch(deletedDocPending());
        try {
            const response = await deleteDocAPI(payload);                      
            if (response?.status === 200) {
                dispatch(deletedDocReceived(response?.data?.success));
                dispatch(actions.successAlert("Document deleted successfully"));
            }
        } catch (error) {    
            dispatch(deletedDocFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const searchContactAction = (payload) => { 
    return async (dispatch) => {
        dispatch(requestContactsPending());
        try {
            const response = await searchContactAPI(payload);                      
            if (response?.status === 200) {
                dispatch(requestContactsReceived(response?.data?.data));
            }
        } catch (error) {    
            dispatch(requestContactsFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const formMrfAction = (payload) => { 
    return async (dispatch) => {
        dispatch(requestFormDetailPending());
        try {
            const response = await formDetailAPI(payload); 
            if (response?.status === 200) {
                dispatch(requestFormDetailReceived(response?.data?.data));
            }
        } catch (error) {    
            dispatch(requestFormDetailFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const formInitialAction = () => { 
    return async (dispatch) => {
        dispatch(requestFormInitialPending());
        try {
            const response = await formInitialAPI();   
            if (response?.status === 200) {
                dispatch(requestFormInitialReceived(response?.data?.data));
            }
        } catch (error) {    
            dispatch(requestFormInitialFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}