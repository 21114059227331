import { actionTypes } from "./actions";

export const initialState = {
  productFormats: [],
  loading: false
};

// ===========================|| COMMON REDUCER ||=========================== //

const productFormatReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCT_FORMAT_PENDING:
      return {
        ...state,
        loading: true,
      };
      case actionTypes.PRODUCT_FORMAT_RECEIVED:
      return {
        ...state,
        productFormats: action.payload.map((dt, index)=>({...dt, id:index})),
        loading: false,
      };
      case actionTypes.PRODUCT_FORMAT_FAILED:
      return {
        ...state,
        isProductFormatFail: false,
        loading: false
      };
    default:
      return state;
  }
};

export default productFormatReducer;
