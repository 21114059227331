
import {getAllActiveRuleGroupTypeAPI, updateRuleGroupAPI, createRuleGroupAPI ,deleteRuleGroupAPI} from './endpoints'
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    RULE_GROUP_GET_REQUEST: "RULEGROUP_GET_REQUEST",
    RULE_GROUP_GET_RECEIVE: "RULEGROUP_GET_RECEIVE",
    RULE_GROUP_GET_FAIL: "RULEGROUP_GET_FAIL"
  };

const requestRuleGroup= () => ({
    type: actionTypes.RULE_GROUP_GET_REQUEST,
});
const receiveRuleGroup = (payload) => ({
    type: actionTypes.RULE_GROUP_GET_RECEIVE,
    payload,
});
const failureRuleGroup = (payload) => ({
    type: actionTypes.RULE_GROUP_GET_FAIL,
    payload,
});
export const getAllActiveRuleGroupAction = () => {
    return async (dispatch) => {
        dispatch(requestRuleGroup());
        try {
            const response = await getAllActiveRuleGroupTypeAPI();
            if (response?.status === 200) {
                dispatch(receiveRuleGroup(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureRuleGroup(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const createRuleGroupAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestRuleGroup());
        try {
            const response = await createRuleGroupAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Rule Group added successfully"));
            }
            dispatch(getAllActiveRuleGroupAction());
        } catch (error){
            dispatch(failureRuleGroup(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateRuleGroupAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestRuleGroup());
        try {
            const response = await updateRuleGroupAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Rule Group updated successfully"));
            }
            dispatch(getAllActiveRuleGroupAction());
        } catch (error) {
            dispatch(failureRuleGroup(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deleteRuleGroupAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestRuleGroup());
        try {
            const response = await deleteRuleGroupAPI(id);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Rule Group deleted successfully"));
            }
            dispatch(getAllActiveRuleGroupAction());
        } catch (error) {
            dispatch(failureRuleGroup(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
