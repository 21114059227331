import { actionTypes } from "./actions";

export const initialState = {
    mainScreenList:[],
    machineTypeDictUIColMappingsMS: [],
    machineTypeMainData: []  
};


const mainScreenReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MAIN_SCREEN_GET_REQUEST:
      return {
        ...state,
        isMainScreenLoading: true,
      };
    case actionTypes.MAIN_SCREEN_STOP_LOADING:
      return {
        ...state,
        isMainScreenLoading: false,
      };
    //   karishma
    case actionTypes.MAIN_SCREEN_GET_RECEIVE:
      let machineData = action.payload.machineData
      let mappingdata=action.payload.machineTypeDictUIColMappings
      let mappingmainData=action.payload.eqmtmainmachineData
      return {
        ...state,
        mainScreenList: machineData.map((dt, index) => ({ ...dt, id: index })),
        machineTypeDictUIColMappingsMS: mappingdata,
        machineTypeMainData:mappingmainData,
        isMainScreenLoading: false,
      };
    case actionTypes.MAIN_SCREEN_GET_FAIL:
      return {
        ...state,
        isMainScreenLoading: false,
        isMainScreenFail: false,
      };
    case  "CLEAR_MAKER_DATA":
        return {
          ...state,
          mainScreenList: [],
          machineTypeDictUIColMappingsMS:[]
        };
        case actionTypes.EXPORT_ALL_LOAD:
          return {
            ...state,
            isExportAllLoading: true,
          };
    case actionTypes.EXPORT_ALL_RECEIVE:
          return {
            ...state,
            isExportAllLoading : false
          };
    case actionTypes.EXPORT_ALL_FAIL:
          return {
            ...state,
            isExportAllLoading: false,
          };
          case actionTypes.EQUIPMENT_TYPE_GET_REQUEST:
            return {
              ...state,
              isequipmentTypeLoading: true,
            };
          case actionTypes.EQUIPMENT_TYPE_GET_RECEIVE:
            let equipmentData = action.payload.equipmentData
            
            return {
              ...state,
              equipmentType: equipmentData.map((dt, index) => ({ ...dt, id: index })),
              isequipmentTypeLoading: false,
            };
      
          case actionTypes.EQUIPMENT_TYPE_GET_FAIL:
            return {
              ...state,
              isequipmentTypeLoading: false,
             
            };
     default:
      return state;
  }
};

export default mainScreenReducer;
