import { actionTypes } from "./actions";

export const initialState = {
  isRoleLoading: false,
  roleList: [],
};


const adminRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADMIN_ROLES_PENDING:
        return {
            ...state,
            isRoleLoading : true
        };
    case actionTypes.ADMIN_ROLES_RECEIVED:
    return {
        ...state,
        roleList:action.payload.map((dt, index)=>({...dt, id:index})),
        isRoleLoading : false
    };
    case actionTypes.ADMIN_ROLES_FAILED:
        return {
            ...state,
            isRoleLoading : false
        };
    default:
      return state;
  }
};

export default adminRoleReducer;
