import { API } from "api";

export const getAllActiveRuleGroupTypeAPI = () => {
    return API.get("/api/rulegroup/getall?page=1&size=500&sortColumnName=RGPKID")
                .then((res) => res);
};

export const createRuleGroupAPI = (payload) => {
    return API.post("/api/RuleGroup/addRuleGroup", payload)
        .then((res) => res);
}

export const updateRuleGroupAPI = (id, payload) => {   
    return API.put(`/api/RuleGroup/updateRuleGroup/${id}`, payload)
        .then((res) => res);
}

export const deleteRuleGroupAPI = (id) => {   
    return API.delete(`/api/RuleGroup/deleterulegroup/${id}`)
        .then((res) => res);
}
