import { API } from "api";

export const getAllConditionAPI = () => {
    return API.get("/api/condition/getall?page=0&size=0")
                .then((res) => res);
};

export const getAllActiveConditionAPI = () => {
    return API.get("/api/condition/getallactive?page=0&size=0")
                .then((res) => res);
};

export const createConditionAPI = (payload) => {
    return API.post("/api/condition/add", payload)
                .then((res) => res);
}

export const updateConditionAPI = (id, payload) => {
    return API.put(`/api/condition/update/${id}`, payload)
                .then((res) => res);
}

export const deleteConditionAPI = (id) => {
    return API.delete(`/api/condition/delete/${id}`)
                .then((res) => res);
}