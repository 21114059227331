import React, { useEffect, useState, useRef } from 'react';
import MainCard from 'components/MainCard';
import {
    Accordion, AccordionDetails, AccordionSummary, Typography, Box, Divider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import MultiSelect from 'components/Form/MultiSelect';
import { Button, Grid } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { TextField } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { IconButton, Stack } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EquipmentTabs from './EquipmentTabs';
import Link from '@mui/material/Link';
import EquipmentEditTabs from './EquipmentEditTabs';
import optionsData from './SearchConstants';
import { useDispatch, useSelector } from 'react-redux';
import { getAllActiveAreaAction } from '../Management/ProductFormat/state/actions';
import { getAllActiveCompanyAction } from '../Management/Companies/state/actions';
import { getAllActiveRegionAction } from '../Management/Regions/state/actions';
import { getAllActiveOemAction } from '../Management/OEM/state/actions';
import { getAllActiveStatusAction, getAllActiveFunctionAction, getAllFunctionalLocationAction, getEquipmentALLExportAction } from './state/actions';
import { getAllActiveConditionAction } from '../Management/Conditions/state/actions';
import { getAllActiveModelAction } from '../Management/Models/state/actions';
import { getAllSearchEquipmentAction } from './state/actions';
import DataGridServerSide from 'components/DataGridServerSide/index';
import DoneIcon from '@mui/icons-material/Done';
import Tooltip from '@mui/material/Tooltip';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';
import HandshakeIcon from '@mui/icons-material/Handshake'
import FilePresentIcon from '@mui/icons-material/FilePresent';
import LockIcon from '@mui/icons-material/Lock';
import DeleteIcon from '@mui/icons-material/Delete';
import { getProcessAction } from 'containers/Management/FunctionalStructure/Process/state/actions';
import { getSubPrAction } from 'containers/Management/FunctionalStructure/SubProcess/state/actions';
import { getAllCompanyForEquipmentAction } from './Companies/state/actions';
import moment from '../../../node_modules/moment/moment';
import manualExport from '../GenericExcelExport/index';
import MultiSelect1 from 'components/Form/MultiSelect1';
import { getModelFunctionAction } from '../SearchViews/FunctionalStructure/state/actions';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { Backdrop, CircularProgress } from '../../../node_modules/@mui/material/index';


const AccordionSummaryRev = styled(AccordionSummary)({
    flexDirection: 'row-reverse',
    padding: '0px !important',
    paddingTop: '10px !important',

    alignItems: "start",
    '& > div': { margin: '0 !important' }
});

const { data, processOption, locationOtions, areaOption, functionOption, srnoOtions, formatOtions, sapOtions, companyOtions, statusOption, conditionOtions, archivedOption, regionOtions, availablefortransOption, disposalOption, reservedOption } = optionsData;
export default function SearchEquipment({ setPageView, needBack = false }) {
    const [formData, setFormData] = useState([]);
    const [prcoesssubprocessOption, setprcoesssubprocessOption] = useState([]);

    const [expanded, setExpanded] = React.useState(true);
    const [lock, setLock] = React.useState(false);
    const [viewName, setView] = React.useState('main');
    const [rowSelected, setSelectedRow] = React.useState({});
    const [formInput, setFormInput] = useState([]);
    const [pageSize, setPageSize] = useState(500);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    useEffect(() => {
        if (viewName === 'main') {
            setSelectedRow({});
        }
    }, [viewName]);

    const setExpandedPre = (e) =>{
        if(!lock){
            setExpanded(e);
        }
    }

    //Get All Active Area Lists data
    const { activeAreaListForSearchEquipment,
        activeStatusList,
        activeFunctions,
        functionalLocationLists
    } = useSelector((state) => state.common);
    const { activeCompanyLists } = useSelector((state) => state.companyLists);
    const { activeRegionLists, isRegionLoading } = useSelector((state) => state.region);
    const { activeOemLists,oemLists } = useSelector((state) => state.oem);
    const { activeConditions } = useSelector((state) => state.conditions);
    const { activeModelList = [] } = useSelector((state) => state.model);
    const { processList = [], isProcessLoading } = useSelector((state) => state.process);
    const { subProcessListNew = [], isSubProLoading } = useSelector((state) => state.subProcess);
    const { isExportAllLoading, searchEquipments, pageInfo = {}, isEquipmentsLoading } = useSelector((state) => state.allEquipments);
    const [filterdata, setFilterData] = React.useState([]);
    const [showError, setShowError] = useState(false);
    let { roles } = useSelector((state) => state.token);
    const [resetFilter, setclearFilter] = useState(false);
    let clearTextFeild = useRef({})
    const [selectFormData, setSelectFormData] = React.useState({isarchived: 'False'});
    const [inputValue, setInputValue] = React.useState({isarchived: 'False'});
    const { companyforEquipmentList = [] } = useSelector((state) => state.companyforEquipmentList);

    const { modelFunctionList = [] } = useSelector((state) => state.modelFunctiolRel);

    useEffect(() => {
        dispatch(getAllActiveAreaAction());
        dispatch(getAllActiveCompanyAction());
        dispatch(getAllCompanyForEquipmentAction());
        dispatch(getAllActiveRegionAction());
        dispatch(getAllActiveOemAction());
        dispatch(getAllActiveStatusAction());
        dispatch(getAllActiveConditionAction());
        dispatch(getAllActiveModelAction());
        dispatch(getAllActiveFunctionAction());
        dispatch(getAllFunctionalLocationAction());
        dispatch(getSubPrAction());
        dispatch(getProcessAction());
        dispatch(getModelFunctionAction());
    }, []);


    const getIconForCondition = (conditionname) => {
        switch (conditionname) {
            case "Very bad condition":
                return <Stack direction="row" spacing={1}>
                    <CloseIcon />
                </Stack>
            case "Refurbishment needed":
                return <Stack direction="row" spacing={1}>
                    <WarningAmberIcon />
                </Stack>
            case "In operating condition":
                return <Stack direction="row" spacing={1}>
                    <DoneIcon />
                </Stack>
            case "Very bad condition":
                return <Stack direction="row" spacing={1}>
                    <CloseIcon />
                </Stack>
            default:
                return <Stack direction="row" spacing={1}>
                    <QuestionMarkIcon />
                </Stack>
        }
    }

    useEffect(() => {
        if (processList.length && subProcessListNew.length) {
            let option = [];
            processList.forEach((p) => {
                subProcessListNew.forEach((s) => {
                    let name = `${p.processName}\\${s.subProcessName}`
                    if (p.pkProcess === s.fkProcess) {
                        option.push({ label: name, value: name })
                    }
                })
            })
      const abc = option.filter((v,i,a)=>a.findIndex(v2=>(v2.label===v.label))===i)

            setprcoesssubprocessOption(abc);
        }

    }, [processList, subProcessListNew])

    const getYesNo = (dt) => {
        if (dt === 'true' || dt === true) {
            return 'Yes'
        } if (dt === 'false' || dt === false) {
            return 'No'
        }
        return dt;
    }
    const columns = [
        {
            field: 'command',
            headerName: 'Command',
            width: 100,
            disableReorder: true,
            hideSortIcons: true,
            sortable: false,
            filterable: false,
            headerClassName: "dgHeader",
            renderCell: ({ row }) => (
                <Stack direction="row" spacing={1}>
                    { roles.includes('admin')|| companyforEquipmentList.find(({ pkCompany }) => pkCompany === row.fkcompany) ? <IconButton color="primary" aria-label="Edit">
                        <EditOutlinedIcon onClick={() => handleDetailClick(row, 'edit')} />
                    </IconButton> : ""
                    }
                </Stack>
            )
        },
        { field: 'longcompanyname', headerName: `Company`, width: 130, headerClassName: "dgHeader", },
        {
            field: 'pkequipment', headerName: `SAM ID`, width: 100, headerClassName: "dgHeader",
            renderCell: ({ row }) => (
                <Stack direction="row" spacing={1}>
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => handleDetailClick(row, 'details')}
                    >
                        {row.pkequipment}
                    </Link>
                </Stack>
            )
        },
        { field: 'areaname', headerName: `Area`, width: 130, headerClassName: "dgHeader", },
        { field: 'functionallocationname', headerName: `Functional Location`, width: 150, headerClassName: "dgHeader", },
        { field: 'prcoesssubprocessname', headerName: `Process\\Sub-Process`, width: 280, headerClassName: "dgHeader", },
        { field: 'functionname', headerName: `Function`, width: 200, headerClassName: "dgHeader", },
        { field: 'oemname', headerName: `OEM`, width: 130, headerClassName: "dgHeader", },
        { field: 'modelname', headerName: `Model`, width: 130, headerClassName: "dgHeader", },
        { field: 'serialnumber', headerName: `Serial Number`, width: 150, headerClassName: "dgHeader", },
        { field: 'statusname', headerName: `Status`, width: 120, headerClassName: "dgHeader", },
        { field: 'p1idnumber', headerName: `SAP/P1 Ref`, width: 150, headerClassName: "dgHeader", },
        { field: 'productformatstring', headerName: `Product Format`, width: 200, headerClassName: "dgHeader", },
        {
            field: 'conditionname', headerName: `Condition`, width: 120, headerClassName: "dgHeader",
            renderCell: ({ row }) => {
                if(row.statusname != "Production" && row.statusname != "NEW" && row.statusname != "Inactive" && row.statusname != "Develop. / Training"){
                	return <Tooltip title={!row.conditionname ? "-" : row.conditionname}>
                    	<span>
                        	{getIconForCondition(row.conditionname)}
                    	</span>
                	</Tooltip>
                }
                else if((row.statusname === "Production" || row.statusname === "NEW" || row.statusname === "Inactive" || row.statusname === "Develop. / Training") && row.conditionname!== null && row.conditionname!== undefined)
                {
                    return <Tooltip title={!row.conditionname ? "-" : row.conditionname}>
                        <span>
                            {getIconForCondition(row.conditionname)}
                        </span>
                    </Tooltip>
                }
            }
        },
        {
            field: 'functionallocationisproductive',
            renderCell: ({ row }) => {
                return <span>
                    {getYesNo(row.functionallocationisproductive)}
                </span>
            },
            headerName: `Is Functional Location Active`, width: 200, headerClassName: "dgHeader",
        },
        {
            field: 'availablefortransferdate',
            renderCell: ({ row }) => {
                return <span>
                    {getYesNo(row.availablefortransferdate)}
                </span>
            },
            headerName: `Available for transfer`, width: 150, headerClassName: "dgHeader",
        },
        {
            field: 'availabletransferdate',
            headerName: `Availablity for transfer(Date)`,
            width: 150,
            filterable: false,
            headerClassName: "dgHeader",
        },
        { field: 'controlsystemname', headerName: `Control System Name`, width: 180, headerClassName: "dgHeader", },
        { field: 'description', headerName: `Equipment Description`, width: 180, headerClassName: "dgHeader", },
        { field: 'manufacturedyear', headerName: `Manufactured Year`, width: 120, headerClassName: "dgHeader", },
        { field: 'firstinstallationyear', headerName: `First Installation Year`, width: 150, headerClassName: "dgHeader", },
        { field: 'lastrebuildyear', headerName: `Last Rebuild Year`, width: 150, headerClassName: "dgHeader", },
        { field: 'designspeed', headerName: `Design Speed`, width: 150, headerClassName: "dgHeader", },
        { field: 'designspeedunit', headerName: `Design Speed Unit`, width: 150, headerClassName: "dgHeader", },
        { field: 'currentmaximumspeed', headerName: `Production Speed`, width: 150, headerClassName: "dgHeader", },
        { field: 'speedunitname', headerName: `Production Speed Unit`, width: 150, headerClassName: "dgHeader", },
        { field: 'comment', headerName: `Equipment Comment`, width: 150, headerClassName: "dgHeader", },
        {
            field: 'info', headerName: `Info`, width: 100, headerClassName: "dgHeader",
            renderCell: ({ row }) => {
                return <>
                    {row.isconfidential ?
                        <Tooltip title="Confidential equipment">
                            <Stack direction="row" spacing={1}>
                                <LockIcon />
                            </Stack>
                        </Tooltip>
                        : ''
                    }{
                        row.isarchived ?
                            <Tooltip title="Archived equipment">
                                <Stack direction="row" spacing={1}>
                                    <DeleteIcon />
                                </Stack>
                            </Tooltip>
                            : ''
                    }
                    {row.reservationRequest ?
                        <Tooltip title={`Open ${row.reservationRequest}`}>
                            <Stack direction="row" spacing={1}>
                                <HandshakeIcon />
                            </Stack>
                        </Tooltip>
                        : ''}
                    {row.disposalRequest ?
                        <Tooltip title={`Open ${row.disposalRequest}`}>
                            <Stack direction="row" spacing={1}>
                                <HandshakeIcon />
                            </Stack>
                        </Tooltip>
                        : ''}
                    {row.numberofdoc ?
                        <Tooltip title={`${row.numberofdoc} attached`}>
                            <Stack direction="row" spacing={1}>
                                <FilePresentIcon />
                            </Stack>
                        </Tooltip>
                        : ''}
                </>
            }
        },
        {
            field: 'isreserved',
            renderCell: ({ row }) => {
                return <span>
                    {getYesNo(row.isreserved)}
                </span>
            },
            headerName: `Is Reserved`, width: 130, headerClassName: "dgHeader",
        },
        { field: 'reservedby', headerName: `Reserved By`, width: 130, headerClassName: "dgHeader", },
        { field: 'reservationRequest', headerName: `MRF Number`, width: 130, headerClassName: "dgHeader", },
        {
            field: 'disposalstatus',
            renderCell: ({ row }) => {
                return <span>
                    {getYesNo(row.disposalstatus)}
                </span>
            }, headerName: `Is Disposal`, width: 130, headerClassName: "dgHeader",
        },
        { field: 'disposalRequest', headerName: `SAM DR Ref`, width: 120, headerClassName: "dgHeader", },
        { field: 'flareaname', headerName: `Functional Location Area`, width: 180, headerClassName: "dgHeader", },
        { field: 'flproductformatstring', headerName: `Functional Location Product Format`, width: 220, headerClassName: "dgHeader", },
        { field: 'exfunctionallocation', headerName: `Ex-Functional Location`, width: 180, headerClassName: "dgHeader", },
        { field: 'exparentname', headerName: `Ex-parent name`, width: 280, headerClassName: "dgHeader", },
        { field: 'netbookvalue', headerName: `Net book value`, width: 130, headerClassName: "dgHeader", },
        { field: 'fknetbookvaluecurrency', headerName: `Net Book Value Currency`, width: 180, headerClassName: "dgHeader", },
        {
            field: 'netbookvaluedate', headerName: `Net Book Value Date`, width: 150, headerClassName: "dgHeader",
            renderCell: ({ row }) => (moment(row.actionDate).format('MM/DD/YYYY'))
        },
        { field: 'regionname', headerName: `Region`, width: 100, headerClassName: "dgHeader", },
        { field: 'clustername', headerName: `Cluster name`, width: 130, headerClassName: "dgHeader", },
        { field: 'countryname', headerName: `Country`, width: 130, headerClassName: "dgHeader", },
        {
            field: 'isconfidential',
            renderCell: ({ row }) => {
                return <span>
                    {getYesNo(row.isconfidential)}
                </span>
            },
            headerName: `Is Confidential`, width: 130, headerClassName: "dgHeader",
        },
        {
            field: 'isarchived',
            renderCell: ({ row }) => {
                return <span>
                    {getYesNo(row.isarchived)}
                </span>
            }, headerName: `Is Archived`, width: 130, headerClassName: "dgHeader",
        },
        { field: 'storagereasoncomment', headerName: `Storage Reason Comment`, width: 180, headerClassName: "dgHeader", },
        {
            field: 'retrievePart',
            renderCell: ({ row }) => {
                return <span>
                    {getYesNo(row.retrievePart)}
                </span>
            }, headerName: `RetrievePart`, width: 130, headerClassName: "dgHeader",
        },
        {
            field: 'recoverPart',
            renderCell: ({ row }) => {
                return <span>
                    {getYesNo(row.recoverPart)}
                </span>
            }, headerName: `RecoverPart`, width: 130, headerClassName: "dgHeader",
        }
    ];
    const onChange = (name, allSelected) => {
        if (allSelected === 'All') {
            Object.entries(name).forEach(([key, value]) => {
                let filterKeyValue = value && value.map((filter) => filter.value.toString());
                setFilterData([...filterdata, {
                    ['columnName']: key,
                    ['filterValues']: [],
                    ['filterOption']: 9
                }
                ]);
            })
        } else {
            Object.entries(name).forEach(([key, value]) => {
                if (key !== '' && value.length > 0) {
                    let filterKeyValue = value && value.map((filter) => filter.value.toString());
                    console.log("key",key)
                    console.log("filterKeyValue",filterKeyValue)
                    let uniquefilterData = [];
                    uniquefilterData = filterdata.filter(item => item.columnName !== key);
                    
                     if(key==='fkfunction'){
                        let findFunction =[]
                        filterKeyValue.forEach(filterItem=>{
                            let findFunctionName = activeFunctions.find(funtionItem=>funtionItem.value ===Number(filterItem)).label
                            findFunction.push(findFunctionName)
                        })
                        let abcssa = [...uniquefilterData, {
                            ['columnName']: key,
                            ['filterValues']: findFunction,
                            ['filterOption']: 9
                        }
                        ];
                        setFilterData(abcssa);
                     }else{
                        let abcssa =[]
                        if(key==='fkmodel'){
                            let findModel =[]
                            filterKeyValue.forEach(filterItem=>{
                                let findFunctionName = activeModelList.find(funtionItem=>funtionItem.value ===Number(filterItem)).label
                                findModel.push(findFunctionName)
                            })
                            abcssa = [...uniquefilterData, {
                                ['columnName']: key,
                                ['filterValues']: findModel,
                                ['filterOption']: 9
                            }
                            ];
                        }
                        else{
                            abcssa = [...uniquefilterData, {
                                ['columnName']: key,
                                ['filterValues']: filterKeyValue,
                                ['filterOption']: 9
                            }
                            ];
                        }
                        
                        if (name['fkmodel']) {
                            const index =  abcssa.findIndex(x => x.columnName ==="fkfunction");
                            if(index !== -1){
                                abcssa.splice(index,1);
                            // setFilterData(filterdt)
                            }                      
                         }
                         setFilterData(abcssa);
                     }

                }
                else {
                    let uniquefilterData = [];
                    uniquefilterData = filterdata.filter(item => item.columnName !== key);
                    setFilterData([...uniquefilterData]);
                }
            })

        }
    }
    const handleOnClickSearch = (pg, sz) => {
        setExpandedPre(false);
        if (Object.entries(filterdata).length === 0 && Object.entries(inputValue).length === 0) {
            let finalFilter = {};
            dispatch(getAllSearchEquipmentAction(
                finalFilter, pg, sz
            ));
        } else {
            let finalFilter = {};
            let inputTextData = [];
            Object.entries(inputValue).forEach(([key, value]) => {
                if(key === 'pkequipment' && value.includes(",")){
                    let inputEquipments = value.split(",")
                    let newEqList = []
                    inputEquipments.forEach(item=>{
                        let newItem = item.replaceAll("%", "")
                        newEqList.push(newItem)
                    })
                    inputTextData = [...inputTextData, {
                        ['columnName']: key,
                        ['filterValues']: [...newEqList],
                        ['filterOption']: 9
                    }];
                }
                else if (key === 'productformatstring' || key === 'serialnumber'|| key === 'p1idnumber'|| key === 'pkequipment') {
                    inputTextData = [...inputTextData, {
                        ['columnName']: key,
                        ['filterValues']: [value.replaceAll("%", "")],
                        ['filterOption']: value.startsWith("%") && value.endsWith("%") ? 3 : value.endsWith("%") ? 1 : value.startsWith("%") ? 2 : 9
                    }];
                } 
                else {
                    inputTextData = [...inputTextData, {
                        ['columnName']: key,
                        ['filterValues']: value === 'All' ? []: [value],
                        ['filterOption']: 9
                    }];
                }
            });
            if (filterdata.length > 0 || inputTextData.length > 0){
                let tmpFilter = JSON.parse(JSON.stringify(filterdata));
                tmpFilter.map(item=> {
                    if(item.columnName.includes('fkfunction'))
                      item.columnName = 'functionname' 
                    if(item.columnName.includes('fkmodel'))
                      item.columnName = 'modelname'
                })
                console.log('tmpFilter',tmpFilter)
                finalFilter['filterParams'] = [...tmpFilter, ...inputTextData];
            }
                
            dispatch(getAllSearchEquipmentAction(
                finalFilter, pg, sz
            ));
        }
    }


    const setPageNew = (value) => {
        handleOnClickSearch(value, pageSize);
        setPage(value);
    }

    const setPageSizeNew = (e) => {
        handleOnClickSearch(1, e.target.value)
        setPage(1);
        setPageSize(e.target.value);
    }

    const handleDetailClick = (row, view) => {
        setSelectedRow(row)
        setView(view)
    }
    const getModelData = () => {
        let modelData = [...activeModelList];
        if (activeOemLists && activeOemLists.length) {
            const ab = activeOemLists.map(({ value }) => value);
            modelData = modelData.filter(({ parent }) => ab.indexOf(parent) > -1);
        }
        return modelData;
    }

    const getFunctionData = () => {
        let modelData = [...activeFunctions];
        const tmpData = filterdata.find(({columnName})=> columnName ==="fkmodel")
        if(tmpData){
            let tmp = modelFunctionList.filter((a)=>{
                return tmpData.filterValues.includes(a.fkModel.toString())
            }) ;
            modelData = modelData.filter((a)=>{
                return tmp.find(({fkFunction})=>{
                    return fkFunction === a.value
                }) ? true : false
            })
        }
        return modelData;
    }

    const optionListVal = {
        'disposalstatus': 'For disposal', 'pkequipment': 'SAM ID', 'oem': 'OEM', 'fkmodel': 'Model',
        'fkcompany': 'Company', 'fkregion': 'Region', 'fkarea': 'Area', 'fkstatus': 'Status', 'functionallocationname': 'Functional location',
        'fkcondition': 'Condition', 'fkfunction': 'Function', 'prcoesssubprocessname': 'Process \\ Sub-process',
        'disposalstatus': 'For disposal', 'isarchived': 'Archived',
        'availablefortransferdate': 'Available for transfer'
    };

    const getFilterChip = () => {
        let str = '';
        Object.keys(optionListVal).forEach(ele => {
            if (typeof formData[ele] === 'string' && formData[ele]) {
                str += optionListVal[ele] + ': ' + formData[ele] + '; ';
            }
            if (formData[ele] && Array.isArray(formData[ele]) && formData[ele].length > 0) {
                str += optionListVal[ele] + ': ';
                formData[ele].forEach((item, index) => {
                    str += ' ' + item.value;
                    str += index + 1 === formData[ele].length ? '' : ',';
                })
                str += '; ';
            };

        });
        return str;
    }

    const clearFilter = () => {
        setclearFilter(!resetFilter);
        Object.entries(clearTextFeild.current).forEach(([key, value]) => {
            let mainText = value.querySelectorAll('input');
            mainText[0].value = '';
        })

        setFormData([]);
        setSelectFormData({});
        setFilterData([]);
        setInputValue({});
    }
    const onInputChange = ({ target }) => {
        if (target.value.length === 0 && target.name.length != '') {
            delete inputValue[target.name]
            setInputValue({ ...inputValue })
        }
        else
        setInputValue({ ...inputValue, [target.name]: target.value })
        let tempData = { [target.name]: target.value }
        setFormData([...formData, tempData]);
        setSelectFormData({ ...selectFormData, [target.name]: target.value })
    }

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            handleOnClickSearch(1, 50);
        }
    }


    const column = [
        {
            title: 'Company',
            dataIndex: 'longcompanyname',
            key: 'longcompanyname',
        },
        {
            title: 'Country',
            dataIndex: 'countryname',
            key: 'countryname',
        },
        {
            title: 'Functional Location Area',
            dataIndex: 'flareaname',
            key: 'flareaname',
        },
        {
            title: ' SAM ID',
            dataIndex: 'pkequipment',
            key: 'pkequipment',
        },
        {
            title: 'Manufactured Year',
            dataIndex: 'manufacturedyear',
            key: 'manufacturedyear',
        },
        {
            title: 'Last Rebuild Year',
            dataIndex: 'lastrebuildyear',
            key: 'lastrebuildyear',
        },
        {
            title: 'Is Functional Location Active',
            dataIndex: 'functionallocationisproductive',
            key: 'functionallocationisproductive',
        },
        {
            title: 'Equipment Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Area',
            dataIndex: "areaname",
            key: "areaname"
        },
        {
            title: ' Functional Location',
            dataIndex: "functionallocationname",
            key: "functionallocationname"
        },
        {
            title: 'Process\Sub-Process',
            dataIndex: "prcoesssubprocessname",
            key: "prcoesssubprocessname"
        },
        {
            title: 'Function',
            dataIndex: "functionname",
            key: "functionname"
        },

        {
            title: 'OEM',
            dataIndex: "oemname",
            key: "oemname"
        },
        {
            title: 'Info',
            dataIndex: "info",
            key: "info"
        },
        {
            title: 'Model',
            dataIndex: 'modelname',
            key: 'modelname',
        },
        {
            title: 'SAP/P1 Ref',
            dataIndex: "p1idnumber",
            key: "p1idnumber"
        },
        {
            title: 'Serial number',
            dataIndex: 'serialnumber',
            key: 'serialnumber',
        },
        {
            title: 'Product format',
            dataIndex: 'productformatstring',
            key: 'productformatstring',
        },
        {
            title: 'Status',
            dataIndex: 'statusname',
            key: 'statusname',
        },
        {
            title: 'Design Speed',
            dataIndex: 'designspeed',
            key: 'designspeed',
        },
        {
            title: 'Design Speed Unit',
            dataIndex: 'designspeedunit',
            key: 'designspeedunit',
        },
        {
            title: 'Production speed ',
            dataIndex: 'currentmaximumspeed',
            key: 'currentmaximumspeed',
        },
        {
            title: 'Production Speed Unit',
            dataIndex: 'speedunitname',
            key: 'speedunitname',
        },
        {
            title: 'Production Speed Unit',
            dataIndex: 'speedunitname',
            key: 'speedunitname',
        },
        {
            title: 'Production Speed Unit',
            dataIndex: 'speedunitname',
            key: 'speedunitname',
        },
        {
            title: 'Condition',
            dataIndex: 'conditionname',
            key: 'conditionname',
        },
        {
            title: 'Availablity for transfer (Date),',
            dataIndex: 'availabletransferdate',
            key: 'availabletransferdate'
        },
        {
            title: 'Available for transfer,',
            dataIndex: 'availablefortransferdate',
            key: 'availablefortransferdate'
        },
        {
            title: 'Cluster name',
            dataIndex: 'clustername',
            key: 'clustername',
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
        },
        {
            title: 'Control system name',
            dataIndex: 'controlsystemname',
            key: 'controlsystemname',
        },
        {
            title: 'Disposal Request',
            dataIndex: 'disposalRequest',
            key: 'disposalRequest',
        },
        {
            title: 'Ex-functional location',
            dataIndex: 'exfunctionallocation',
            key: 'exfunctionallocation',
        },
        {
            title: 'Ex-parent name',
            dataIndex: 'exparentname',
            key: 'exparentname',
        },
        {
            title: 'First installation year',
            dataIndex: 'firstinstallationyear',
            key: 'firstinstallationyear',
        },
        {
            title: 'functional location product format',
            dataIndex: 'flproductformatstring',
            key: 'flproductformatstring',
        },
        {
            title: 'Net book value currency',
            dataIndex: 'fknetbookvaluecurrency',
            key: 'fknetbookvaluecurrency',
        },
        {
            title: 'Net book value date',
            dataIndex: 'netbookvaluedate',
            key: 'netbookvaluedate',
        },
        {
            title: 'Product format',
            dataIndex: 'flproductformatstring',
            key: 'flproductformatstring',
        },
        {
            title: 'Region',
            dataIndex: 'regionname',
            key: 'regionname',
        },
        {
            title: 'Storage reason comment',
            dataIndex: 'storagereasoncomment',
            key: 'storagereasoncomment',
        },
        {
            title: 'Net book value',
            dataIndex: 'netbookvalue',
            key: 'netbookvalue',
        },
        {
            title: 'Is Reserved',
            dataIndex: 'isreserved',
            key: 'isreserved',
        },
        {
            title: 'Is Disposal',
            dataIndex: 'disposalstatus',
            key: 'disposalstatus',
        },
        {
            title: 'Reserved By',
            dataIndex: 'reservedby',
            key: 'reservedby',
        },
        {
            title: 'MRF Number',
            dataIndex: 'reservationRequest',
            key: 'reservationRequest',
        },
        {
            title: 'Is Confidential',
            dataIndex: 'isconfidential',
            key: 'isconfidential',
        },
        {
            title: 'Is Archived',
            dataIndex: 'isarchived',
            key: 'isarchived',
        }
    ];
     const handleExportAllClick = () => {
             dispatch(getEquipmentALLExportAction({}))
     }

     const handleExportClick = () => {
        if (Object.entries(filterdata).length === 0 && Object.entries(inputValue).length === 0) {
            let finalFilter = {};
            dispatch(getEquipmentALLExportAction(finalFilter));
        } else {
            let finalFilter = {};
            let inputTextData = [];
            Object.entries(inputValue).forEach(([key, value]) => {
                if(key === 'pkequipment' && value.includes(",")){
                    let inputEquipments = value.split(",")
                    let newEqList = []
                    inputEquipments.forEach(item=>{
                        let newItem = item.replaceAll("%", "")
                        newEqList.push(newItem)
                    })
                    inputTextData = [...inputTextData, {
                        ['columnName']: 'SAM_ID',
                        ['filterValues']: [...newEqList],
                        ['filterOption']: 9
                    }];
                }
                else if (key === 'productformatstring' || key === 'serialnumber'|| key === 'p1idnumber'|| key === 'pkequipment') {
                    inputTextData = [...inputTextData, {
                        ['columnName']: key,
                        ['filterValues']: [value.replaceAll("%", "")],
                        ['filterOption']: value.startsWith("%") && value.endsWith("%") ? 3 : value.endsWith("%") ? 1 : value.startsWith("%") ? 2 : 9
                    }];
                } 
                else {
                    if(value === 'All'){
                        inputTextData = [...inputTextData];
                    }else{
                        inputTextData = [...inputTextData, {
                            columnName: key,
                            filterValues:  [value],
                            filterOption: 9
                        }];
                    }
                }
            });
            if (filterdata.length > 0 || inputTextData.length > 0){
                let tmpFilter = JSON.parse(JSON.stringify(filterdata));
                tmpFilter.map(item=> {
                    if(item.columnName.includes('ProductFormatString'))
                      item.columnName = 'Format';
                    if(item.columnName.includes('serialnumber'))
                      item.columnName = 'Serial_number';
                    if(item.columnName.includes('p1idnumber'))
                      item.columnName = 'SAP_P1_Ref';
                    if(item.columnName.includes('prcoesssubprocessname'))
                     item.columnName = 'ProcessSubProcessName';
                    
                })
                console.log('tmpFilter',tmpFilter)
                finalFilter['filterParams'] = [...tmpFilter, ...inputTextData];
            }
            finalFilter.filterParams.map(item => {
                if(item.columnName.includes('fkfunction'))
                  item.columnName = 'Function';
                  if(item.columnName.includes('pkequipment'))
                  item.columnName = 'SAM_ID';
                if(item.columnName.includes('fkmodel'))
                  item.columnName = 'Model';
                  if(item.columnName.includes('ProductFormatString'))
                  item.columnName = 'Format';
                  if(item.columnName.includes('serialnumber'))
                  item.columnName = 'Serial_number';
                if(item.columnName.includes('p1idnumber'))
                  item.columnName = 'SAP_P1_Ref';
                  if(item.columnName.includes('fkcompany')){
                    item.columnName = 'Company';
                    item.filterValues = item.filterValues.map((b) => {
                     return activeCompanyLists.find(({value})=> value == b).label
                    });
                }
                if(item.columnName.includes('fkarea')){
                  item.columnName = 'Area';
                  item.filterValues = item.filterValues.map((b) => {
                    return activeAreaListForSearchEquipment.find(({value})=> value == b).label
                   });
               }
                if(item.columnName.includes('fkregion')){
                  item.columnName = 'Region';
                  item.filterValues = item.filterValues.map((b) => {
                    return activeRegionLists.find(({value})=> value == b).lb
                   });
               }
                  if(item.columnName.includes('functionallocationname'))
                  item.columnName = 'Functional_location';
                   if(item.columnName.includes('oemname'))
                  item.columnName = 'OEM';
                if(item.columnName.includes('fkstatus')){
                    item.columnName = 'Status';
                    item.filterValues = item.filterValues.map((b) => {
                        return activeStatusList.find(({value})=> value == b).label
                       });
                 }
                  if(item.columnName.includes('fkcondition')){
                  item.columnName = 'Condition';
                  item.filterValues = item.filterValues.map((b) => {
                    return activeConditions.find(({value})=> value == b).label
                   });
                  }
                  if(item.columnName.includes('isarchived')){
                  item.columnName = 'Is_archived';
                  item.filterValues = item.filterValues.map((b) => {
                    return b == 'True' ? 'Yes' : 'No'
                   });
                 }
                  if(item.columnName.includes('isreserved')){
                  item.columnName = 'Is_reserved';
                  item.filterValues = item.filterValues.map((b) => {
                    return b == 'True' ? 'Yes' : 'No'
                   });
                 }
                  if(item.columnName.includes('disposalstatus')){
                  item.columnName = 'For_disposal';
                  item.filterValues = item.filterValues.map((b) => {
                    return b == 'True' ? 'Yes' : 'No'
                   });
                 }
                if(item.columnName.includes('availablefortransferdate')){
                  item.columnName = 'Available_for_transfer';
                  item.filterValues = item.filterValues.map((b) => {
                    return b == 'True' ? 'Yes' : 'No'
                   });
                 }

            })
           dispatch(getEquipmentALLExportAction(finalFilter))
        }
    }
    // const handleClick = (data, fileName, column) => {
    //     const newData = data.map((a) => {
    //         return {
    //             ...a, info:
    //                 (a.disposalRequest ? a.disposalRequest : " ") +
    //                 (a.reservationRequest ? a.reservationRequest : "") +
    //                 (a.isconfidential ? "confidential equipment" : "") +
    //                 (a.isarchived ? "archieved equipment" : "") +
    //                 (a.numberofdoc ? a.numberofdoc : "")
    //         }
    //     });

    //     const tmpdt = newData.map((a) => {
    //         var oldValue = {};
    //         for (let i in a) {
    //             oldValue[i] = getYesNo(a[i]);
    //         }
    //         return oldValue;
    //     })
    //     manualExport(tmpdt, fileName, column);
    // };


    return <>
        <><MainCard sx={{ display: viewName === 'main' ? 'block' : 'none' }} border={false}>
            {needBack ? <Grid item sx={{ p: 2 }} xs={12} sm={1}>
                <Box display="flex" justifyContent="flex-end">
                    <Button onClick={setPageView} variant="outlined">Back</Button>
                </Box>
            </Grid> : ""}
             {isExportAllLoading ? <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open
            >
                <CircularProgress color="inherit" />
            </Backdrop> : ""}

            <Grid sx={{
                border: '1px solid rgba(224, 224, 224, 1)',
                borderRadius: '4px'
            }}>
                <Grid container sx={{ pl: 1, mt: 1 }} spacing={1}>
                    <Grid item xs={11} >
                        <Typography variant="h3">Search Equipment</Typography>
                    </Grid>
                    <Grid  item xs={1} justifyContent="flex-end">
                        {lock ?
                            <Tooltip title="Click to unlock expand">
                                <LockOutlinedIcon onClick={()=>{setExpanded(true);setLock(!lock)}} />
                            </Tooltip>
                            :
                            <Tooltip title="Click to lock expand">
                                <LockOpenOutlinedIcon onClick={()=>{setExpanded(true);setLock(!lock)}} />
                            </Tooltip>
                        }
                    </Grid>
                </Grid>
                <Accordion expanded={expanded} onChange={() => setExpandedPre(!expanded)} sx={{ p: 0, boxShadow: 'none' }}>
                    <AccordionSummaryRev
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={11}>
                                <Box sx={{ fontWeight: 600, display: 'block' }}>Search criteria</Box>
                            </Grid>

                            {!expanded && <Grid item xs={12}>
                                <Typography sx={{ fontWeight: 'bold', color: '#00b0ff' }}>{getFilterChip()}</Typography>
                            </Grid>}
                        </Grid>
                    </AccordionSummaryRev>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                <MultiSelect
                                    error={showError}
                                    resetFilter={resetFilter}
                                    helperText={showError && !filterdata}
                                    onChange={onChange}
                                    name='fkcompany'
                                    data={{ label: "Company", options: activeCompanyLists }}
                                />

                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MultiSelect
                                    onChange={onChange}
                                    resetFilter={resetFilter}
                                    name='fkregion'
                                    data={{ label: "Region", options: activeRegionLists }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box sx={{ my: 0.25, mr: 2 }}>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        ref={ref => clearTextFeild.current['samid'] = ref}
                                        size="small"
                                        id="samid"
                                        name="pkequipment"
                                        label="SAM ID"
                                        variant="outlined"
                                        onChange={onInputChange}
                                        onKeyDown={onKeyDown}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 2, borderBottomWidth: 2 }} />
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                <MultiSelect
                                    onChange={onChange}
                                    resetFilter={resetFilter}
                                    name='fkarea'
                                    data={{ label: "Area", options: activeAreaListForSearchEquipment }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MultiSelect
                                    resetFilter={resetFilter}
                                    onChange={onChange}
                                    name='functionallocationname'
                                    data={{ label: "Functional location", options: functionalLocationLists }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MultiSelect
                                    resetFilter={resetFilter}
                                    onChange={onChange}
                                    data={{ label: "Process \\ Subprocess", options: prcoesssubprocessOption }}
                                    name='prcoesssubprocessname'
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ mt: 2 }}>
                            <Grid item xs={12} sm={4}>
                                <MultiSelect
                                    onChange={onChange}
                                    resetFilter={resetFilter}
                                    name='fkmodel'
                                    data={{ label: "Model", options: getModelData() }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MultiSelect1
                                    onChange={onChange}
                                    tmpData={filterdata.find(({columnName})=> columnName ==="fkfunction") ? null : []}
                                    resetFilter={resetFilter}
                                    name='fkfunction'
                                    data={{ label: "Function", options: getFunctionData() }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MultiSelect
                                    resetFilter={resetFilter}
                                    onChange={onChange}
                                    name='oemname'
                                    data={{ label: "OEM", options: oemLists }}
                                />
                            </Grid>

                        </Grid>
                        <Divider sx={{ my: 2, borderBottomWidth: 2 }} />
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                <Box sx={{ my: 0.25, mr: 2 }}>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        ref={ref => clearTextFeild.current['srno'] = ref}
                                        size="small"
                                        id="srno"
                                        name="serialnumber"
                                        label="Serial Number"
                                        variant="outlined"
                                        onChange={onInputChange}
                                        onKeyDown={onKeyDown}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MultiSelect
                                    onChange={onChange}
                                    resetFilter={resetFilter}
                                    name='fkstatus'
                                    data={{ label: "Status", options: activeStatusList }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MultiSelect
                                    onChange={onChange}
                                    resetFilter={resetFilter}
                                    name='fkcondition'
                                    data={{ label: "Condition", options: activeConditions }}
                                />
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 2, borderBottomWidth: 2 }} />
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                <Box sx={{ my: 0.25, mr: 2 }}>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        ref={ref => clearTextFeild.current['productformatstring'] = ref}
                                        size="small"
                                        id="productformatstring"
                                        name="productformatstring"
                                        label="Format"
                                        variant="outlined"
                                        onChange={onInputChange}
                                        onKeyDown={onKeyDown}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box sx={{ my: 0.25, mr: 2 }}>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        ref={ref => clearTextFeild.current['p1idnumber'] = ref}
                                        size="small"
                                        id="p1idnumber"
                                        name="p1idnumber"
                                        label="SAP/P1 Ref"
                                        variant="outlined"
                                        onChange={onInputChange}
                                        onKeyDown={onKeyDown}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box sx={{ my: 0.25, mr: 2 }}>
                                    <FormControl size="small" sm={{ width: '100%' }} fullWidth>
                                        <InputLabel id="archivedOption-label">{archivedOption.label}</InputLabel>
                                        <Select
                                            labelId="archivedOption-label"
                                            ref={ref => clearTextFeild.current['archivedOption'] = ref}
                                            id="archivedOption"
                                            name="isarchived"
                                            value={selectFormData && selectFormData['isarchived'] !== undefined ? selectFormData['isarchived'] : ''}
                                            label={archivedOption.label}
                                            onChange={onInputChange}
                                        >
                                            {archivedOption.options.map((a, index) => <MenuItem key={index} value={a.value}>{a.label}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ mt: 2 }}>
                            <Grid item xs={12} sm={4}>
                                <Box sx={{ my: 0.25, mr: 2 }}>
                                    <FormControl size="small" sm={{ width: '100%' }} fullWidth>
                                        <InputLabel id="availablefortransOption-label">{availablefortransOption.label}</InputLabel>
                                        <Select
                                            labelId="availablefortransOption-label"
                                            ref={ref => clearTextFeild.current['availablefortransOption'] = ref}
                                            id="availablefortransOption"
                                            name="availablefortransferdate"
                                            value={selectFormData && selectFormData['availablefortransferdate'] !== undefined ? selectFormData['availablefortransferdate'] : ''}
                                            label={availablefortransOption.label}
                                            onChange={onInputChange}
                                        >
                                            {availablefortransOption.options.map((a, index) => <MenuItem key={index} value={a.value}>{a.label}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box sx={{ my: 0.25, mr: 2 }}>
                                    <FormControl size="small" sm={{ width: '100%' }} fullWidth>
                                        <InputLabel id="reservedOption-label">{reservedOption.label}</InputLabel>
                                        <Select
                                            labelId="reservedOption-label"
                                            ref={ref => clearTextFeild.current['reservedOption'] = ref}
                                            id="reservedOption"
                                            name="isreserved"
                                            value={selectFormData && selectFormData['isreserved'] !== undefined ? selectFormData['isreserved'] : ''}
                                            label={reservedOption.label}
                                            onChange={onInputChange}
                                        >
                                            {reservedOption.options.map((a, index) => <MenuItem key={index} value={a.value}>{a.label}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box sx={{ my: 0.25, mr: 2 }}>
                                    <FormControl size="small" sm={{ width: '100%' }} fullWidth>
                                        <InputLabel id="disposalOption-label">{disposalOption.label}</InputLabel>
                                        <Select
                                            labelId="disposalOption-label"
                                            ref={ref => clearTextFeild.current['disposalOption'] = ref}
                                            id="disposalOption"
                                            name="disposalstatus"
                                            value={selectFormData && selectFormData['disposalstatus'] !== undefined ? selectFormData['disposalstatus'] : ''}
                                            label={disposalOption.label}
                                            onChange={onInputChange}
                                        >
                                            {disposalOption.options.map((a, index) => <MenuItem key={index} value={a.value}>{a.label}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Grid>
                    </AccordionDetails>

                    <Divider sx={{ my: 2, borderBottomWidth: 2 }} />
                    <Grid container>
                        <Grid item p={1} sm={12}>
                            <Box display="flex" justifyContent="flex-end">
                                <Button
                                    size="small"
                                    variant="outlined"
                                    sx={{ "marginRight": "1rem" }}
                                    startIcon={<AutorenewIcon />}
                                    onClick={clearFilter}
                                >
                                    Clear Filter
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<SearchOutlinedIcon />}
                                    size="small"
                                    onClick={() => handleOnClickSearch(1, 500)}
                                >
                                    Search
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                </Accordion>
            </Grid>
            <Grid xs={12} sx={{ mt: 2, mb: -1 }}>
                <Button variant="outlined" onClick={handleExportClick}>
                    Export
                </Button>
                <Button sx={{ ml: 1 }} variant="outlined" onClick={handleExportAllClick}>
                    Export All
                </Button>
            </Grid>
            <Grid container sx={{ mt: 3 }}>
                <div style={{
                    height: '500px',
                    width: '100%'
                }}>
                    <DataGridServerSide
                        columns={columns}
                        rows={searchEquipments}
                        pageSize1={pageSize}
                        page={page}
                        hideCSV={true}
                        totalRow={Math.ceil(pageInfo.totalRecords / pageSize)}
                        loading={isEquipmentsLoading}
                        setPage={setPageNew}
                        setPageSize={setPageSizeNew}
                        rowHeight={70}
                    />
                </div>
            </Grid>
        </MainCard></>

        {viewName === 'details' && <EquipmentTabs setView={setView} samid={rowSelected.pkequipment} />}
        {viewName === 'edit' && <EquipmentEditTabs setView={setView} samid={rowSelected.pkequipment} />}
    </>
}
