
import {getStatusAPI, deleteStatusAPI, createStatusAPI, updateStatusAPI} from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    STATUS_REQUEST: "STATUS_REQUEST",
    STATUS_RECIEVE: "STATUS_RECIEVE",
    STATUS_FAIL: "STATUS_FAIL",
    CREATE_STATUS_PENDING: "CREATE_STATUS_PENDING",
    CREATE_STATUS_RECEIVED: "CREATE_STATUS_RECEIVED",
    CREATE_STATUS_FAILED: "CREATE_COORDINATOR_FAILED",
    UPDATE_STATUS_PENDING: "UPDATE_STATUS_PENDING",
    UPDATE_STATUS_RECEIVED: "UPDATE_STATUS_RECEIVED",
    UPDATE_STATUS_FAILED: "UPDATE_STATUS_FAILED",
    DELETE_STATUS_PENDING: "DELETE_STATUS_PENDING",
    DELETE_STATUS_RECEIVED: "DELETE_STATUS_RECEIVED",
    DELETE_STATUS_FAILED: "DELETE_STATUS_FAILED",
};
const requestStatus= ()=>({
    type: actionTypes.STATUS_REQUEST,
})
const receiveStatus= (payload)=>({
    type: actionTypes.STATUS_RECIEVE,
    payload
})
const failStatus= ()=>({
    type: actionTypes.STATUS_FAIL,
})

const createStatusRequest = () => ({
    type: actionTypes.CREATE_STATUS_PENDING,
});
const createStatusReceived= (payload) => ({
    type: actionTypes.CREATE_STATUS_RECEIVED,
    payload,
});
const createStatusFailed = (payload) => ({
    type: actionTypes.CREATE_STATUS_FAILED,
    payload,
});
const updateStatusRequest = () => ({
    type: actionTypes.UPDATE_STATUS_PENDING,
});
const updateStatusReceived= (payload) => ({
    type: actionTypes.UPDATE_STATUS_RECEIVED,
    payload,
});
const updateStatusFailed = (payload) => ({
    type: actionTypes.UPDATE_STATUS_FAILED,
    payload,
});

const deleteStatusRequest = () => ({
    type: actionTypes.DELETE_STATUS_PENDING,
});
const deleteStatusReceived= (payload) => ({
    type: actionTypes.DELETE_STATUS_RECEIVED,
    payload,
});
const deleteStatusFailed = (payload) => ({
    type: actionTypes.DELETE_STATUS_FAILED,
    payload,
});
export const getStatusAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestStatus());
        try {
            const response = await getStatusAPI(payload);
            if (response?.status === 200) 
                dispatch(receiveStatus(response?.data?.data));
        } catch (error) {
            dispatch(failStatus(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const createStatusAction = (payload) => { 
    return async (dispatch) => {
        dispatch(createStatusRequest());
        try {
            const response = await createStatusAPI(payload);  
            if (response?.status === 201) {
                dispatch(createStatusReceived(response?.data?.data));
                dispatch(actions.successAlert("Status added successfully"));
                dispatch(getStatusAction());
            }
        } catch (error) {  
            console.log("error",error)    
            dispatch(createStatusFailed(error?.response?.data));        
            if (error?.response) {
                if(error?.response?.data?.errorMessage !== undefined)
                    dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
                else
                    dispatch(actions.errorAlert(error?.response?.data?.title));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const updateStatusAction = (payload,id) => { 
    return async (dispatch) => {
        dispatch(updateStatusRequest());
        try {
            const response = await updateStatusAPI(payload,id);    
            if (response?.status === 200) {
                dispatch(updateStatusReceived(response?.data?.data));
                dispatch(getStatusAction());
                dispatch(actions.successAlert("Status updated successfully"));
            }
        } catch (error) {  
            console.log("error",error)    
            dispatch(updateStatusFailed(error?.response?.data));        
            if (error?.response) {
                if(error?.response?.data?.errorMessage !== undefined)
                    dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
                else
                    dispatch(actions.errorAlert(error?.response?.data?.title));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deleteStatusAction = (id) => {
    return async (dispatch) => {
        dispatch(deleteStatusRequest());
        try {
            const response = await deleteStatusAPI(id);
            if (response?.status === 200) {
                dispatch(deleteStatusReceived(response?.data?.success));
                dispatch(actions.successAlert("Status deleted successfully"));
                dispatch(getStatusAction());
            }
        } catch (error) {
            dispatch(deleteStatusFailed(error?.response?.data));     
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));

            }
        }
    };
}