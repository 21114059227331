// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'routes/menuItems';
import { useSelector } from 'react-redux';
// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    let { roles } = useSelector((state) => state.token);
    let modifiedMenuItem = []
    
    if(roles.length >0){
        menuItem.forEach(item=>{
            item.children = item.children.filter(childItem => childItem.role.some(roleItem=>roles.includes(roleItem)))
        })
        menuItem.map(({children})=>{
            children.filter(childItem => {
                if(childItem.children !== undefined){
                    let data =childItem.children.filter(childItem => childItem.role.some(roleItem=>roles.includes(roleItem)>0)
                    )
                    childItem.children = [...data]
                    return childItem
                }
                else 
                return childItem
            })
        })
        modifiedMenuItem = [...menuItem]
    }
    const navGroups = modifiedMenuItem.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
