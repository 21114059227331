import { actionTypes } from "./actions";

export const initialState = {
  productFormatValue: {}
};

// ===========================|| COMMON REDUCER ||=========================== //

const pfvReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCT_FORMAT_VALUE_GET_REQUEST:
      return {
        ...state,
        isPFVLoading: true,
      };
    case actionTypes.PRODUCT_FORMAT_VALUE_GET_RECEIVE:
      return {
        ...state,
        productFormatValueList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isPFVLoading: false,
      };
    case actionTypes.PRODUCT_FORMAT_VALUE_GET_FAIL:
      return {
        ...state,
        isOemFail: false,
        isPFVLoading: false
      };
    default:
      return state;
  }
};

export default pfvReducer;
