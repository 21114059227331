import { API } from "api";

export const getAllSpeedUnitAPI = () => {
    return API.get("/api/speedunit/getall?page=0&size=0")
                .then((res) => res);
};

export const getAllActiveSpeedUnitAPI = () => {
    return API.get("/api/speedunit/getallactive?page=0&size=0")
                .then((res) => res);
};

export const createSpeedUnitAPI = (payload) => {
    return API.post("/api/speedunit/add", payload)
                .then((res) => res);
}

export const updateSpeedUnitAPI = (id, payload) => {
    return API.put(`/api/speedunit/update/${id}`, payload)
                .then((res) => res);
}

export const deleteSpeedUnitAPI = (id) => {
    return API.delete(`/api/speedunit/delete/${id}`)
                .then((res) => res);
}