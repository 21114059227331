
// import { actionTypes } from "./actions";
import { createFunctionAPI, updateFunctionAPI, deleteFunctionAPI, getFunctionListAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';
import { gettFnstrAction } from '../../state/actions';

export const actionTypes = {
    FUNCTION_GET_REQUEST: "FUNCTION_GET_REQUEST",
    FUNCTION_GET_RECEIVE: "FUNCTION_GET_RECEIVE",
    FUNCTION_GET_FAIL: "FUNCTION_GET_FAIL"
};

const requestFunction = () => ({
    type: actionTypes.FUNCTION_GET_REQUEST,
});
const receiveFunction = (payload) => ({
    type: actionTypes.FUNCTION_GET_RECEIVE,
    payload,
});
const failureFunction = (payload) => ({
    type: actionTypes.FUNCTION_GET_FAIL,
    payload,
});



export const getFunctionAction = () => {
    return async (dispatch) => {
        dispatch(requestFunction());
        try {
            const response = await getFunctionListAPI();
            if (response?.status === 200) {
                dispatch(receiveFunction(response?.data?.data));
                // dispatch(actions.successAlert("Coutries fetched successfully"));
            }
        } catch (error) {
            dispatch(failureFunction(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const createFunctionAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestFunction());
        try {
            const response = await createFunctionAPI(payload);
            if (response?.status === 201) {
               // dispatch(receiveFunction([]));
                dispatch(actions.successAlert("Function added successfully"));
            }
            dispatch(gettFnstrAction());
            dispatch(getFunctionAction());
            
        } catch (error) {
            dispatch(failureFunction(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateFunctionAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestFunction());
        try {
            const response = await updateFunctionAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Function updated successfully"));
            }
            dispatch(gettFnstrAction());

        } catch (error) {
            dispatch(failureFunction(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deleteFunctionAPIAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestFunction());
        try {
            const response = await deleteFunctionAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Function deleted successfully"));
            }
            dispatch(gettFnstrAction());

        } catch (error) {
            dispatch(failureFunction(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
