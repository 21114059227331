import { actions } from 'store/Reducer/actions';
import { getFileUplaodAPI, downloadFileAPI } from './endpoints';
export const actionTypes = {
    UPLAOD_STATUS_PENDING: "UPLAOD_STATUS_PENDING",
    UPLAOD_STATUS_RECEIVED: "UPLAOD_STATUS_RECEIVED",
    UPLAOD_STATUS_FAILED: "UPLAOD_STATUS_FAILED",
    DOWNLOAD_FILE_PENDING: "DOWNLOAD_FILE_PENDING",
    DOWNLOAD_FILE_RECEIVED: "DOWNLOAD_FILE_RECEIVED",
    DOWNLOAD_FILE_FAILED: "DOWNLOAD_FILE_FAILED"
};
const requestUploadStatus= () => ({
    type: actionTypes.UPLAOD_STATUS_PENDING,
});
const receiveUploadStatus = (payload) => ({
    type: actionTypes.UPLAOD_STATUS_RECEIVED,
    payload,
});
const failureUploadStatus = (payload) => ({
    type: actionTypes.UPLAOD_STATUS_FAILED,
    payload,
});
const downloadFilePending= () => ({
    type: actionTypes.DOWNLOAD_FILE_PENDING,
});
const downloadFileReceived = (payload) => ({
    type: actionTypes.DOWNLOAD_FILE_RECEIVED,
    payload,
});
const downloadFileFailed = (payload) => ({
    type: actionTypes.DOWNLOAD_FILE_FAILED,
    payload,
})
export const getFileUplaodAction = () => {   
    return async (dispatch) => {
        dispatch(requestUploadStatus());
        try {
            const response = await getFileUplaodAPI();
            if (response?.status === 200) {
                dispatch(receiveUploadStatus(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureUploadStatus(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const downloadFileAction = (payload) => { 
    return async (dispatch) => {
        dispatch(downloadFilePending());
        try {
            const response = await downloadFileAPI(payload);                      
            if (response?.status === 200) {
                dispatch(downloadFileReceived(response?.data?.data));
            }
        } catch (error) {    
            dispatch(downloadFileFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}