import { actionTypes } from "./actions";

export const initialState = {
  modelList: [],
  modelListNoD: []
};

// ===========================|| COMMON REDUCER ||=========================== //

const modelReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MODEL_GET_REQUEST:
      return {
        ...state,
        isModelLoading: true,
      };
    case actionTypes.MODEL_GET_RECEIVE:
      const filterData = action.payload.filter((v,i,a)=>a.findIndex(v2=>(v2.modelName===v.modelName))===i)
      return {
        ...state,
        activeModelList: filterData.map((dt, index) => ({label:dt.modelName, value: dt.pkModel, parent: dt.fkOem})),
        modelList: filterData.map((dt, index) => ({ ...dt, id: index })),
        modelListNoD: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isModelLoading: false 
      };
      case actionTypes.MODEL_CREATE_RECEIVE:
      return {
        ...state,
        isModelLoading: false,
      };
    case actionTypes.MODEL_GET_FAIL:
      return {
        ...state,
        isModelLoading: false,
        isModelFail: false,
      };
    default:
      return state;
  }
};

export default modelReducer;
