import { notificationAPI } from "api";

export const getNotificationById = (payload) => {
    let apiUrl = `/notifications?userIdFilter=${payload}&readStatusFilter=`    
    return notificationAPI.get(apiUrl).then((res) => res);
};
export const updateReadNotificationStatusAPI = (notificationIdFilter, userIdFilter) => {
    let apiUrl = `/notifications?notificationIdFilter=${notificationIdFilter}&userIdFilter=${userIdFilter}&readStatusValue=1`
    return notificationAPI.post(apiUrl).then((res) => res);
               
}

export const archiveNotificationAPI = (notificationIdFilter, userIdFilter) => {
    let apiUrl = `/notifications?notificationIdFilter=${notificationIdFilter}&userIdFilter=${userIdFilter}`
    return notificationAPI.head(apiUrl).then((res) => res);
}