import { getFormWorkFlowListAPI, getFormStepAPI, getFormStateAPI, getFormTypeListAPI, deleteFormWorkFlowAPI, updateFormWorkFlowAPI, createFormWorkFlowAPI} from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    FORM_TYPE_GET_REQUEST:"FORM_TYPE_GET_REQUEST",
    FORM_TYPE_GET_RECEIVE:"FORM_TYPE_GET_RECEIVE",
    FORM_TYPE_GET_FAIL: "FORM_TYPE_GET_FAIL",
    FORM_STATE_GET_REQUEST:"FORM_STATE_GET_REQUEST",
    FORM_STATE_GET_RECEIVE: "FORM_STATE_GET_RECEIVE",
    FORM_STATE_GET_FAIL: "FORM_STATE_GET_FAIL",
    FORM_WORKFLOW_GET_REQUEST: "FORM_WORKFLOW_GET_REQUEST",
    FORM_WORKFLOW_GET_RECEIVE: "FORM_WORKFLOW_GET_RECEIVE",
    FORM_WORKFLOW_GET_FAIL: "FORM_WORKFLOW_GET_GET_FAIL",
    FORM_STEP_GET_REQUEST: "FORM_STEP_GET_REQUEST",
    FORM_STEP_GET_RECEIVE: "FORM_STEP_GET_RECEIVE",
    FORM_STEP_GET_FAIL: "FORM_STEP_GET_FAIL",
    ADD_FORM_WORKFLOW_REQUEST: "ADD_FORM_WORKFLOW_REQUEST",
    ADD_FORM_WORKFLOW_RECEIVE: "ADD_FORM_WORKFLOW_RECEIVE",
    ADD_FORM_WORKFLOW: "ADD_FORM_WORKFLOW"
};

const requestFormWorkFlow = () => ({
    type: actionTypes.FORM_WORKFLOW_GET_REQUEST,
});
const recieveFormWorkFlow = (payload) => ({
    type: actionTypes.FORM_WORKFLOW_GET_RECEIVE,
    payload,
});
const failureFormWorkFlow = (payload) => ({
    type: actionTypes.FORM_WORKFLOW_GET_FAIL,
    payload,
});

const requestFormStateFlow = () => ({
    type: actionTypes.FORM_STATE_GET_REQUEST,
});
const recieveFormStateFlow = (payload) => ({
    type: actionTypes.FORM_STATE_GET_RECEIVE,
    payload,
});
const failureFormStateFlow = (payload) => ({
    type: actionTypes.FORM_STATE_GET_FAIL,
    payload,
});

const requestFormStepFlow = () => ({
    type: actionTypes.FORM_STEP_GET_REQUEST,
});
const recieveFormStepFlow = (payload) => ({
    type: actionTypes.FORM_STEP_GET_RECEIVE,
    payload,
});
const failureFormStepFlow = (payload) => ({
    type: actionTypes.FORM_STEP_GET_FAIL,
    payload,
});
const requestFormTypeFlow = () => ({
    type: actionTypes.FORM_TYPE_GET_REQUEST,
});
const recieveFormTypeFlow = (payload) => ({
    type: actionTypes.FORM_TYPE_GET_RECEIVE,
    payload,
});
const failureFormTypeFlow = (payload) => ({
    type: actionTypes.FORM_TYPE_GET_FAIL,
    payload,
});
const requestAddFormWorkflow = () => ({
    type: actionTypes.ADD_FORM_WORKFLOW_REQUEST,
});
const recieveAddFormWorkflow = (payload) => ({
    type: actionTypes.ADD_FORM_WORKFLOW_RECEIVE,
    payload,
});
const failureAddFormWorkflow = (payload) => ({
    type: actionTypes.ADD_FORM_WORKFLOW_FAIL,
    payload,
});


export const getFormWorkFlowAction = () => {
    return async (dispatch) => {
        dispatch(requestFormWorkFlow());
        try {
            const response = await getFormWorkFlowListAPI();
            if (response?.status === 200) {
                dispatch(recieveFormWorkFlow(response?.data?.data))
            }
        } catch (error) {
            dispatch(failureFormWorkFlow(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));

            }
        }
    };
}
export const getFormStateAction = () => {
    return async (dispatch) => {
        dispatch(requestFormStateFlow());
        try {
            const response = await getFormStateAPI();
            if (response?.status === 200) {
                dispatch(recieveFormStateFlow(response?.data?.data))
            }
        } catch (error) {
            dispatch(failureFormStateFlow(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const getFormstepAction = () => {
    return async (dispatch) => {
        dispatch(requestFormStepFlow());
        try {
            const response = await getFormStepAPI();
            if (response?.status === 200) {
                dispatch(recieveFormStepFlow(response?.data?.data))
            }
        } catch (error) {
            dispatch(failureFormStepFlow(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const getFormTypeByrefAction = (id, nextStepList, nextStateList) => {
    return async (dispatch) => {
        dispatch(requestFormTypeFlow());
        try {
            const response = await getFormTypeListAPI(id);
            if (response?.status === 200) {
                const customSearchResultArray = [];
                response?.data?.data?.forEach(element => {
                    const nextStepId = nextStepList.find(({stepValue })=>stepValue===element.nextStepId )
                    const nextStateId = nextStateList.find(({stateValue })=>stateValue===element.nextStateId)
                    element.currentStepId=  `${element?.currentStepId} - ${nextStepId?.stepName}`
                    element.nextStep = `${nextStepId?.stepValue} - ${nextStepId?.stepName}`;
                    element.nextState = `${nextStateId?.stateValue} - ${nextStateId?.stateName}`;
                    customSearchResultArray.push(element);
                });
               
                dispatch(recieveFormTypeFlow(response?.data?.data))
                
            }
        } catch (error) {
            dispatch(failureFormTypeFlow(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const deleteFormWorkFlowAction = (payload, id, nextStepList, nextStateList) => {
    return async (dispatch) => {
        dispatch(requestFormTypeFlow());
        try {
            const response = await deleteFormWorkFlowAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Form WorkFlow deleted successfully"));
            }
            dispatch(getFormTypeByrefAction(id, nextStepList, nextStateList));
        } catch (error) {
            dispatch(failureFormTypeFlow(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));

            }
        }
    };
}
export const updateFormWorkFlowAction = (id, payload, nextStepList, nextStateList) => {
    return async (dispatch) => {
        dispatch(requestFormTypeFlow());
        try {
            const response = await updateFormWorkFlowAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Form WorkFlow updated successfully"));
            }
            dispatch(getFormTypeByrefAction(id, nextStepList, nextStateList));

        } catch (error) {
            dispatch(failureFormTypeFlow(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const createFormWorkFlowAction = (payload, nextStepList, nextStateList) => {
    return async (dispatch) => {
        dispatch(requestFormTypeFlow());
        try {
            const response = await createFormWorkFlowAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Form Workflow added successfully"));
            }
            dispatch(getFormTypeByrefAction(payload.formType, nextStepList, nextStateList));
        } catch (error) {
            dispatch(failureFormTypeFlow(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}