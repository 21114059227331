
// import { actionTypes } from "./actions";
import { createSubProAPI, updateSubProAPI, deleteSubProAPI, getSubProListAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';
import { gettFnstrAction } from '../../state/actions';

export const actionTypes = {
    SUBPR_GET_REQUEST: "SUBPR_GET_REQUEST",
    SUBPR_GET_RECEIVE: "SUBPR_GET_RECEIVE",
    SUBPR_GET_FAIL: "SUBPR_GET_FAIL"
};

const requestSubPr = () => ({
    type: actionTypes.SUBPR_GET_REQUEST,
});
const receiveSubPr = (payload) => ({
    type: actionTypes.SUBPR_GET_RECEIVE,
    payload,
});
const failureSubPr = (payload) => ({
    type: actionTypes.SUBPR_GET_FAIL,
    payload,
});



export const getSubPrAction = () => {
    return async (dispatch) => {
        dispatch(requestSubPr());
        try {
            const response = await getSubProListAPI();
            if (response?.status === 200) {
                dispatch(receiveSubPr(response?.data?.data));
                // dispatch(actions.successAlert("Coutries fetched successfully"));
            }
        } catch (error) {
            dispatch(failureSubPr(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const createSubPrAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestSubPr());
        try {
            const response = await createSubProAPI(payload);
            if (response?.status === 201) {
                // dispatch(receiveSubPr(response?.data?.data));
                dispatch(actions.successAlert("Sub Process added successfully"));
            }
            dispatch(gettFnstrAction());
            dispatch(getSubPrAction());

        } catch (error) {
            dispatch(failureSubPr(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateSubPrAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestSubPr());
        try {
            const response = await updateSubProAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Sub Process updated successfully"));
            }
            dispatch(gettFnstrAction());

        } catch (error) {
            dispatch(failureSubPr(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deleteSubPrAPIAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestSubPr());
        try {
            const response = await deleteSubProAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Sub Process deleted successfully"));
            }
            dispatch(gettFnstrAction());

        } catch (error) {
            dispatch(failureSubPr(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
