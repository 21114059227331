import { actionTypes } from "./actions";

export const initialState = {
  modelFunctionList: {}
};

// ===========================|| COMMON REDUCER ||=========================== //

const modelFunctionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MODEL_FUNCTION_GET_REQUEST:
      return {
        ...state,
        isMdelFUnctionLoading: true,
      };
    case actionTypes.MODEL_FUNCTION_GET_RECEIVE:
      return {
        ...state,
        modelFunctionList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isMdelFUnctionLoading: false,
      };
    case actionTypes.MODEL_FUNCTION_GET_FAIL:
      return {
        ...state,
        isOemFail: false,
        isMdelFUnctionLoading: false
      };
    default:
      return state;
  }
};

export default modelFunctionReducer;
