
// import { actionTypes } from "./actions";
import { createProductFormatValueAPI, deleteProductFormatValueAPI, getProductFormatValueListAPI, updateProductFormatValueAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';
import { getAllProductFormatAPI } from 'containers/Management/ProductFormat/state/endpoints';

export const actionTypes = {
    PRODUCT_FORMAT_VALUE_GET_REQUEST: "PRODUCT_FORMAT_VALUE_GET_REQUEST",
    PRODUCT_FORMAT_VALUE_GET_RECEIVE: "PRODUCT_FORMAT_VALUE_GET_RECEIVE",
    PRODUCT_FORMAT_VALUE_GET_FAIL: "PRODUCT_FORMAT_VALUE_GET_FAIL"
};

const requestPfv = () => ({
    type: actionTypes.PRODUCT_FORMAT_VALUE_GET_REQUEST,
});
const receivePfv = (payload) => ({
    type: actionTypes.PRODUCT_FORMAT_VALUE_GET_RECEIVE,
    payload,
});
const failurePfv = (payload) => ({
    type: actionTypes.PRODUCT_FORMAT_VALUE_GET_FAIL,
    payload,
});
export const getPfvAction = (id) => {
    return async (dispatch) => {
        dispatch(requestPfv());
        try {
            const response = await getProductFormatValueListAPI(id);
            if (response?.status === 200) {
                dispatch(receivePfv(response?.data?.data));
                // dispatch(actions.successAlert("Coutries fetched successfully"));
            }
        } catch (error) {
            dispatch(failurePfv(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const createPfvAction = (payload, fkid) => {
    return async (dispatch) => {
        dispatch(requestPfv());
        try {
            const response = await createProductFormatValueAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Product format value added successfully"));
            }
            dispatch(getPfvAction(fkid));
        } catch (error) {
            dispatch(failurePfv(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updatePfvAction = (id, payload, fkid) => {
    return async (dispatch) => {
        dispatch(requestPfv());
        try {
            const response = await updateProductFormatValueAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Product format value updated successfully"));
            }
            dispatch(getPfvAction(fkid));
        } catch (error) {
            dispatch(failurePfv(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deletePfvAPIAction = (id) => {
    return async (dispatch) => {
        dispatch(requestPfv());
        try {
            const response = await deleteProductFormatValueAPI(id);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Product format value deleted successfully"));
            }
            dispatch(getPfvAction(id));
        } catch (error) {
            dispatch(failurePfv(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));

            }
        }
    };
}
