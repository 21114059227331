import { API } from "api";

export const getAllActiveListTypeAPI = () => {
    return API.get("/api/listtype/getallactive?page=1&size=5000")
                .then((res) => res);
};

export const createListTypeAPI = (payload) => {
    return API.post("/api/listtype/add", payload)
        .then((res) => res);
}

export const updateListTypeAPI = (id, payload) => {   
    return API.put(`/api/listtype/update/${id}`, payload)
        .then((res) => res);
}




