import React, { useEffect, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid,Divider,Button } from '@mui/material';
import StyledDataGrid from 'components/DataGrid/index';
import moment from 'moment'

import { getEquipmentByIdAction, getSearchEquipmentAction } from './state/actions';
import { getParentEquipmentAction } from './EquipmentByCompany/state/actions';

export default function EquipmentDetails({ setView,samid }) {
    const [detailData, setDetailData] = useState({});
    const dispatch = useDispatch();
    const { detailEquipment } = useSelector((state) => state.detailsEquipment);
    const { getEquipment } = useSelector((state) => state.allEquipments);
    const { parentEquipmentList = [] } = useSelector((state) => state.parentEquipmentList);
    const [parentEquipment, setParentEquipment] = useState('');
    
    console.log("detailData",detailData)
    let finalFilter = {};
    finalFilter['filterParams'] = [{columnName: "pkequipment", filterValues: [samid.toString()], filterOption: 9}];
    useEffect(() => {
        dispatch(getSearchEquipmentAction(finalFilter));
        dispatch(getEquipmentByIdAction(samid));
    }, []);

    
    useEffect(() => {
        setDetailData({...detailEquipment,...getEquipment[0]});
    }, [detailEquipment, getEquipment]);

    useEffect(() => {
        dispatch(getParentEquipmentAction({ FkArea: detailEquipment.fkArea, FkFunctionalLocation: detailEquipment.fkFunctionalLocation, equipment: detailEquipment.pkEquipment }));
    }, [detailEquipment]);

    useEffect(() => {
        if(parentEquipmentList.length>0 && detailEquipment.fkEquipment){
            let parentEquipment = parentEquipmentList.filter((item)=>item.pkEquipment ===detailEquipment.fkEquipment)
           if(parentEquipment[0]){
            setParentEquipment(parentEquipment[0].possibleParent);
           }
        }
    }, [parentEquipmentList]);

    const columns = [
        {
            field: 'productFormatName',
            headerName: 'Product Format',
            width: 700,
            disableReorder: false,
            hideSortIcons: false,
            sortable: false,
            filterable: false,
            headerClassName: "dgHeader",
        },
        {
            field: 'productFormatTypeValue', disableReorder: false,
            hideSortIcons: false,
            sortable: false,
            filterable: false, headerName: `Value`, width: 600, headerClassName: "dgHeader",
        },
    ];
    const productFormatAssignmentFromDTO = detailData.hasOwnProperty('productFormatAssignmentFromDTO')?
    detailData.productFormatAssignmentFromDTO.map((dt,index)=>({...dt, id:index})) :[]
    const rows = [...productFormatAssignmentFromDTO]

    return <>
        <Box sx={{ p: 3 }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ fontSize: '16px', fontWeight: '600', mb: 1 }}>General Information</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Company</Typography>
                    </Box>
                    <Box>{detailData.longcompanyname}</Box>
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Area</Typography>
                    </Box>
                    <Box>{detailData.areaname}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Functional Location</Typography>
                    </Box>
                    <Box>{detailData.functionallocationname}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Function</Typography>
                    </Box>
                    <Box>{detailData.functionname==='' || detailData.functionname===null?'-': detailData.functionname}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>OEM</Typography>
                    </Box>
                    <Box>{detailData.oemName}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Model</Typography>
                    </Box>
                    <Box>{detailData.modelname}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Serial Number</Typography>
                    </Box>
                    <Box>{detailData.serialnumber}</Box>
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>SAP/P1 Ref.</Typography>
                    </Box>
                    <Box>{detailData.p1idnumber==='' || detailData.p1idnumber===null?'-': detailData.p1idnumber || detailData.sapP1ref}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Parent Equipment</Typography>
                    </Box>
                    <Box>{parentEquipment}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Ex Active functional location</Typography>
                    </Box>
                    <Box>{detailData.exfunctionallocation==='' || detailData.exfunctionallocation===null?'-': detailData.exfunctionallocation}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Ex-parent</Typography>
                    </Box>
                    <Box>{detailData.exparent==='' || detailData.exparent===null?'-': detailData.exparent}</Box>
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Description</Typography>
                    </Box>
                    <Box>{detailData.description==='' || detailData.description===null?'-': detailData.description}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Process \P1 Sub-process</Typography>
                    </Box>
                    <Box>{detailData.prcoesssubprocessname==='' || detailData.prcoesssubprocessname===null?'-': detailData.prcoesssubprocessname}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Condition</Typography>
                    </Box>
                    <Box>{detailData.conditionname==='' || detailData.conditionname===null?'-': detailData.conditionname}</Box>
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />

            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Default speed</Typography>
                    </Box>
                    <Box>{detailData.designspeed === '' || detailData.designspeed===null?'-': `${detailData.designspeed} ${detailData.designspeedunit || ''}`}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Production speed</Typography>
                    </Box>
                    <Box>{detailData.currentmaximumspeed==='' || detailData.currentmaximumspeed===null?'-': `${detailData.currentmaximumspeed} ${detailData.speedunitname || ''}`}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Control system</Typography>
                    </Box>
                    <Box>{detailData.controlsystemname==='' || detailData.controlsystemname===null?'-': detailData.controlsystemname}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Manufacturing year</Typography>
                    </Box>
                    <Box>{detailData.manufacturedYear==='' || detailData.manufacturedYear===null?'-': detailData.manufacturedYear}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>First installation year</Typography>
                    </Box>
                    <Box>{detailData.firstInstallationYear==='' || detailData.firstInstallationYear===null?'-': detailData.firstInstallationYear}</Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Last rebuild year</Typography>
                    </Box>
                    <Box>{detailData.lastrebuildyear==='' || detailData.lastrebuildyear===null?'-': detailData.lastrebuildyear}</Box>
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ fontSize: '16px', fontWeight: '600', mb: 1 }}>Equipment status Information</Typography>
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Status</Typography>
                    </Box>
                    <Box>{detailData.statusname==='' || detailData.statusname===null?'-': detailData.statusname}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Available for transfer</Typography>
                    </Box>
                    <Box>{detailData.availableTransferDate==='' || detailData.availableTransferDate===null?'No': 'Yes'}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Available for transfer date</Typography>
                    </Box>
                    <Box>{detailData.availableTransferDate==='' || detailData.availableTransferDate===null?'-': moment(detailData.availableTransferDate).format('DD/MM/YYYY')}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Storage reason comment</Typography>
                    </Box>
                    <Box>{detailData.storagereasoncomment==='' || detailData.storagereasoncomment===null?'-': detailData.storagereasoncomment}</Box>
                </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Net book value</Typography>
                    </Box>
                    <Box>{detailData.netbookvalue==='' || detailData.netbookvalue===null?'-': `${detailData.netbookvalue} ${detailData.netbookvaluecurrency || ''} `}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Net book value date</Typography>
                    </Box>
                    <Box>{detailData.netBookValueDate==='' || detailData.netBookValueDate===null?'-': detailData.netBookValueDate}</Box>
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Comment</Typography>
                    </Box>
                    <Box>{detailData.comment==='' || detailData.comment===null?'-': detailData.comment}</Box>
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Is confidential</Typography>
                    </Box>
                    <Box>{detailData.isConfidential==='' || detailData.isConfidential===null?'-': detailData.isConfidential === true? 'Yes': 'No'}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>SAM comment</Typography>
                    </Box>
                    <Box>{detailData.samComment==='' || detailData.samComment===null?'-': detailData.samComment}</Box>
                </Grid>
            </Grid>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ fontWeight: 600, fontSize: '16px', pl: 3 }}>Product format string: {detailData.productformatstring==='' || detailData.productformatstring===null?'-': detailData.productformatstring}</Box>
        <Grid container sx={{ my: 2 }}>
            <div style={{
                width: '100%'
            }}>

                <StyledDataGrid
                    hideFooterPagination
                    disableColumnFilter
                    autoHeight
                    columns={columns}
                    rows={rows}
                    components={{}}
                    componentsProps={{
                    }}
                />
            </div>
        </Grid>

        <Box sx={{ fontWeight: 600, fontSize: '16px', pl: 3 }}>For disposal: {detailData.equipmentForDisposalResponseDTO === null || detailData.equipmentForDisposalResponseDTO===undefined? 'No' :'Yes'}</Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ fontWeight: 600, fontSize: '16px', pl: 3 }}>Is reserved: {detailData.isreserved==='' || detailData.isreserved===null || detailData.isreserved === false?'No': 'Yes'}</Box>
        <Divider sx={{ my: 2 }} />
        <Grid container>
            <Grid item p={3} sm={12}>
                <Box display="flex" justifyContent="flex-end">
                    <Button onClick={() => setView('main')} variant="outlined">Back</Button>
                </Box>
            </Grid>
        </Grid>
    </>

}