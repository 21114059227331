import { API } from "api";

export const getProductFormatValueListAPI = (id) => {
    return API.get(`api/productformattypevalue/getallbyref?FkProductFormatType=${id}&page=0&size=0`)
                .then((res) => res);
};


    export const createProductFormatValueAPI = (payload) => {
        return API.post("/api/productformattypevalue/add", payload)
                    .then((res) => res);
    }
    
    export const updateProductFormatValueAPI = (id, payload) => {
        return API.put(`/api/productformattypevalue/update/${id}`, payload)
                    .then((res) => res);
    }
    
    export const deleteProductFormatValueAPI = (id) => {
        return API.delete(`/api/productformattypevalue/delete/${id}`)
                    .then((res) => res);
    }
