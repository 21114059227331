import { actionTypes } from "./actions";

export const initialState = {  
  initiatorData: {},
  // isInitiatorLoading: false
  contactList:[],
  isContactLoading : false,
};

  const formInitiatorReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FORM_INITIATOR_REQUEST:
        return {
          ...state,
          isInitiatorLoading: true,
        };
      case actionTypes.FORM_INITIATOR_RECEIVE:
        return {
          ...state,
          initiatorData: action.payload,
          isInitiatorLoading: false,
        };
      case actionTypes.FORM_INITIATOR_FAIL:
        return {
          ...state,        
          isInitiatorLoading: false
        };
        case actionTypes.GET_CONTACTS_PENDING:
          return {
              ...state,
              isContactLoading : true
          };
          case actionTypes.GET_CONTACTS_RECEIVED:
            return {
                ...state,
                contactList:action.payload.map((dt, index)=>({...dt, id:index,label:dt.displayName,value:dt.loginName})),
                isContactLoading : false
            };
        case actionTypes.GET_CONTACTS_FAILED:
            return {
              ...state,
              isContactLoading : false
          };
          case actionTypes.SAVE_INITIATOR_PENDING:
            return {
              ...state,
              isInitiatorLoading: true,
            };
          case actionTypes.SAVE_INITIATOR_RECEIVED:
            return {
              ...state,
              initiatorData: action.payload,
              isInitiatorLoading: false,
            };
          case actionTypes.SAVE_INITIATOR_FAILED:
            return {
              ...state,        
              isInitiatorLoading: false
            };
      default:
        return state;
    }
  };
  export default formInitiatorReducer