import { actionTypes } from "./actions";

export const initialState = {
  isRegionLoading: false,
  regionData: {},
  formUploadData:{},
  PkForm: '',
  decisionData: {},
  showNotification: false,
  documentList:[],
  isDocLoading: false,
  docUrl: '',
  isDocDownloaded: false,
  isDeleted: false,
  contactList:[],
  isContactLoading : false,
  detailData:{},
  isDetailLoading: false,
  isSubmited: false,
  initialData:{},
  isFormInitialLoading:false,
  isSubmitSuccessfull: false,
  resubmitData:{},
};
const businessProcessReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REGION_PENDING:
        return {
            ...state,
            isRegionLoading : true
        };
    case actionTypes.GET_REGION_RECEIVED:
      return {
          ...state,
          regionData:action.payload,
          isRegionLoading : false
      };
    case actionTypes.GET_REGION_FAILED:
        return {
          ...state,
          isRegionLoading : false
      };
    case actionTypes.FORM_UPLOAD_PENDING:
      return {
        isDocLoading : true,
          ...state,
      };
    case actionTypes.FORM_UPLOAD_RECEIVED:
      let docData = {...action.payload}
      docData.id=action.payload.pkFormDocument
      return {
          ...state,
          isDocLoading : false,
          formUploadData:docData
      };
    case actionTypes.FORM_UPLOAD_FAILED:
        return {
          ...state,
          isDocLoading : false,
          formUploadData:action.payload,
      };
    case actionTypes.DR_SUBMIT_PENDING:
      return {
          ...state,
          showNotification : false,
          isSubmited: true
      };
    case actionTypes.DR_SUBMIT_RECEIVED:
      let data = action.payload //"Pkform: 6121"

      let firstIndx = data.indexOf('[')
      let lastIndx = data.lastIndexOf(']')
      let tempData = data.substring(firstIndx+1,lastIndx)

      firstIndx = tempData.indexOf('-')
      lastIndx = tempData.lastIndexOf('-')
      let FormId = tempData.substring(firstIndx+1,lastIndx)

      return {
          ...state,
          PkForm:FormId,
          showNotification : true,
          isSubmited: false
      };
    case actionTypes.DR_SUBMIT_FAILED:
      return {
        ...state,
        showNotification : false,
        isSubmited: false
      };
    case actionTypes.DECISION_SUBMIT_PENDING:
      return {
          ...state,
          showNotification : false
      };
    case actionTypes.DECISION_SUBMIT_RECEIVED:
      return {
          ...state,
          decisionData:action.payload,
          showNotification : true
      };
    case actionTypes.DECISION_SUBMIT_FAILED:
      return {
        ...state,
        showNotification : false
      };
    case actionTypes.UPDATE_SUBMIT_PENDING:
      return {
        ...state,
        showNotification : false
      };
    case actionTypes.UPDATE_SUBMIT_RECEIVED:
      return {
          ...state,
          updateData:action.payload,
          showNotification : true
      };
    case actionTypes.UPDATE_SUBMIT_FAILED:
      return {
        ...state,
        showNotification : false
      };
      case actionTypes.DR_RESUBMIT_PENDING:
        return {
          ...state,
          showNotification : false
        };
      case actionTypes.DR_RESUBMIT_RECEIVED:
        return {
            ...state,
            resubmitData:action.payload,
            showNotification : true
        };
      case actionTypes.DR_RESUBMIT_FAILED:
        return {
          ...state,
          showNotification : false
        };
    case actionTypes.COMPLETE_SUBMIT_PENDING:
      return {
        ...state,
        showNotification : false
      };
    case actionTypes.COMPLETE_SUBMIT_RECEIVED:
      return {
          ...state,
          completeData:action.payload,
          showNotification : true
      };
      case actionTypes.COMPLETE_SUBMIT_FAILED:
        return {
          ...state,
          showNotification : false
        };
      case actionTypes.CANCEL_SUBMIT_PENDING:
        return {
          ...state,
          showNotification : false
        };
      case actionTypes.CANCEL_SUBMIT_RECEIVED:
        return {
            ...state,
            cancelData:action.payload,
            showNotification : true
        };
      case actionTypes.CANCEL_SUBMIT_FAILED:
        return {
          ...state,
          showNotification : false
        };
    case actionTypes.DOWNLOAD_DOC_PENDING:
      return {
        ...state,
        isDocDownloaded: false,
      };
    case actionTypes.DOWNLOAD_DOC_RECEIVED:
      return {
        ...state,
        docUrl:action.payload,
        isDocDownloaded: true,
      };
    case actionTypes.DOWNLOAD_DOC_FAILED:
      return {
        ...state,
        isDocDownloaded: false,
      };
    case actionTypes.DELETE_DOC_PENDING:
      return {
        ...state,
        isDeleted: false,
      };
    case actionTypes.DELETE_DOC_RECEIVED:
      return {
        ...state,
        isDeleted: true,
      };
    case actionTypes.DELETE_DOC_FAILED:
      return {
        ...state,
        isDeleted: false,
      };
    case actionTypes.GET_CONTACTS_PENDING:
      return {
          ...state,
          isContactLoading : true
      };
      case actionTypes.GET_CONTACTS_RECEIVED:
        return {
            ...state,
            contactList:action.payload.map((dt, index)=>({...dt, id:index,label:dt.displayName,value:dt.loginName})),
            isContactLoading : false
        };
    case actionTypes.GET_CONTACTS_FAILED:
        return {
          ...state,
          isContactLoading : false
      };
      case actionTypes.GET_DR_PENDING:
        return {
            ...state,
            detailData:{},
            isDetailLoading : true
        };
      case actionTypes.GET_DR_RECEIVED:
        return {
            ...state,
            detailData:action.payload,
            isDetailLoading : false,
        };
      case actionTypes.GET_DR_FAILED:
        return {
          ...state,
          detailData:{},
          isDetailLoading : false
        };
        case actionTypes.GET_INITIAL_PENDING:
          return {
              ...state,
              isFormInitialLoading : true
          };
        case actionTypes.GET_INITIAL_RECEIVED:
          return {
              ...state,
              initialData:action.payload,
              isFormInitialLoading : false
          };
        case actionTypes.GET_INITIAL_FAILED:
          return {
            ...state,
            isFormInitialLoading : false
          };
        case actionTypes.GET_TEST_DATA:
          return {
            ...state,
            showNotification : true,
          };
      
        case 'GET_INITIAL_DATA':
          return {
            ...state,
            showNotification : false,
            formUploadData:{},
            detailData:{},
            PkForm:''
          };
      case  "CLEAR_DR_DATA":
        return {
          ...state,
          isDetailLoading: false,
          formUploadData:{},
          detailData: {},
          PkForm:'',
          showNotification : false
        };
      default:
        return state;
    }
};

export default businessProcessReducer;