import { actionTypes } from "./actions";

export const initialState = {
  downloadContent: '',
  isTemplateLoaded: false,
};

const downloadReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.DOWNLOAD_TEMPLATE_PENDING:
        return {
          ...state,
          isTemplateLoaded: false,
        };
      case actionTypes.DOWNLOAD_TEMPLATE_RECEIVED:
        return {
          ...state,
          downloadContent:action.payload,
          isTemplateLoaded: true,
        };
      case actionTypes.DOWNLOAD_TEMPLATE_FAILED:
        return {
          ...state,
          isTemplateLoaded: false,
        };
      default:
       return state;
  }
};

export default downloadReducer;
