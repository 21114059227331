import axios from 'axios';
import { API, equipmentAPI} from "api";
export const getRegionByIdAPI = (payload) => {
    return API.get(`api/region/${payload}`).then((res) => res);
}
export const formDocumentAPI = (payload) => {
    let  apiUrl= `${process.env.REACT_APP_EQUIPMENT_BASE_URL}/api/formdocument`
      return axios.post(apiUrl,payload,{
        headers: {"Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem('idToken')}`
        },
    }).then(resp=>resp)
}
export const submitDRAPI = (payload) => {
    return equipmentAPI.post("api/equipmentdisposal/submit", payload).then((res) => res);
};
export const decisionAPI = (payload) => {
    return equipmentAPI.post("api/equipmentdisposal/step10submit", payload).then((res) => res);
};
export const updateAPI = (payload) => {
    return equipmentAPI.post("api/equipmentdisposal/update", payload).then((res) => res);
};
export const reSubmitAPI = (payload) => {
    return equipmentAPI.post("api/equipmentdisposal/resubmit", payload).then((res) => res);
};
export const cancelAPI = (payload) => {
    return equipmentAPI.post("api/equipmentdisposal/cancel", payload).then((res) => res);
};
export const completeAPI = (payload) => {
    return equipmentAPI.post("api/equipmentdisposal/complete", payload).then((res) => res);
};
export const downloadDocAPI = (payload) => {
    return equipmentAPI.get(`api/formdocument/${payload.formId}/${payload.docId}`).then((res) => res);
};
export const deleteDocAPI = (payload) => {
    return equipmentAPI.delete(`api/formdocument/${payload}`).then((res) => res);
};
export const searchContactAPI = (payload) => {
    return API.get(`api/security/search/${payload}`).then((res) => res);
};
export const formDetailAPI = (payload) => {
    return equipmentAPI.get(`api/equipmentdisposal/getformdetail?PkForm=${payload}`).then((res) => res);
};
export const formInitialAPI = () => {
    return equipmentAPI.get("/api/equipmentdisposal/getinitialform").then((res) => res);
};
