import EqDisposalRequest from 'containers/SearchViews/EquipmentDisposal/EqDisposalRequest';
import FunctionalStructureRef from 'containers/SearchViews/FunctionalStructure/FunctionalStructureRef';
// import GeneralEditTab from 'containers/SearchViews/GeneralEditTab';
import MachineReservationSearch from 'containers/SearchViews/MachineReservationSearch/MachineReservationSearch';
import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable/Loadable';
import MainLayout from '../layout/MainLayout/MainLayout';
import ReloadAddSamid from 'containers/SearchViews/ReloadAddSamid';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('../containers/Dashboard/Dashboard')));
const SearchEquipment = Loadable(lazy(() => import('../containers/SearchViews/SearchEquipment')));
const DetailEquipment = Loadable(lazy(() => import('../containers/SearchViews/DetailEquipment')));
const EquipmentByCompany = Loadable(lazy(() => import('../containers/SearchViews/EquipmentByCompany/EquipmentByCompany')));
//Companies lists
const CompaniesLists = Loadable(lazy(() => import('../containers/SearchViews/Companies/CompaniesLists')));

//Coordinators List
const CoordinatorsList = Loadable(lazy(() => import('../containers/SearchViews/Coordinators/CoordinatorsList')))

// Management
const Regions = Loadable(lazy(() => import('containers/Management/Regions/Regions')));
const Clusters = Loadable(lazy(() => import('containers/Management/Clusters/Clusters')));
// const Menu2 = Loadable(lazy(() => import('../containers/Menu2/Menu2')));
const Countries = Loadable(lazy(() => import('../containers/Management/Countries/Countries')));
const Companies = Loadable(lazy(() => import('../containers/Management/Companies/Companies')));
const Conditions = Loadable(lazy(() => import('../containers/Management/Conditions/Conditions')));
const ControlSystems = Loadable(lazy(() => import('../containers/Management/ControlSystem/ControlSystem')));
const Currencies = Loadable(lazy(() => import('../containers/Management/Currencies/Currencies')));
const SpeedUnits = Loadable(lazy(() => import('../containers/Management/SpeedUnits/SpeedUnits')));
const OEM = Loadable(lazy(() => import('../containers/Management/OEM/OEM')));
const Models = Loadable(lazy(() => import('../containers/Management/Models/Model')));
const FunctionalStructure = Loadable(lazy(() => import('../containers/Management/FunctionalStructure/FunctionalStructure')));
const ProductFormatValue = Loadable(lazy(() => import('../containers/Management/ProductFormatTypeValue/ProductFormatTypeValue')));
const ProductFormat = Loadable(lazy(() => import('../containers/Management/ProductFormat/ProductFormat')));
const FunctionProductFormat = Loadable(lazy(() => import('../containers/Management/FunctionProductFormat/FunctionProductFormat')));
const AdministratorRole = Loadable(lazy(() => import('../containers/Management/AdministratorRole/AdministratorRole')))
const AdministratorCoordinators = Loadable(lazy(() => import('../containers/Management/AdministratorCoordinators/AdministratorCoordinators')))
const UploadTemplate = Loadable(lazy(() => import('../containers/Management/UploadTemplate/UploadTemplate')))
const DownloadTemplate = Loadable(lazy(() => import('../containers/Management/DownloadTemplate/DownloadTemplate')))
const EquipmentAction = Loadable(lazy(() => import('../containers/Management/EquipmentActions/EquipmentAction')))
const CompanyValidationStatus = Loadable(lazy(() => import('../containers/Management/Reports/CompanyValidationSTatus')))
const FunctionalLocations= Loadable(lazy(() => import('containers/Management/FunctionalLocations/FunctionalLocations')));
const ValidateCompanyInformation= Loadable(lazy(() => import('containers/Management/ValidationCompanyInformation/ValidateCompanyInformation')));
const SamTeamFocus= Loadable(lazy(() => import('containers/Management/SamTeamFocus/SamTeamFocus')));
// Design speed
const ListTypeValue= Loadable(lazy(() => import('containers/DesignSpeed/Lists/ListTypeValue/ListTypeValue')));
const ListType= Loadable(lazy(() => import('containers/DesignSpeed/Lists/ListType/ListType')));
const ListOfSystemOwner= Loadable(lazy(() => import('containers/DesignSpeed/Security/ListOfSystemOwner')));
const ListOfEquipmentOwner= Loadable(lazy(() => import('containers/DesignSpeed/Security/ListOfEquipmentOwner')));
const EquipmentType= Loadable(lazy(() => import('containers/DesignSpeed/EquipmentType/EquipmentType')));
const EquipmentColumnMapper= Loadable(lazy(() => import('containers/DesignSpeed/EquipmentColumnMapper/index')));
const MachineType= Loadable(lazy(() => import('containers/DesignSpeed/MachineType/MachineType')));
const ViewMachineType= Loadable(lazy(() => import('containers/DesignSpeed/ViewMachineType/ViewMachineType')));
const MainScreen= Loadable(lazy(() => import('containers/DesignSpeed/MainScreen/MainScreen')));
const RequestHistory= Loadable(lazy(() => import('containers/DesignSpeed/RequestHistory/RequestHistory.js')));
const Approvals= Loadable(lazy(() => import('containers/DesignSpeed/Approvals/ApprovalHistory.js')));
const RequestReports = Loadable(lazy(() => import('containers/DesignSpeed/Configuration/RequestReports/RequestReports.js')));
const FormInitiator = Loadable(lazy(() => import('../containers/Management/FormInitiator/FormInitiator')))
//Business Process
const NewDisposalRequest = Loadable(lazy(() => import('../containers/BusinessProcess/DisposalRequest/NewDisposalRequest')));
const NewMRRequest = Loadable(lazy(() => import('../containers/BusinessProcess/MachineReservationRequest/NewMRRequest')));

const FormWorkFlow =Loadable(lazy(() => import('../containers/DeveloperHomePage/FormWorkFlow/FormWorkFlow')))
const PowerBI = Loadable(lazy(() => import('../containers/PowerBI/PowerBI')));
const UpdateTemplate = Loadable(lazy(() => import('../containers/Management/UpdateTemplate/UpdateTemplate')))
const BulkUploadStatus = Loadable(lazy(() => import('../containers/Management/BulkUploadStatus/BulkUploadStatus')))
const Status =Loadable(lazy(() => import('../containers/DeveloperHomePage/Status/Status')))
const DeveloperProductFormat =Loadable(lazy(() => import('../containers/DeveloperHomePage/ProductFormat/ProductFormat')))
const RuleGroup= Loadable(lazy(() => import('containers/DesignSpeed/RuleGroup/RuleGroup')));
const RuleGroupConfiguration= Loadable(lazy(() => import('containers/DesignSpeed/RuleGroupConfiguration/index')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/',
            children: [
                {
                    path: 'dashboard',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: '/search-views',
            children: [
                {
                    path: 'search-equipment',
                    element: <SearchEquipment />
                },
                {
                    path: 'equipment-company',
                    element: <EquipmentByCompany />
                }, 
                {
                    path: 'disposal-search',
                    element: <EqDisposalRequest />
                },
                {
                    path: 'companies-lists',
                    element: <CompaniesLists />
                },
                {
                    path: 'coordinators-list',
                    element: <CoordinatorsList />
                },
                {
                    path: 'functional-structure-ref',
                    element: <FunctionalStructureRef />
                },
                {
                    path: 'reservation-search',
                    element: <MachineReservationSearch />
                },
                {
                    path: 'detail-equipment',
                    element: <DetailEquipment />
                },
                {
                    path: 'detail-equipment:id',
                    element: <DetailEquipment />
                },
            ]
        },
        {
            path: '/management',
            children: [
                {
                    path: 'regions',
                    element: <Regions />,
                },
                {
                    path: 'countries',
                    element: <Countries />
                },
                {
                    path: 'companies',
                    element: <Companies />
                },
                {
                    path: 'clusters',
                    element: <Clusters />,
                },
                {
                    path: 'conditions',
                    element: <Conditions />,
                },
                {
                    path: 'controlSystems',
                    element: <ControlSystems />,
                },
                {
                    path: 'currencies',
                    element: <Currencies />,
                },
                {
                    path: 'speedUnits',
                    element: <SpeedUnits />,
                },
                {
                    path: 'oem',
                    element: <OEM />,
                },
                {
                    path: 'models',
                    element: <Models />,
                }, {
                    path: 'functional-structure',
                    element: <FunctionalStructure />
                },
                {
                    path: 'productFormatValue',
                    element: <ProductFormatValue />,
                },
                {
                    path: 'productFormat',
                    element: <ProductFormat />,
                },
                {
                    path: 'functionProductFormat',
                    element: <FunctionProductFormat />,
                },
                {
                    path: 'administratorRole',
                    element: <AdministratorRole />,
                },
                {
                    path: 'administratorCoordinators',
                    element: <AdministratorCoordinators />,
                },
                {
                    path: 'uploadTemplate',
                    element: <UploadTemplate/>,
                },
                {
                    path: 'downloadTemplate',
                    element: <DownloadTemplate/>,
                },  
                {
                    path: 'equipment-actions',
                    element: <EquipmentAction/>
                },
                {
                    path: 'company-validation-status',
                    element: <CompanyValidationStatus />,
                },
                {
                    path: 'updateTemplate',
                    element: <UpdateTemplate/>,
                },
                {
                    path: 'bulkUploadStatus',
                    element: <BulkUploadStatus/>,
                },
                {
                    path: 'functional-locations',
                    element: <FunctionalLocations />,
                },
                // {
                //     path: 'validate-company-information',
                //     element: <ValidateCompanyInformation />,
                // },
                {
                    path: 'eq-by-company',
                    element: <EquipmentByCompany />,
                },
                {
                    path: 'team-focus',
                    element:  <SamTeamFocus />
                },
                
                {
                    path: 'create-new-eq',
                    element:  <ReloadAddSamid />
                },
                {
                    path: 'form-initiator',
                    element: <FormInitiator/>
                },
            ]
            
        },
        {
            path: '/business-process',
            children: [
                {
                    path: 'new-disposal-request',
                    element: <NewDisposalRequest />
                },  
                {
                    path: 'new-disposal-request/:id',
                    element: <NewDisposalRequest />
                },                
            ]
        },
        {
            path: '/business-process',
            children: [
                {
                    path: 'new-mr-request',
                    element: <NewMRRequest />
                }, 
                {
                    path: 'new-mr-request/:id',
                    element: <NewMRRequest />
                },                
            ]
        },
        {
            path: '/developer-homePage',
            children: [
                {
                    path: 'form-workFlow',
                    element: <FormWorkFlow />
                }
            ]
        },
        {
            path: '/developer-homePage',
            children: [
                {
                    path: 'status',
                    element: <Status />
                }
            ]
        },
        {
            path: '/developer-homePage',
            children: [
                {
                    path: 'product-format',
                    element: <DeveloperProductFormat />
                }
            ]
        },
       
        {
            path: '/',
            children: [
                {
                    path: 'powerbi',
                    element: <PowerBI />
                }
            ]
        },
        {
            path: '/business-process',
            children: [
                {
                    path: 'disposal-request',
                    element: <EqDisposalRequest />
                },
                {
                    path: 'machine-reservation',
                    element: <MachineReservationSearch />
                }
            ]
        },
        {
            path: '/design-speed',
            children: [
                {
                    path: 'main-screen',
                    element: <MainScreen />
                },
                {
                    path: 'equipment-type',
                    element: <EquipmentType />
                },
                {
                    path: 'equipment-column-mapper',
                    element: <EquipmentColumnMapper />
                },
                {
                    path: 'machine-type',
                    element: <MachineType />
                }, {
                    path: 'view-machine-type',
                    element: <ViewMachineType />
                },
                {
                    path: 'list-type',
                    element: <ListType />
                },
                {
                    path: 'list-type-value',
                    element: <ListTypeValue />
                },
                {
                    path: 'list-of-system-owner',
                    element: <ListOfSystemOwner />
                },
                {
                    path: 'list-of-equipment-owner',
                    element: <ListOfEquipmentOwner />
                },
                {
                    path: 'request-history',
                    element:<RequestHistory/>
                },
                {
                   path: 'approvals',
                   element: <Approvals/>,   
                },             
                {
                    path: 'request-reports',
                    element:<RequestReports/>
                },
                {
                    path: 'rule-group',
                    element: <RuleGroup />
                },
                {
                    path: 'ruleGroup-configuration',
                    element: <RuleGroupConfiguration />
                },
            ]
        },

    ]
};

export default MainRoutes;