import { actionTypes } from "./actions";

export const initialState = {
  compStatusList: []
};

// ===========================|| COMMON REDUCER ||=========================== //

const CompanyStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CSTATUS_GET_REQUEST:
      return {
        ...state,
        isCompStaLoading: true,
      };
    case actionTypes.CSTATUS_GET_RECEIVE:
      return {
        ...state,
        compStatusList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isCompStaLoading: false,
      };
    case actionTypes.CSTATUS_GET_FAIL:
      return {
        ...state,
        isCompStaLoading: false,
        isCompStaFail: false,
      };
    default:
      return state;
  }
};

export default CompanyStatusReducer;
