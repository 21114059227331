import { API } from "api";

export const getAllActiveListTypeValueAPI = () => {
    return API.get("/api/listtypevalues/getall?page=1&size=5000")
                .then((res) => res);
};
export const createListTypeValueAPI = (payload) => {
    return API.post("/api/listtypevalues/add", payload)
                .then((res) => res);
}

export const updateListTypeValueAPI = (id, payload) => {
    return API.put(`/api/listtypevalues/update/${id}`, payload)
                .then((res) => res);
}
export const deleteListTypeValueAPI = (id) => {
    return API.delete(`/api/listtypevalues/delete/${id}`)
                .then((res) => res);
}

