import { API } from "api";

export const getProcessListAPI = (req) => {
    return API.get("api/process/getallactive?page=0&size=0")
        .then((res) => res);
};


export const createProcessAPI = (payload) => {
    return API.post("/api/process/add", payload)
        .then((res) => res);
}

export const updateProcessAPI = (id, payload) => {
    return API.put(`/api/process/update/${id}`, payload)
        .then((res) => res);
}

export const deleteProcessAPI = (id) => {
    return API.delete(`/api/process/delete/${id}`)
        .then((res) => res);
}
