import { API} from "api";
export const getDSSysOwnerRolesAPI = () => {
    return API.get('api/security/dssystemowner/getall/true').then((res) => res);
}

export const getDSEquipmentRolesAPI = () => {
    return API.get('api/security/dssystemowner/getall/true').then((res) => res);
}

export const getDSUsersRolesAPI = () => {
    return API.get('api/security/dssystemowner/getall/true').then((res) => res);
}

export const AddDSEquipmentOwnerAPI = (payload) => {
    return API.post('api/equipmentowner/addequipmentowner',payload).then((res) => res);
}

export const GetDSEquipmentOwnerAPI = () => {
    return API.get('api/equipmentowner/getall?page=1&size=1000&sortColumnName=pkDSListEquipmentOwner').then((res) => res);
}

export const GetAllDSEquipmentOwnerAPI = () => {
    return API.get('api/security/dsequipmentowner/getall/true').then((res) => res);
}

export const UpdateDSEquipmentOwnerAPI = (payload) => {
    return API.put('api/equipmentowner/addequipmentowner',payload).then((res) => res);
}

export const deleteDSEquipmentOwnerAPI = (id) => {
    return API.delete(`/api/equipmentowner/DeleteEquipmentOwner/${id}`)
                .then((res) => res);
}
