import { actionTypes } from "./actions";

export const initialState = {  
  allCompaniesList: [],
  countryList: [],
  companyforEquipmentList: []
};

// ===========================|| COMMON REDUCER ||=========================== //

export const companyListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.All_COMPANIES_REQUEST:
      return {
        ...state,
        isCompanyLoading: true,
      };
    case actionTypes.All_COMPANIES_RECEIVE:
      return {
        ...state,
        allCompaniesList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isCompanyLoading: false,
      };
    case actionTypes.All_COMPANIES_FAIL:
      return {
        ...state,        
        isCompanyLoading: false
      };
    default:
      return state;
  }
};

export const companyForEquipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COMPANY_FOR_EQUIPMENT_REQUEST:
      return {
        ...state,
        isCompanyForEquipment: true,
      };
    case actionTypes.COMPANY_FOR_EQUIPMENT_RECEIVE:
      return {
        ...state,
        companyforEquipmentList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isCompanyForEquipment: false,
      };
    case actionTypes.COMPANY_FOR_EQUIPMENT_FAIL:
      return {
        ...state,        
        isCompanyForEquipment: false
      };
    default:
      return state;
  }
};

export const countryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.All_COUNTRY_REQUEST:
      return {
        ...state,
        isCountryLoading: true,
      };
    case actionTypes.All_COUNTRY_RECEIVE:
      return {
        ...state,
        countryList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isCountryLoading: false,
      };
    case actionTypes.All_COUNTRY_FAIL:
      return {
        ...state,        
        isCountryLoading: false
      };
    default:
      return state;
  }
};
