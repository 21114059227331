import { actionTypes } from "./actions";

export const initialState = {
    allMachineTypeList:[],
  parentDictUIColMappings: []  
};


const machineTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MACHINE_TYPE_GET_REQUEST:
      return {
        ...state,
        isMachineTypeLoading: true,
      };
    case actionTypes.MACHINE_TYPE_STOP_LOADING:
      return {
        ...state,
        isMachineTypeLoading: false,
      };
    case actionTypes.MACHINE_TYPE_GET_RECEIVE:
      let machineData = action.payload.machineData
      return {
        ...state,
        allMachineTypeList: machineData.map((dt, index) => ({ ...dt, id: index })),
        machineTypeDictUIColMappings: action.payload.machineTypeDictUIColMappings,
        isMachineTypeLoading: false,
      };
    case actionTypes.MACHINE_TYPE_GET_FAIL:
      return {
        ...state,
        isMachineTypeLoading: false,
        isMachineTypeFail: false,
      };
    case  "CLEAR_MAKER_DATA":
        return {
          ...state,
          allMachineTypeList: [],
          machineTypeDictUIColMappings:[]
        };
    default:
      return state;
  }
};

export default machineTypeReducer;
