import { getAffiliateAPI,getRequestHistoryAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    RQT_HISTORY_GET_REQUEST: "RQTHISTORY_GET_REQUEST",
    RQT_HISTORY_GET_RECEIVE: "RQTHISTORY_GET_RECEIVE",
    RQT_HISTORY_GET_FAIL: "RQTHISTORY_GET_FAIL",
    RQT_HISTORY_STOP_LOADING: " MAIN_SCREEN_STOP_LOADING",
    AFFILIATE_GET_REQUEST: " AFFILIATE_GET_REQUEST",
    AFFILIATE_GET_RECEIVE: " AFFILIATE_GET_RECEIVE",
    AFFILIATE_GET_FAIL: " AFFILIATE_GET_FAIL",
  };
  const requestAffiliate= () => ({
    type: actionTypes.AFFILIATE_GET_REQUEST,
});
const receiveAffiliate = (payload) => ({
    type: actionTypes.AFFILIATE_GET_RECEIVE,
    payload,
});
const failureAffiliate= (payload) => ({
    type: actionTypes.AFFILIATE_GET_FAIL,
    payload,
});
const requestHistoryScreen= () => ({
    type: actionTypes.RQT_HISTORY_GET_REQUEST,
});
const receiveHistoryScreen = (payload) => ({
    type: actionTypes.RQT_HISTORY_GET_RECEIVE,
    payload,
});
const failureHistoryScreen = (payload) => ({
    type: actionTypes.RQT_HISTORY_GET_FAIL,
    payload,
});

export const getAffiliateAction = () => {
    return async (dispatch) => {
        dispatch(requestAffiliate());
        try {
            const response = await getAffiliateAPI();
            if (response?.status === 200) {
            console.log("action affiliate data", response?.data?.data?.dsAffilateData)
                dispatch(receiveAffiliate(response?.data?.data?.dsAffilateData));
            }
        } catch (error) {
            dispatch(failureAffiliate(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const getRequestHistoryAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestHistoryScreen());
        try {
            const response = await getRequestHistoryAPI(payload);
            if (response?.status === 200) {
                dispatch(receiveHistoryScreen( response?.data?.data));
            }
        } catch (error) {
            dispatch(failureHistoryScreen(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
