
import { getAllEqByCompanyAPI, createFLAPI, getAllFLAPI, getAllSUAPI, createEQAPI, 
    getAllStatusAPI,deleteEQAPI, getAllConditionAPI, updateFLlocationAPI, getEqbyIdAPI, updateEQAPI, 
    getParentEquipmentAPI, checkFunctionLocationAPI,hasChildrenAPI, getChildrenAPI, getAllEqByCompanyExcelAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    EQ_COMPANY_GET_REQUEST: "EQ_COMPANY_GET_REQUEST",
    EQ_COMPANY_GET_RECEIVE: "EQ_COMPANY_GET_RECEIVE",
    EQ_COMPANY_GET_FAIL: "EQ_COMPANY_GET_FAIL",
    FL_GET_REQUEST: "FL_GET_REQUEST",
    FL_GET_RECEIVE: "FL_GET_RECEIVE",
    FL_GET_FAIL: "FL_GET_FAIL",
    SPUNIT_REQUEST: "SPUNIT_REQUEST",
    SPUNIT_RECEIVE: "SPUNIT_RECEIVE",
    SPUNIT_FAIL: "SPUNIT_FAIL",
    ADD_EQ_REQUEST: "ADD_EQ_REQUEST",
    ADD_EQ_RECEIVE: "ADD_EQ_RECEIVE",
    ADD_EQ_FAIL: "ADD_EQ_FAIL",
    GET_STATUS_RECEIVE: "GET_STATUS_RECEIVE",
    GET_STATUS_REQUEST: "GET_STATUS_REQUEST",
    GET_STATUS_FAIL: "GET_STATUS_FAIL",
    GET_EQ_ID_RECEIVE: 'GET_EQ_ID_RECEIVE',
    GET_EQ_ID_REQUEST: "GET_EQ_ID_REQUEST",
    GET_EQ_ID_FAIL: "GET_EQ_ID_FAIL",
    GET_FL_ID_RECEIVE: 'GET_FL_ID_RECEIVE',
    GET_FL_ID_REQUEST: "GET_FL_ID_REQUEST",
    GET_FL_ID_FAIL: "GET_FL_ID_FAIL",
    SAM_EQ_RECEIVE: "SAM_EQ_RECEIVE",   PARENT_EQUIPMENT_PENDING: "PARENT_EQUIPMENT_PENDING",
    PARENT_EQUIPMENT_RECEIVED: "PARENT_EQUIPMENT_RECEIVED",
    PARENT_EQUIPMENT_FAILED: "PARENT_EQUIPMENT_FAILED",
    CHECK_CHILDREN_PENDING: "CHECK_CHILDREN_PENDING",
    CHECK_CHILDREN_RECEIVED: "CHECK_CHILDREN_RECEIVED",
    CHECK_CHILDREN_FAILED: "CHECK_CHILDREN_FAILED",
    GET_CHILDREN_PENDING: "GET_CHILDREN_PENDING",
    GET_CHILDREN_RECEIVED: "GET_CHILDREN_RECEIVED",
    GET_CHILDREN_FAILED: "GET_CHILDREN_FAILED"
  };

const requestParentEquipment= () => ({
    type: actionTypes.PARENT_EQUIPMENT_PENDING,
});
const receiveParentEquipment = (payload) => ({
    type: actionTypes.PARENT_EQUIPMENT_RECEIVED,
    payload,
});
const failureParentEquipment = (payload) => ({
    type: actionTypes.PARENT_EQUIPMENT_FAILED,
    payload,
});

const requestCompany = () => ({
    type: actionTypes.EQ_COMPANY_GET_REQUEST,
});
const receiveCompany = (payload) => ({
    type: actionTypes.EQ_COMPANY_GET_RECEIVE,
    payload,
});
const failureCompany = (payload) => ({
    type: actionTypes.EQ_COMPANY_GET_FAIL,
    payload,
});
const checkChildrenPending= () => ({
    type: actionTypes.CHECK_CHILDREN_PENDING,
});
const checkChildrenReceived = (payload) => ({
    type: actionTypes.CHECK_CHILDREN_RECEIVED,
    payload,
});
const checkChildrenFailed = (payload) => ({
    type: actionTypes.CHECK_CHILDREN_FAILED,
    payload,
})

const getChildrenPending= () => ({
    type: actionTypes.GET_CHILDREN_PENDING,
});
const getChildrenReceived = (payload) => ({
    type: actionTypes.GET_CHILDREN_RECEIVED,
    payload,
});
const getChildrenFailed = (payload) => ({
    type: actionTypes.GET_CHILDREN_FAILED,
    payload,
})

export const getAllEqByCompanyAction = (a, b, c) => {
    return async (dispatch) => {
        dispatch(requestCompany());
        try {
            const response = await getAllEqByCompanyAPI(a, b, c);
            if (response?.status === 200) {
                dispatch(receiveCompany(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureCompany(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert("Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getAllEqByCompanyExcelAction = (a, b, c) => {
    return async (dispatch) => {
        dispatch(requestCompany());
        try {
            const response = await getAllEqByCompanyExcelAPI(a, b, c);
            if (response?.status === 200) {
                let a = document.createElement('a');
                a.href = response?.data?.data;
                document.body.appendChild(a);
                a.click();           
                a.remove();
                dispatch(receiveCompany(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureCompany(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};


export const createFLAction = (payload) => {
    return async (dispatch) => {
        try {
            const response = await createFLAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Functional location added successfully"));
            }
        } catch (error) {
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateFlLocationAction = (id, payload, b, c) => {
    return async (dispatch) => {
        try {
            const response = await updateFLlocationAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Location updated successfully"));
            }
            dispatch(getAllEqByCompanyAction(payload.fkCompany, b, c));
        } catch (error) {
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

const requestFL = () => ({
    type: actionTypes.FL_GET_REQUEST,
});
const receiveFL = (payload) => ({
    type: actionTypes.FL_GET_RECEIVE,
    payload,
});
const failureFL = (payload) => ({
    type: actionTypes.FL_GET_FAIL,
    payload,
});

export const getAllFLocationAction = (a) => {
    return async (dispatch) => {
        dispatch(requestFL());
        try {
            const response = await getAllFLAPI(a);
            if (response?.status === 200) {
                dispatch(receiveFL(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureFL(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

const requestSU = () => ({
    type: actionTypes.SPUNIT_REQUEST,
});
const receiveSU = (payload) => ({
    type: actionTypes.SPUNIT_RECEIVE,
    payload,
});
const failureSU = (payload) => ({
    type: actionTypes.SPUNIT_FAIL,
    payload,
});

export const getAllSpeedUnitAction = () => {
    return async (dispatch) => {
        dispatch(requestSU());
        try {
            const response = await getAllSUAPI();
            if (response?.status === 200) {
                dispatch(receiveSU(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureSU(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};


const requestAdEq = () => ({
    type: actionTypes.ADD_EQ_REQUEST,
});
const receiveAdEq = (payload) => ({
    type: actionTypes.ADD_EQ_RECEIVE,
    payload,
});
const failureAdEq = (payload) => ({
    type: actionTypes.ADD_EQ_FAIL,
    payload,
});

const receiveSamIdForAdEq = (payload) => ({
    type: actionTypes.SAM_EQ_RECEIVE,
    payload,
});

export const createEQAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestAdEq());
        try {
            const response = await createEQAPI(payload);           

            if (response?.status === 201) {
                dispatch(actions.successAlert(`Equipment Added Successfully with SAM ID ${response.data.data}`));
                dispatch(receiveSamIdForAdEq(response.data.data))                
            }
            dispatch(receiveAdEq())
        } catch (error) {
            dispatch(failureAdEq(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const updateEQAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestAdEq());
        try {
            const response = await updateEQAPI(id, payload);

            if (response?.status === 200) {
                dispatch(actions.successAlert("Equipment updated successfully"));
            }
            dispatch(receiveAdEq())
        } catch (error) {
            dispatch(failureAdEq(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const deleteEQAction = (id) => {
    return async (dispatch) => {
        dispatch(requestAdEq());
        try {
            const response = await deleteEQAPI(id);

            if (response?.status === 200) {
                dispatch(actions.successAlert("Equipment deleted successfully"));
            }
            dispatch(receiveAdEq())
        } catch (error) {
            dispatch(failureAdEq(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

const receiveStatus = (payload) => ({
    type: actionTypes.GET_STATUS_RECEIVE,
    payload,
});

const requestStatus = (payload) => ({
    type: actionTypes.GET_STATUS_REQUEST,
    payload,
});

const failStatus = (payload) => ({
    type: actionTypes.GET_STATUS_FAIL,
    payload,
});

export const getAllStatusAction = () => {
    return async (dispatch) => {
        dispatch(requestStatus());
        try {
            const response = await getAllStatusAPI();
            if (response?.status === 200) {
                dispatch(receiveStatus(response?.data?.data));
            }
        } catch (error) {
            dispatch(failStatus());
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

const receivegetEq = (payload) => ({
    type: actionTypes.GET_EQ_ID_RECEIVE,
    payload,
});
const requestgetEq = (payload) => ({
    type: actionTypes.GET_EQ_ID_REQUEST,
    payload,
});

const failgetEq = (payload) => ({
    type: actionTypes.GET_EQ_ID_FAIL,
    payload,
});

const receivegetFl = (payload) => ({
    type: actionTypes.GET_FL_ID_RECEIVE,
    payload,
});
const requestgetFl = (payload) => ({
    type: actionTypes.GET_FL_ID_REQUEST,
    payload,
});

const failgetFl = (payload) => ({
    type: actionTypes.GET_FL_ID_FAIL,
    payload,
});

export const getEqbyIdAction = (id) => {
    return async (dispatch) => {
        dispatch(requestgetEq());
        try {
            const response = await getEqbyIdAPI(id);
            if (response?.status === 200) {
                dispatch(receivegetEq(response?.data?.data));
            }
        } catch (error) {
            dispatch(failgetEq());
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getParentEquipmentAction = (payload) => {   
    return async (dispatch) => {
        dispatch(requestParentEquipment());
        try {
            const response = await getParentEquipmentAPI(payload);            
            if (response?.status === 200) {
                dispatch(receiveParentEquipment(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureParentEquipment(error?.response?.data?.data));
            if (error?.response) {
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const checkFunctionLocationAction = (payloadobj,samid, payload) => {
    return async (dispatch) => {
        dispatch(requestgetFl());
        try {
            const response = await checkFunctionLocationAPI(payloadobj);
            if (response?.status === 200) {
                dispatch(receivegetFl(response?.data?.data));
                if(!response?.data?.data){
                    payload.flWarning =3
                    dispatch(updateEQAction(samid, payload))
                }
            }
        } catch (error) {
            dispatch(failgetFl());
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const hasChildrenAction = (samid, formid) => {
    return async (dispatch) => {
        dispatch(checkChildrenPending());
        try {
            const response = await hasChildrenAPI(samid, formid);
            if (response?.status === 200) {
                dispatch(checkChildrenReceived(response?.data?.data));
            }
        }
        catch (error) {    
            dispatch(checkChildrenFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const getChildrenAction = (samid, formid) => {
    return async (dispatch) => {
        dispatch(getChildrenPending());
        try {
            const response = await getChildrenAPI(samid, formid);
            if (response?.status === 200) {
                dispatch(getChildrenReceived(response?.data?.data));
            }
        }
        catch (error) {    
            dispatch(getChildrenFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
