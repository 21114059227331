import { actionTypes } from "./actions";

export const initialState = {
  speedUnits: [],
  loading: false
};

// ===========================|| COMMON REDUCER ||=========================== //

const speedUnitReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SPEED_UNIT_PENDING:
      return {
        ...state,
        loading: true
      };
      case actionTypes.SPEED_UNIT_RECEIVED:
      return {
        ...state,
        speedUnits: action.payload.map((dt, index)=>({...dt, id:index})),
        loading: false,
      };
      case actionTypes.SPEED_UNIT_FAILED:
      return {
        ...state,
        isSpeedUnitFail: false,
        loading: false
      };
    default:
      return state;
  }
};

export default speedUnitReducer;
