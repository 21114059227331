import { actions } from 'store/Reducer/actions';
import { getRegionByIdAPI, formDocumentAPI, submitDRAPI,decisionAPI, updateAPI,completeAPI,cancelAPI, 
    downloadDocAPI, deleteDocAPI, searchContactAPI, formDetailAPI, formInitialAPI, reSubmitAPI } from './endpoints';
export const actionTypes = {
    GET_REGION_PENDING: "GET_REGION_PENDING",
    GET_REGION_RECEIVED: "GET_REGION_RECEIVED",
    GET_REGION_FAILED: "GET_REGION_FAILED",
    FORM_UPLOAD_PENDING: "FORM_UPLOAD_PENDING",
    FORM_UPLOAD_RECEIVED: "FORM_UPLOAD_RECEIVED",
    FORM_UPLOAD_FAILED: "FORM_UPLOAD_FAILED",
    DR_SUBMIT_PENDING: "DR_SUBMIT_PENDING",
    DR_SUBMIT_RECEIVED: "DR_SUBMIT_RECEIVED",
    DR_SUBMIT_FAILED: "DR_SUBMIT_FAILED",
    DR_RESUBMIT_PENDING: "DR_RESUBMIT_PENDING",
    DR_RESUBMIT_RECEIVED: "DR_RESUBMIT_RECEIVED",
    DR_RESUBMIT_FAILED: "DR_RESUBMIT_FAILED",
    DECISION_SUBMIT_PENDING: "DECISION_SUBMIT_PENDING",
    DECISION_SUBMIT_RECEIVED: "DECISION_SUBMIT_RECEIVED",
    DECISION_SUBMIT_FAILED: "DECISION_SUBMIT_FAILED",
    UPDATE_SUBMIT_PENDING: "UPDATE_SUBMIT_PENDING",
    UPDATE_SUBMIT_RECEIVED: "UPDATE_SUBMIT_RECEIVED",
    UPDATE_SUBMIT_FAILED: "UPDATE_SUBMIT_FAILED",
    COMPLETE_SUBMIT_PENDING: "COMPLETE_SUBMIT_PENDING",
    COMPLETE_SUBMIT_RECEIVED: "COMPLETE_SUBMIT_RECEIVED",
    COMPLETE_SUBMIT_FAILED: "COMPLETE_SUBMIT_FAILED",
    CANCEL_SUBMIT_PENDING: "CANCEL_SUBMIT_PENDING",
    CANCEL_SUBMIT_RECEIVED: "CANCEL_SUBMIT_RECEIVED",
    CANCEL_SUBMIT_FAILED: "CANCEL_SUBMIT_FAILED",
    GET_DOCUMENT_PENDING: "GET_DOCUMENT_PENDING",
    GET_DOCUMENT_RECEIVED: "GET_DOCUMENT_RECEIVED",
    GET_DOCUMENT_FAILED: "GET_DOCUMENT_FAILED",
    DOWNLOAD_DOC_PENDING: "DOWNLOAD_DOC_PENDING",
    DOWNLOAD_DOC_RECEIVED: "DOWNLOAD_DOC_RECEIVED",
    DOWNLOAD_DOC_FAILED: "DOWNLOAD_DOC_FAILED",
    DELETE_DOC_PENDING: "DELETE_DOC_PENDING",
    DELETE_DOC_RECEIVED: "DELETE_DOC_RECEIVED",
    DELETE_DOC_FAILED: "DELETE_DOC_FAILED",
    GET_CONTACTS_PENDING: "GET_CONTACTS_PENDING",
    GET_CONTACTS_RECEIVED: "GET_CONTACTS_RECEIVED",
    GET_CONTACTS_FAILED: "GET_CONTACTS_FAILED",
    GET_DR_PENDING: "GET_DR_PENDING",
    GET_DR_RECEIVED: "GET_DR_RECEIVED",
    GET_DR_FAILED: "GET_DR_FAILED",
    GET_INITIAL_PENDING: "GET_INITIAL_PENDING",
    GET_INITIAL_RECEIVED: "GET_INITIAL_RECEIVED",
    GET_INITIAL_FAILED: "GET_INITIAL_FAILED"
    
};
const regionRequestPending= () => ({
    type: actionTypes.GET_REGION_PENDING,
});
const regionRequestReceived = (payload) => ({
    type: actionTypes.GET_REGION_RECEIVED,
    payload,
});
const regionRequestFailed = (payload) => ({
    type: actionTypes.GET_REGION_FAILED,
    payload,
});

const formUploadPending= () => ({
    type: actionTypes.FORM_UPLOAD_PENDING,
});
const formUploadReceived = (payload) => ({
    type: actionTypes.FORM_UPLOAD_RECEIVED,
    payload,
});
const formUploadFailed = (payload) => ({
    type: actionTypes.FORM_UPLOAD_FAILED,
    payload,
})
const drSubmitPending= () => ({
    type: actionTypes.DR_SUBMIT_PENDING,
});
const drSubmitReceived = (payload) => ({
    type: actionTypes.DR_SUBMIT_RECEIVED,
    payload,
});
const drSubmitFailed = (payload) => ({
    type: actionTypes.DR_SUBMIT_FAILED,
    payload,
})
const drReSubmitPending= () => ({
    type: actionTypes.DR_RESUBMIT_PENDING,
});
const drReSubmitReceived = (payload) => ({
    type: actionTypes.DR_RESUBMIT_RECEIVED,
    payload,
});
const drReSubmitFailed = (payload) => ({
    type: actionTypes.DR_RESUBMIT_FAILED,
    payload,
})
const decisionPending= () => ({
    type: actionTypes.DECISION_SUBMIT_PENDING,
});
const decisionReceived = (payload) => ({
    type: actionTypes.DECISION_SUBMIT_RECEIVED,
    payload,
});
const decisionFailed = (payload) => ({
    type: actionTypes.DECISION_SUBMIT_FAILED,
    payload,
})
const updatePending= () => ({
    type: actionTypes.UPDATE_SUBMIT_PENDING,
});
const updateReceived = (payload) => ({
    type: actionTypes.UPDATE_SUBMIT_RECEIVED,
    payload,
});
const updateFailed = (payload) => ({
    type: actionTypes.UPDATE_SUBMIT_FAILED,
    payload,
})

const completePending= () => ({
    type: actionTypes.COMPLETE_SUBMIT_PENDING,
});
const completeReceived = (payload) => ({
    type: actionTypes.COMPLETE_SUBMIT_RECEIVED,
    payload,
});
const completeFailed = (payload) => ({
    type: actionTypes.COMPLETE_SUBMIT_FAILED,
    payload,
})

const cancelPending= () => ({
    type: actionTypes.CANCEL_SUBMIT_PENDING,
});
const cancelReceived = (payload) => ({
    type: actionTypes.CANCEL_SUBMIT_RECEIVED,
    payload,
});
const cancelFailed = (payload) => ({
    type: actionTypes.CANCEL_SUBMIT_FAILED,
    payload,
})
const downloadDocPending= () => ({
    type: actionTypes.DOWNLOAD_DOC_PENDING,
});
const downloadDocReceived = (payload) => ({
    type: actionTypes.DOWNLOAD_DOC_RECEIVED,
    payload,
});
const downloadDocFailed = (payload) => ({
    type: actionTypes.DOWNLOAD_DOC_FAILED,
    payload,
})
const deletedDocPending= () => ({
    type: actionTypes.DELETE_DOC_PENDING,
});
const deletedDocReceived = (payload) => ({
    type: actionTypes.DELETE_DOC_RECEIVED,
    payload,
});
const deletedDocFailed = (payload) => ({
    type: actionTypes.DELETE_DOC_FAILED,
    payload,
})
const requestContactsPending= () => ({
    type: actionTypes.GET_CONTACTS_PENDING,
});
const requestContactsReceived = (payload) => ({
    type: actionTypes.GET_CONTACTS_RECEIVED,
    payload,
});
const requestContactsFailed = (payload) => ({
    type: actionTypes.GET_CONTACTS_FAILED,
    payload,
})
const requestFormDetailPending= () => ({
    type: actionTypes.GET_DR_PENDING,
});
const requestFormDetailReceived = (payload) => ({
    type: actionTypes.GET_DR_RECEIVED,
    payload,
});

const requestFormDetailFailed = (payload) => ({
    type: actionTypes.GET_DR_FAILED,
    payload,
})
const requestFormInitialPending= () => ({
    type: actionTypes.GET_INITIAL_PENDING,
});
const requestFormInitialReceived = (payload) => ({
    type: actionTypes.GET_INITIAL_RECEIVED,
    payload,
});
const requestFormInitialFailed = (payload) => ({
    type: actionTypes.GET_INITIAL_FAILED,
    payload,
})


export const getRegionByIdAction = (payload) => {   
    return async (dispatch) => {
        dispatch(regionRequestPending());
        try {
            const response = await getRegionByIdAPI(payload);
            if (response?.status === 200) {
                dispatch(regionRequestReceived(response?.data?.data));
            }
        } catch (error) {
            dispatch(regionRequestFailed(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const formDocumentAction = (payload) => { 
    return async (dispatch) => {
        dispatch(formUploadPending());
        try {
            const response = await formDocumentAPI(payload);                      
            if (response?.status === 201) {
                dispatch(formUploadReceived(response?.data?.data));
                dispatch(actions.successAlert("Document uploaded successfully"));
            }
        } catch (error) {    
            dispatch(formUploadFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const submitDRAction = (payload) => { 
    return async (dispatch) => {
        dispatch(drSubmitPending());
        try {
            const response = await submitDRAPI(payload);    
            if (response?.status === 201) {
                dispatch(drSubmitReceived(response?.data?.data));
                dispatch(actions.successAlert("Disposal request added successfully"));
            }
        } catch (error) {  
            console.log("error",error)    
            dispatch(drSubmitFailed(error?.response?.data));        
            if (error?.response) {
                if(error?.response?.data?.errorMessage !== undefined)
                    dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
                else
                    dispatch(actions.errorAlert(error?.response?.data?.title));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const reSubmitAction = (payload) => { 
    return async (dispatch) => {
        dispatch(drReSubmitPending());
        try {
            const response = await reSubmitAPI(payload);    
            if (response?.status === 201) {
                dispatch(drReSubmitReceived(response?.data?.data));
                dispatch(actions.successAlert("Disposal request re-submitted successfully"));
            }
        } catch (error) {  
            console.log("error",error)    
            dispatch(drReSubmitFailed(error?.response?.data));        
            if (error?.response) {
                if(error?.response?.data?.errorMessage !== undefined)
                    dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
                else
                    dispatch(actions.errorAlert(error?.response?.data?.title));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const decisionAction = (payload) => { 
    return async (dispatch) => {
        dispatch(decisionPending());
        try {
            const response = await decisionAPI(payload);   
            if (response?.status === 200) {
                dispatch(decisionReceived(response.data));
                dispatch(actions.successAlert("Disposal request updated successfully"));
            }
        } catch (error) {    
            dispatch(decisionFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const updateAction = (payload) => { 
    return async (dispatch) => {
        dispatch(updatePending());
        try {
            const response = await updateAPI(payload);   
            if (response?.status === 201) {
                dispatch(updateReceived(response.data));
                dispatch(actions.successAlert("Disposal request updated successfully"));
            }
        } catch (error) {    
            dispatch(updateFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const completeAction = (payload) => { 
    return async (dispatch) => {
        dispatch(completePending());
        try {
            const response = await completeAPI(payload);                      
            if (response?.status === 200) {
                 dispatch(completeReceived(response.data));
                dispatch(actions.successAlert("Disposal request completed successfully"));
            }
        } catch (error) {    
            dispatch(completeFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const cancelAction = (payload) => { 
    return async (dispatch) => {
        dispatch(cancelPending());
        try {
            const response = await cancelAPI(payload);                      
            if (response?.status === 200) {
                dispatch(cancelReceived(response.data));
                dispatch(actions.successAlert("Disposal request cancelled"));
            }
        } catch (error) {    
            dispatch(cancelFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const downloadDocAction = (payload) => { 
    return async (dispatch) => {
        dispatch(downloadDocPending());
        try {
            const response = await downloadDocAPI(payload);                      
            if (response?.status === 200) {
                dispatch(downloadDocReceived(response?.data?.data));
            }
        } catch (error) {    
            dispatch(downloadDocFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const deleteDocAction = (payload) => { 
    return async (dispatch) => {
        dispatch(deletedDocPending());
        try {
            const response = await deleteDocAPI(payload);                      
            if (response?.status === 200) {
                dispatch(deletedDocReceived(response?.data?.success));
                dispatch(actions.successAlert("Document deleted successfully"));
            }
        } catch (error) {    
            dispatch(deletedDocFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const searchContactAction = (payload) => { 
    return async (dispatch) => {
        dispatch(requestContactsPending());
        try {
            const response = await searchContactAPI(payload);                      
            if (response?.status === 200) {
                dispatch(requestContactsReceived(response?.data?.data));
            }
        } catch (error) {    
            dispatch(requestContactsFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const formDetailAction = (payload) => { 
    return async (dispatch) => {
        dispatch(requestFormDetailPending());
        try {
            const response = await formDetailAPI(payload); 
            if (response?.status === 200) {
                dispatch(requestFormDetailReceived(response?.data?.data));
            }
        } catch (error) {    
            dispatch(requestFormDetailFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const formInitialAction = () => { 
    return async (dispatch) => {
        dispatch(requestFormInitialPending());
        try {
            const response = await formInitialAPI();   
            if (response?.status === 200) {
                dispatch(requestFormInitialReceived(response?.data?.data));
            }
        } catch (error) {    
            dispatch(requestFormInitialFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}