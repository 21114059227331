
// import { actionTypes } from "./actions";
import { getformStepListAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    FORMTSTEP_GET_REQUEST: "FORMTSTEP_GET_REQUEST",
    FORMTSTEP_GET_RECEIVE: "FORMTSTEP_GET_RECEIVE",
    FORMTSTEP_GET_FAIL: "FORMTSTEP_GET_FAIL"
  };

const requestFormStage= () => ({
    type: actionTypes.FORMTSTEP_GET_REQUEST,
});
const receiveFormStage = (payload) => ({
    type: actionTypes.FORMTSTEP_GET_RECEIVE,
    payload,
});
const failureFormStage = (payload) => ({
    type: actionTypes.FORMTSTEP_GET_FAIL,
    payload,
});
export const getFormStepAction = () => {
    return async (dispatch) => {
        dispatch(requestFormStage());
        try {
            const response = await getformStepListAPI();
            if (response?.status === 200) {
                dispatch(receiveFormStage(response?.data?.data));
               // dispatch(actions.successAlert("Coutries fetched successfully"));
            }
        } catch (error) {
            dispatch(failureFormStage(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
