import MainCard from 'components/MainCard';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { Grid, Typography} from '@mui/material';
import Link from '@mui/material/Link';
import {  formDetailAction } from 'containers/BusinessProcess/DisposalRequest/state/actions';
import {  formMrfAction } from 'containers/BusinessProcess/MachineReservationRequest/state/actions';
const ConfirmationPage =({formType, notificationAction, formId, setPageView})=>{
    const dispatch = useDispatch();

    const handlePageRedirection =()=>{
        if(formType ==='DR'){
            dispatch(formDetailAction(formId))
            setPageView('newDisposal')
            dispatch({type:'GET_INITIAL_DATA'})
        }
        if(formType ==='MR'){
            dispatch(formMrfAction(formId))
            setPageView('newMrf')
            dispatch({type:'GET_MR_INITIAL_DATA'})
        }
        if(formType ==='CR'){
            setPageView('search')
        }
    }
    return <MainCard boxShadow={false} border={false}>
            <Grid container spacing={1} style={{textAlign:'center'}}>
                <Grid item xs={12}>
                    <Typography sx={{ fontSize: '16px', fontWeight: '600'}}>
                        {notificationAction === 'update'? 'UPDATE'
                        : notificationAction === 'submit'? 'SUBMIT'
                        :notificationAction === 'complete'? 'COMPLETE'
                        :notificationAction === 'cancel'? 'CANCELLATION'
                        :notificationAction === 'resubmit'? 'RE-SUBMIT'
                        :''} CONFIRMATION
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">The  <Typography sx={{ fontWeight: '600'}}>&nbsp;{formType==='DR'?'Disposal Request':'Machine Reservation Request'} #{formId} &nbsp;</Typography> has been {notificationAction === 'update'? 'updated'
                        : notificationAction === 'submit'? 'submitted'
                        :notificationAction === 'complete'? 'completed'
                        :notificationAction === 'cancel'? 'cancelled'
                        :notificationAction === 'resubmit'? 're-submitted'
                        :''}</Box>
                    <Box>Click <Link onClick={handlePageRedirection}  component="button"
                        variant="body2" style={{fontSize:'16px'}}> here</Link> to view the request</Box>
                </Grid>
            </Grid>
    </MainCard>
}
export default ConfirmationPage