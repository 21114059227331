import {  getAllAreaListAPI, getProcessListAPI, getSubprocessListAPI, getFunctionProductFormatListAPI, getFunctionListAPI, deleteFunctionProductFormatValueAPI, getAllProductFormatTypeAPI, createFunctionProductFormatAPI, updateFunctionProductFormatAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    AREA_PENDING: "AREA_PENDING",
    AREA_RECEIVED: "AREA_RECEIVED",
    AREA_FAILED: "AREA_FAILED",
    PROCESS_PENDING: "PROCESS_PENDING",
    PROCESS_RECEIVED: "PROCESS_RECEIVED",
    PROCESS_FAILED: "PROCESS_FAILED",
    SUBPROCESS_PENDING: "SUBPROCESS_PENDING",
    SUBPROCESS_RECEIVED: "SUBPROCESS_RECEIVED",
    SUBPROCESS_FAILED: "SUBPROCESS_FAILED",
    FPF_PENDING: "FPF_PENDING",
    FPF_RECEIVED: "FPF_RECEIVED",
    FPF_FAILED: "FPF_FAILED",
    FUNCTION_PENDING: "FUNCTION_PENDING",
    FUNCTION_RECEIVED: "FUNCTION_RECEIVED",
    FUNCTION_FAILED: "FUNCTION_FAILED",
    PRODUCT_FORMAT_TYPE_PENDING: "PRODUCT_FORMAT_TYPE_PENDING",
    PRODUCT_FORMAT_TYPE_RECEIVED: "PRODUCT_FORMAT_TYPE_RECEIVED",
    PRODUCT_FORMAT_TYPE_FAILED: "PRODUCT_FORMAT_TYPE_FAILED",
    PRODUCT_FORMAT_FUNCTION_REQUEST: "PRODUCT_FORMAT_FUNCTION_REQUEST",
    PRODUCT_FORMAT_FUNCTION_RECEIVED: "PRODUCT_FORMAT_FUNCTION_RECEIVED",
    PRODUCT_FORMAT_FUNCTION_FAILED: "PRODUCT_FORMAT_FUNCTION_FAILED"
};
const requestAllProductFormatType= () => ({
    type: actionTypes.PRODUCT_FORMAT_TYPE_PENDING,
});
const receiveAllProductFormatType = (payload) => ({
    type: actionTypes.PRODUCT_FORMAT_TYPE_RECEIVED,
    payload,
});
const failureAllProductFormatType = (payload) => ({
    type: actionTypes.PRODUCT_FORMAT_TYPE_FAILED,
    payload,
});

const requestAllAreaList= () => ({
    type: actionTypes.AREA_PENDING,
});
const receiveAllAreaList = (payload) => ({
    type: actionTypes.AREA_RECEIVED,
    payload,
});
const failureAllAreaList = (payload) => ({
    type: actionTypes.AREA_FAILED,
    payload,
});
const requestAllProcess= () => ({
    type: actionTypes.PROCESS_PENDING,
});
const receiveAllProcess = (payload) => ({
    type: actionTypes.PROCESS_RECEIVED,
    payload,
});
const failureAllProcess = (payload) => ({
    type: actionTypes.PROCESS_FAILED,
    payload,
});
const requestAllSubProcess= () => ({
    type: actionTypes.SUBPROCESS_PENDING,
});
const receiveAllSubProcess = (payload) => ({
    type: actionTypes.SUBPROCESS_RECEIVED,
    payload,
});
const failureAllSubProcess = (payload) => ({
    type: actionTypes.SUBPROCESS_FAILED,
    payload,
});
const requestAllFunction= () => ({
    type: actionTypes.FUNCTION_PENDING,
});
const receiveAllFunction = (payload) => ({
    type: actionTypes.FUNCTION_RECEIVED,
    payload,
});
const failureAllFunction = (payload) => ({
    type: actionTypes.FUNCTION_FAILED,
    payload,
});

const requestProductFormatFunction= () => ({
    type: actionTypes.PRODUCT_FORMAT_FUNCTION_REQUEST,
});
const receiveProductFormatFunction = (payload) => ({
    type: actionTypes.PRODUCT_FORMAT_FUNCTION_RECEIVED,
    payload,
});
const failureProductFormatFunction = (payload) => ({
    type: actionTypes.PRODUCT_FORMAT_FUNCTION_FAILED,
    payload,
});

const requestFpf= () => ({
    type: actionTypes.FPF_PENDING,
});
const receiveFpf = (payload) => ({
    type: actionTypes.FPF_RECEIVED,
    payload,
});
const failureFpf = (payload) => ({
    type: actionTypes.FPF_FAILED,
    payload,
});

export const getFunctionProductFormatAction = (id, productFormatArray, productFormatTypeArray) => {
    return async (dispatch) => {
        dispatch(requestFpf());
        try {
            const response = await getFunctionProductFormatListAPI(id);
            if (response?.status === 200) {
                const customSearchResultArray = [];
                response?.data?.data?.forEach(element => {
                    const productFormat = productFormatArray.find(({pkProductFormat})=>pkProductFormat===element.fkProductFormat)
                    const productFormatType = productFormatTypeArray.find(({pkProductFormatType})=>pkProductFormatType===productFormat.fkProductFormatType)
                    element.productFormatName = productFormat?.productFormatName;
                    element.allowMultiSelect = productFormat?.allowMultiSelect;
                    element.productFormatTypeName = productFormatType?.productFormatTypeName;
                    customSearchResultArray.push(element);
                });
                dispatch(receiveFpf(customSearchResultArray));
            }
        } catch (error) {
            dispatch(failureFpf(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getAllActiveAreaAction = () => {
    return async (dispatch) => {
        dispatch(requestAllAreaList());
        try {
            const response = await getAllAreaListAPI();
            if (response?.status === 200) {
                dispatch(receiveAllAreaList(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureAllAreaList(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getAllActiveProcessAction = () => {
    return async (dispatch) => {
        dispatch(requestAllProcess());
        try {
            const response = await getProcessListAPI();
            if (response?.status === 200) {
                dispatch(receiveAllProcess(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureAllProcess(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const getAllActiveSubProcessAction = () => {
    return async (dispatch) => {
        dispatch(requestAllSubProcess());
        try {
            const response = await getSubprocessListAPI();
            if (response?.status === 200) {
                dispatch(receiveAllSubProcess(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureAllSubProcess(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const getAllActiveFunctionAction = () => {
    return async (dispatch) => {
        dispatch(requestAllFunction());
        try {
            const response = await getFunctionListAPI();
            if (response?.status === 200) {
                dispatch(receiveAllFunction(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureAllFunction(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const deleteFpfAPIAction = (payload, id, productFormatArray, productFormatTypeArray) => {
    return async (dispatch) => {
        dispatch(requestFpf());
        try {
            const response = await deleteFunctionProductFormatValueAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Function Product format deleted successfully"));
            }
            dispatch(getFunctionProductFormatAction(id, productFormatArray, productFormatTypeArray));
        } catch (error) {
            dispatch(failureFpf(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));

            }
        }
    };
}
export const getAllProductFormatTypeAction = () => {
    return async (dispatch) => {
        dispatch(requestAllProductFormatType());
        try {
            const response = await getAllProductFormatTypeAPI();
            if (response?.status === 200) {
                dispatch(receiveAllProductFormatType(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureAllProductFormatType(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const createFunctionProductFormatAction = (payload, productFormatArray, productFormatTypeArray) => {
    return async (dispatch) => {
        dispatch(requestProductFormatFunction());
        try {
            const response = await createFunctionProductFormatAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Function Product Format added successfully"));
            }
            dispatch(getFunctionProductFormatAction(payload.fkFunction, productFormatArray, productFormatTypeArray));
        } catch (error) {
            dispatch(failureProductFormatFunction(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const updateFunctionProductFormatAction = (id, payload, productFormatArray, productFormatTypeArray) => {
    return async (dispatch) => {
        dispatch(requestProductFormatFunction());
        try {
            const response = await updateFunctionProductFormatAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Function Product Format updated successfully"));
            }
            dispatch(getFunctionProductFormatAction(payload.fkFunction, productFormatArray, productFormatTypeArray));
        } catch (error) {
            dispatch(failureProductFormatFunction(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}