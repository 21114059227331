import { actions } from 'store/Reducer/actions';
import { getAdminRolesAPI } from './endpoints';
export const actionTypes = {
    ADMIN_ROLES_PENDING: "ADMIN_ROLES_PENDING",
    ADMIN_ROLES_RECEIVED: "ADMIN_ROLES_RECEIVED",
    ADMIN_ROLES_FAILED: "ADMIN_ROLES_FAILED",
};
const requestAdminRoles= () => ({
    type: actionTypes.ADMIN_ROLES_PENDING,
});
const receiveAdminRoles = (payload) => ({
    type: actionTypes.ADMIN_ROLES_RECEIVED,
    payload,
});
const failureAdminRoles = (payload) => ({
    type: actionTypes.ADMIN_ROLES_FAILED,
    payload,
});
export const getAdminRolesAction = () => {   
    return async (dispatch) => {
        dispatch(requestAdminRoles());
        try {
            const response = await getAdminRolesAPI();
            if (response?.status === 200) {
                dispatch(receiveAdminRoles(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureAdminRoles(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};