import { API, equipmentAPI} from "api";

export const getInitiatorAPI = (payload) => {
    return equipmentAPI.get(`/api/forminitiator/${payload}`)
        .then((res) => res);
}
export const searchContactAPI = (payload) => {
    return API.get(`api/security/search/${payload}`).then((res) => res);
};
export const saveInitiatorAPI = (payload) => {
    return equipmentAPI.put(`/api/forminitiator/${payload.PkForm}/${payload.NewInitiatorId}`)
        .then((res) => res);
}