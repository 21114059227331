export const actionTypes = {
  SHOW_TOAST: "SHOW_TOAST",
  HIDE_TOAST: "HIDE_TOAST",
  SET_ACTIVE_ITEM: "SET_ACTIVE_ITEM",
  ACTIVE_COMPONENT: "ACTIVE_COMPONENT",
  OPEN_DRAWER: "OPEN_DRAWER",
  OPEN_COMPOENT_DRAWER: "OPEN_COMPOENT_DRAWER",
  AREA_PENDING: "AREA_PENDING",
  AREA_RECEIVED: "AREA_RECEIVED",
  AREA_FAILED: "AREA_FAILED",
  PRODUCT_FORMAT_TYPE_PENDING: "PRODUCT_FORMAT_TYPE_PENDING",
  PRODUCT_FORMAT_TYPE_RECEIVED: "PRODUCT_FORMAT_TYPE_RECEIVED",
  PRODUCT_FORMAT_TYPE_FAILED: "PRODUCT_FORMAT_TYPE_FAILED",
  ACTIVE_COUNTRIES_PENDING: "ACTIVE_COUNTRIES_PENDING",
  ACTIVE_COUNTRIES_RECEIVED: "ACTIVE_COUNTRIES_RECEIVED",
  ACTIVE_COUNTRIES_FAILED: "ACTIVE_COUNTRIES_FAILED",
  TOKEN_PENDING: "TOKEN_PENDING",
  TOKEN_RECEIVED: "TOKEN_RECEIVED",
  TOKEN_FAILED: "TOKEN_FAILED",
  ACTIVE_STATUS_PENDING: "ACTIVE_STATUS_PENDING",
  ACTIVE_STATUS_RECEIVED: "ACTIVE_STATUS_RECEIVED",
  ACTIVE_STATUS_FAILED: "ACTIVE_STATUS_FAILED",
  ACTIVE_FUNCTION_PENDING: "ACTIVE_FUNCTION_PENDING",
  ACTIVE_FUNCTION_RECEIVED: "ACTIVE_FUNCTION_RECEIVED",
  ACTIVE_FUNCTION_FAILED: "ACTIVE_FUNCTION_FAILED",
  FUNCTIONAL_LOCATION_PENDING: "FUNCTIONAL_LOCATION_PENDING",
  FUNCTIONAL_LOCATION_RECEIVED: "FUNCTIONAL_LOCATION_RECEIVED",
  FUNCTIONAL_LOCATION_FAILED: "FUNCTIONAL_LOCATION_FAILED",
};

export const actions = {
  hideToast: () => ({
    type: actionTypes.HIDE_TOAST,
    payload: { open: false, severity: "info", message: "" },
  }),
  successAlert: (message) => ({
    type: actionTypes.SHOW_TOAST,
    payload: { open: true, severity: "success", message },
    
  }),
  errorAlert: (message) => ({
    type: actionTypes.SHOW_TOAST,
    payload: { open: true, severity: "error", message },
  }),
  activeItem: (payload) => ({
    type: actionTypes.SET_ACTIVE_ITEM,
    payload
  }),
  activeComponent: (payload) => ({
    type: actionTypes.ACTIVE_COMPONENT,
    payload
  }),
  openDrawer: (payload) => ({
    type: actionTypes.OPEN_DRAWER,
    payload
  }),
  openComponentDrawer: (payload) => ({
    type: actionTypes.OPEN_COMPOENT_DRAWER,
    payload
  }),
  requestArea: () => ({
    type: actionTypes.AREA_PENDING,
  }),
 receiveArea: (payload) => ({
    type: actionTypes.AREA_RECEIVED,
    payload,
  }),
  failureArea: (payload) => ({
    type: actionTypes.AREA_FAILED,
    payload,
  }),
  requestProductFormatType: () => ({
    type: actionTypes.PRODUCT_FORMAT_TYPE_PENDING,
  }),
 receiveProductFormatType: (payload) => ({
    type: actionTypes.PRODUCT_FORMAT_TYPE_RECEIVED,
    payload,
  }),
  failureProductFormatType: (payload) => ({
    type: actionTypes.PRODUCT_FORMAT_TYPE_FAILED,
    payload,
  }),

  requestActiveCountries: () => ({
    type: actionTypes.ACTIVE_COUNTRIES_PENDING,
  }),
 receiveActiveCountries: (payload) => ({
    type: actionTypes.ACTIVE_COUNTRIES_RECEIVED,
    payload,
  }),
  failureActiveCountries: (payload) => ({
    type: actionTypes.ACTIVE_COUNTRIES_FAILED,
    payload,
  }),
  requestToken: () => ({
    type: actionTypes.TOKEN_PENDING,
  }),
  receiveToken: (payload) => ({
    type: actionTypes.TOKEN_RECEIVED,
    payload,
  }),
  failureToken: (payload) => ({
    type: actionTypes.TOKEN_FAILED,
    payload,
  }),
  
  requestActiveStatus: () => ({
    type: actionTypes.ACTIVE_STATUS_PENDING,
  }),
 receiveActiveStatus: (payload) => ({
    type: actionTypes.ACTIVE_STATUS_RECEIVED,
    payload,
  }),
  failureActiveStatus: (payload) => ({
    type: actionTypes.ACTIVE_STATUS_FAILED,
    payload,
  }),
  requestActiveFunction: () => ({
    type: actionTypes.ACTIVE_FUNCTION_PENDING,
  }),
 receiveActiveFunction: (payload) => ({
    type: actionTypes.ACTIVE_FUNCTION_RECEIVED,
    payload,
  }),
  failureActiveFunction: (payload) => ({
    type: actionTypes.ACTIVE_FUNCTION_FAILED,
    payload,
  }),
  requestFunctionalLocation: () => ({
    type: actionTypes.FUNCTIONAL_LOCATION_PENDING,
  }),
 receiveFunctionalLocation: (payload) => ({
    type: actionTypes.FUNCTIONAL_LOCATION_RECEIVED,
    payload,
  }),
  failureFunctionalLocation: (payload) => ({
    type: actionTypes.FUNCTIONAL_LOCATION_FAILED,
    payload,
  })
};

  export const getTokenAction = (payload) => {
    return async (dispatch) => {
        dispatch(actions.requestToken());
        try {
              dispatch(actions.receiveToken(payload));
        } catch (error) {
            dispatch(actions.failureToken(error.message));
            if (error) {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
  };
