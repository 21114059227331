import { actionTypes } from "./actions";

export const initialState = {
    stgmainScreenList:[],
    isstgMainScreenLoading:true,
    stgmachineTypeDictUIColMappingsMS: []  ,
};


const affiliateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AFFILIATE_GET_REQUEST:
      return {
        ...state,
        affiliateListLoading: true,
      };
    case actionTypes.AFFILIATE_GET_RECEIVE:    
        return {
          ...state,
          affiliateList: action.payload.map((dt, index) => ({ ...dt, id: index })),
          affiliateListLoading: false,
        };
      case actionTypes.AFFILIATE_GET_FAIL:
         return {
      ...state,
      affiliateListLoading: false,
    };
    case actionTypes.RQT_HISTORY_GET_REQUEST:
      return {
        ...state,
        isstgMainScreenLoading: true,
      };
    case actionTypes.RQT_HISTORY_STOP_LOADING:
      return {
        ...state,
        isMainScreenLoading: false,
      };
    //   karishma
    case actionTypes.RQT_HISTORY_GET_RECEIVE:
      let stgmachineData = action.payload.stgmachineData;
      let stgmachineDataMapper = action.payload.stgmachineTypeDictUIColMappings;
      return {
        ...state,
        stgmainScreenList: stgmachineData.map((dt, index) => ({ ...dt, id: index })),
        stgmachineTypeDictUIColMappingsMS: stgmachineDataMapper,
        isstgMainScreenLoading: false,
      };
    case actionTypes.RQT_HISTORY_GET_FAIL:
      return {
        ...state,
        isstgMainScreenLoading: true,
        isMainScreenFail: false,
      };
    case  "CLEAR_MAKER_DATA":
        return {
          ...state,
          mainScreenList: [],
          machineTypeDictUIColMappingsMS:[]
        };
        case actionTypes.GET_MACHINE_DETAIL_BY_ID_REQUEST:
          return {
            ...state,
            isstgMainScreenLoading: true,
          };
        case actionTypes.GET_MACHINE_DETAIL_BY_ID_RECEIVE:
          let stgmachineDetail = action.payload.StgMachineDtlHdrByEqmtResponseModelUI

            return {
              ...state,
              stgmainScreenList: stgmachineDetail.stgmachineData.map((dt, index) => ({ ...dt, id: index })),
              stgmachineTypeDictUIColMappingsMS: stgmachineDetail.machineTypeDictUIColMappings,
              isstgMainScreenLoading: false,
            };
          case actionTypes.GET_MACHINE_DETAIL_BY_ID_FAIL:
            return {
              ...state,
              machineDetailByIdLoading: false,
            };
    default:
      return state;
  }
};

export default affiliateReducer;
