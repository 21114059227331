import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { 
    Box,
    Toolbar, 
    useMediaQuery 
} from '@mui/material';

// project import
import MainDrawer from './Drawer/MainDrawer';
import Header from './Header';
import navigation from 'routes/menuItems';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import Toaster from 'components/Toast/Toast';
// types
import { actions } from '../../store/Reducer/actions';
import { getNotificationByIdAction } from './Header/HeaderContent/state/actions';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
    const dispatch = useDispatch();
    const { decodedToken } = useSelector((state) => state.token);

    const { drawerOpen } = useSelector((state) => state.common);

    // drawer toggler
    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(actions.openDrawer({ drawerOpen: !open }));
    };

    useEffect(() => {
        if(decodedToken.email !== undefined && decodedToken.email!==''){
            setTimeout(() => {
            dispatch(getNotificationByIdAction(decodedToken.email))      
            }, 2000);
        }
    }, [decodedToken.email]);
    

    useEffect(() => {
        setOpen(!matchDownLG);
        dispatch(actions.openDrawer({ drawerOpen: !matchDownLG }));

    }, [matchDownLG]);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
    }, [drawerOpen]);

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Header open={open} handleDrawerToggle={handleDrawerToggle} />
            <MainDrawer open={open} setOpen={setOpen} handleDrawerToggle={handleDrawerToggle} />
            <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                <Toolbar />
                <Toaster />
                <Outlet />
            </Box>
        </Box>
    );
};

export default MainLayout;
