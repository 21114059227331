import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Grid, Button } from '@mui/material';
import { Divider } from '@mui/material';
import StyledDataGrid from 'components/DataGrid/index';
import { useSelector, useDispatch } from 'react-redux';
import { getEquipmentByIdAction } from './state/actions';
import manualExport from '../GenericExcelExport/index';

export default function EquipmentHistory({ samid }) {
    const dispatch = useDispatch();
    const { equipmentHistory } = useSelector((state) => state.detailsEquipment);
    useEffect(() => {
        dispatch(getEquipmentByIdAction(samid));
    }, []);

    const columns = [
        {
            field: 'actionDate',
            headerName: 'Action date',
            width: 150,
            headerClassName: "dgHeader",
            valueFormatter: (params) => {
                const valueFormatted = new Date(params.value + 'Z').toLocaleString();
                return `${valueFormatted}`;
            }
        },
        { field: 'actionType', headerName: `Action type`, width: 200, headerClassName: "dgHeader", },
        { field: 'action', headerName: `Action`, width: 500, headerClassName: "dgHeader", },
        {
            field: 'actionBy',
            headerName: `Action by`,
            width: 150,
            filterable: false,
            headerClassName: "dgHeader"
        },
    ];

    const column = [
        {
            title: 'Action date',
            dataIndex: "actionDate",
            key: "actionDate"
        },
        {
            title: ' Action type',
            dataIndex: "actionType",
            key: "actionType"
        },
        {
            title: 'Action',
            dataIndex: "action",
            key: "action"
        },
        {
            title: 'Action by',
            dataIndex: 'actionBy',
            key: 'actionBy',
        }
    ];
    const handleClick = (data, fileName, column) => {
        manualExport(data, fileName, column);
    };

    const rows = [...equipmentHistory]
    
    const newRows = rows.map((row) => {
        if (row) {
            const updatedItem = {
                ...row,
                actionDate: new Date(row.actionDate + 'Z').toLocaleString()
            };
            return updatedItem;
        };
        return row;
    }
    );
    return <>
        <Box sx={{ p: 3 }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Grid xs={12}>
                        <Button variant="outlined" onClick={() => handleClick(newRows, 'Equipment_History.xlsx', column)}>
                            Export
                        </Button>
                    </Grid>
                    <Grid container sx={{ mt: 3 }}>
                        <div style={{
                            height: '500px',
                            width: '100%'
                        }}>
                            <StyledDataGrid
                                columns={columns}
                                rows={rows}
                                csvOptions={{
                                    fileName: 'Equipment_History',
                                    fields: ['actionDate', 'actionType', 'action', 'actionBy']

                                }}
                                hideCSV={true}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
        </Box>
    </>

}