export const filterNestedJson = (data = [], columns = [], query) => {
  console.log('columns', columns);
  console.log('query', query);
  console.log('data', data);
  let result = [];
  for (let elem of data) {
    if ("children" in elem) {
      const output = filterNestedJson(elem?.children || [], columns, query);
      if (output.length > 0) {
        elem["children"] = output;
        result.push(elem);
      }
    }

    for (let [key, value] of Object.entries(elem)) {
      if (columns.includes(key)) {
        if (typeof (value) === 'string') {
          const colValue = value?.toLowerCase();
          if (colValue.includes(query?.toLowerCase())) {
            result.push(elem);
            break;
          }
        } else {
          const colValue = value;
          if (('' + colValue).includes(query)) {
            result.push(elem);
            break;
          }
        }
      }
    }
  }
  return result;
};

export const getTimeAgo = (time) => {
  switch (typeof time) {
    case 'number':
      break;
    case 'string':
      time = +new Date(time);
      break;
    case 'object':
      if (time.constructor === Date) time = time.getTime();
      break;
    default:
      time = +new Date();
  }
  var dateObj = new Date(time),
    curHour = dateObj.getHours() > 12 ? dateObj.getHours() - 12 : (dateObj.getHours() < 10 ? "0" + dateObj.getHours() : dateObj.getHours()),
    curMinute = dateObj.getMinutes() < 10 ? "0" + dateObj.getMinutes() : dateObj.getMinutes(),
    curSeconds = dateObj.getSeconds() < 10 ? "0" + dateObj.getSeconds() : dateObj.getSeconds(),
    curMeridiem = dateObj.getHours() > 12 ? "PM" : "AM";
  var time_formats = [
    [60, 'seconds', 1], // 60
    [120, '1 minute ago', '1 minute from now'], // 60*2
    [3600, 'minutes', 60], // 60*60, 60
    [7200, '1 hour ago', '1 hour from now'], // 60*60*2
    [86400, 'hours', 3600], // 60*60*24, 60*60
    [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
    [604800, 'days', 86400], // 60*60*24*7, 60*60*24
    [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
    [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
    [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
    [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
    [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  let dateTime = curHour + ":" + curMinute + "." + curMeridiem;
  var seconds = (+new Date() - time) / 1000,
    //token = 'ago',
    token = dateTime,
    list_choice = 1;
  if (seconds == 0) {
    //return 'Just now'
    return dateTime
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    //token = 'from now';
    token = dateTime;
    list_choice = 2;
  }
  var i = 0,
    format;
  while (format = time_formats[i++])
    if (seconds < format[0]) {
      if (typeof format[2] == 'string')
        return format[list_choice] + '$' + token;
      else
        return Math.floor(seconds / format[2]) + ' ' + format[1] + '$' + token;
    }
  return time;
}