import axios from 'axios';
export const bulkUploadAPI = (payload) => {
    let  apiUrl= `${process.env.REACT_APP_EQUIPMENT_BASE_URL}/api/bulkupload/uploadinstruments`
      return axios.post(apiUrl,payload,{
            headers: {"Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem('idToken')}`
          },
            
        }).then(resp=>resp)
}
