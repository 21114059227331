import { actionTypes } from "./actions";

export const initialState = {  
 companyCoordinatorList: [],
 allCompanyCoordinatorList: []
};

export const companyCoordinatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COMPANY_COORDINATOR_REQUEST:
      return {
        ...state,
        isCompanyCoordinatorLoading: true,
      };
    case actionTypes.COMPANY_COORDINATOR_RECEIVE:
      return {
        ...state,
        companyCoordinatorList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isCompanyCoordinatorLoading: false,
      };
    case actionTypes.COMPANY_COORDINATOR_FAIL:
      return {
        ...state,        
        isCompanyCoordinatorLoading: false
      };
    default:
      return state;
  }
};
export const allCompanyCoordinatorReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.ALL_COMPANY_COORDINATOR_REQUEST:
        return {
          ...state,
          isCompanyCoordinatorLoading: true,
        };
      case actionTypes.ALL_COMPANY_COORDINATOR_RECEIVE:
        return {
          ...state,
          allCompanyCoordinatorList: action.payload.map((dt, index) => ({ ...dt, id: index })),
          isCompanyCoordinatorLoading: false,
        };
      case actionTypes.ALL_COMPANY_COORDINATOR_FAIL:
        return {
          ...state,        
          isCompanyCoordinatorLoading: false
        };
      default:
        return state;
    }
  };

