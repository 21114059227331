import React, { useEffect, useState } from 'react';
import {
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const [pageSize, setPageSize] = useState(500)
    const pageSizeOptions = [20, 50, 100, 500];

    useEffect(() => {
      apiRef.current.setPageSize(pageSize);
    // eslint-disable-next-line
    }, [pageSize])

    const handlePageSizeChange = (event) => {
      setPageSize(event.target.value);
    }

    return (
      <Grid container spacing={5} justifyContent='right' alignItems='center'>
        <Grid item>
          <TextField select variant="standard" value={pageSize} onChange={handlePageSizeChange}>
            {pageSizeOptions.map((elem) => 
              <MenuItem key={elem} value={elem}>
                <Typography>{elem} rows</Typography>
              </MenuItem>
            )}
          </TextField>
        </Grid>
        <Grid item>
          <Pagination
            color="primary"
            showFirstButton
            showLastButton
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
          />
        </Grid>
      </Grid>
    );
}

export default CustomPagination;