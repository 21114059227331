import { actionTypes } from "./actions";

export const initialState = {
  isdssysownerLoading: false,
  isdseqmtownerLoading: false,
  isdsalleqmtownerLoading: false,
  isdsuserLoading: false,
  dssysownerroleList: [],
  dsallequipmentownerroleList: [],
  dsequipmentownerroleList: [],
  dssusersroleList: [],
};


const DSAdminRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DSSYSOWN_ROLES_PENDING:
        return {
            ...state,
            isdssysownerLoading : true
        };
    case actionTypes.DSSYSOWN_ROLES_RECEIVED:
    return {
        ...state,
        dssysownerroleList:action.payload.map((dt, index)=>({...dt, id:index})),
        isdssysownerLoading : false
    };
    case actionTypes.DSSYSOWN_ROLES_FAILED:
        return {
            ...state,
            isdssysownerLoading : false
        };
    case actionTypes.DSEQOWN_ALLROLES_PENDING:
          return {
              ...state,
              isdsalleqmtownerLoading : true
          };
    case actionTypes.DSEQOWN_ALLROLES_RECEIVED:
      return {
          ...state,
          dsallequipmentownerroleList:action.payload.map((dt, index)=>({...dt, id:index})),
          isdsalleqmtownerLoading : false
      };
      case actionTypes.DSEQOWN_ALLROLES_FAILED:
          return {
              ...state,
              isdsalleqmtownerLoading : false
          };        
          case actionTypes.DSEQOWN_DATA_PENDING:
            return {
                ...state,
                isdseqmtownerLoading : true
            };
      case actionTypes.DSEQOWN_DATA_RECEIVED:
        return {
            ...state,
            dsequipmentownerroleList:action.payload.map((dt, index)=>({...dt, id:index})),
            isdseqmtownerLoading : false
        };
        case actionTypes.DSEQOWN_DATA_FAILED:
            return {
                ...state,
                isdseqmtownerLoading : false
            };        
  
      case actionTypes.DSEQUSERS_ROLES_PENDING:
            return {
                ...state,
                isdsalleqmtownerLoading : true
            };
      case actionTypes.DSEQUSERS_ROLES_RECEIVED:
        return {
            ...state,
            dssusersroleList:action.payload.map((dt, index)=>({...dt, id:index})),
            isdsalleqmtownerLoading : false
        };
        case actionTypes.DSEQUSERS_ROLES_FAILED:
            return {
                ...state,
                isdsalleqmtownerLoading : false
            };           
    default:
      return state;
  }
};

export default DSAdminRoleReducer;
