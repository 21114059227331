
import { getAllActiveStatusAPI, getsearchEquipmentAPI, getAllDocIdAPI,
    getAllActiveFunctionAPI, getAllFunctionalLoacationAPI,getEquipmentByIdAPI,
    uploadDocAPI,deleteDocAPI, downloadFileAPI, updateDocumentAPI, getProductFormatAssignmentFromAPI, 
    getProductFormatAssignmentToAPI, getAllProductFormatTypesAPI, updateAPI, updateKitAPI, getEquipmentALLExportAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    SEACRH_EQUIPMENT_PENDING: "SEACRH_EQUIPMENT_PENDING",
    SEACRH_EQUIPMENT_RECEIVED: "SEACRH_EQUIPMENT_RECEIVED",
    SEACRH_EQUIPMENT_FAILED: "SEACRH_EQUIPMENT_FAILED",
    REQUEST_EQUIPMENT_PENDING: "REQUEST_EQUIPMENT_PENDING",
    REQUEST_EQUIPMENT_RECEIVED: "REQUEST_EQUIPMENT_RECEIVED",
    REQUEST_EQUIPMENT_FAILED: "REQUEST_EQUIPMENT_FAILED",
    EQUIPMENT_REQUEST_PENDING: "EQUIPMENT_REQUEST_PENDING",
    EQUIPMENT_REQUEST_RECEIVED: "EQUIPMENT_REQUEST_RECEIVED",
    EQUIPMENT_REQUEST_FAILED: "EQUIPMENT_REQUEST_FAILED",
    EQUIPMENT_DOCUMENTSID_PENDING: "EQUIPMENT_DOCUMENTSID_PENDING",
    EQUIPMENT_DOCUMENTSID_RECEIVED: "EQUIPMENT_DOCUMENTSID_RECEIVED",
    EQUIPMENT_DOCUMENTSID_FAILED: "EQUIPMENT_DOCUMENTSID_FAILED",
    DOC_UPLOAD_PENDING: "DOC_UPLOAD_PENDING",
    DOC_UPLOAD_RECEIVED: "DOC_UPLOAD_RECEIVED",
    DOC_UPLOAD_FAILED: "DOC_UPLOAD_FAILED",
    DOC_DELETE_PENDING: "DOC_DELETE_PENDING",
    DOC_DELETE_RECEIVED: "DOC_DELETE_RECEIVED",
    DOC_DELETE_FAILED: "DOC_DELETE_FAILED",
    DOWNLOAD_FILE_PENDING: "DOWNLOAD_FILE_PENDING",
    DOWNLOAD_FILE_RECEIVED: "DOWNLOAD_FILE_RECEIVED",
    DOWNLOAD_FILE_FAILED: "DOWNLOAD_FILE_FAILED",
    DOC_EDIT_PENDING: "DOC_EDIT_PENDING",
    DOC_EDIT_RECEIVED: "DOC_EDIT_RECEIVED",
    DOC_EDIT_FAILED: "DOC_EDIT_FAILED",
    GET_PF_FROM_PENDING: "GET_PF_FROM_PENDING",
    GET_PF_FROM_RECEIVED: "GET_PF_FROM_RECEIVED",
    GET_PF_FROM_FAILED: "GET_PF_FROM_FAILED",
    GET_PF_TO_PENDING: "GET_PF_TO_PENDING",
    GET_PF_TO_RECEIVED: "GET_PF_TO_RECEIVED",
    GET_PF_TO_FAILED: "GET_PF_TO_FAILED",
    GET_FUNCTION_LOCATION_CHILD_PENDING: "GET_FUNCTION_LOCATION_CHILD_PENDING",
    GET_FUNCTION_LOCATION_CHILD_RECEIVED: "GET_FUNCTION_LOCATION_CHILD_RECEIVED",
    GET_FUNCTION_LOCATION_CHILD_FAILED: "GET_FUNCTION_LOCATION_CHILD_FAILED",
    UPDATE_PF_PENDING: "UPDATE_PF_PENDING",
    UPDATE_PF_RECEIVED: "UPDATE_PF_RECEIVED",
    UPDATE_PF_FAILED: "UPDATE_PF_FAILED",
    UPDATE_PF_KIT_PENDING: "UPDATE_PF_KIT_PENDING",
    UPDATE_PF_KIT_RECEIVED: "UPDATE_PF_KIT_RECEIVED",
    UPDATE_PF_KIT_FAILED: "UPDATE_PF_KIT_FAILED",
    EXPORT_ALL_LOAD: "EXPORT_ALL_LOAD",
    EXPORT_ALL_RECEIVE: "EXPORT_ALL_RECEIVE",
    EXPORT_ALL_FAIL: "EXPORT_ALL_FAIL"
  };

  const requestExportAll= () => ({
    type: actionTypes.EXPORT_ALL_LOAD,
});
const receiveExportAll = (payload) => ({
    type: actionTypes.EXPORT_ALL_RECEIVE,
    payload,
});
const failureExportAll = (payload) => ({
    type: actionTypes.EXPORT_ALL_FAIL,
    payload,
});
const requestSearchEquipment= () => ({
    type: actionTypes.SEACRH_EQUIPMENT_PENDING,
});
const receiveSearchEquipment = (payload) => ({
    type: actionTypes.SEACRH_EQUIPMENT_RECEIVED,
    payload,
});
const failureSearchEquipment = (payload) => ({
    type: actionTypes.SEACRH_EQUIPMENT_FAILED,
    payload,
});
const requestEquipment= () => ({
    type: actionTypes.REQUEST_EQUIPMENT_PENDING,
});
const receiveEquipment = (payload) => ({
    type: actionTypes.REQUEST_EQUIPMENT_RECEIVED,
    payload,
});
const failureEquipment = (payload) => ({
    type: actionTypes.REQUEST_EQUIPMENT_FAILED,
    payload,
});
const equipmentDocIdPending= () => ({
    type: actionTypes.EQUIPMENT_DOCUMENTSID_PENDING,
})
const equipmentDocIdReceived = (payload) => ({
    type: actionTypes.EQUIPMENT_DOCUMENTSID_RECEIVED,
    payload,
});
const equipmentDocIdFailed = (payload) => ({
    type: actionTypes.EQUIPMENT_DOCUMENTSID_FAILED,
    payload,
});
const equipmentRequestPending= () => ({
    type: actionTypes.EQUIPMENT_REQUEST_PENDING,
});
const equipmentRequestReceived = (payload) => ({
    type: actionTypes.EQUIPMENT_REQUEST_RECEIVED,
    payload,
});
const equipmentRequestFailed = (payload) => ({
    type: actionTypes.SEACRH_EQUIPMENT_FAILED,
    payload,
});
const docUploadPending= () => ({
    type: actionTypes.DOC_UPLOAD_PENDING,
});
const docUploadReceived = (payload) => ({
    type: actionTypes.DOC_UPLOAD_RECEIVED,
    payload,
});
const docUploadFailed = (payload) => ({
    type: actionTypes.DOC_UPLOAD_FAILED,
    payload,
})
const docDeletePending= () => ({
    type: actionTypes.DOC_DELETE_PENDING,
});
const docDeleteReceived = (payload) => ({
    type: actionTypes.DOC_DELETE_RECEIVED,
    payload,
});
const docDeleteFailed = (payload) => ({
    type: actionTypes.DOC_DELETE_FAILED,
    payload,
})
const downloadFilePending= () => ({
    type: actionTypes.DOWNLOAD_FILE_PENDING,
});
const downloadFileReceived = (payload) => ({
    type: actionTypes.DOWNLOAD_FILE_RECEIVED,
    payload,
});
const downloadFileFailed = (payload) => ({
    type: actionTypes.DOWNLOAD_FILE_FAILED,
    payload,
})
const docEditPending= () => ({
    type: actionTypes.DOC_EDIT_PENDING,
});
const docEditReceived = (payload) => ({
    type: actionTypes.DOC_EDIT_RECEIVED,
    payload,
});
const docEditFailed = (payload) => ({
    type: actionTypes.DOC_EDIT_FAILED,
    payload,
})
const pfAssignmentFromPending= () => ({
    type: actionTypes.GET_PF_FROM_PENDING,
});
const pfAssignmentFromReceived = (payload) => ({
    type: actionTypes.GET_PF_FROM_RECEIVED,
    payload,
});
const pfAssignmentFromFailed = (payload) => ({
    type: actionTypes.GET_PF_FROM_FAILED,
    payload,
});
const pfAssignmentToPending= () => ({
    type: actionTypes.GET_PF_TO_PENDING,
});
const pfAssignmentToReceived = (payload) => ({
    type: actionTypes.GET_PF_TO_RECEIVED,
    payload,
});
const pfAssignmentToFailed = (payload) => ({
    type: actionTypes.GET_PF_TO_FAILED,
    payload,
});
const functionLocationChildRequestPending= () => ({
    type: actionTypes.GET_FUNCTION_LOCATION_CHILD_PENDING,
});
const functionLocationChildRequestReceived = (payload) => ({
    type: actionTypes.GET_FUNCTION_LOCATION_CHILD_RECEIVED,
    payload,
});
const functionLocationChildRequestFailed = (payload) => ({
    type: actionTypes.GET_FUNCTION_LOCATION_CHILD_FAILED,
    payload,
});


const updatePending= () => ({
    type: actionTypes.UPDATE_PF_PENDING,
});
const updateReceived = (payload) => ({
    type: actionTypes.UPDATE_PF_RECEIVED,
    payload,
});
const updateFailed = (payload) => ({
    type: actionTypes.UPDATE_PF_FAILED,
    payload,
})
const updateKitPending= () => ({
    type: actionTypes.UPDATE_PF_KIT_PENDING,
});
const updateKitReceived = (payload) => ({
    type: actionTypes.UPDATE_PF_KIT_RECEIVED,
    payload,
});
const updateKitFailed = (payload) => ({
    type: actionTypes.UPDATE_PF_KIT_FAILED,
    payload,
})

export const getAllActiveStatusAction = () => {
    return async (dispatch) => {
        dispatch(actions.requestActiveStatus());
        try {
            const response = await getAllActiveStatusAPI();
            if (response?.status === 200) {
                dispatch(actions.receiveActiveStatus(response?.data?.data));
            }
        } catch (error) {
            dispatch(actions.failureActiveStatus(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getAllActiveFunctionAction = () => {
    return async (dispatch) => {
        dispatch(actions.requestActiveFunction());
        try {
            const response = await getAllActiveFunctionAPI();
            // console.log('right', response);
            if (response?.status === 200) {
                dispatch(actions.receiveActiveFunction(response?.data?.data));
            }
        } catch (error) {
            dispatch(actions.failureActiveFunction(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getAllFunctionalLocationAction = () => {
    return async (dispatch) => {
        dispatch(actions.requestFunctionalLocation());
        try {
            const response = await getAllFunctionalLoacationAPI();
            // console.log('right', response);
            if (response?.status === 200) {
                dispatch(actions.receiveFunctionalLocation(response?.data?.data));
            }
        } catch (error) {
            dispatch(actions.failureFunctionalLocation(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getAllSearchEquipmentAction = (payload, a = 1,b = 50 ) => {   
    return async (dispatch) => {
        dispatch(requestSearchEquipment());
        try {
            const response = await getsearchEquipmentAPI(payload, a, b);
            if (response?.status === 200) {
                dispatch(receiveSearchEquipment(response?.data));
            }
        } catch (error) {
            dispatch(failureSearchEquipment(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const getSearchEquipmentAction = (payload) => {   
    return async (dispatch) => {
        dispatch(requestEquipment());
        try {
            const response = await getsearchEquipmentAPI(payload, 1, 50);
            if (response?.status === 200) {
                dispatch(receiveEquipment(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureEquipment(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const getEquipmentByIdAction = (payload) => { 
    return async (dispatch) => {
        dispatch(equipmentRequestPending());
        try {
            const response = await getEquipmentByIdAPI(payload);                      
            if (response?.status === 200) {
                dispatch(equipmentRequestReceived(response?.data?.data));
            }
        } catch (error) {    
            dispatch(equipmentRequestFailed(error?.response?.data?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const getAllDocIdAction = (payload) => { 
    return async (dispatch) => {
        dispatch(equipmentDocIdPending());
        try {
            const response = await getAllDocIdAPI(payload);                      
            if (response?.status === 200) {
                let docIDList = []
                docIDList = [...response.data.data]
                dispatch(equipmentDocIdReceived(docIDList));
                
            }
        } catch (error) {    
            dispatch(equipmentDocIdFailed(error?.response?.data?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const uploadDocAction = (payload, samid) => { 
    return async (dispatch) => {
        dispatch(docUploadPending());
        try {
            const response = await uploadDocAPI(payload);                      
            if (response?.status === 200 || response?.status === 201) {
                dispatch(docUploadReceived(response.data));
                dispatch(getAllDocIdAction(samid));
            }
        } catch (error) {    
            dispatch(docUploadFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const deleteDocAction = (payload) => { 
    return async (dispatch) => {
        dispatch(docDeletePending());
        try {
            const response = await deleteDocAPI(payload);                      
            if (response?.status === 200 || response?.status === 201) {
                dispatch(getAllDocIdAction(payload.eqId));
                dispatch(actions.successAlert("Document deleted successfully"));
            }
        } catch (error) {    
            dispatch(docDeleteFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const downloadFileAction = (payload) => { 
    return async (dispatch) => {
        dispatch(downloadFilePending());
        try {
            const response = await downloadFileAPI(payload);     
            if (response?.status === 200) {
                dispatch(downloadFileReceived(response?.data?.data));
            }
        } catch (error) {    
            dispatch(downloadFileFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const updateDocumentAction = (payload, samid) => { 
    return async (dispatch) => {
        dispatch(docEditPending());
        try {
            const response = await updateDocumentAPI(payload);                      
            if (response?.status === 200 || response?.status === 201) {
                dispatch(docEditReceived(response.data));
                dispatch(getAllDocIdAction(samid));
                dispatch(actions.successAlert("Document updated successfully"));
            }
        } catch (error) {    
            dispatch(docEditFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const getProductFormatAssignmentFromAction = (payload) => {   
    return async (dispatch) => {
        dispatch(pfAssignmentFromPending());
        try {
            const response = await getProductFormatAssignmentFromAPI(payload);
            if (response?.status === 200) 
                dispatch(pfAssignmentFromReceived(response?.data?.data));
        } catch (error) {
            dispatch(pfAssignmentFromFailed(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const getProductFormatAssignmentToAction = (payload) => {   
    return async (dispatch) => {
        dispatch(pfAssignmentToPending());
        try {
            const response = await getProductFormatAssignmentToAPI(payload);
            if (response?.status === 200) 
                dispatch(pfAssignmentToReceived(response?.data?.data));
        } catch (error) {
            dispatch(pfAssignmentToFailed(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const getAllProductFormatTypesAction = (payload) => {   
    return async (dispatch) => {
        dispatch(functionLocationChildRequestPending());
        try {
            const response = await getAllProductFormatTypesAPI(payload);
            if (response?.status === 200) 
                dispatch(functionLocationChildRequestReceived(response?.data?.data));
        } catch (error) {
            dispatch(functionLocationChildRequestFailed(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const updateAction = (id,payload) => { 
    return async (dispatch) => {
        dispatch(updatePending());
        try {
            const response = await updateAPI(id,payload);   
            if (response?.status === 201 || response?.status === 200) {
                dispatch(updateReceived(response.data));
                dispatch(actions.successAlert("Product format updated successfully"));
            }
        } catch (error) {   
            console.log("error",error) 
            dispatch(updateFailed(error?.response?.data));        
            if (error?.response) {
                if(error?.response?.status === 400){
                dispatch(actions.errorAlert('Maximum allowed length for FreeTextValue is 300 characters'));
                }else{
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
                }
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const updateKitAction = (id,payload) => { 
    return async (dispatch) => {
        dispatch(updateKitPending());
        try {
            const response = await updateKitAPI(id,payload);   
            if (response?.status === 201 || response?.status === 200) {
                dispatch(updateKitReceived(response.data));
                dispatch(actions.successAlert("Product format updated successfully"));
            }
        } catch (error) {   
            console.log("error",error) 
            dispatch(updateKitFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}


export const getEquipmentALLExportAction = (payload) => {   
    return async (dispatch) => {
        dispatch(requestExportAll());
        try {
            const response = await getEquipmentALLExportAPI(payload);
            if (response?.status === 200) {
                let a = document.createElement('a');
                a.href = response?.data?.data;
                document.body.appendChild(a);
                // a.target='_blank';
                //a.download = true;
                //a.setAttribute('download','')
                a.click();           
                a.remove();
               dispatch(receiveExportAll(response?.data));
            }
        } catch (error) {
            dispatch(failureExportAll(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};