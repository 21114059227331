import { actionTypes } from "./actions";

export const initialState = {
    regions: [],
    activeRegionLists: [],
    activeRegionListsName: []
};

// ===========================|| COMMON REDUCER ||=========================== //

const regionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REGION_GET_REQUEST:
      return {
        ...state,
        isRegionLoading: true,
      };
    case actionTypes.REGION_GET_RECEIVE:
      return {
        ...state,
        regions: action.payload.map((dt, index) => ({ ...dt, id: index })),
        activeRegionLists: action.payload.map((dt, index)=>({label: dt.regionDescription, lb: dt.regionName, value: dt.pkRegion})),
        activeRegionListsName: action.payload.map((dt, index)=>({label:dt.regionName, value: dt.pkRegion})),        
        isRegionLoading: false,
      };
    case actionTypes.REGION_GET_FAIL:
      return {
        ...state,
        isRegionLoading: false,
        isRegionFail: false,
      };
    default:
      return state;
  }
};

export default regionReducer;
