import React, { useEffect, useState } from 'react';
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

function CustomPagination(props) {
  const { pageSize1, setPageSize, totalRow, page, setPage } = props;
  const pageSizeOptions = [20, 50, 100, 500];

  return (
    <Grid container spacing={5} justifyContent='right' alignItems='center'>
      <Grid item>
        <TextField select variant="standard" value={pageSize1} onChange={setPageSize}>
          {pageSizeOptions.map((elem) =>
            <MenuItem key={elem} value={elem}>
              <Typography>{elem} rows</Typography>
            </MenuItem>
          )}
        </TextField>
      </Grid>
      <Grid item>
        <Pagination
          color="primary"
          showFirstButton
          showLastButton
          count={totalRow}
          page={page}
          onChange={(event, value) => setPage(value)}
        />
      </Grid>
    </Grid>
  );
}

export default CustomPagination;