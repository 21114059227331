import { actionTypes } from "./actions";

export const initialState = {
  cluster: [], 
  isClusterLoading: false
};

// ===========================|| COMMON REDUCER ||=========================== //

const clusterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLUSTER_REQUEST:
      return {
        ...state,
        isClusterLoading: true,
      };
      case actionTypes.CLUSTER_RECEIVE:
      return {
        ...state,
        cluster: action.payload.map((dt, index)=>({...dt, id:index})),
        isClusterLoading: false,
      };
      case actionTypes.CLUSTER_FAILED:
      return {
        ...state,
        isClusterFail: false,
        isClusterLoading: false
      };
    default:
      return state;
  }
};

export default clusterReducer;
