import React from 'react';
import { Table } from 'antd';
import { IconButton } from "@mui/material";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { styled } from '@mui/material/styles';

const NewTable = styled(Table)({
    tbody: {
        backGround: 'red',
        tr: {
            td: {
                padding: '9px 16px'
            }
        }
    }
});

export function StyledTreeDataGrid({ columns, rows, ...props }) {
    return <div style={{
        marginTop: "16px",
        border: "1px solid rgba(224, 224, 224, 1)",
        borderRadius: "5px"
    }}>
        <NewTable
            {...props}
            rowClassName={(record, index) => {
                if (Array.isArray(record?.children)) {
                    return 'table-row-dark';
                }
                return record.key % 1 === 0 ? 'table-row-dark' : 'table-row-light-even';
            }}
            columns={columns}
            pagination={{
                pageSize: 10,
                position: 'bottomCenter',
                showSizeChanger: false
              }}
            key={rows.length}
            size="small"
            dataSource={rows}
            expandable={{
                childrenColumnName: "children",
                defaultExpandAllRows: props?.expandAllRows ? true : false,
                expandIcon: ({ expanded, onExpand, record }) => {
                    if (!record?.children) {
                        return null;
                    }
                    return expanded ? (
                        <IconButton color="primary" onClick={e => onExpand(record, e)}>
                            <ExpandLessOutlinedIcon />
                        </IconButton>
                    ) : (
                        <IconButton color="primary" onClick={e => onExpand(record, e)}>
                            <ExpandMoreOutlinedIcon />
                        </IconButton>
                    )
                }
            }}
        />
    </div>
}