import { getFunctionalLoacatioIdnAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    FLID_GET_REQUEST: "FLID_GET_REQUEST",
    FLID_GET_RECEIVE: "FLID_GET_RECEIVE",
    FLID_GET_FAIL: "FLID_GET_FAIL"
  };

const requestflbyId= () => ({
    type: actionTypes.FLID_GET_REQUEST,
});
const receiveflbyId = (payload) => ({
    type: actionTypes.FLID_GET_RECEIVE,
    payload,
});
const failureflbyId = (payload) => ({
    type: actionTypes.FLID_GET_FAIL,
    payload,
});
export const getFlbyIdAction = (id) => {
    return async (dispatch) => {
        dispatch(requestflbyId());
        try {
            const response = await getFunctionalLoacatioIdnAPI(id);
            if (response?.status === 200) {
                dispatch(receiveflbyId(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureflbyId(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
