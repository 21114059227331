import { useRef, useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { getTimeAgo } from '../../../../utils/utils';
import {
    Badge,
    Box,
    ClickAwayListener,
    Divider,
    IconButton,
    List,
    ListItemButton,
    ListItemText,
    ListItemSecondaryAction,
    Paper,
    Popper,
    Typography,
    useMediaQuery,
    TextField,
    InputAdornment
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationByIdAction, updateReadNotificationAction, archiveNotificationAction } from '../HeaderContent/state/actions';

// assets
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ArchiveIcon from '@mui/icons-material/Archive';
// sx styles
const avatarSX = {
    width: 36,
    height: 36,
    fontSize: '1rem'
};

const actionSX = {
    mt: '6px',
    ml: 1,
    top: 'auto',
    right: 'auto',
    alignSelf: 'flex-start',

    transform: 'none'
};

const notificationData = [
    {
        NOTIFICATION_ID: "02884353-9d62-4478-8a60-8bb6c2c37702",
        NOTIFICATION_TIMESTAMP: "2023-02-24T09:23:10",
        NOTIFICATION_PUBLISHER_SERVICE: "BULK UPLOAD SERVICE",
        NOTIFICATION_TYPE_ID: 1,
        NOTIFICATION_MESSAGE_SUBJECT: "SUBJECT: Bulk Upload is complete",
        NOTIFICATION_MESSAGE_BODY: "MESSAGE BODY : Bulk Upload is completed.",
        NOTIFICATION_EXPIRY_DATE: "2023-02-25T09:23:10",
        NOTIFICATION_READ_STATUS: 1,
        NOTIFICATION_VALIDITY_STATE: 1,
        NOTIFICATION_RECEIVER_USER_ID: "ritisha.lulla@contracted.sampoerna.com",
        NOTIFICATION_RECEIVER_TYPE_ID: 0
    },
    {
        NOTIFICATION_ID: "136cb66c-2d20-4bda-a1fc-c38df440fa66",
        NOTIFICATION_TIMESTAMP: "2023-02-24T09:34:10",
        NOTIFICATION_PUBLISHER_SERVICE: "BULK UPLOAD SERVICE",
        NOTIFICATION_TYPE_ID: 1,
        NOTIFICATION_MESSAGE_SUBJECT: "SUBJECT: Bulk Upload is complete",
        NOTIFICATION_MESSAGE_BODY: "MESSAGE BODY : Bulk Upload is completed.",
        NOTIFICATION_EXPIRY_DATE: "2023-02-25T09:34:10",
        NOTIFICATION_READ_STATUS: 0,
        NOTIFICATION_VALIDITY_STATE: 1,
        NOTIFICATION_RECEIVER_USER_ID: "ritisha.lulla@contracted.sampoerna.com",
        NOTIFICATION_RECEIVER_TYPE_ID: 0
    },
    {
        NOTIFICATION_ID: "136cb66c-2d20-4bda-a1fc-c38df440fa66",
        NOTIFICATION_TIMESTAMP: "2023-02-24T09:34:10",
        NOTIFICATION_PUBLISHER_SERVICE: "BULK UPLOAD SERVICE",
        NOTIFICATION_TYPE_ID: 1,
        NOTIFICATION_MESSAGE_SUBJECT: "SUBJECT: Bulk Upload is complete",
        NOTIFICATION_MESSAGE_BODY: "MESSAGE BODY : Bulk Upload is completed.",
        NOTIFICATION_EXPIRY_DATE: "2023-02-25T09:34:10",
        NOTIFICATION_READ_STATUS: 0,
        NOTIFICATION_VALIDITY_STATE: 1,
        NOTIFICATION_RECEIVER_USER_ID: "ritisha.lulla@contracted.sampoerna.com",
        NOTIFICATION_RECEIVER_TYPE_ID: 0
    },
    {
        NOTIFICATION_ID: "136cb66c-2d20-4bda-a1fc-c38df440fa66",
        NOTIFICATION_TIMESTAMP: "2023-02-24T09:34:10",
        NOTIFICATION_PUBLISHER_SERVICE: "BULK UPLOAD SERVICE",
        NOTIFICATION_TYPE_ID: 1,
        NOTIFICATION_MESSAGE_SUBJECT: "SUBJECT: Bulk Upload is complete",
        NOTIFICATION_MESSAGE_BODY: "MESSAGE BODY : Bulk Upload is completed.",
        NOTIFICATION_EXPIRY_DATE: "2023-02-25T09:34:10",
        NOTIFICATION_READ_STATUS: 0,
        NOTIFICATION_VALIDITY_STATE: 1,
        NOTIFICATION_RECEIVER_USER_ID: "ritisha.lulla@contracted.sampoerna.com",
        NOTIFICATION_RECEIVER_TYPE_ID: 0
    },
    {
        NOTIFICATION_ID: "136cb66c-2d20-4bda-a1fc-c38df440fa66",
        NOTIFICATION_TIMESTAMP: "2023-02-24T09:34:10",
        NOTIFICATION_PUBLISHER_SERVICE: "BULK UPLOAD SERVICE",
        NOTIFICATION_TYPE_ID: 1,
        NOTIFICATION_MESSAGE_SUBJECT: "SUBJECT: Bulk Upload is complete",
        NOTIFICATION_MESSAGE_BODY: "MESSAGE BODY : Bulk Upload is completed.",
        NOTIFICATION_EXPIRY_DATE: "2023-02-25T09:34:10",
        NOTIFICATION_READ_STATUS: 0,
        NOTIFICATION_VALIDITY_STATE: 1,
        NOTIFICATION_RECEIVER_USER_ID: "ritisha.lulla@contracted.sampoerna.com",
        NOTIFICATION_RECEIVER_TYPE_ID: 0
    }
];

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const { decodedToken } = useSelector((state) => state.token);
    const dispatch = useDispatch();
    const { notifications, loading } = useSelector((state) => state.notification);
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
        dispatch(getNotificationByIdAction(decodedToken.email))
    };

    const updateNotificationStatusByEmail = (notificationId, email) => {
        // API call here to update notification status from unread to read by using email, notificationId and status = 1
        // after success this condition call
        dispatch(updateReadNotificationAction(notificationId, email))
    }
    const handleArchiveNotification = (notificationId, email) => {
        dispatch(archiveNotificationAction(notificationId, email))
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };   

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <IconButton
                disableRipple
                // color={theme.palette.common.white}
                sx={{ color: theme.palette.common.white }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                 { notifications.filter(({NOTIFICATION_READ_STATUS}) => !NOTIFICATION_READ_STATUS).length ? <Badge badgeContent={notifications.filter(({NOTIFICATION_READ_STATUS}) => !NOTIFICATION_READ_STATUS).length} color="error">
                    <NotificationsActiveOutlinedIcon />
                </Badge> : <NotificationsActiveOutlinedIcon />}
            </IconButton>
            <Popper
                sx={{
                    // boxShadow: theme.customShadows.z1,

                    height: '300px',
                    overflowY: notifications?.length > 2 ? 'scroll': 'none',

                }}
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? -5 : 0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                // boxShadow: theme.customShadows.z1,
                                width: '100%',
                                minWidth: 285,
                                maxWidth: 350,
                                // height: '300px',
                                // overflow: 'scroll',                               
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 285
                                }
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    title="Notifications"
                                    elevation={0}
                                    border={false}
                                    content={false}
                                    secondary={
                                        <IconButton size="small" onClick={handleToggle}>
                                            <CloseOutlinedIcon />
                                        </IconButton>
                                    }
                                >
                                    <List
                                        component="nav"
                                        sx={{
                                            p: 0,
                                            '& .MuiListItemButton-root': {
                                                py: 0.5,
                                                '& .MuiAvatar-root': avatarSX,
                                                '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                                            }
                                        }}
                                    >

                                        { notifications.length > 0 ? notifications.map((data) => {
                                            return (
                                                <div>
                                                    <Divider textAlign="center">{getTimeAgo(data.NOTIFICATION_TIMESTAMP+'Z').split('$')[0]}</Divider>
                                                    <ListItemButton>
                                                        <ListItemText
                                                            onClick={() => updateNotificationStatusByEmail(data.NOTIFICATION_ID, data.NOTIFICATION_RECEIVER_USER_ID)}
                                                            primary={
                                                                <Typography variant="h6">
                                                                    {data.NOTIFICATION_PUBLISHER_SERVICE}
                                                                </Typography>
                                                            }
                                                            secondary={data.NOTIFICATION_MESSAGE_BODY}
                                                            style={data.NOTIFICATION_READ_STATUS === 1 ? null: {color:'#0F5689', fontWeight: 'bold'}}
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <Typography variant="caption" noWrap>
                                                                {getTimeAgo(data.NOTIFICATION_TIMESTAMP+'Z').split('$')[1]}
                                                            </Typography>
                                                            <ListItemButton sx={{ textAlign: 'center', py: `${12}px !important` }} 
                                                            onClick={() => handleArchiveNotification(data.NOTIFICATION_ID, data.NOTIFICATION_RECEIVER_USER_ID)} 
                                                            >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h6" color="primary">
                                                        <CloseOutlinedIcon />
                                                    </Typography>
                                                }
                                            />
                                        </ListItemButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItemButton>
                                                </div>
                                            )
                                        }) : 
                                        <Typography sx = {{textAlign: 'center'}} variant="h6" color="primary">
                                                        No Notification
                                                    </Typography>
                                        
                                        }
                                    </List>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default Notification;
