
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MainCard from 'components/MainCard';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { getAllActiveCompanyAction } from 'containers/Management/Companies/state/actions';
import { getModelAction } from 'containers/Management/Models/state/actions';
import { getOemAction } from 'containers/Management/OEM/state/actions';
import { Autocomplete, Box, Button, Grid, LinearProgress, TextField } from '@mui/material/index';
import { getAreaAction } from 'containers/Management/FunctionalStructure/Area/state/actions';
import { getFunctionAction } from 'containers/Management/FunctionalStructure/Function/state/actions';
import { getProcessAction } from 'containers/Management/FunctionalStructure/Process/state/actions';
import { getSubPrAction } from 'containers/Management/FunctionalStructure/SubProcess/state/actions';
import { getFormStateAction } from '../StateList/actions';
import { getFormStepAction } from '../StepList/actions';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getAllConditionAction } from 'containers/Management/Conditions/state/actions';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { createFilterOptions } from '@mui/material/Autocomplete';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { StyledTreeDataGrid } from 'components/DataGrid/Tree/index';
import { drDecision, drRule, getTreeData, TabPanel } from './constant';
import { Chip, CircularProgress, Modal, Stack } from '@mui/material/index';
import moment from 'moment';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import EquipmentTabs from '../EquipmentTabs';
import { searchEqForDisposal } from './state/actions';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import NewDisposalRequest from 'containers/BusinessProcess/DisposalRequest/NewDisposalRequest';
import eqExport from './eqExport';
import { formDetailAction } from 'containers/BusinessProcess/DisposalRequest/state/actions';
import { createSmartfilterAction, deleteSmartFilterAPIAction, getSmartFilterAction, updateSmartFIlterAction } from 'containers/Dashboard/CustomFilterState/actions';
import DeleteModal from 'components/DeleteModal';
import MultiSelect1 from 'components/Form/MultiSelect1';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material/index';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    pl: 2,
    pt: 2
};



const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const yesNoOption = [{ id: 'yes', label: 'Yes' }, { id: 'no', label: 'No' }];

export default function EqDisposalRequest() {
    const [formData, setFormData] = useState([]);
    const [view, setView] = useState('main');
    const [eqId, setEqId] = useState('');
    const [drId, setDrId] = useState('');
    const [lock, setLock] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openDetails, setOpenDetails] = React.useState({});
    const handleDeatilsClose = () => setOpenDetails({});

    const [headerValue, onHeaderChange] = useState('');
    const { smartFilterList = [], isSmartFilterLoading = false, isFilterSaved } = useSelector((state) => state.smartFilter);
    const [selectedChip, setSelectedChip] = useState('');

    const [openDeleteModal, toggleDeleteModal] = useState({});
    const [selectedOptions, setSelectedOptions] = React.useState([{ value: 50, label: 'Portugal (TABAQUEIRA)' }]);

    const setSelected = (a) => {
        setSelectedOptions({ ...selectedOptions, ...a })
    }
    const [resetFilter, setclearFilter] = useState(false);

    const [expanded, setExpanded] = React.useState('panel1');
    const [smartFilterListNew, setSmartFIlter] = useState([]);


    const dispatch = useDispatch();
    const menu = useSelector((state) => state.common);
    const { drawerOpen } = menu;
    let { roles } = useSelector((state) => state.token);

    const { companyLists = [], isCompanyLoading } = useSelector((state) => state.company);
    const { areaList = [], isAreaLoading } = useSelector((state) => state.area);
    const { conditions = [], conditionLoading } = useSelector((state) => state.conditions);
    const { oemList = [], isOemLoading } = useSelector((state) => state.oem);
    const { modelList = [], isModelLoading } = useSelector((state) => state.model);
    const { functionList = [], isFunctionLoading } = useSelector((state) => state.function);
    const { formStateList = [], isFormStateLoading } = useSelector((state) => state.formState);
    const { formStepList = [], isFormStepLoading } = useSelector((state) => state.formStep);
    const { processList = [], isProcessLoading } = useSelector((state) => state.process);
    const { subProcessList = [], isSubProLoading } = useSelector((state) => state.subProcess);
    const { eqDispSearchList = [], iseqDispSearchListLoading } = useSelector((state) => state.eqSearch);
    
    const isDropdwonsLoading = () => {
        if (isCompanyLoading || isAreaLoading || conditionLoading || isOemLoading || isModelLoading
            || isFunctionLoading || isFormStateLoading || isFormStepLoading ||
            isProcessLoading || isSubProLoading || iseqDispSearchListLoading) {
            return true;
        } else {
            return false;
        }
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 500,
    });

    useEffect(() => {
        if (roles.includes('admin') || roles.includes('coordinator')) {
            dispatch(getSmartFilterAction());
        }
        dispatch({type: 'CLEAR_DISP_DATA'});
    }, [])

    useEffect(() => {
        setSmartFIlter(smartFilterList.filter((a) => a.type === 'DR'));
    }, [smartFilterList])

    React.useEffect(() => {
        dispatch(getAreaAction());
        dispatch(getFormStateAction());
        dispatch(getSubPrAction());
        dispatch(getAllConditionAction());
        dispatch(getFormStepAction());
        dispatch(getOemAction());
        dispatch(getProcessAction());
        dispatch(getModelAction());
        dispatch(getFunctionAction());
        dispatch(getAllActiveCompanyAction());

    }, [])

    const onSamDeatilsClick = (data) => {
        setEqId(data.equipmentId)
        setView('DETAILS_EQ');
    }

    const onInputChange = ({ target }) => {
        setFormData({ ...formData, [target.name]: target.value });
    }

    const onChange = (name, allSelected, event) => {

        if (allSelected === 'All' && !event.target.checked) {
            const gdf = Object.keys(name)[0]
            setFormData({ ...formData, [gdf]: [] })
        } else {
            setFormData({ ...formData, ...name })
        }
    }

    const handleOnClickClear = () => {
        setclearFilter(!resetFilter);
        setFormData({
            SamDrfref: "",
            TransferredMonthYear: null,
            RequestValidityDate: null,
            SerialNumber: "", equipmentId: '', SapP1ref: ''
        });
    }

    const handleChange = (panel) => (event, newExpanded) => {
        if(!lock){
            setExpanded(newExpanded ? panel : false);
        }
    };
    const handleOnClickSearch = () => {

        const payload = [];
        for (let keys in formData) {
            if (formData[keys]) {
                if (typeof (formData[keys]) === 'object') {
                    if (Array.isArray(formData[keys])) {
                        if (formData[keys].length)
                            payload.push({
                                "columnName": keys,
                                "filterValues": formData[keys].map((a) => `${a.value}`),
                                "filterOption": 9
                            });
                    } else {
                        payload.push({
                            "columnName": keys,
                            "filterValues": [moment(formData[keys]).format('YYYY-MM-DD')],
                            "filterOption": 9
                        });
                    }

                } else {
                    if (keys === 'SerialNumber'|| keys === 'SapP1ref') {
                        payload.push({
                            "columnName": keys,
                            "filterValues": [formData[keys].replaceAll("%", "")],
                            "filterOption": formData[keys].startsWith("%") && formData[keys].endsWith("%")?3:formData[keys].endsWith("%")?1:formData[keys].startsWith("%")?2 :9
                        });
                    } 
                    else {
                        if (keys === 'SamDrfref') {
                            payload.push({
                                "columnName": keys,
                                "filterValues": [formData[keys].replaceAll("%", "")],
                                "filterOption": 3
                            });
                        } 
                        else {
                            payload.push({
                                "columnName": keys,
                                "filterValues": [`${formData[keys]}`],
                                "filterOption": 9
                            });
                        }
                    }
                }
            }
        }
        dispatch(searchEqForDisposal({
            "filterParams": payload,
        }, 0, 100000))
        if (!lock) {
            setExpanded('both');
        }
    }

    const onSaveClick = () => {

        const payload = [];
        for (let keys in formData) {
            if (formData[keys]) {
                if (typeof (formData[keys]) === 'object') {
                    if (Array.isArray(formData[keys])) {
                        if (formData[keys].length)
                            payload.push({
                                "columnName": keys,
                                "filterValues": formData[keys].map((a) => `${a.value}`),
                                "filterOption": 9
                            });
                    } else {
                        payload.push({
                            "columnName": keys,
                            "filterValues": [moment(formData[keys]).format('YYYY-MM-DD')],
                            "filterOption": 9
                        });
                    }

                } else {
                    payload.push({
                        "columnName": keys,
                        "filterValues": [`${formData[keys]}`],
                        "filterOption": 9
                    });
                }
            }
        }
        const pld = {
            "title": headerValue,
            "type": "DR",
            "filterCriteria": {
                "filterParams": payload
            }
        }
        if (selectedChip) {
            dispatch(updateSmartFIlterAction(selectedChip, pld));
        } else {
            dispatch(createSmartfilterAction(pld));
        }
    }


    const getLabelId = (list, value, id, label) => {

        const abdt = list.find((a) => a[id] == value);
        return abdt ? { id: abdt[id], label: abdt[label] } : '';
    }

    const getFilterList = (id) => {
        const filt = smartFilterListNew.find((a) => a.pkSmartFilter === id).filterCriteria;

        const abc = [];
        filt.filterParams.forEach((a) => {
            switch (a.columnName) {
                case 'RequesterCompanyId':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(companyLists, b, 'pkCompany', 'longCompanyName').label
                        });
                        abc.push({ id: 'Company', value: name.join(",") });
                    }
                    break;
                    case 'SamDrfref':
                        if (a.filterValues && a.filterValues.length) {
                           
                            abc.push({ id: 'SAM DR Ref', value: a.filterValues[0] });
                        }
                        break;
                case 'StateId':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(formStateList, b, 'stateValue', 'stateName').label
                        });

                        abc.push({ id: 'State', value: name.join(",") });
                    }
                    break;
                case 'StepId':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(formStepList, b, 'stepValue', 'stepName').label
                        });

                        abc.push({ id: 'Step', value: name.join(",") });
                    }
                    break;
                case 'DrRule':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(drRule, b, 'id', 'label').label
                        });

                        abc.push({ id: 'Dr rule', value: name.join(",") });
                    }
                    break;
                case 'DrDecision':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(drDecision, b, 'id', 'label').label
                        });
                        abc.push({ id: 'DR decision', value: name.join(",") });

                    }
                    break;
                case 'EquipmentAreaId':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(areaList, b, 'pkArea', 'areaName').label
                        });

                        abc.push({ id: 'Area', value: name.join(",") });

                    }
                    break;
                case 'EquipmentProcessName':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(processList, b, 'processName', 'processName').label
                        });

                        abc.push({ id: 'Process', value: name.join(",") });
                    }
                    break;
                case 'EquipmentSubProcessName':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(subProcessList, b, 'subProcessName', 'subProcessName').label
                        });
                        abc.push({ id: 'Sub Process', value: name.join(",") });
                    }
                    break;
                case 'EquipmentFunctionName':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(functionList, b, 'functionName', 'functionName').label
                        });
                        abc.push({ id: 'Function', value: name.join(",") });
                    }
                    break;

                case 'EquipmentOemid':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(oemList, b, 'pkOem', 'oemName').label
                        });
                        abc.push({ id: 'Oem', value: name.join(",") });
                    }
                    break;
                case 'ConditionId':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(conditions, b, 'pkCondition', 'conditionName').label
                        });
                        abc.push({ id: 'Condition', value: name.join(",") });
                    }
                    break;
                case 'EqReservedInMRFId':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(yesNoOption, b, 'id', 'label').label
                        });
                        abc.push({ id: 'Reserved', value: name.join(",") });
                    }
                    break;
                case 'IsRepairandReplacement':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId([{ id: 'true', label: 'Yes' }, { id: 'false', label: 'No' }], b, 'id', 'label').label
                        });
                        abc.push({ id: 'Repair and Replace', value: name.join(",") });
                    }
                    break;
                case 'ScrapImmediately':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId([{ id: 'true', label: 'Yes' }, { id: 'false', label: 'No' }], b, 'id', 'label').label
                        });
                        abc.push({ id: 'Scrap Immediately', value: name.join(",") });
                    }
                    break;
                default:

                    break;
            }
        })
        return abc;
    }

    const setChipSelected = (id) => {
        if (id === selectedChip) {
            // handleOnClickSearch();
            setSelectedChip('');
        } else {
            const filt = smartFilterListNew.find((a) => a.pkSmartFilter === id).filterCriteria;
            const a = { filterParams: [...filt.filterParams] };
            const dt = filt.filterParams.reduce((acc, cur) => ({ ...acc, [cur.columnName]: cur.filterValues }), {})

            const {
                RequesterCompanyId,
                StateId,
                StepId,
                DrRule,
                DrDecision,
                EquipmentAreaId,
                EquipmentProcessId,
                EquipmentSubProcessId,
                EquipmentFunctionId,
                EquipmentModelId,
                EquipmentOemid,
                ConditionId,
                EqReservedInMRFId,
                IsRepairandReplacement,
                ScrapImmediately
            } = dt;


            const formData = {
                ...dt,
                RequesterCompanyId: RequesterCompanyId ? getLabelId(companyLists, RequesterCompanyId, 'pkCompany', 'longCompanyName') : "",
                StateId: StateId ? getLabelId(formStateList, StateId, 'stateValue', 'stateName') : "",
                StepId: StepId ? getLabelId(formStepList, StepId, 'stepValue', 'stepName') : "",
                DrRule: DrRule ? getLabelId(drRule, DrRule, 'id', 'label') : "",
                DrDecision: DrDecision ? getLabelId(drDecision, DrDecision, 'id', 'label') : "",
                EquipmentAreaId: EquipmentAreaId ? getLabelId(areaList, EquipmentAreaId, 'pkArea', 'areaName') : "",
                EquipmentProcessId: EquipmentProcessId ? getLabelId(processList, EquipmentProcessId, 'pkProcess', 'processName') : "",
                EquipmentSubProcessId: EquipmentSubProcessId ? getLabelId(subProcessList, EquipmentSubProcessId, 'pkSubProcess', 'subProcessName') : "",
                EquipmentFunctionId: EquipmentFunctionId ? getLabelId(processList, EquipmentFunctionId, 'pkFunction', 'functionName') : "",
                EquipmentModelId: EquipmentProcessId ? getLabelId(modelList, EquipmentModelId, 'pkModel', 'modelName') : "",
                EquipmentOemid: EquipmentOemid ? getLabelId(oemList, EquipmentOemid, 'pkOem', 'processName') : "",
                ConditionId: ConditionId ? getLabelId(conditions, ConditionId, 'pkCondition', 'conditionName') : "",
                EqReservedInMRFId: EqReservedInMRFId ? getLabelId(yesNoOption, EqReservedInMRFId, 'id', 'label') : "",
                IsRepairandReplacement: IsRepairandReplacement ? getLabelId([{ id: 'true', label: 'Yes' }, { id: 'false', label: 'No' }], IsRepairandReplacement, 'id', 'label') : "",
                ScrapImmediately: ScrapImmediately ? getLabelId([{ id: 'true', label: 'Yes' }, { id: 'false', label: 'No' }], ScrapImmediately, 'id', 'label') : "",
            }
            // setFormData(formData)
            dispatch(searchEqForDisposal(a, 0, 100000));
            setSelectedChip(id);
            if (!lock) {
            setExpanded('both');
            }
        }
    }

    useEffect(() => {
        if (isFilterSaved) {
            handleClose();
            dispatch({ type: 'SFILTER_CLEAR_FLAG' })
        }
        // dispatch(searchContactAction())
    }, [isFilterSaved, dispatch])

    const onDelete = (e, id) => {
        e.stopPropagation();
        toggleDeleteModal({ open: true, data: id });
    }

    const deleteConfirm = (data) => {
        toggleDeleteModal({});
        dispatch(deleteSmartFilterAPIAction(data));
    }

    const getStepName = (stepId) => {
        if (!formStepList) return '';
        const abc = formStepList.find((a) => a.stepValue === stepId);
        return abc ? `${abc.stepValue} ${abc.stepName}` : '';
    }

    const getStateName = (stateId) => {
        if (!formStateList) return '';
        const abc = formStateList.find((a) => a.stateValue === stateId);
        return abc ? `${abc.stateValue} ${abc.stateName}` : '';
    }
    const handleFormEdit = (samDrfref) => {
        let firstIndx = samDrfref.indexOf('-')
        let lastIndx = samDrfref.lastIndexOf('-')
        let drId = samDrfref.substring(firstIndx + 2, lastIndx)
        setDrId(drId);
        dispatch(formDetailAction(drId))
        setView('DETAILS_DR')
    }
    useEffect(() => {
        if (view === 'DETAILS_DR')
            dispatch({ type: 'GET_INITIAL_DATA' })
    }, [view]);

    const getIconForCondition = (id) => {
        switch (id) {
            case 1:
                return <Stack direction="row" spacing={1}>
                    <CloseOutlinedIcon />
                </Stack>
            case 8:
                return <Stack direction="row" spacing={1}>
                    <DoneOutlinedIcon />
                </Stack>
            case 9:
                return <Stack direction="row" spacing={1}>
                    <GppMaybeOutlinedIcon />
                </Stack>
            case 10:
                return <Stack direction="row" spacing={1}>
                    <HelpOutlineOutlinedIcon />
                </Stack>
            default:
                return ''
        }
    }

    const columns = [{
        title: '',
        dataIndex: '',
        key: 'y',
        width: 80,
    },
    {
        title: 'SAM Dr Ref',
        dataIndex: '',
        key: 'y',
        width: 110,
        render: (data) => {
            return <Stack direction="column" spacing={1}>
                {data ? <>
                    <Button onClick={() => handleFormEdit(data.samDrfref)} variant="text">{data.samDrfref}</Button>
                </> : ''}
            </Stack>
        }
    },
    {
        title: 'Current State SAM ID',
        dataIndex: '',
        key: 'y',
        width: 100,
        render: (data) => {
            return <Stack direction="column" spacing={1}>
                {data ? <>
                    {data.stateId ? getStateName(data.stateId) : ""}
                    <Button onClick={() => onSamDeatilsClick(data)} variant="text">{data.equipmentId}</Button>
                </> : ''}
            </Stack>
        }
    },
    {
        title: 'Current Step Location',
        dataIndex: '',
        width: 120,
        key: 'y',
        render: (data) => {
            return <Stack direction="row" spacing={1}>
                {data ? <>
                    {data.stepId ? getStepName(data.stepId) : ""}<br />{data.location}
                </> : ''}
            </Stack>
        }
    },
    {
        title: 'Company Function - OEM Model',
        dataIndex: '', key: 'y',
        width: 120,
        render: (data) => {
            return <Stack direction="row" spacing={1}>
                {data ? <>
                    {data.requesterCompanyName}{data.equipmentFunctionName} {data.equipmentOemname} {data.equipmentModelName}
                </> : ''}
            </Stack>
        }
    },
    {
        title: 'Request Date',
        dataIndex: '',
        width: 120,
        key: 'y',
        render: (data) => {
            return <Stack direction="row" spacing={1}>
                {data ? <>
                    {moment(data.creationDate).format('MM/DD/YYYY')}
                </> : ''}
            </Stack>
        }
    },
    {
        title: 'Requester Name Serial Number',
        dataIndex: '',
        width: 120,
        key: 'y',
        render: (data) => {
            return <Stack direction="row" spacing={1}>
                {data ? <>
                    {data.requesterDisplayName}{data.serialNumber}
                </> : ''}
            </Stack>
        }
    },
    {
        title: 'Last Update SAP/P1 Ref',
        dataIndex: '',
        width: 120,
        key: 'y',
        render: (data) => {
            return <Stack direction="row" spacing={1}>
                {data ? <>
                    {moment(data.lastUpdateDate).format('MM/DD/YYYY, h:mm:ss A')}{data.sapP1ref}
                </> : ''}
            </Stack>
        }
    },
    {
        title: 'Last Update By Publication Period	',
        dataIndex: '',
        width: 120,
        key: 'y',
        render: (data) => {
            return <Stack direction="row" spacing={1}>
                {data ? <>
                    {data.lastUpdateBy}
                </> : ''}
            </Stack>
        }
    },
    {
        title: 'DR Rule',
        dataIndex: '',
        width: 120,
        key: 'y',
        render: (data) => {
            return <Stack direction="row" spacing={1}>
                {data ? <>
                    {data.drRule}
                </> : ''}
            </Stack>
        }

    },
    {
        title: 'DR Decision',
        dataIndex: '',
        width: 120,
        key: 'y',
        render: (data) => {
            return <Stack direction="row" spacing={1}>
                {data ? <>
                    {data.drDecision}
                </> : ''}
            </Stack>
        }

    },
    {
        title: 'Status',
        dataIndex: 'status',
        width: 120,
        key: 'status',
        // render: OrderColumnComponent
    },
    {
        title: 'Condition',
        dataIndex: '',
        width: 120,
        key: 'y',
        render: (data) => {
            return getIconForCondition(data.conditionId)
        }
    },
    ];

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            handleOnClickSearch();
        }
    }

    const handleClick = (data, fileName) => {
        eqExport(data, formStateList, formStepList, fileName);
    };

    return <><MainCard border={false}>
        <DeleteModal modalData={openDeleteModal} deleteConfirm={deleteConfirm} toggleModal={() => toggleDeleteModal({})} />

        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Filter
                    </Typography>
                    <Grid  container> <Grid item xs={12}>
                        <TextField
                            onChange={(e) => { onHeaderChange(e.target.value) }}
                            sx={{ width: '100%', mb: 2 }}
                            id="outlined-basic" size="small"
                            value={headerValue}
                            label="View Name" variant="standard" />
                    </Grid>

                        <Grid item p={2} sm={12}>
                            <Box display="flex" justifyContent="flex-end">
                                {isSmartFilterLoading ? <CircularProgress /> :
                                    <Button
                                        variant="contained"
                                        startIcon={<SaveOutlinedIcon />}
                                        size="small"
                                        sx={{ mr: 1 }}
                                        disabled={!headerValue}
                                        onClick={onSaveClick}
                                    >
                                        Save
                                    </Button>
                                }

                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
        <div>
            <Modal
                open={openDetails.open}
                onClose={handleDeatilsClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style1}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        Filter details:
                    </Typography>
                    <Box sx={{ height: '500px', overflowY: 'auto' }}>
                    <Grid container> <Grid item xs={12}>
                        <Grid container>{openDetails.open ? getFilterList(openDetails.id).map((a) => {
                            return <><Grid sx={{ fontWeight: 800 }} item xs={4}>{a.id}: </Grid><Grid item xs={8}> {a.value}</Grid></>
                        }) : ''}</Grid>
                    </Grid>

                        <Grid item p={1} mt={4} sm={12}>
                            <Box display="flex" justifyContent="flex-end">
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ mr: 1 }}
                                    onClick={handleDeatilsClose}
                                >
                                    Got it
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    </Box>
                </Box>
            </Modal>
        </div>
        {view === 'main' || view === 'DETAILS_DR' ?
            <Box sx={{ p: 1, display: view === 'DETAILS_DR' ? 'none' : 'block' }}>
              <Grid container sx={{ pl: 1, mt: 1 }} spacing={1}>
              <Grid item  xs={11} sx={{pl:1}}>
                       <Typography variant="h3">{'Disposal Request - Dashboard'}</Typography>
                </Grid>
                    <Grid item xs={1} justifyContent="flex-end">
                        {lock ?
                            <Tooltip title="Click to unlock expand">
                                <LockOutlinedIcon onClick={() => {setExpanded('all');setLock(!lock)}} />
                            </Tooltip>
                            :
                            <Tooltip title="Click to lock expand(Default behaviour)">
                                <LockOpenOutlinedIcon onClick={() => {setExpanded('all');setLock(!lock)}} />
                            </Tooltip>
                        }
                    </Grid>
                </Grid>
             

                <Box sx={{ p: 1 }}>
                    <Accordion expanded={expanded === 'panel1' || expanded === 'all'} onChange={handleChange('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Seach criteria</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {isDropdwonsLoading() ? <Box sx={{ width: '100%', mb: 1 }}>
                                <LinearProgress />
                            </Box> : ''}
                            <>
                                <Grid sx={{ pl: 0, pb: 2, pr: 2 }} xs={8}> Saved filters:
                                    {smartFilterListNew.map((a) => (
                                        <>  <Chip onClick={() => setChipSelected(a.pkSmartFilter)}
                                            color={selectedChip === a.pkSmartFilter ? 'primary' : 'default'}
                                            sx={{ mr: 2 }}
                                            icon={<><DeleteOutlineOutlinedIcon size="small"
                                                sx={{ cursor: 'pointer', ml: 2 }}
                                                onClick={(e) => onDelete(e, a.pkSmartFilter)} />
                                                <InfoOutlinedIcon onClick={(e) => { e.stopPropagation(); setOpenDetails({ id: a.pkSmartFilter, open: true }) }} />
                                            </>} label={a.title} /></>
                                    ))}
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.RequesterCompanyId}
                                            setSelectedOptions={setSelected}
                                            // selectedOption={formData.RequesterCompanyId}
                                            name='RequesterCompanyId'
                                            data={{ label: "Company", options: companyLists.map((dt) => ({ label: dt.longCompanyName, value: dt.pkCompany })) }}
                                        />

                                    </Grid>
                                    <Grid sx={{ pr: 4 }} item xs={12} sm={3}>
                                        <TextField fullWidth name="SamDrfref" onChange={onInputChange}
                                            label="SAM DR Ref"
                                            onKeyDown={onKeyDown}
                                            //inputProps={{ maxLength: 4 }}
                                            value={formData.SamDrfref} size='small'
                                        />
                                    </Grid>
                                    <Grid sx={{ pr: 2 }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.StateId}
                                            setSelectedOptions={setSelected}
                                            // selectedOption={formData.RequesterCompanyId}
                                            name='StateId'
                                            data={{ label: "State", options: formStateList.map((dt) => ({ label: dt.stateName, value: dt.stateValue })) }}
                                        />

                                    </Grid>
                                    <Grid sx={{ pr: 2 }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.StepId}
                                            setSelectedOptions={setSelected}
                                            // selectedOption={formData.RequesterCompanyId}
                                            name='StepId'
                                            data={{ label: "Step", options: formStepList.map((dt) => ({ label: dt.stepName, value: dt.stepValue })) }}
                                        />

                                    </Grid>
                                    <Grid sx={{ pr: 4 }} item xs={12} sm={3}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopDatePicker
                                                label="Publication Date"
                                                inputFormat="MM/DD/YYYY"
                                                emptyLabel="Select date"
                                                size="small"
                                                fullWidth
                                                value={formData.CreationDate || null}
                                                onChange={(value) => onInputChange({ target: { name: 'CreationDate', value } })}
                                                renderInput={(params) => <TextField label="Publication Date" fullWidth size="small" {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid sx={{ pr: 4 }} item xs={12} sm={3}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopDatePicker
                                                label="Decision Date"
                                                inputFormat="MM/DD/YYYY"
                                                emptyLabel="Select date"
                                                size="small"
                                                fullWidth
                                                value={formData.DrDecisionDate || null}
                                                onChange={(value) => onInputChange({ target: { name: 'DrDecisionDate', value } })}
                                                renderInput={(params) => <TextField label="Decision Date" fullWidth size="small" {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.DrRule}
                                            setSelectedOptions={setSelected}
                                            // selectedOption={formData.RequesterCompanyId}
                                            name='DrRule'
                                            data={{ label: "DR Rule", options: drRule.map((dt) => ({ label: dt.label, value: dt.id })) }}
                                        />
                                    </Grid>
                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.DrDecision}
                                            setSelectedOptions={setSelected}
                                            name='DrDecision'
                                            data={{ label: "DR Decision", options: drDecision.map((dt) => ({ label: dt.label, value: dt.id })) }}
                                        />

                                    </Grid>
                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.EquipmentAreaId}
                                            setSelectedOptions={setSelected}
                                            name='EquipmentAreaId'
                                            data={{ label: "Area", options: areaList.map((dt) => ({ label: dt.areaName, value: dt.pkArea })) }}
                                        />

                                    </Grid>

                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>

                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.EquipmentProcessId}
                                            setSelectedOptions={setSelected}
                                            name='EquipmentProcessName'
                                            data={{
                                                label: "Process", options: processList.map(({ pkProcess, processName }) =>
                                                    ({ value: processName, label: processName }))
                                            }}
                                        />
                                    </Grid>
                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.EquipmentSubProcessId}
                                            setSelectedOptions={setSelected}
                                            name='EquipmentSubProcessName'
                                            data={{
                                                label: "Subprocess", options: subProcessList.map(({ pkSubProcess, subProcessName }) =>
                                                    ({ value: subProcessName, label: subProcessName }))
                                            }}
                                        />

                                    </Grid>

                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.EquipmentFunctionId}
                                            setSelectedOptions={setSelected}
                                            name='EquipmentFunctionName'
                                            data={{
                                                label: "Function", options: functionList.map(({ pkFunction, functionName }) =>
                                                    ({ value: functionName, label: functionName }))
                                            }}
                                        />

                                    </Grid>

                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>


                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.EquipmentModelId}
                                            setSelectedOptions={setSelected}
                                            name='EquipmentModelName'
                                            data={{
                                                label: "Model", options: modelList.map(({ pkModel, modelName }) => ({ value: modelName, label: modelName }))
                                            }}
                                        />
                                    </Grid>
                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.EquipmentOemid}
                                            setSelectedOptions={setSelected}
                                            name='EquipmentOemid'
                                            data={{ label: "OEM", options: oemList.map(({ pkOem, oemName }) => ({ value: pkOem, label: oemName })) }}
                                        />
                                    </Grid>
                                    <Grid sx={{ pr: 4 }} item xs={12} sm={3}>
                                        <TextField
                                            id={'srno'}
                                            fullWidth
                                            onKeyDown={onKeyDown}
                                            size="small"
                                            label='Serial number'
                                            name='SerialNumber'
                                            value={formData.SerialNumber}
                                            inputProps={{ maxLength: 20 }}
                                            onChange={onInputChange}
                                        />
                                    </Grid>
                                    <Grid sx={{ pr: 4 }} item xs={12} sm={3}>
                                        <TextField
                                            id='SapP1ref'
                                            fullWidth
                                            size="small"
                                            label='SAP/P1 Ref'
                                            name='SapP1ref'
                                            value={formData.SapP1ref}
                                            inputProps={{ maxLength: 20 }}
                                            onChange={onInputChange}
                                        />
                                    </Grid>
                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>

                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.ConditionId}
                                            setSelectedOptions={setSelected}
                                            name='ConditionId'
                                            data={{ label: "Condition", options: conditions.map(({ pkCondition, conditionName }) => ({ value: pkCondition, label: conditionName })) }}
                                        />
                                    </Grid>
                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.EqReservedInMRFId}
                                            setSelectedOptions={setSelected}
                                            name='EqReservedInMRFId'
                                            data={{ label: "Reserved", options: yesNoOption.map(({ id, label }) => ({ value: id, label })) }}
                                        />
                                    </Grid>  <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.ScrapImmediately}
                                            setSelectedOptions={setSelected}
                                            name='ScrapImmediately'
                                            data={{ label: "Scrapped", options: [{ id: 'true', label: 'Yes' }, { id: 'false', label: 'No' }].map(({ id, label }) => ({ value: id, label })) }}
                                        />
                                    </Grid>  <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.IsRepairandReplacement}
                                            setSelectedOptions={setSelected}
                                            name='IsRepairandReplacement'
                                            data={{ label: "Repar and Replace", options: [{ id: 'true', label: 'Yes' }, { id: 'false', label: 'No' }].map(({ id, label }) => ({ value: id, label })) }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item p={4} sm={12}>
                                        <Box display="flex" justifyContent="flex-end">
                                        <Tooltip title="Maximum 3 smart filters can be added">
                                        <span>
                                            <Button
                                                variant="contained"
                                                startIcon={<SaveOutlinedIcon />}
                                                size="small"
                                                sx={{ mr: 1 }}
                                                disabled={!selectedChip && smartFilterListNew.length === 3}
                                                onClick={handleOpen}
                                            >
                                                {selectedChip ? 'Update filter' : 'Save as filter'}
                                            </Button>
                                            </span>
                                            </Tooltip>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                sx={{ mr: 2 }}
                                                onClick={handleOnClickClear}
                                            >
                                                Clear search
                                            </Button>
                                            <Button
                                                variant="contained"
                                                startIcon={<SearchOutlinedIcon />}
                                                size="small"
                                                onClick={() => handleOnClickSearch()}
                                            >
                                                Search
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>

                            </>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2' || expanded === 'all' || expanded === 'both'} onChange={handleChange('panel2')}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Typography>DRF In progress</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container sx={{ mt: 3 }}>
                                <Grid xs={12}>
                                    <Button variant="outlined" onClick={() => handleClick(eqDispSearchList.filter(({ stateId }) => stateId === 10), 'DRF_In_Progress.xlsx')}>
                                        Export
                                    </Button>
                                </Grid>
                                <Grid xs={12}>
                                    <div style={{
                                          width: drawerOpen ? `${window.innerWidth - 430}px` : `${window.innerWidth - 250}px`
                                    }}>
                                        <StyledTreeDataGrid
                                            columns={columns}
                                            scroll={{ x: 1000, y: 500 }}
                                            loading={iseqDispSearchListLoading}
                                            rows={eqDispSearchList.length ? getTreeData(eqDispSearchList.filter(({ stateId }) => stateId === 10)) : []}
                                            csvOptions={{
                                                fileName: 'Companies lists'
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3' || expanded === 'all' ||  expanded === 'both'} onChange={handleChange('panel3')}>
                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                            <Typography>DRF Completed</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container sx={{ mt: 3 }}>
                                <Grid xs={12}>
                                    <Button variant="outlined" onClick={() => handleClick(eqDispSearchList.filter(({ stateId }) => stateId !== 10), 'DRF_Completed.xlsx')}>
                                        Export
                                    </Button>
                                </Grid>
                                <Grid xs={12}>
                                    <div style={{
                                         width: drawerOpen ? `${window.innerWidth - 430}px` : `${window.innerWidth - 250}px`
                                    }}>
                                        <StyledTreeDataGrid
                                            columns={columns}
                                            scroll={{ x: 1000, y: 500 }}
                                            loading={iseqDispSearchListLoading}
                                            rows={eqDispSearchList.length ? getTreeData(eqDispSearchList.filter(({ stateId }) => stateId !== 10)) : []}
                                            csvOptions={{
                                                fileName: 'Companies lists'
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Box>
            : ''}
        {view === 'DETAILS_DR' ? <NewDisposalRequest drId={drId} setView={() => { setDrId(''); setView('main') }} needBack={true} /> : ''}
        {view === 'DETAILS_EQ' ? <TabPanel value={0} index={0}>
            <EquipmentTabs samid={eqId} setView={setView} />
        </TabPanel> : ""}
    </MainCard>
    </>
}