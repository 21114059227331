import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export default function AppTitle() {
    return <Stack direction="column">
        <Typography
            variant="h3"
            component="div"
            sx={{ flexGrow: 1 }}
        >
            PMI SAM DB
        </Typography>
        <Typography
            component="p"
            sx={{ flexGrow: 1 }}
        >
            Strategic Asset Management Database
        </Typography>
    </Stack>
}