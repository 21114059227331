import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, FormControlLabel } from '@mui/material';
import { getEquipmentByIdAction } from './state/actions';

export default function EquipmentDisposal({ samid }) {
    const dispatch = useDispatch();
    const { detailEquipment } = useSelector((state) => state.detailsEquipment);
    const [isReseved, setIsReseved] = useState(false);
    const [isDisposal, setIsDisposal] = useState(false);
    useEffect(() => {
        dispatch(getEquipmentByIdAction(samid));
    }, []);
    useEffect(() => {
        let isDisposal = !detailEquipment.equipmentForDisposalResponseDTO ? false : true
        setIsDisposal(isDisposal)
        let isReseved = !detailEquipment.equipmentForReservationResponseDTO ? false : true
        setIsReseved(isReseved)
    }, [detailEquipment]);
    return <>
        <Box sx={{ p: 3 }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ fontSize: '16px', fontWeight: '600', mb: 1 }}>Disposal information</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox checked={isDisposal} />}
                        label="For disposal"
                        labelPlacement="start"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{ fontSize: '16px', fontWeight: '600', mb: 1 }}>Reservation information</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox checked={isReseved} />}
                        label="Is Reserved"
                        labelPlacement="start"
                    />
                </Grid>
            </Grid>
        </Box>
    </>

}