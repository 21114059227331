import { API } from "api";

export const getAllControlSystemAPI = () => {
    return API.get("/api/controlsystem/getall?page=0&size=0")
                .then((res) => res);
};

export const createControlSystemAPI = (payload) => {
    return API.post("/api/controlsystem/add", payload)
                .then((res) => res);
}

export const updateControlSystemAPI = (id, payload) => {
    return API.put(`/api/controlsystem/update/${id}`, payload)
                .then((res) => res);
}

export const deleteControlSystemAPI = (id) => {
    return API.delete(`/api/controlsystem/delete/${id}`)
                .then((res) => res);
}