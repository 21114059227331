import { actionTypes } from "./actions";

export const initialState = {  
  securityCompanyCoordinatorList: [],
  coordinatorList: [],
  isSecurityCompanyCoordinatorLoading: false,
  addCoordinator:'',
  updateCoordinator:'',
  coordinatorData:{}
};
  export const securityCompanyCoordinatorReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SECURITY_COMPANY_COORDINATOR_REQUEST:
        return {
          ...state,
          isSecurityCompanyCoordinatorLoading: true,
        };
      case actionTypes.SECURITY_COMPANY_COORDINATOR_RECIEVE:
        return {
          ...state,
          securityCompanyCoordinatorList: action.payload.map((dt, index) => ({ ...dt, id: index })),
          isSecurityCompanyCoordinatorLoading: false,
        };
       
      case actionTypes.SECURITY_COMPANY_COORDINATOR_FAIL:
        return {
          ...state,        
          isSecurityCompanyCoordinatorLoading: false
        };
      case actionTypes.SECURITY_COORDINATOR_REQUEST:
        return {
          ...state,
        };
      case actionTypes.SECURITY_COORDINATOR_RECEIVE:
        return {
          ...state,
          coordinatorList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        };
        
      case actionTypes.SECURITY_COORDINATOR_FAILED:
        return {
          ...state,        
        };
      case actionTypes.CREATE_COORDINATOR_PENDING:
        return {
          ...state,
        };
      case actionTypes.CREATE_COORDINATOR_RECEIVED:
        return {
          ...state,
          addCoordinator: action.payload,
        };
      case actionTypes.CREATE_COORDINATOR_FAILED:
        return {
          ...state,        
        };
        case actionTypes.UPDATE_COORDINATOR_PENDING:
          return {
            ...state,
          };
        case actionTypes.UPDATE_COORDINATOR_RECEIVED:
          return {
            ...state,
            updateCoordinator: action.payload,
          };
        case actionTypes.UPDATE_COORDINATOR_FAILED:
          return {
            ...state,        
          };
          case actionTypes.GET_COORDINATOR_PENDING:
            return {
              ...state,
            };
          case actionTypes.GET_COORDINATOR_RECEIVED:
            return {
              ...state,
              coordinatorData: action.payload,
            };
          case actionTypes.GET_COORDINATOR_FAILED:
            return {
              ...state,        
            };
      case 'CLEAR_COORDINATOR':
        return {
          ...state,
          securityCompanyCoordinatorList :[]
        };
      default:
        return state;
    }
  };

