import { equipmentAPI } from "api";

export const getAllEquipmentTypeAPI = () => {
    return equipmentAPI.get("/api/equipmenttype/getall?page=1&size=1000")
                .then((res) => res);
};
export const createEquipmentTypeAPI = (payload) => {
    return equipmentAPI.post("/api/equipmenttype/add", payload)
                .then((res) => res);
}

export const updateEquipmentTypeAPI = (id, payload) => {
    return equipmentAPI.put(`/api/equipmenttype/update/${id}`, payload)
                .then((res) => res);
}
export const deleteEquipmentTypeAPI = (id) => {
    return equipmentAPI.delete(`/api/equipmenttype/delete/${id}`)
                .then((res) => res);
}

export const getAllEqmtDynamicFormAPI = (id) => {
    return equipmentAPI.post("api/equipmenttype/geteqmtdynamicforms?EqmtType="+id)
                .then((res) => res);
};

export const AddEquipmentDtlsAPI = (payload,EqmtID) => {
    return equipmentAPI.post("api/machinedetail/AddMainDatabyEquipmentID?sEqDtl="+JSON.stringify(payload)+"&EqmtID="+EqmtID)
                .then((res) => res);
};

export const GetAllEquipmentMainDataDtlsAPI = (EqmtID) => {
    return equipmentAPI.post("api/machinedetail/getallbyeqmtid?StrserviceRequest="+EqmtID+"&opt=eqmttyp")
                .then((res) => res);
};

export const deleteMachineTypeAPI = (id) => {
    return equipmentAPI.delete(`/api/machinedetail/delete/${id}`)
                .then((res) => res);
}

export const GetAllEquipmentRowDtlAPI = (rowid) => {
    return equipmentAPI.post(`api/machinedetail/getallbyeqmtidbyrow/${rowid}`)
                .then((res) => res);
};

export const UpdateEquipmentRowDtlAPI = (rowid,payload) => {
    return equipmentAPI.post("api/machinedetail/UpdateMainDatabyRowID/"+rowid+"?sEqDtl="+ JSON.stringify(payload))  
                .then((res) => res);
};
 


