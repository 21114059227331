import { actionTypes } from "./actions";

export const initialState = {  
 equipmentActionList: []
};

  export const equipmentActionReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.EQUIPMENT_ACTION_REQUEST:
        return {
          ...state,
          isEquipmentActionLoading: true,
        };
      case actionTypes.EQUIPMENT_ACTION_RECEIVE:
        return {
          ...state,
          equipmentActionList: action.payload.map((dt, index) => ({ ...dt, id: index })),
          isEquipmentActionLoading: false,
        };
      case actionTypes.EQUIPMENT_ACTION_FAIL:
        return {
          ...state,        
          isEquipmentActionLoading: false
        };
      default:
        return state;
    }
  };