import { actionTypes } from "./actions";

export const initialState = {
  conditions: [],
  conditionLoading: false,
  activeConditions: []
};

// ===========================|| COMMON REDUCER ||=========================== //

const conditionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CONDITION_PENDING:
      return {
        ...state,
        conditionLoading: true,
      };
      case actionTypes.CONDITION_RECEIVED:
      return {
        ...state,
        conditions: action.payload.map((dt, index)=>({...dt, id:index})),
        activeConditions: action.payload.map((dt, index)=>({label:dt.conditionName, value: dt.pkCondition})),
        conditionLoading: false,
      };
      case actionTypes.CONDITION_FAILED:
      return {
        ...state,
        isConditionFail: false,
        conditionLoading: false
      };
    default:
      return state;
  }
};

export default conditionReducer;
