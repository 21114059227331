import { getCompanyCoordinatorListAPI, getAllCompanyCoordinatorListAPI} from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    COMPANY_COORDINATOR_REQUEST: "COMPANY_COORDINATOR_REQUEST",
    COMPANY_COORDINATOR_RECEIVE: "COMPANY_COORDINATOR_RECEIVE",
    COMPANY_COORDINATOR_FAIL: "COMPANY_COORDINATOR_FAIL",
    ALL_COMPANY_COORDINATOR_REQUEST: "ALL_COMPANY_COORDINATOR_REQUEST",
    ALL_COMPANY_COORDINATOR_RECEIVE: "ALL_COMPANY_COORDINATOR_RECEIVE",
    ALL_COMPANY_COORDINATOR_FAIL: "ALL_COMPANY_COORDINATOR_FAIL"
};

const requestCompanyCoordinator = () => ({
    type: actionTypes.COMPANY_COORDINATOR_REQUEST,
});
const receiveCompanyCoordinator= (payload) => ({
    type: actionTypes.COMPANY_COORDINATOR_RECEIVE,
    payload,
});
const failureCompanyCoordinator = (payload) => ({
    type: actionTypes.COMPANY_COORDINATOR_FAIL,
    payload,
});
const allrequestCompanyCoordinator = () => ({
    type: actionTypes.ALL_COMPANY_COORDINATOR_REQUEST,
});
const allreceiveCompanyCoordinator= (payload) => ({
    type: actionTypes.ALL_COMPANY_COORDINATOR_RECEIVE,
    payload,
});
const allfailureCompanyCoordinator = (payload) => ({
    type: actionTypes.ALL_COMPANY_COORDINATOR_FAIL,
    payload,
});

export const getCompanyCoordinatorAction = (payload, companyList, activeCountry, regions) => {   
    return async (dispatch) => {
        dispatch(requestCompanyCoordinator());
        try {
            const response = await getCompanyCoordinatorListAPI(payload);            
            if (response?.status === 200) {
                const customArray = [];
                response?.data?.data?.forEach(element => {
                    const companyID = companyList.find(({pkCompany})=>pkCompany===element.fkCompany)
                    const regionID = regions.find(({pkRegion})=>pkRegion===companyID?.fkRegion)
                    const countyrID = activeCountry.find(({pkCountry})=>pkCountry===companyID?.fkCountry)
                    element.companyCity = companyID?.companyCity;
                    element.regionName = regionID?.regionName;
                    element.countryName = countyrID?.countryName;
                    element.longCompanyName = companyID?.longCompanyName;
                    customArray.push(element);
                });
                dispatch(receiveCompanyCoordinator(customArray));
            }
        } catch (error) {
            dispatch(failureCompanyCoordinator(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage?error?.response?.data?.errorMessage:error?.response?.data?.title));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getCoordinatorAction = () => {
    return async (dispatch) => {
        dispatch(allrequestCompanyCoordinator());
        try {
            const response = await getAllCompanyCoordinatorListAPI();
            if (response?.status === 200) {
                dispatch(allreceiveCompanyCoordinator(response?.data?.data));
            }
        } catch (error) {
            dispatch(allfailureCompanyCoordinator(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};