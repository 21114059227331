import { API } from "api";

export const getFunctionalLocationListAPI = (id) => {
    return API.get(`api/functionallocation/getallbyref?FkCompany=${id}&page=0&size=0`)
                .then((res) => res);
};
export const getCompanyListAPI = (req) => {
    return API.get("api/company/getallactive?sortColumnName=LongCompanyName&sortOrder=1")
        .then((res) => res);
};
export const createFunctionalLocationsAPI = (payload) => {
    return API.post("/api/functionallocation/add", payload)
                .then((res) => res);
};
export const updateFunctionalLocationAPI = (id, payload) => {
    return API.put(`/api/functionallocation/update/${id}`, payload)
                .then((res) => res);
};
export const deleteFunctionalLocationAPI = (id) => {
    return API.delete(`/api/functionallocation/delete/${id}`)
                .then((res) => res);
};
export const getAllByFunctionallocationAPI = (id) => {
    return API.get(`api/pfadetail/getallbyfunctionallocation/${id}`)
    .then((res) => res
    );
};
export const updateAPI = (id,payload) => {
    return API.put(`/api/pfa/updateforfunctionallocation/${id}`, payload).then((res) => res);
};
export const getAllProductFormatTypesAPI = (id) => {
    return API.get(`/api/productformattypevalue/getallactive?page=0&size=0`)
    .then((res) => res);
};
export const deleteFLProductFormatAPI = (id) => {
    return API.delete(`/api/pfa/delete/functionallocation/${id}`)
                .then((res) => res);
};
