import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { Popper } from '@mui/material/index';
import { Paper } from '@mui/material/index';
import { Checkbox, FormControlLabel, Autocomplete, FormGroup, FormLabel, FormControl } from '@mui/material/index';
import { Typography } from '@mui/material/index';
import SaveIcon from "@mui/icons-material/Save";
import { Button, useMediaQuery } from "@mui/material";
import { Grid } from '@mui/material/index';
import { useFormik } from 'formik';
import * as yup from "yup";
import { createFilterOptions } from '@mui/material/Autocomplete';

export function createYupSchema(schema, config) {
    const { id, validationType, validations = [] } = config;
    if (!yup[validationType]) {
        return schema;
    }
    let validator = yup[validationType]();
    validations.forEach(validation => {
        const { params, type } = validation;
        if (!validator[type]) {
            return;
        }
        validator = validator[type](...params).nullable();
    });
    schema[id] = validator;
    return schema;
}

export default function AddEditPopperWithFormik({ popperState, onSubmit, onClosePopper, upLiftOnChange = () => { } }) {
    const theme = useTheme();
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const { open, placement, inputForm = { form: [] }, state = {} } = popperState;
    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 500,
      });

    const initialValues = {};
    inputForm.form.forEach(item => {
        initialValues[item.id] = (state[item.id] || state[item.id] === false) ? state[item.id] : "";
    });

    const yepSchema = inputForm.form.reduce(createYupSchema, {});
    const validateSchema = yup.object().shape(yepSchema);

    const formik = useFormik({
        initialValues: { ...initialValues },
        enableReinitialize: true,
        validationSchema: validateSchema,
        onSubmit: (values) => {
            onSubmit(values);
        },
    });

    const getDependingOptions = (options, id, dId) =>{  
      return formik?.values[dId] ? options.filter((ad)=> ad[dId] === formik.values[dId].id): [];
    }

    return <Box sx={{ position: 'relative' }}><Popper
        open={open}
        key={placement}
        modifiers={{
            offset: {
                enabled: true,
                offset: '0, 300px'
            }
        }}
        anchorEl={placement}
        placement={matchDownSm ? "bottom" : "bottom-start"} >
        <Paper sx={{ ...(matchDownSm ? { width: '273px', margin: '0 80px' } : { minWidth: '500px', margin: '0 80px' }) }} elevation={3} style={{ padding: '1rem', maxWidth: "50rem" }}>
            <Typography sx={{ m: 1 }} variant="h5">{inputForm.header}</Typography>
            <Grid container spacing={1}>{
                inputForm.form.map((crr) => {
                    switch (crr.type) {
                        case 'text':
                            return <Grid item xs={12} sm={4}>
                                <TextField
                                    id={crr.id}
                                    fullWidth
                                    multiline={crr.multiline || false}
                                    size="small"
                                    label={crr.label}
                                    name={crr.id}
                                    value={formik.values[crr.id]}
                                    onChange={formik.handleChange}
                                    error={formik.touched[crr.id] && Boolean(formik.errors[crr.id])}
                                    helperText={formik.touched[crr.id] && formik.errors[crr.id]}
                                    inputProps={{ ...crr.other }}
                                    disabled={crr.disabled}
                                />
                            </Grid>

                        case 'checkbox':
                            return <Grid item xs={12} sm={4}><FormControlLabel control={
                                <Checkbox
                                    checked={Boolean(formik.values[crr.id])}
                                    name={crr.id}
                                    id={crr.id}
                                    defaultSelected
                                    value={Boolean(formik.values[crr.id])}
                                    onChange={formik.handleChange}
                                    error={formik.touched[crr.id] && Boolean(formik.errors[crr.id])}
                                    helperText={formik.touched[crr.id] && formik.errors[crr.id]}
                                    size="small"
                                />
                            } label={crr.label} />
                            </Grid>
                        case 'checkboxgroup':
                            return <Grid item xs={12} sm={4}>
                                <FormControl sx={{ m: 3, width: "35rem" }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">{crr.label}</FormLabel>
                                    <FormGroup row={true}>
                                        {crr.options.map((option) => {
                                            return <FormControlLabel
                                                control={<Checkbox checked={option} name={option} />}
                                                label={option}
                                            />
                                        })}
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        case 'select':
                            return <Grid item xs={12} sm={4}>
                                <Autocomplete
                                    disablePortal
                                    size="small"
                                    id={crr.id}
                                    filterOptions={filterOptions}
                                    disabled={crr.disabled}
                                    name={crr.id}
                                    value={formik.values?.[crr.id] ?? ""}
                                    options={crr.dependsOn ?
                                        getDependingOptions(crr.options, crr.id, crr.dependsOn) :
                                        crr.options ? crr.options : [] }
                                    onChange={(event, newValue) => {
                                        if (crr.hasChild) {
                                          crr.childDep.forEach((next)=>{
                                            formik.setFieldValue(next,'')
                                          })  
                                        };
                                        formik.handleChange({
                                            target: { name: crr.id, value: newValue }
                                        })
                                    }
                                    }
                                    error={formik.touched[crr.id] && Boolean(formik.errors[crr.id])}
                                    helperText={formik.touched[crr.id] && formik.errors[crr.id]}
                                    renderInput={(params) => <TextField
                                        error={formik.touched[crr.id] && Boolean(formik.errors[crr.id])}
                                        helperText={formik.touched[crr.id] && formik.errors[crr.id]}
                                        maxWidth="20px" {...params} label={crr.label ?? ""} />}
                                />
                            </Grid>
                        default:
                            return '';
                    }
                })}
            </Grid>

            <Grid container>
                <Grid item pt={3} sm={12}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            size="small"
                            variant="outlined"
                            sx={{ "marginRight": "1rem" }}
                            onClick={onClosePopper}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<SaveIcon />}
                            size="small"
                            onClick={formik.handleSubmit}
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    </Popper>
    </Box>
}
