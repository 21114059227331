import { actionTypes } from "./actions";

export const initialState = {
    activeRuleGroup: [],
};

const ruleGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RULE_GROUP_GET_REQUEST:
      return {
        ...state,
        isRuleGroupLoading: true,
      };
    case actionTypes.RULE_GROUP_GET_RECEIVE:
      return {
        ...state,
        activeRuleGroup: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isRuleGroupLoading: false,
      };
    case actionTypes.RULEGROUP_GET_FAIL:
      return {
        ...state,
        isRuleGroupLoading: false,
       
      };
    default:
      return state;
  }
};

export default ruleGroupReducer;
