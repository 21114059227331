import { actionTypes } from "./actions";

export const initialState = {
  oemList: [],
  activeOemLists: [],
  oemLists:[],
  isOemLoading: true
};

// ===========================|| COMMON REDUCER ||=========================== //

const oemReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OEM_GET_REQUEST:
      return {
        ...state,
        isOemLoading: true,
      };
    case actionTypes.OEM_GET_RECEIVE:
      const gftf = action.payload.filter((v,i,a)=>a.findIndex(v2=>(v2.oemName===v.oemName))===i)
      return {
        ...state,
        oemList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        activeOemLists: gftf.map((dt, index) => ({label:dt.oemName, value: dt.pkOem})),
        oemListNoD: action.payload.map((dt, index) => ({ ...dt, id: index })),
        oemLists: action.payload.map((dt, index) => ({label:dt.oemName, value: dt.oemName})),
        isOemLoading: false,
      };
    case actionTypes.OEM_GET_FAIL:
      return {
        ...state,
        isOemFail: false,
        isOemLoading: false
      };
    default:
      return state;
  }
};

export default oemReducer;
