import React, { useEffect, useState } from 'react';
import { Grid, Link } from "@mui/material";
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment'

const AccordionSummaryRev = styled(AccordionSummary)({
    flexDirection: 'row-reverse',
    alignItems: "start",
    '& > div': { margin: '0 !important' }
});
const FormIntial = ({formType, initialInfo}) => {
    const [formExpanded, setFormExpanded] = React.useState(true);
    const [expanded, setExpanded] = React.useState(true);
    let {formInfo,requestorInfo}=initialInfo
    return <>
        {initialInfo.formInfo !== undefined?
            <Accordion expanded={formExpanded} onChange={() => setFormExpanded(!formExpanded)} sx={{ boxShadow: 'none' }}>
                    <AccordionSummaryRev
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Box sx={{ fontWeight: 600, display: 'block' }}>Form information</Box>
                            </Grid>
                        </Grid>
                    </AccordionSummaryRev>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={2}>
                                <Box>
                                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Current Step</Typography>
                                </Box>
                                <Box>{formInfo.stepName}</Box>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Box>
                                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Current State</Typography>
                                </Box>
                                <Box>{formInfo.stateName}</Box>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Box><Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Form Type</Typography></Box>
                                <Box>{formInfo.formType}</Box>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Box><Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Form #</Typography></Box>
                                <Box>{formInfo.pkForm !== undefined && formInfo.pkForm !==0? formInfo.pkForm :'NA'}</Box>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Box><Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Request Date</Typography></Box>
                                <Box>{ new Date(formInfo.requestDate).toLocaleString()}</Box>
                                
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Box><Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Last Update Date</Typography></Box>
                                <Box>{formInfo.lastUpdateDate === null? 'NA' : new Date(formInfo.lastUpdateDate+'Z').toLocaleString() }</Box>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
            </Accordion>
        :''}
        {initialInfo.requestorInfo !== undefined?
            <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={{ boxShadow: 'none' }}>
                    <AccordionSummaryRev
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Box sx={{ fontWeight: 600, display: 'block' }}>Requestor information</Box>
                            </Grid>
                        </Grid>
                    </AccordionSummaryRev>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Name</Typography>
                                </Box>
                                <Box>{requestorInfo.name}</Box>
                            </Grid>
                            <Grid item xs={12} sm={4} style={{paddingRight:2}}>
                                <Box>
                                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Email</Typography>
                                </Box>
                                <Box>{requestorInfo.email}</Box>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Box>
                                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Alias</Typography>
                                </Box>
                                <Box>{requestorInfo.alias}</Box>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Box>
                                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Phone Number</Typography>
                                </Box>
                                <Box>{requestorInfo.phone !==''? requestorInfo.phone :'-'}</Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ mt: 2 }}>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Title</Typography>
                                </Box>
                                <Box>{requestorInfo.title !==''? requestorInfo.title :'-'}</Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Department</Typography>
                                </Box>
                                <Box>{requestorInfo.department !==''? requestorInfo.department :'-'}</Box>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Box>
                                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Country</Typography>
                                </Box>
                                <Box>{requestorInfo.country !==''? requestorInfo.country :'-'}</Box>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Box>
                                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Company</Typography>
                                </Box>
                                <Box>{requestorInfo.company !==''? requestorInfo.company :'-'}</Box>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
            </Accordion>
        :''}
    </>
}
export default FormIntial