import { API } from "api";

export const getSmartFilterListAPI = () => {
    return API.get("api/smartfilter/getall?page=0&size=0")
                .then((res) => res);
};

export const createSmartFilterAPI = (payload) => {
    return API.post("/api/smartfilter/add", payload)
                .then((res) => res);
}
    
export const updateSmartFilterAPI = (id, payload) => {
    return API.put(`/api/smartfilter/update/${id}`, payload)
                .then((res) => res);
}
    
export const deleteSamartFilterAPI = (id) => {
    return API.delete(`/api/smartfilter/delete/${id}`)
                .then((res) => res);
}
