
// import { actionTypes } from "./actions";
import { getCompanyStatusListAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    CSTATUS_GET_REQUEST: "CSTATUS_GET_REQUEST",
    CSTATUS_GET_RECEIVE: "CSTATUS_GET_RECEIVE",
    CSTATUS_GET_FAIL: "CSTATUS_GET_FAIL"
  };

const requestCompSta= () => ({
    type: actionTypes.CSTATUS_GET_REQUEST,
});
const receiveCompSta = (payload) => ({
    type: actionTypes.CSTATUS_GET_RECEIVE,
    payload,
});
const failureCompSta = (payload) => ({
    type: actionTypes.CSTATUS_GET_FAIL,
    payload,
});
export const getCompanyStatusAction = () => {
    return async (dispatch) => {
        dispatch(requestCompSta());
        try {
            const response = await getCompanyStatusListAPI();
            if (response?.status === 200) {
                dispatch(receiveCompSta(response?.data?.data));
               // dispatch(actions.successAlert("Coutries fetched successfully"));
            }
        } catch (error) {
            dispatch(failureCompSta(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
