import { API } from "api";

export const getStatusAPI = () => { 
    let apiUrl = `api/status/getall?page=0&size=0`
    return API.get(apiUrl).then((res) => res);
}

export const createStatusAPI = (payload) => {
    return API.post("/api/status/add", payload)
        .then((res) => res);
}
export const updateStatusAPI = (payload,id) => {
    return API.put(`/api/status/update/${id}`, payload)
        .then((res) => res);
}

export const deleteStatusAPI = (id) => {
    return API.delete(`/api/status/delete/${id}`)
                .then((res) => res);
}