import { getRuleGroupConfigAPI,addRuleGroupConfigAPI, updateRuleGroupConfigAPI,deleteRuleGroupConfigAPI,getRuleGroupConfigByIDAPI,getEquipmentTypeConfigAPI,getRuleGroupConfigByEqmtIDAPI} from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    RULEGROUP_CONFIG_REQUEST: "RULEGROUP_CONFIG_REQUEST",
    RULEGROUP_CONFIG_RECEIVE: "RULEGROUP_CONFIG_RECEIVE",
    RULEGROUP_CONFIG_FAIL: "RULEGROUP_CONFIG_FAIL",
    RULEGROUP_CONFIG_LOADING: "RULEGROUP_CONFIG_LOADING",
    EQMTCONFIG_CONFIG_RECEIVE: "EQMTCONFIG_CONFIG_RECEIVE",
    EQMTCONFIG_CONFIG_REQUEST: "EQMTCONFIG_CONFIG_FAIL",
    EQMTCONFIG_CONFIG_LOADING: "EQMTCONFIG_CONFIG_LOADING"

  };

const requestRuleGroupConfig= () => ({
    type: actionTypes.RULEGROUP_CONFIG_REQUEST,
});
const receiveRuleGroupConfig = (payload) => ({
    type: actionTypes.RULEGROUP_CONFIG_RECEIVE,
    payload,
});
const failureRuleGroupConfig = (payload) => ({
    type: actionTypes.RULEGROUP_CONFIG_FAIL,
    payload,
});
const requestEqmtTypeConfig= () => ({
    type: actionTypes.EQMTCONFIG_CONFIG_REQUEST,
});
const receiveEqmtTypeConfig = (payload) => ({
    type: actionTypes.EQMTCONFIG_CONFIG_RECEIVE,
    payload,
});
const failureEqmtTypeConfig = (payload) => ({
    type: actionTypes.EQMTCONFIG_CONFIG_FAIL,
    payload,
});


export const getRuleGroupConfigAction = () => {
    return async (dispatch) => {
        dispatch(requestRuleGroupConfig());
        try {
            const response = await getRuleGroupConfigAPI();
            if (response?.status === 200) {
                dispatch(receiveRuleGroupConfig( response?.data?.data));
            }
        } catch (error) {
            dispatch(failureRuleGroupConfig(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getRuleGroupConfigByIDAction = (id) => {
    return async (dispatch) => {
        dispatch(requestRuleGroupConfig());
        try {
            const response = await getRuleGroupConfigByIDAPI(id);
            if (response?.status === 200) {
                dispatch(receiveRuleGroupConfig(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureRuleGroupConfig(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getRuleGroupConfigByEqmtIDAction = (id) => {
    return async (dispatch) => {
        dispatch(requestRuleGroupConfig());
        try {
            const response = await getRuleGroupConfigByEqmtIDAPI(id);
            if (response?.status === 200) {
                dispatch(receiveRuleGroupConfig(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureRuleGroupConfig(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getEquipmentTypeConfigByIDAction = (id) => {
    return async (dispatch) => {
        dispatch(requestEqmtTypeConfig());
        try {
            const response = await getEquipmentTypeConfigAPI(id);
            if (response?.status === 200) {
                dispatch(receiveEqmtTypeConfig(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureEqmtTypeConfig(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};



export const addRuleGroupConfigAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestRuleGroupConfig());
        try {
            const response = await addRuleGroupConfigAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Rule Group Configuration added successfully"));
            }
            dispatch(getRuleGroupConfigAction());
        } catch (error) {
            dispatch(failureRuleGroupConfig(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const updateRuleGroupConfigAction = (id,payload) => {
    return async (dispatch) => {
        dispatch(requestRuleGroupConfig());
        try {
            const response = await updateRuleGroupConfigAPI(id,payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Rule Group Configuration updated successfully"));
            }
            dispatch(getRuleGroupConfigAction());
        } catch (error) {
            dispatch(failureRuleGroupConfig(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const deleteRuleGroupConfigAction = (id) => {
    return async (dispatch) => {
        dispatch(requestRuleGroupConfig());
        try {
            const response = await deleteRuleGroupConfigAPI(id);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Rule Group Configuration deleted successfully"));
            }
            dispatch(getRuleGroupConfigAction());
        } catch (error) {
            dispatch(failureRuleGroupConfig(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};