import { API } from "api";

export const getAllCompaniesByCountryIdAPI = (payload) => {        
        return API.post("/api/company/getall?page=0&size=0", payload)
                    .then((res) => res);
}
export const getAllCountrytAPI = () => {        
        return API.get(`api/company/getallactive?page=0&size=0`)               
        .then((res) => res);
    }
    export const getAllcompanyforEquipment = () => {        
        return API.get(`api/company/getallforcreateequipment?page=0&size=0`)               
        .then((res) => res);
    }