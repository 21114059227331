import { getAllMakerPackerAPI, updateMakerPackerAPI, deleteMakerPackerAPI, createMakerPackerAPI, getMakerPackerEquipmentByIdAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    MAKERS_GET_REQUEST: "MAKERS_GET_REQUEST",
    MAKERS_GET_RECEIVE: "MAKERS_GET_RECEIVE",
    MAKERS_GET_FAIL: "MAKERS_GET_FAIL",
    MAKERS_STOP_LOADING: "MAKERS_STOP_LOADING"
  };

const requestMakers= () => ({
    type: actionTypes.MAKERS_GET_REQUEST,
});
const receiveMakers = (payload) => ({
    type: actionTypes.MAKERS_GET_RECEIVE,
    payload,
});
const failureMakers = (payload) => ({
    type: actionTypes.MAKERS_GET_FAIL,
    payload,
});
export const getMakerPackerAction = (filterdata) => {
    return async (dispatch) => {
        dispatch(requestMakers());
        try {
            const response = await getAllMakerPackerAPI(filterdata);
            if (response?.status === 200) {
                dispatch(receiveMakers( response?.data?.data));
            }
        } catch (error) {
            dispatch(failureMakers(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const updateMakerPackerAction = (id, payload, filterData) => {
    return async (dispatch) => {
        dispatch(requestMakers());
        try {
            const response = await updateMakerPackerAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Maker Packer updated successfully."));
                dispatch(getMakerPackerAction(filterData));
            }
        } catch (error) {
            dispatch(failureMakers(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const deleteMakerPackerAction = (id, filterData) => {
    return async (dispatch) => {
        dispatch(requestMakers());
        try {
            const response = await deleteMakerPackerAPI(id);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Maker Packer deleted successfully."));
            }
            dispatch(getMakerPackerAction(filterData));
        } catch (error) {
            dispatch(failureMakers(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const getMakerPackerEquipmentByIdAction = (id) => {
    return async (dispatch) => {
        dispatch(requestMakers());
        try {
            const response = await getMakerPackerEquipmentByIdAPI(id);
            if (response?.status === 200) {
                dispatch(actions.successAlert(""));
            }
        } catch (error) {
            dispatch(failureMakers(error?.response?.data?.data));
            if (error?.response) {
                console.log("getMakerPackerEquipmentByIdAction error: ", error?.response?.data?.errorMessage);
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert("Something went wrong, please contact administrator"));
            }
        }
    };
};
export const createMakerPackerAction = (payload, filterData) => {
    return async (dispatch) => {
        dispatch(requestMakers());
        try {
                                   
            if ((payload?.makerSAMID === null) && ((payload?.packerSAMID === null)))
            {
                dispatch({type: "MAKERS_STOP_LOADING"});
                dispatch(actions.errorAlert("Either Maker or Packer SAM ID is required to create a SAM Team Focus."));
            }
            else{
                const response = await createMakerPackerAPI(payload);
                if (response?.status === 200 || response?.status === 201) {
                    dispatch({type: "MAKERS_STOP_LOADING"});
                    dispatch(actions.successAlert("Maker Packer added successfully."));
                }
            }
            
            if(filterData && filterData.length)
                dispatch(getMakerPackerAction(filterData));
        } catch (error){
            dispatch(failureMakers(error?.response?.data?.data));
            
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};