import { API } from "api";

export const getAllAreaListAPI = () => {
    return API.get("api/area/getallactive?page=0&size=0")
        .then((res) => res);
};
export const getProcessListAPI = () => {
    return API.get("api/process/getallactive?page=0&size=0")
        .then((res) => res);
};
export const getSubprocessListAPI = () => {
    return API.get("api/subprocess/getallactive?page=0&size=0")
        .then((res) => res);
};
export const getFunctionListAPI = () => {
    return API.get("api/function/getallactive?page=0&size=0")
        .then((res) => res);
};
export const getFunctionProductFormatListAPI = (id) => {
    return API.get(`api/functionproductformat/getallbyref?FkFunction=${id}&page=0&size=0`)
        .then((res) => res);
};
export const deleteFunctionProductFormatValueAPI = (id) => {
    return API.delete(`/api/functionproductformat/delete/${id}`)
                .then((res) => res);
}
export const getAllProductFormatTypeAPI = () => {
    return API.delete(`/api/productformattype/getall?page=0&size=0`)
                .then((res) => res);
}
export const createFunctionProductFormatAPI = (payload) => {
    return API.post("/api/functionproductformat/add", payload)
                .then((res) => res);
}
export const updateFunctionProductFormatAPI = (id, payload) => {
    return API.put(`/api/functionproductformat/update/${id}`, payload)
                .then((res) => res);
}
