import { actionTypes } from "./actions";

export const initialState = {
  decodedToken : '',
  roles : []
};

// ===========================|| COMMON REDUCER ||=========================== //

const commonReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case actionTypes.TOKEN_PENDING:
      return {
        ...state,
        decodedToken: action.payload,
      };
    case actionTypes.TOKEN_RECEIVED:
      let pmiRoles = action.payload['custom:group'].split(',')
      let pmiRoleList = []
      pmiRoles.forEach(item=> {
        if(item.includes('[')){
          let newItem = item.replace('[','') 
          pmiRoleList.push(newItem.trim())
        }
        else if(item.includes(']')){
          let newItem = item.replace(']','') 
          pmiRoleList.push(newItem.trim())
        }
        else 
        pmiRoleList.push(item.trim())
      })
      let tmpRoleArry = []
      let appEnv = process.env.REACT_APP_ENV
      pmiRoleList.forEach((item)=>{
          if(appEnv ==='dev'){
            if(item === 'PMIGLOBALURSAMDBDATABASEREPAIRREPLACECOORDINATORDEVIGA')
              tmpRoleArry.push('rr')
            else if(item === 'PMIGLOBALURSAMDBDATABASECOORDINATORDEVIGA')
              tmpRoleArry.push('coordinator')
            else if(item === 'PMIGLOBALURSAMDBDATABASEREADERDEVIGA')
              tmpRoleArry.push('reader')
            else if(item === 'PMIGLOBALURSAMDBDATABASEDEVELOPERDEVIGA')
              tmpRoleArry.push('developer')
            else if(item === 'PMIGLOBALURSAMDBDATABASEADMINSTRATORSDEVIGA')
              tmpRoleArry.push('admin')
              else if(item === 'PMIGLOBALURSAMDBDSEQPOWNERDEVIGA')
              tmpRoleArry.push('dseqown')    
              else if(item === 'PMIGLOBALURSAMDBDSSYSOWNERDEVIGA')
              tmpRoleArry.push('dssysown')                 
              else if(item === 'PMIGLOBALURSAMDBDSUSERDEVIGA')
              tmpRoleArry.push('dsuser')                    
          }
          else if(appEnv ==='qa'){
            if(item === 'PMIGLOBALURSAMDBDATABASEREPAIRREPLACECOORDINATORQASIGA')
              tmpRoleArry.push('rr')
            else if(item === 'PMIGLOBALURSAMDBDATABASECOORDINATORQASIGA')
              tmpRoleArry.push('coordinator')
            else if(item === 'PMIGLOBALURSAMDBDATABASEREADERQASIGA')
              tmpRoleArry.push('reader')
            else if(item === 'PMIGLOBALURSAMDBDATABASEDEVELOPERQASIGA')
              tmpRoleArry.push('developer')
            else if(item === 'PMIGLOBALURSAMDBDATABASEADMINSTRATORSQASIGA')
              tmpRoleArry.push('admin')
              else if(item === 'PMIGLOBALURSAMDBDSEQPOWNERQAIGA')
              tmpRoleArry.push('dseqown')    
              else if(item === 'PMIGLOBALURSAMDBDSSYSOWNERQAIGA')
              tmpRoleArry.push('dssysown')                 
              else if(item === 'PMIGLOBALURSAMDBDSUSERQAIGA')
              tmpRoleArry.push('dsuser')                
          }
          else if(appEnv ==='prod'){
            if(item === 'PMIGLOBALURSAMDBDATABASEREPAIRREPLACECOORDINATORPRDIGA')
              tmpRoleArry.push('rr')
            else if(item === 'PMIGLOBALURSAMDBDATABASECOORDINATORPRDIGA')
              tmpRoleArry.push('coordinator')
            else if(item === 'PMIGLOBALURSAMDBDATABASEREADERPRDIGA')
              tmpRoleArry.push('reader')
            else if(item === 'PMIGLOBALURSAMDBDATABASEDEVELOPERPRDIGA')
              tmpRoleArry.push('developer')
            else if(item === 'PMIGLOBALURSAMDBDATABASEADMINSTRATORSPRDIGA')
              tmpRoleArry.push('admin')
              else if(item === 'PMIGLOBALURSAMDBDSEQPOWNERPRDIGA')
              tmpRoleArry.push('dseqown')    
              else if(item === 'PMIGLOBALURSAMDBDSSYSOWNERPRDIGA')
              tmpRoleArry.push('dssysown')                 
              else if(item === 'PMIGLOBALURSAMDBDSUSERPRDIGA')
              tmpRoleArry.push('dsuser')               
          }
      })
      return {
        ...state,
        decodedToken: action.payload,
        roles : [...tmpRoleArry]
      }

    case actionTypes.TOKEN_FAILED:
      return {
        ...state,
        decodedToken: action.payload,
        roles : [...tmpRoleArry]
      }     
    default:
      return state;
  }
};

export default commonReducer;
