import { useEffect, useState } from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
    Autocomplete, TextField, FormControlLabel, FormControl, InputLabel, MenuItem, Select, LinearProgress, Box,
    Checkbox, CircularProgress, Divider, Button, Link
} from "@mui/material/index";
import moment from 'moment'

import { getEqbyIdAction,hasChildrenAction, getChildrenAction } from '../../SearchViews/EquipmentByCompany/state/actions';
import { getAllActiveStatusAction } from "containers/SearchViews/state/actions";
import { getFlbyIdAction } from "./stateNew/actions";
import { getAllActiveCompanyAction } from "containers/Management/Companies/state/actions";
import { getAreaAction } from "containers/Management/FunctionalStructure/Area/state/actions";
import { getFunctionAction } from "containers/Management/FunctionalStructure/Function/state/actions";
import { getModelAction } from "containers/Management/Models/state/actions";
import { getAllConditionAction } from "containers/Management/Conditions/state/actions";
import { getOemAction } from 'containers/Management/OEM/state/actions';
import { getAllCurrencyAction } from "containers/Management/Currencies/state/actions";

function TableRows({ modelList, loading, currencies, conditionsList, rowsData, deleteTableRows, handleChange, onSamIdBlur,
    drRuleList, isEdit, handleScrapDecision, drDecisionList, drFinalStatusList, formDisabled,handlekeyPress,
    isDisableDrRule, isError, stepId, handleScrapStatus, defaultCurrency,getChildren }) {

    const conOption = conditionsList.map(({ pkCondition, conditionName }) => ({ id: pkCondition, label: conditionName }));
    return (
        rowsData.map((data, index) => {
            const { condition, p1idNumber, serialNumber, pkEquipment, fkNetBookValueCurrency,
                inSilentStoreSince, equipmentComment, scrapImmediately, availability, netBookValueValidity,
                location, company, status, area, functionVal, model, netBookValue, drDecisionDate, recoverParts,retrievePart,
                drDecision, modelName, oemName, drRule, drDecisionBy, drFinalStatus, finalLevel, decesionLevel, mrfNumber, 
                isRepairandReplacement, showChildren } = data;

            const netBookValueValidityNew = netBookValueValidity && typeof netBookValueValidity === 'string' && netBookValueValidity.indexOf('Z') === -1 ? netBookValueValidity + 'Z' : netBookValueValidity
            const inSilentStoreSinceNew = inSilentStoreSince && typeof inSilentStoreSince === 'string' && inSilentStoreSince.indexOf('Z') === -1 ? inSilentStoreSince + 'Z' : inSilentStoreSince
            let isRr = false;
            
            if (!isRepairandReplacement && modelName) {
                isRr = modelName && modelList.length ? modelList.find((a) => modelName === a.modelName).isRepairandReplacement : false
            }
            return (
                <tr valine="top" style={{ borderBottom: '1px solid #aab2b34f', backgroundColor: '#eaf2f3f0', width: '100%' }} key={index}>
                    <td className="align-top" style={{ width: '5%' }}>{index !== 0 && isEdit ? <button className="btn btn-outline-danger btn-sm " onClick={() => (deleteTableRows(index))}>x</button> : ''}</td>
                    <td className="align-top" >
                        {!isEdit ?
                            <TextField
                                id={'pkEquipment'}
                                fullWidth
                                size="small"
                                disabled={true}
                                label=""
                                name="pkEquipment"
                                value={pkEquipment}
                                error={false}
                            />
                            :
                            loading ? <Box sx={{ display: 'flex' }}>
                                <CircularProgress />
                            </Box>
                                :
                                <>
                                    <TextField
                                        id={'pkEquipment'}
                                        fullWidth
                                        size="small"
                                        label=""
                                        name="pkEquipment"
                                        value={pkEquipment}
                                        onBlur={onSamIdBlur}
                                        onChange={(evnt) => (handleChange(index, evnt))}
                                        error={false}
                                        onKeyUp={(event) => handlekeyPress(event)}
                                    />
                                    {showChildren?
                                        <Link onClick={()=>getChildren(pkEquipment,index)}  component="button"
                                            variant="body2" style={{fontSize:'16px'}}> Add children</Link>
                                        :''
                                    }
                                </>
                                
                        }
                    </td>
                    <td className="align-top" style={{ width: '12%' }}>
                        <label>{location} &nbsp;{company} &nbsp;{status}</label>
                    </td>
                    <td className="align-top" style={{ width: '12%' }}>
                        <label>{area}</label>
                        <label>{functionVal}</label>
                        <label>{oemName} &nbsp; {model}</label>
                        {
                            (isRepairandReplacement || isRr) ?
                                <label style={{ color: 'red' }}>This machine is part of the R&R program</label> : ''}
                    </td>
                    <td className="align-top" style={{ width: '12%' }}>
                        <label style={{ height: '47px' }}>{serialNumber}  </label>
                        <div style={{ display: 'flex' }}>
                            <TextField
                                id={'p1idNumber'}
                                fullWidth
                                size="small"
                                label="SAP/P1 Ref"
                                name="p1idNumber"
                                InputLabelProps={{ shrink: true }}
                                value={p1idNumber}
                                onChange={(evnt) => (handleChange(index, evnt))}
                                disabled={formDisabled}
                            />
                            {((p1idNumber === undefined || p1idNumber === '') && isError) ? <span style={{ color: 'red' }}>*</span> : ''}
                        </div>
                    </td> <td className="align-top" style={{ width: '12%' }}>
                        <div style={{ display: 'flex' }}>
                            <Autocomplete
                                disablePortal
                                size="small"
                                fullWidth
                                id="condition"
                                onChange={(e, value) => handleChange(index, { target: { name: 'condition', value } })}
                                name='condition'
                                value={condition ? { id: condition.id, label: condition.label } : null}
                                options={conOption}
                                renderInput={(params) => <TextField label="Condition" {...params} />}
                                disabled={formDisabled}
                            />
                            {(!condition && isError) ? <span style={{ color: 'red' }}>*</span> : ''}
                        </div>
                        <div style={{ display: 'flex' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    label="Date"
                                    inputFormat="MM/DD/YYYY"
                                    size="small"
                                    fullWidth
                                    value={(inSilentStoreSinceNew === null || inSilentStoreSinceNew === '' || inSilentStoreSinceNew === undefined) ? null : dayjs(inSilentStoreSinceNew)}
                                    onChange={(value) => handleChange(index, { target: { name: 'inSilentStoreSince', value } })}
                                    renderInput={(params) => <TextField sx={{ mt: 2 }} label="Date" fullWidth size="small" {...params} />}
                                    disabled={formDisabled}
                                />
                            </LocalizationProvider>
                        </div>
                    </td>
                    <td className="align-top" style={{ width: '12%' }}>
                        <div style={{ display: 'flex' }}>
                            <TextField
                                id='netBookValueId'
                                fullWidth
                                size="small"
                                label="Net book value"
                                name="netBookValue"
                                value={netBookValue}
                                onChange={(evnt) => (handleChange(index, evnt))}
                                disabled={formDisabled}
                            />
                            {(!netBookValue && isError) ? <span style={{ color: 'red' }}>*</span> : ''}
                            {((/^[0-9\b]+$/).test(netBookValue))?'':(!netBookValue ?" " :(<span style={{ color: 'red', fontSize:'11px', paddingLeft:'6px' }} >Please enter intergers only</span>))
                            }
                            
                        </div>
                        <Autocomplete
                            disablePortal
                            size="small"
                            fullWidth
                            sx={{ mt: 1 }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            id="fkNetBookValueCurrency"
                            onChange={(e, value) => handleChange(index, { target: { name: 'fkNetBookValueCurrency', value } })}
                            name='fkNetBookValueCurrency'
                            value={fkNetBookValueCurrency ? { id: fkNetBookValueCurrency.id, label: fkNetBookValueCurrency.label } : { id: defaultCurrency?.id, label: defaultCurrency?.currencyName }}
                            options={currencies.map(({ pkCurrency, currencyName }) => ({ id: pkCurrency, label: currencyName }))}
                            renderInput={(params) => <TextField label="Currency" {...params} />}
                            disabled={formDisabled}
                        />
                        <div style={{ display: 'flex' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    label="Date"
                                    inputFormat="MM/DD/YYYY"
                                    size="small"
                                    fullWidth
                                    value={(netBookValueValidityNew === null || netBookValueValidityNew === '' || netBookValueValidityNew === undefined) ? null : dayjs(netBookValueValidityNew)}
                                    onChange={(value) => handleChange(index, { target: { name: 'netBookValueValidity', value } })}
                                    renderInput={(params) => <TextField sx={{ mt: 2 }}
                                        label="Date" fullWidth size="small" {...params} />}
                                    disabled={formDisabled}
                                />
                            </LocalizationProvider>
                            {(!netBookValueValidity && isError) ? <span style={{ color: 'red' }}>*</span> : ''}
                        </div>
                    </td>
                    <td className="align-top" style={{ width: '12%' }}>
                        <div style={{ display: 'flex' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    label="Date"
                                    inputFormat="MM/DD/YYYY"
                                    size="small"
                                    error={true}
                                    fullWidth
                                    value={(availability === null || availability === '' || availability === undefined) ? null : dayjs(availability)}
                                    onChange={(value) => handleChange(index, { target: { name: 'availability', value } })}
                                    renderInput={(params) => <TextField
                                        sx={{ mt: 2 }} label="Date"
                                        error={true}
                                        fullWidth size="small" {...params} />}
                                    disabled={formDisabled}
                                />
                            </LocalizationProvider>
                            {(!availability && isError) ? <span style={{ color: 'red' }}>*</span> : ''}
                        </div>
                        <FormControlLabel labelPlacement="start"
                            control={
                                <Checkbox
                                    checked={scrapImmediately}
                                    name='scrapImmediately'
                                    id='scrapImmediately_id'
                                    onChange={(event) => {
                                        handleChange(index, event)
                                    }}
                                    size="small"
                                    disabled={formDisabled}
                                />
                            }
                            label={'Scrap immediately'} />
                    </td>
                    <td className="align-top" style={{ width: '12%' }}>
                        <TextField
                            id="comment-static"
                            label="Equipment Comment"
                            multiline
                            rows={4}
                            value={equipmentComment}
                            InputLabelProps={{ shrink: true }}
                            name="equipmentComment"
                            onChange={(evnt) => (handleChange(index, evnt))}
                            sx={{ width: '100%' }}
                            variant="outlined"
                            disabled={formDisabled}
                        />
                    </td>
                    {stepId >= 10 ?
                        <>
                            <td>
                                <div>
                                    <div style={{ display: 'flex' }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="drRuleList-label">{drRuleList.value}</InputLabel>
                                            <Select
                                                labelId="drRuleList-label"
                                                id="drRule"
                                                name="drRule"
                                                value={(drRule === null || drRule === undefined || drRule.trim() === '') ? 'No rule' : drRule.trim()}
                                                label={drRuleList.value}
                                                onChange={(evnt) => (handleChange(index, evnt))}
                                                disabled={formDisabled || isDisableDrRule}
                                            >
                                                {drRuleList.map((a, index) => <MenuItem key={index} value={a.value}>{a.value}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                        {isError && (drRule === 'No rule' || drRule === null) ? <span style={{ color: 'red' }}>*</span> : ''}
                                    </div>
                                    {mrfNumber !== null || mrfNumber !== '' ? <div>{mrfNumber}</div> : ''}
                                    {recoverParts !== undefined ?
                                        <>
                                            <div>-</div>
                                            <div>
                                                <FormControlLabel labelPlacement="start"
                                                    control={
                                                        <Checkbox
                                                            checked={recoverParts}
                                                            name='recoverParts'
                                                            id='recoverPart'
                                                            onChange={(event) => {
                                                                handleChange(index, event)
                                                            }}
                                                            size="small"
                                                            disabled={formDisabled}
                                                        />
                                                    }
                                                    label={'Recover Parts'} />
                                            </div>
                                        </>
                                        : ''
                                    }
                                </div>
                            </td>
                            <td>
                                <div>
                                    <FormControl size="small" sm={{ width: '100%' }} fullWidth>
                                        <InputLabel id="drDecisionList-label">{drDecisionList.value}</InputLabel>
                                        <Select
                                            labelId="drDecisionList-label"
                                            id="drDecisions"
                                            name="drDecision"
                                            value={(drDecision === undefined || drDecision === null || drDecision.trim() === '') ? '' : drDecision.trim()}
                                            label={drDecisionList.value}
                                            disabled={true}
                                        >
                                            {drDecisionList.map((a, index) => <MenuItem key={index} value={a.value}>{a.value}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    {decesionLevel !== '' && stepId >= 20 ?
                                        <Button onClick={() => handleScrapDecision(index)} variant="outlined">{decesionLevel}</Button>
                                        : ''
                                    }
                                    <div style={{ display: 'flex' }}>
                                        <TextField
                                            id={'drDecisionDate'}
                                            fullWidth
                                            size="small"
                                            sx={{ mt: 1 }}
                                            disabled={true}
                                            label=""
                                            name="drDecisionDate"
                                            value={(drDecisionDate !== '' && drDecisionDate !== null) ? moment(drDecisionDate).format('DD/MM/YYYY') : ''}
                                            error={false}
                                        />
                                        {isError && (drDecisionDate === '' || drDecisionDate === null) ? <span style={{ color: 'red' }}>*</span> : ''}
                                    </div>
                                    <span>{drDecisionBy}</span>
                                </div>
                            </td>
                            {stepId >= 20 ?
                                <td>
                                    <div>
                                        <div style={{ display: 'flex' }}>
                                            <FormControl size="small" sm={{ width: '100%' }} fullWidth>
                                                <InputLabel id="drFinalStatus-label">{drFinalStatusList.value}</InputLabel>
                                                <Select
                                                    labelId="drFinalStatus-label"
                                                    id="drFinalStatus"
                                                    name="drFinalStatus"
                                                    value={(drFinalStatus === undefined || drFinalStatus === null || drFinalStatus.trim() === '') ? '' : drFinalStatus.trim()}
                                                    label={drFinalStatusList.value}
                                                    onChange={(evnt) => (handleChange(index, evnt))}
                                                    disabled={true}
                                                >
                                                    {drFinalStatusList.map((a, index) => <MenuItem key={index} value={a.value}>{a.value}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                            {isError && (drFinalStatus === '' || drFinalStatus === null) ? <span style={{ color: 'red' }}>*</span> : ''}
                                        </div>

                                        {finalLevel !== '' && stepId === 20 ?
                                            <Button onClick={() => handleScrapStatus(index)} variant="outlined">{finalLevel}</Button>
                                            : ''
                                        }
                                    {recoverParts !== undefined  && recoverParts ===true && retrievePart !== undefined ?
                                        <>
                                            <div>-</div>
                                            <div>
                                                <FormControlLabel labelPlacement="start"
                                                    control={
                                                        <Checkbox
                                                           checked={retrievePart}
                                                            name='retrievePart'
                                                            id='RetrieveParts'
                                                            onChange={(event) => {
                                                                handleChange(index, event)
                                                            }}
                                                            size="small"
                                                            disabled={formDisabled}
                                                        />
                                                    }
                                                    label={'Part Retrieve'} />
                                            </div>
                                        </>
                                        : ''
                                    }
                                    </div>
                                </td>
                                : ''}
                        </>
                        : ''}
                </tr>
            )
        })
    )
}

function DynamicTable({ rowsData, setRowsData, isEdit, formDisabled, isDisableDrRule, isError, stepId, setIsError }) {
    const { equipmentWithId = {}, getEqLoading,hasChildren, checkChildrenLoading,isChildrenLoading,childEquipmentList=[] } = useSelector((state) => state.equipment);
    const { flByIdList = {}, isflByIDLoading } = useSelector((state) => state.flByID);
    const { companyList = [] } = useSelector((state) => state.company);
    const { activeStatusList = [] } = useSelector((state) => state.common);
    const { areaList = [], isAreaLoading } = useSelector((state) => state.area);
    const { functionListNoD :functionList = [] } = useSelector((state) => state.function);
    const { modelListNoD: modelList = [] } = useSelector((state) => state.model);
    const { oemList = [], isOemLoading } = useSelector((state) => state.oem);
    const { conditions = [], conditionLoading } = useSelector((state) => state.conditions);
    const { currencies = [], currencyLoading } = useSelector((state) => state.currency);
    const { decodedToken } = useSelector((state) => state.token);
    const menu = useSelector((state) => state.common);

    const { drawerOpen } = menu;
    const defaultCurrency = currencies.find((item) => item.pkCurrency === 'USD')
    const drRuleList = [
        { value: 'No rule' },
        { value: 'Cancel' },
        { value: 'Scrap Immediately' },
        { value: '3 Months Publishing' }
    ]

    const drDecisionList = [
        { value: '' },
        { value: 'Scrap' },
        { value: 'Cancelled' },
        { value: 'Reserved' },
    ]

    const drFinalStatusList = [
        { value: '' },
        { value: 'Scrapped' },
        { value: 'Cancelled' },
        { value: 'Transfrred' },
    ]

    useEffect(() => {
        dispatch(getAllActiveCompanyAction());
        dispatch(getAllActiveStatusAction());
        dispatch(getAreaAction());
        dispatch(getAllConditionAction());
        dispatch(getAllCurrencyAction());
        dispatch(getFunctionAction());
        dispatch(getModelAction());
        dispatch(getOemAction());
    }, [])

    useEffect(() => {
        if (equipmentWithId.fkFunctionalLocation && !getEqLoading) {
            dispatch(getFlbyIdAction(equipmentWithId.fkFunctionalLocation));
        }
    }, [equipmentWithId.fkFunctionalLocation, getEqLoading])

    useEffect(() => {
        if (equipmentWithId.pkEquipment && !getEqLoading && !isOemLoading && isEdit && !checkChildrenLoading) {
            const location = flByIdList.functionalLocationName;
            const dt = JSON.parse(JSON.stringify(rowsData));
            const { fkCondition, p1idNumber, serialNumber, pkEquipment, inSilentStoreSince,
                fkCompany, fkStatus, fkArea, fkFunction, fkModel, availability, netBookValueDate,
                fkNetBookValueCurrency, netBookValue, comment, fkOem } = equipmentWithId;

            const company = fkCompany && companyList.length ? companyList.find(({ pkCompany }) => fkCompany === pkCompany).companyName : "";
            const status = fkStatus && activeStatusList.length ? activeStatusList.find(({ value }) => value === fkStatus).label : "";
            const area = fkArea && areaList.length ? areaList.find(({ pkArea }) => pkArea === fkArea).areaName : "";
            const functionVal = fkFunction && functionList.length ? functionList.find(({ pkFunction }) => pkFunction === fkFunction)?.functionName : "";
            const model = fkModel && modelList.length ? modelList.find(({ pkModel }) => pkModel === fkModel).modelName : "";
            const oemName = fkOem && oemList.length ? oemList.find(({ pkOem }) => pkOem === fkOem).oemName : "";
            const isModelRR = fkModel && modelList.length ? modelList.find(({ pkModel }) => pkModel === fkModel).isRepairandReplacement : false
            const isRepairandReplacement = (isModelRR) ? true : false;
            const showChildren = hasChildren?true: false
            
            const condition = () => {
                let temp = conditions.find(({ pkCondition }) => pkCondition === fkCondition);
                return temp ? { id: temp.pkCondition, label: temp.conditionName } : '';
            }
            const curr = () => {
                let temp = currencies.find(({ pkCurrency }) => pkCurrency === fkNetBookValueCurrency);
                return temp ? { id: temp.pkCurrency, label: temp.currencyName } : '';
            }
            let mrfNumber = ''
            if (equipmentWithId.equipmentForReservationResponseDTO !== null)
                mrfNumber = equipmentWithId.equipmentForReservationResponseDTO.samMrfref !== undefined ? equipmentWithId.equipmentForReservationResponseDTO.samMrfref : ''

            const equipmentComment = comment !== null ? comment : ''           
            const netBookValueValidity = netBookValueDate
            if(dt[dt.length - 1].company==null)
            {
                dt[dt.length - 1] = {
                    equipmentComment, netBookValueValidity, availability, netBookValue,
                    fkNetBookValueCurrency: curr(), condition: condition(), inSilentStoreSince, mrfNumber, serialNumber,
                    location, pkEquipment, fkModel, company, status, area, functionVal, model, oemName, p1idNumber, 
                    isRepairandReplacement,hasChildren,showChildren
                };
                setRowsData([...dt]);
            }
        }
    }, [equipmentWithId.pkEquipment, getEqLoading, flByIdList.functionalLocationName, isOemLoading,checkChildrenLoading])

    useEffect(() => {
        if(childEquipmentList.length >0 && !isChildrenLoading){
            childEquipmentList.forEach((item)=>{
                dispatch(getFlbyIdAction(item.fkFunctionalLocation));
            })
        }
    }, [childEquipmentList, isChildrenLoading])

    useEffect(() => {
        if (childEquipmentList.length>0 && !isChildrenLoading) {
            let dt = [...rowsData]
            childEquipmentList.forEach((item)=>{
                const location = flByIdList.functionalLocationName;
                const { fkCondition, p1idNumber, serialNumber, pkEquipment, inSilentStoreSince,
                    fkCompany, fkStatus, fkArea, fkFunction, fkModel, availability, netBookValueDate,
                    fkNetBookValueCurrency, netBookValue, fkOem } = item;
                const company = fkCompany && companyList.length ? companyList.find(({ pkCompany }) => fkCompany === pkCompany).companyName : "";
                const status = fkStatus && activeStatusList.length ? activeStatusList.find(({ value }) => value === fkStatus).label : "";
                const area = fkArea && areaList.length ? areaList.find(({ pkArea }) => pkArea === fkArea).areaName : "";
                const functionVal = fkFunction && functionList.length ? functionList.find(({ pkFunction }) => pkFunction === fkFunction).functionName : "";
                const model = fkModel && modelList.length ? modelList.find(({ pkModel }) => pkModel === fkModel).modelName : "";
                const oemName = fkOem && oemList.length ? oemList.find(({ pkOem }) => pkOem === fkOem).oemName : "";
                const isModelRR = fkModel && modelList.length ? modelList.find(({ pkModel }) => pkModel === fkModel).isRepairandReplacement : false
                const isRepairandReplacement = (isModelRR) ? true : false;
                const showChildren = false
                const equipmentComment = `Child of equipment with SAM ID ${item.fkEquipment}`
                const condition = () => {
                    let temp = conditions.find(({ pkCondition }) => pkCondition === fkCondition);
                    return temp ? { id: temp.pkCondition, label: temp.conditionName } : '';
                }
                const curr = () => {
                    let temp = currencies.find(({ pkCurrency }) => pkCurrency === fkNetBookValueCurrency);
                    return temp ? { id: temp.pkCurrency, label: temp.currencyName } : '';
                }
                let mrfNumber = ''
                if (item.equipmentForReservationResponseDTO !== null)
                    mrfNumber = item.equipmentForReservationResponseDTO.samMrfref !== undefined ? item.equipmentForReservationResponseDTO.samMrfref : ''
    
                const netBookValueValidity = netBookValueDate
                let tmpObj = {
                    equipmentComment, netBookValueValidity, availability, netBookValue,
                    fkNetBookValueCurrency: curr(), condition: condition(), inSilentStoreSince, mrfNumber, serialNumber,
                    pkEquipment, location,fkModel, company, status, area, functionVal, model, oemName, p1idNumber, 
                    isRepairandReplacement,showChildren
                };
                dt.push(tmpObj)
            })
            setRowsData(dt);
        }

    }, [childEquipmentList, isChildrenLoading])


    const deleteTableRows = (index) => {
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
    }

    const addTableRows = () => {
        const rowsInput = {
            pkEquipment: '',
            emailAddress: '',
            salary: '',
            netBookValue: '',
            location: ''
        }
        setRowsData([...rowsData, rowsInput]);
    }
    const dispatch = useDispatch();

    const handlekeyPress =(e)=>{
        if (e.key== 'Enter'){
            if (e.target.value){
                dispatch({ type: 'CLEAR_FLID' })
                dispatch(getEqbyIdAction(e.target.value));
                dispatch(hasChildrenAction(e.target.value,0))
            }
        }
    }
    const onSamIdBlur = ({ target }) => {
        if (target.value) {
            dispatch({ type: 'CLEAR_FLID' })
            dispatch(getEqbyIdAction(target.value));
            dispatch(hasChildrenAction(target.value,0))
        }
    };
    const getChildren =(samid,indx)=>{
        dispatch(getChildrenAction(samid,0))
        const rowsInput = [...rowsData];
        rowsInput[indx]['showChildren'] = false;
        setRowsData(rowsInput);
    }
    const handleScrapDecision = (index) => {
        const rowsInput = [...rowsData];
        rowsInput[index]['drDecision'] = 'Scrap';
        rowsInput[index]['decesionLevel'] = '';
        setRowsData(rowsInput);
    }
    const handleScrapStatus = (index) => {
        const rowsInput = [...rowsData];
        rowsInput[index]['drFinalStatus'] = 'Scrapped';
        rowsInput[index]['finalLevel'] = '';
        setRowsData(rowsInput);
    }
    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const regex = /^[0-9\b]+$/;
        if (name === 'pkEquipment' && value && !regex.test(value)) {
            return;
        }
        else if (name === 'recoverParts' || name === 'scrapImmediately') {
            const rowsInput = [...rowsData];
            rowsInput[index][name] = evnt.target.checked ? true : false;
            setRowsData(rowsInput);
        }
        else if (name === 'retrievePart') {
            const rowsInput = [...rowsData];
            rowsInput[index][name] = evnt.target.checked ? true : false;
            setRowsData(rowsInput);
        }
        else if (name === 'drRule' && stepId === 10) {
            const rowsInput = [...rowsData];
            rowsInput[index][name] = value;
            rowsInput[index]['drDecisionDate'] = '';
            rowsInput[index]['drDecision'] = '';
            rowsInput[index]['drDecisionBy'] = "";

            if (evnt.target.value === 'Cancel') {
                rowsInput[index]['drDecisionDate'] = new Date()
                rowsInput[index]['drDecision'] = 'Cancelled';
                rowsInput[index]['drDecisionBy'] = decodedToken.name;
            }
            else if (evnt.target.value === 'Scrap Immediately') {
                rowsInput[index]['drDecisionDate'] = new Date()
                rowsInput[index]['drDecision'] = 'Scrap';
                rowsInput[index]['drDecisionBy'] = decodedToken.name;
            }
            else if (evnt.target.value === '3 Months Publishing') {
                rowsInput[index]['drDecisionDate'] = new Date()
            }
            if (evnt.target.value === 'No rule')
                setIsError(true)

            setRowsData(rowsInput);
        }
        else if (name === 'drRule' && stepId === 20) {
            const rowsInput = [...rowsData];
            rowsInput[index][name] = value;

            rowsInput[index]['drDecisionDate'] = '';
            rowsInput[index]['drDecision'] = '';
            rowsInput[index]['drDecisionBy'] = "";
            rowsInput[index]['finalLevel'] = '';
            rowsInput[index]['drFinalStatus'] = '';
            rowsInput[index]['decesionLevel'] = '';

            if (evnt.target.value === 'Cancel') {
                rowsInput[index]['drDecisionDate'] = new Date()
                rowsInput[index]['drDecision'] = 'Cancelled';
                rowsInput[index]['drFinalStatus'] = 'Cancelled';
                rowsInput[index]['drDecisionBy'] = decodedToken.name;
            }
            else if (evnt.target.value === 'Scrap Immediately') {
                rowsInput[index]['drDecisionDate'] = new Date()
                rowsInput[index]['drDecision'] = 'Scrap';
                rowsInput[index]['finalLevel'] = 'Scrapped';
                rowsInput[index]['drDecisionBy'] = decodedToken.name;
            }
            else if (evnt.target.value === '3 Months Publishing') {
                rowsInput[index]['drDecisionDate'] = new Date()
                rowsInput[index]['decesionLevel'] = 'Scrap';
            }
            if (evnt.target.value === 'No rule')
                setIsError(true)

            setRowsData(rowsInput);
        }
        else {
            const rowsInput = [...rowsData];
            rowsInput[index][name] = value;
            setRowsData(rowsInput);
        }
    }
    const thClass = {
        borderRight: '1px solid #ada0a0',
        minWidth: '170px'
    }
    const thClass1 = {
        borderRight: '1px solid #ada0a0',
        minWidth: '220px'
    }
    return (
        <div className="">
            <div className="row" style={{ margin: 0 }}>
            <div style={{ overflowX: 'auto', overflowY: 'auto', height: '400px', width: drawerOpen ? `${window.innerWidth - 410}px` : `${window.innerWidth - 220}px` }} className="col-sm-12">
                    {isflByIDLoading || getEqLoading ? <tr style={{ width: '100%' }}>
                        <Box sx={{ width: '100%', mb: 1 }}>
                            <LinearProgress />
                        </Box></tr> : ''}
                    <table className="table">
                        <thead style={{ color: '#1179b7', height: '7px' }}>
                            <tr>
                                <th style={{ minWidth: '50px' }}></th>
                                <th style={thClass}>SAM ID</th>
                                <th style={thClass}>Location<br />Company <br />Status</th>
                                <th style={thClass}>Area <br />Function <br />OEM Model</th>
                                <th style={thClass}>Serial Number <br />SAP/P1 Ref</th>
                                <th style={thClass1}>Condition <br />In silent store since</th>
                                <th style={thClass}>Net book value and validity</th>
                                <th style={thClass}>Availablity</th>
                                <th>Justification/Comment</th>
                                {stepId >= 10 ?
                                    <>
                                        <th style={thClass}>DR Rule</th>
                                        <th style={thClass}>DR Decision
                                            <br />and Date Decision by</th>
                                        {stepId >= 20 ? <th style={thClass}>DR Final Status</th> : ''}
                                    </>
                                    : ''
                                }
                            </tr>
                            <Divider />
                        </thead>
                        <tbody style={{ borderTop: '1px solid #85d0e1', backgroundColor: '#eaf2f3f0', height: '7px' }}>
                            <TableRows modelList={modelList} loading={getEqLoading || isflByIDLoading} conditionsList={conditions} currencies={currencies}
                                onSamIdBlur={onSamIdBlur} rowsData={rowsData} deleteTableRows={deleteTableRows}
                                getChildren ={getChildren}
                                drRuleList={drRuleList}
                                drDecisionList={drDecisionList}
                                drFinalStatusList={drFinalStatusList}
                                handleChange={handleChange}
                                isEdit={isEdit}
                                handleScrapDecision={handleScrapDecision}
                                handleScrapStatus={handleScrapStatus}
                                formDisabled={formDisabled}
                                isDisableDrRule={isDisableDrRule}
                                isError={isError}
                                decodedToken={decodedToken}
                                stepId={stepId}
                                defaultCurrency={defaultCurrency}
                                handlekeyPress={handlekeyPress}
                            />
                        </tbody>
                        {(rowsData.find(({ pkEquipment }) => !pkEquipment) || getEqLoading || isflByIDLoading) ? "" :
                            <button className="btn btn-outline-primary btn-sm m-2" onClick={addTableRows} >+</button>}
                    </table>
                </div>
            </div>
        </div>
    )
}
export default DynamicTable;