import { actionTypes } from "./actions";

export const initialState = {
  notifications: [],
  notificationsLoading: false
};

// ===========================|| COMMON REDUCER ||=========================== //

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_PENDING:
      return {
        ...state,
        notificationsLoading: true,
      };
      case actionTypes.NOTIFICATION_RECEIVED:
      return {
        ...state,
        notifications: action.payload.map((dt, index)=>({...dt, id:index})),
        notificationsLoading: false,
      };
      case actionTypes.NOTIFICATION_FAILED:
      return {
        ...state,
        isCountryFail: false,
        notificationsLoading: false
      };
    default:
      return state;
  }
};

export default notificationsReducer;
