
import {getProductFormatTypeAPI, createProductFormatTypeAPI, updateProductFormatTypeAPI, deleteProductFormatTypeAPI} from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    GET_PRODUCT_FORMAT_TYPE_REQUEST: "GET_PRODUCT_FORMAT_TYPE_REQUEST",
    GET_PRODUCT_FORMAT_TYPE_RECEIVED: "GET_PRODUCT_FORMAT_TYPE_RECEIVED",
    GET_PRODUCT_FORMAT_TYPE_FAILED: "GET_PRODUCT_FORMAT_TYPE_FAILED",
    CREATE_PRODUCT_FORMAT_TYPE_REQUEST: "CREATE_PRODUCT_FORMAT_TYPE_REQUEST",
    CREATE_PRODUCT_FORMAT_TYPE_RECEIVED: "CREATE_PRODUCT_FORMAT_TYPE_RECEIVED",
    CREATE_PRODUCT_FORMAT_TYPE_FAILED: "CREATE_PRODUCT_FORMAT_TYPE_FAILED",
    UPDATE_PRODUCT_FORMAT_TYPE_REQUEST: "UPDATE_PRODUCT_FORMAT_TYPE_REQUEST",
    UPDATE_PRODUCT_FORMAT_TYPE_RECEIVED: "UPDATE_PRODUCT_FORMAT_TYPE_RECEIVED",
    UPDATE_PRODUCT_FORMAT_TYPE_FAILED: "UPDATE_PRODUCT_FORMAT_TYPE_FAILED",
    DELETE_PRODUCT_FORMAT_TYPE_REQUEST: "DELETE_PRODUCT_FORMAT_TYPE_REQUEST",
    DELETE_PRODUCT_FORMAT_TYPE_RECEIVED: "DELETE_PRODUCT_FORMAT_TYPE_RECEIVED",
    DELETE_PRODUCT_FORMAT_TYPE_FAILED: "DELETE_PRODUCT_FORMAT_TYPE_FAILED",
};
const getProductFormatTypeRequest= ()=>({
    type: actionTypes.GET_PRODUCT_FORMAT_TYPE_REQUEST,
})
const getProductFormatTypeReceived= (payload)=>({
    type: actionTypes.GET_PRODUCT_FORMAT_TYPE_RECEIVED,
    payload
})
const getProductFormatTypeFailed= ()=>({
    type: actionTypes.GET_PRODUCT_FORMAT_TYPE_FAILED,
})

const createProductFormatTypeRequest = () => ({
    type: actionTypes.CREATE_PRODUCT_FORMAT_TYPE_REQUEST,
});
const createProductFormatTypeReceived= (payload) => ({
    type: actionTypes.CREATE_PRODUCT_FORMAT_TYPE_RECEIVED,
    payload,
});
const createProductFormatTypeFailed = (payload) => ({
    type: actionTypes.CREATE_PRODUCT_FORMAT_TYPE_FAILED,
    payload,
});
const updateProductFormatTypeRequest = () => ({
    type: actionTypes.UPDATE_PRODUCT_FORMAT_TYPE_REQUEST,
});
const updateProductFormatTypeReceived= (payload) => ({
    type: actionTypes.UPDATE_PRODUCT_FORMAT_TYPE_RECEIVED,
    payload,
});
const updateProductFormatTypeFailed = (payload) => ({
    type: actionTypes.UPDATE_PRODUCT_FORMAT_TYPE_FAILED,
    payload,
});

const deleteProductFormatTypeRequest = () => ({
    type: actionTypes.DELETE_PRODUCT_FORMAT_TYPE_REQUEST,
});
const deleteProductFormatTypeReceived= (payload) => ({
    type: actionTypes.DELETE_PRODUCT_FORMAT_TYPE_RECEIVED,
    payload,
});
const deleteProductFormatTypeFailed = (payload) => ({
    type: actionTypes.DELETE_PRODUCT_FORMAT_TYPE_FAILED,
    payload,
});

export const getProductFormatTypeAction = (payload) => {
    return async (dispatch) => {
        dispatch(getProductFormatTypeRequest());
        try {
            const response = await getProductFormatTypeAPI(payload);
            if (response?.status === 200) 
                dispatch(getProductFormatTypeReceived(response?.data?.data));
        } catch (error) {
            dispatch(getProductFormatTypeFailed(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const createProductFormatTypeAction = (payload) => { 
    return async (dispatch) => {
        dispatch(createProductFormatTypeRequest());
        try {
            const response = await createProductFormatTypeAPI(payload);  
            if (response?.status === 201) {
                dispatch(createProductFormatTypeReceived(response?.data?.data));
                dispatch(actions.successAlert("Product Format Type added successfully"));
                dispatch(getProductFormatTypeAction());
            }
        } catch (error) {  
            console.log("error",error)    
            dispatch(createProductFormatTypeFailed(error?.response?.data));        
            if (error?.response) {
                if(error?.response?.data?.errorMessage !== undefined)
                    dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
                else
                    dispatch(actions.errorAlert(error?.response?.data?.title));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const updateProductFormatTypeAction = (payload,id) => { 
    return async (dispatch) => {
        dispatch(updateProductFormatTypeRequest());
        try {
            const response = await updateProductFormatTypeAPI(payload,id);    
            if (response?.status === 200) {
                dispatch(updateProductFormatTypeReceived(response?.data?.data));
                dispatch(getProductFormatTypeAction());
                dispatch(actions.successAlert("Product Format Type updated successfully"));
            }
        } catch (error) {  
            console.log("error",error)    
            dispatch(updateProductFormatTypeFailed(error?.response?.data));        
            if (error?.response) {
                if(error?.response?.data?.errorMessage !== undefined)
                    dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
                else
                    dispatch(actions.errorAlert(error?.response?.data?.title));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deleteProductFormatTypeAction = (id) => {
    return async (dispatch) => {
        dispatch(deleteProductFormatTypeRequest());
        try {
            const response = await deleteProductFormatTypeAPI(id);
            if (response?.status === 200) {
                dispatch(deleteProductFormatTypeReceived(response?.data?.success));
                dispatch(actions.successAlert("Product Format Type deleted successfully"));
                dispatch(getProductFormatTypeAction());
            }
        } catch (error) {
            dispatch(deleteProductFormatTypeFailed(error?.response?.data));     
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));

            }
        }
    };
}