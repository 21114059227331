// material-ui
import { Box, useMediaQuery, Divider, Typography, Grid, Container } from '@mui/material';

// project import
// import Search from './Search';
import Notification from './Notification';
import MobileSection from './MobileSection';
import AppTitle from './AppTitle';
import { 
    ButtonBase,
    // Toolbar 
} from '@mui/material';
import { Link } from 'react-router-dom';
import SAMDBIcon from 'assets/images/icons/SAM DB.png';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import { useSelector } from 'react-redux';
import { equipmentAPI } from 'api/index';
import { Tooltip } from '../../../../../node_modules/@mui/material/index';
// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { decodedToken } = useSelector((state) => state.token);

    const downloadFileClick = async () =>{
        let apiUrl = `/api/bulkupload/getuserguide/getuserguide`
        
        try {
            const response = await equipmentAPI.get(apiUrl).then((res) => res);
            if (response?.status === 200) {
                const docUrl = response?.data?.data;
                var link = document.createElement("a");
                link.download = "name";
                link.href = docUrl;
                document.body.appendChild(link);
                link.target='_blank';
                link.click();
                document.body.removeChild(link);
           
            }
        } catch (error) {
          
        }
    }
    return (
        <>
        <Container sx={{maxWidth: '100'}}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item sx={{ display: 'flex', flex: '1' }}>
                        <ButtonBase disableRipple component={Link} to='/' sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                            <img src={SAMDBIcon} alt="SAMDB" height="35px" />
                        </ButtonBase>

                        <Box sx={{ display: { md: 'flex' } }}>
                            <AppTitle AppOpt="DS"/>
                        </Box>
                    </Grid>
                    <Grid item sx={{mb:1, mr:1}}>
                        <Notification />
                    </Grid>
                    {!matchesXs && 
                    <>
                        <Grid item sx={{ mb:1}}>
                            <Tooltip title="User Manual" >
                            <MenuBookOutlinedIcon sx={{cursor: 'pointer', mr:-1}} onClick={downloadFileClick} />
                            </Tooltip>
                        </Grid>
                      <Grid item sx={{mr:1 ,cursor: 'pointer'}}> <Box>
                                <Typography variant="h6" onClick={downloadFileClick}>
                               Download User Manual
                            </Typography></Box>
                            </Grid> 
                        <Grid item>
                            <Divider orientation="vertical" variant="middle" flexItem sx={{ backgroundColor: '#fff' }} />
                        </Grid>
                    </>
                   }
                    <Grid item sx={{ ml: 1 }}>
                        <Box sx={{ flexGrow: 0, display: { xs: 'none', sm: 'block' }, justifyContent: 'flex-end' }}>
                            <Typography variant="h6" noWrap>
                                Welcome <strong>{decodedToken.name}</strong>
                            </Typography>
                        </Box>
                    </Grid>
                    {matchesXs && <MobileSection />}
                </Grid>
        </Container>
        </>
    );
};

export default HeaderContent;
