
import { getAllControlSystemAPI, createControlSystemAPI, updateControlSystemAPI, deleteControlSystemAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    CONTROL_SYSTEM_PENDING: "CONTROL_SYSTEM_PENDING",
    CONTROL_SYSTEM_RECEIVED: "CONTROL_SYSTEM_RECEIVED",
    CONTROL_SYSTEM_FAILED: "CONTROL_SYSTEM_FAILED"
};

const requestControlSystems= () => ({
    type: actionTypes.CONTROL_SYSTEM_PENDING,
});
const receiveCountries = (payload) => ({
    type: actionTypes.CONTROL_SYSTEM_RECEIVED,
    payload,
});
const failureControlSystems = (payload) => ({
    type: actionTypes.CONTROL_SYSTEM_FAILED,
    payload,
});
export const getAllControlSystemAction = () => {
    return async (dispatch) => {
        dispatch(requestControlSystems());
        try {
            const response = await getAllControlSystemAPI();
            if (response?.status === 200) {
                dispatch(receiveCountries(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureControlSystems(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const createControlSystemAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestControlSystems());
        try {
            const response = await createControlSystemAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Control system added successfully"));
            }
            dispatch(getAllControlSystemAction());
        } catch (error) {
            dispatch(failureControlSystems(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateControlSystemAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestControlSystems());
        try {
            const response = await updateControlSystemAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Control system updated successfully"));
            }
            dispatch(getAllControlSystemAction());
        } catch (error) {
            dispatch(failureControlSystems(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deleteControlSystemAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestControlSystems());
        try {
            const response = await deleteControlSystemAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Control system deleted successfully"));
            }
            dispatch(getAllControlSystemAction());
        } catch (error) {
            dispatch(failureControlSystems(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
