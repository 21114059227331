
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MainCard from 'components/MainCard';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { getAllActiveCompanyAction } from 'containers/Management/Companies/state/actions';
import { getModelAction } from 'containers/Management/Models/state/actions';
import { getOemAction } from 'containers/Management/OEM/state/actions';
import { Box, Button, Grid, LinearProgress, TextField } from '@mui/material/index';
import { getAreaAction } from 'containers/Management/FunctionalStructure/Area/state/actions';
import { getFunctionAction } from 'containers/Management/FunctionalStructure/Function/state/actions';
import { getProcessAction } from 'containers/Management/FunctionalStructure/Process/state/actions';
import { getSubPrAction } from 'containers/Management/FunctionalStructure/SubProcess/state/actions';
import { getFormStateAction } from '../StateList/actions';
import { getFormStepAction } from '../StepList/actions';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getAllConditionAction } from 'containers/Management/Conditions/state/actions';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { StyledTreeDataGrid } from 'components/DataGrid/Tree/index';
import { eqDecision, getTreeData, requestReason, TabPanel } from './constant';
import { Chip, CircularProgress, Modal, Stack, Tooltip } from '@mui/material/index';
import moment from 'moment';
import EquipmentTabs from '../EquipmentTabs';
import { searchMachineReser } from './state/actions';
import NewMRRequest from 'containers/BusinessProcess/MachineReservationRequest/NewMRRequest';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import { formMrfAction } from 'containers/BusinessProcess/MachineReservationRequest/state/actions';
import { createSmartfilterAction, deleteSmartFilterAPIAction, getSmartFilterAction, updateSmartFIlterAction } from 'containers/Dashboard/CustomFilterState/actions';
import DeleteModal from 'components/DeleteModal';
import MultiSelect1 from 'components/Form/MultiSelect1';
import { mrExport } from '../EquipmentDisposal/eqExport';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 500,
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    pl: 2,
    pt: 2
};

const yesNoOption = [{ id: true, label: 'Yes' }, { id: false, label: 'No' }];

export default function MachineReservationSearch() {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState([]);
    const [view, setView] = useState('main');
    const [eqId, setEqId] = useState('');
    const [lock, setLock] = React.useState(false);
    const [smartFilterListNew, setSmartFIlter] = useState([]);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openDetails, setOpenDetails] = React.useState({});
    const handleDeatilsClose = () => setOpenDetails({});

    const [headerValue, onHeaderChange] = useState('');
    const { smartFilterList = [], isSmartFilterLoading = false, isFilterSaved } = useSelector((state) => state.smartFilter);
    const [selectedChip, setSelectedChip] = useState('');
    const [openDeleteModal, toggleDeleteModal] = useState({});

    const [selectedOptions, setSelectedOptions] = React.useState([{ value: 50, label: 'Portugal (TABAQUEIRA)' }]);

    const setSelected = (a) => {
        setSelectedOptions({ ...selectedOptions, ...a })
    }
    const [resetFilter, setclearFilter] = useState(false);



    const [expanded, setExpanded] = React.useState('panel1');
    const [mrfId, setMrfId] = useState('');
    const menu = useSelector((state) => state.common);
    const { drawerOpen } = menu;
    let { roles } = useSelector((state) => state.token);
    const { companyLists = [], isCompanyLoading } = useSelector((state) => state.company);
    const { areaList = [], isAreaLoading } = useSelector((state) => state.area);
    // const { conditions = [] } = useSelector((state) => state.conditions);
    const { oemList = [], isOemLoading } = useSelector((state) => state.oem);
    const { modelList = [], isModelLoading } = useSelector((state) => state.model);
    const { functionList = [], isFunctionLoading } = useSelector((state) => state.function);
    const { formStateList = [], isFormStateLoading } = useSelector((state) => state.formState);
    const { formStepList = [], isFormStepLoading } = useSelector((state) => state.formStep);
    const { processList = [], isProcessLoading } = useSelector((state) => state.process);
    const { subProcessList = [], isSubProLoading } = useSelector((state) => state.subProcess);
    const { machineReserList = [], ismachineReserLoading } = useSelector((state) => state.mchnSearch);

    const isDropdwonsLoading = () => {
        if (isCompanyLoading || isAreaLoading || isOemLoading || isModelLoading
            || isFunctionLoading || isFormStateLoading || isFormStepLoading ||
            isProcessLoading || isSubProLoading || ismachineReserLoading) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (roles.includes('admin') || roles.includes('coordinator')) {
            dispatch(getSmartFilterAction());
        }
        dispatch({type: 'CLEAR_RESR_DATA'});
    }, [])

    useEffect(() => {
        setSmartFIlter(smartFilterList.filter((a) => a.type === 'MR'));
    }, [smartFilterList])

    React.useEffect(() => {
        dispatch(getAreaAction());
        dispatch(getFormStateAction());
        dispatch(getSubPrAction());
        //  dispatch(getAllConditionAction());
        dispatch(getFormStepAction());
        dispatch(getOemAction());
        dispatch(getProcessAction());
        dispatch(getModelAction());
        dispatch(getFunctionAction());
        dispatch(getAllActiveCompanyAction());

    }, [])

    const onSamDeatilsClick = (data) => {
        setEqId(data.equipmentId)
        setView('DETAILS_EQ');
    }

    const onInputChange = ({ target }) => {
        // setSubmit(false)
        if (target.value && target.name === 'fkCompany') {
            //   dispatch(getAllFLocationAction(target.value.id));
            setFormData({ ...formData, [target.name]: target.value });
        } else {
            setFormData({ ...formData, [target.name]: target.value });
        }
    }

    const handleChange = (panel) => (event, newExpanded) => {
        if(!lock){
          setExpanded(newExpanded ? panel : false);
        }
    };
    const handleOnClickClear = () => {
        setclearFilter(!resetFilter);
        setFormData({
            SamMrfref: "",
            ReasonForRequest: "",
            TransferredMonthYear: null,
            EquipmentId:'',
            RequestValidityDate: null,
            SerialNumber: "", equipmentId: ''
        });
    }

    const onChange = (name, allSelected, event) => {

        if (allSelected === 'All' && !event.target.checked) {
            const gdf = Object.keys(name)[0]
            setFormData({ ...formData, [gdf]: [] })
        } else {
            setFormData({ ...formData, ...name })
        }
    }

    const handleOnClickSearch = () => {

        const payload = [];
        for (let keys in formData) {
            if (formData[keys]) {
                if (typeof (formData[keys]) === 'object') {
                    if (Array.isArray(formData[keys])) {
                        if (formData[keys].length)
                            payload.push({
                                "columnName": keys,
                                "filterValues": formData[keys].map((a) => `${a.value}`),
                                "filterOption": 9
                            });
                    } else {
                        if (keys === 'TransferredMonthYear') {
                            const dt = new Date(formData[keys]);
                            const dt1 = new Date(dt.getFullYear(), dt.getMonth()+1, 0);
                            let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(dt);
                            let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(dt);
                            let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(dt);
                            let ye1 = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(dt1);
                            let mo1 = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(dt1);
                            let da1 = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(dt1);

                            payload.push({
                                "columnName": 'CreationDate',
                                "filterValues": [`${ye}-${mo}-${da}`],
                                "filterOption": 6
                            }, {
                                "columnName": 'CreationDate',
                                "filterValues": [`${ye1}-${mo1}-${da1}`],
                                "filterOption": 8
                            });
                        } else {
                            payload.push({
                                "columnName": keys,
                                "filterValues": [formData[keys]],
                                "filterOption": 9
                            });
                        }
                    }

                } else {
                    if (keys === 'SerialNumber'|| keys ===  'EquipmentId')
                     {
                        payload.push({
                            "columnName": keys,
                            "filterValues": [formData[keys].replaceAll("%", "")],
                            "filterOption": formData[keys].startsWith("%") && formData[keys].endsWith("%")?3:formData[keys].endsWith("%")?1:formData[keys].startsWith("%")?2 :9
                        });
                    } 
                   else{
                    if (keys === 'SamMrfref') {
                        payload.push({
                            "columnName": keys,
                            "filterValues": [formData[keys].replaceAll("%", "")],
                            "filterOption": 3
                        });
                    } 
                    else {
                        payload.push({
                            "columnName": keys,
                            "filterValues": [`${formData[keys]}`],
                            "filterOption": 9
                        });
                    }
                }
                }
            }
        }


        dispatch(searchMachineReser({
            "filterParams": payload,
        }, 0, 100000))
        if (!lock) {
          setExpanded('both');
        }
    }

    const onSaveClick = () => {

        const payload = [];
        for (let keys in formData) {
            if (formData[keys]) {
                if (typeof (formData[keys]) === 'object') {
                    if (Array.isArray(formData[keys])) {
                        if (formData[keys].length)
                            payload.push({
                                "columnName": keys,
                                "filterValues": formData[keys].map((a) => `${a.value}`),
                                "filterOption": 9
                            });
                    } else {
                        payload.push({
                            "columnName": keys,
                            "filterValues": [moment(formData[keys]).format('YYYY-MM-DD')],
                            "filterOption": 9
                        });
                    }

                } else {
                    payload.push({
                        "columnName": keys,
                        "filterValues": [`${formData[keys]}`],
                        "filterOption": 1
                    });
                }
            }
        }
        const pld = {
            "title": headerValue,
            "type": "MR",
            "filterCriteria": {
                "filterParams": payload
            }
        }
        if (selectedChip) {
            dispatch(updateSmartFIlterAction(selectedChip, pld));
        } else {
            dispatch(createSmartfilterAction(pld));
        }
    }


    const getLabelId = (list, value, id, label) => {
        const abdt = list.find((a) => a[id] == value);
        return abdt ? { id: abdt[id], label: abdt[label] } : '';
    }

    const getFilterList = (id) => {
        const filt = smartFilterListNew.find((a) => a.pkSmartFilter === id).filterCriteria;

        const abc = [];
        filt.filterParams.forEach((a) => {
            switch (a.columnName) {
                case 'RequesterCompanyId':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(companyLists, b, 'pkCompany', 'longCompanyName').label
                        });
                        abc.push({ id: 'Company', value: name.join(",") });
                    }
                    break; 
                    case 'EquipmentCompanyId':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(companyLists, b, 'pkCompany', 'longCompanyName').label
                        });
                        abc.push({ id: 'Donor', value: name.join(",") });
                    }
                    break; 
                    case 'SamMrfref':
                    if (a.filterValues && a.filterValues.length) {
                       
                        abc.push({ id: 'MRF Ref', value: a.filterValues[0] });
                    }
                    break;
                case 'StateId':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(formStateList, b, 'stateValue', 'stateName').label
                        });

                        abc.push({ id: 'State', value: name.join(",") });
                    }
                    break;
                case 'StepId':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(formStepList, b, 'stepValue', 'stepName').label
                        });

                        abc.push({ id: 'Step', value: name.join(",") });
                    }
                    break; 
                    case 'ReasonForRequest':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(requestReason, b, 'label', 'id').label
                        });

                        abc.push({ id: 'Reason For Request', value: name.join(",") });

                    }
                    break; 
                    
                    case 'Step10Decision':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(eqDecision, b, 'id', 'label').label
                        });
                        abc.push({ id: 'Equipment Decision', value: name.join(",") });

                    }
                    break; 
                    case 'IsTransfered':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId([{ id: 'true', label: 'Yes' }, { id: 'false', label: 'No' }], b, 'id', 'label').label
                        });
                        abc.push({ id: 'Transferred', value: name.join(",") });
                    }
                    break;

                    case 'TransferredMonthYear':
                    if (a.filterValues && a.filterValues.length) {
                        
                        abc.push({ id: 'Request Month', value: moment(a.filterValues[0]).format('MM-yyyy')});
                    }
                    break; 
                    case 'RequestValidityDate':
                    if (a.filterValues && a.filterValues.length) {
                      
                        abc.push({ id: 'Validity Until', value: a.filterValues[0] });
                    }
                    break;
                
                case 'Area':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(areaList, b, 'areaName', 'areaName').label
                        });

                        abc.push({ id: 'Area', value: name.join(",") });

                    }
                    break;
                case 'EquipmentProcess':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(processList, b, 'processName', 'processName').label
                        });

                        abc.push({ id: 'Process', value: name.join(",") });
                    }
                    break;
                case 'EquipmentSubProcess':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(subProcessList, b, 'subProcessName', 'subProcessName').label
                        });
                        abc.push({ id: 'Sub Process', value: name.join(",") });
                    }
                    break;
                case 'EquipmentFunction':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(functionList, b, 'functionName', 'functionName').label
                        });
                        abc.push({ id: 'Function', value: name.join(",") });
                    }
                    break;
                case 'EquipmentOemname':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(oemList, b, 'oemName', 'oemName').label
                        });
                        abc.push({ id: 'OEM', value: name.join(",") });
                    }
                    break;
                    case 'EquipmentOemmodel':
                    if (a.filterValues && a.filterValues.length) {
                        const name = a.filterValues.map((b) => {
                            return getLabelId(modelList, b, 'modelName', 'modelName').label
                        });
                        abc.push({ id: 'Model', value: name.join(",") });
                    }
                    break;
                case 'SerialNumber':
                    if (a.filterValues && a.filterValues.length) {
                        abc.push({ id: 'Serial number', value: a.filterValues[0] });
                    }
                    break;
                    case 'EquipmentId':
                        if (a.filterValues && a.filterValues.length) {
                            abc.push({ id: 'SAM Id', value: a.filterValues[0] });
                        }
                        break;
                default:

                    break;
            }
        })
        return abc;
    }


    const setChipSelected = (id) => {
        if (id === selectedChip) {
            setSelectedChip('');
        } else {
            const filt = smartFilterListNew.find((a) => a.pkSmartFilter === id).filterCriteria;
            const a = { filterParams: [...filt.filterParams] };

            const dt = filt.filterParams.reduce((acc, cur) => ({ ...acc, [cur.columnName]: cur.filterValues }), {})

            const {
                RequesterCompanyId,
                StateId,
                StepId,
                EquipmentAreaId,
                EquipmentProcessId,
                EquipmentModelId,
                EqReservedInMRFId,
                IsRepairandReplacement,
                ScrapImmediately,
                EquipmentCompanyId,
                EquipmentProcess,
                SubProcessName,
                EquipmentFunction,
                EquipmentOemname
            } = dt;

            const formData = {
                ...dt,
                RequesterCompanyId: RequesterCompanyId ? getLabelId(companyLists, RequesterCompanyId, 'pkCompany', 'longCompanyName') : "",
                EquipmentCompanyId: EquipmentCompanyId ? getLabelId(companyLists, EquipmentCompanyId, 'pkCompany', 'longCompanyName') : "",
                StateId: StateId ? getLabelId(formStateList, StateId, 'stateValue', 'stateName') : "",
                StepId: StepId ? getLabelId(formStepList, StepId, 'stepValue', 'stepName') : "",
                EquipmentAreaId: EquipmentAreaId ? getLabelId(areaList, EquipmentAreaId, 'pkArea', 'areaName') : "",
                EquipmentProcess: EquipmentProcess ? getLabelId(processList, EquipmentProcess, 'processName', 'processName') : "",
                SubProcessName: SubProcessName ? getLabelId(subProcessList, SubProcessName, 'subProcessName', 'subProcessName') : "",
                EquipmentFunction: EquipmentFunction ? getLabelId(functionList, EquipmentFunction, 'functionName', 'functionName') : "",
                EquipmentModelId: EquipmentProcessId ? getLabelId(modelList, EquipmentModelId, 'pkModel', 'modelName') : "",
                EquipmentOemname: EquipmentOemname ? getLabelId(oemList, EquipmentOemname, 'oemName', 'oemName') : "",
                EqReservedInMRFId: EqReservedInMRFId ? getLabelId(yesNoOption, EqReservedInMRFId, 'id', 'label') : "",
                IsRepairandReplacement: IsRepairandReplacement ? getLabelId([{ id: 'true', label: 'Yes' }, { id: 'false', label: 'No' }], IsRepairandReplacement, 'id', 'label') : "",
                ScrapImmediately: ScrapImmediately ? getLabelId([{ id: 'true', label: 'Yes' }, { id: 'false', label: 'No' }], ScrapImmediately, 'id', 'label') : "",
            }
            dispatch(searchMachineReser(a, 0, 100000));
            setSelectedChip(id);
            if (!lock) {
              setExpanded('both');
            }
        }
    }

    useEffect(() => {
        if (isFilterSaved) {
            handleClose();
            dispatch({ type: 'SFILTER_CLEAR_FLAG' })
        }
    }, [isFilterSaved, dispatch])

    const onDelete = (e, id) => {
        e.stopPropagation();
        toggleDeleteModal({ open: true, data: id });
    }


    const deleteConfirm = (data) => {
        toggleDeleteModal({});
        dispatch(deleteSmartFilterAPIAction(data));
    }

    const handleFormEdit = (samMrfref) => {
        let firstIndx = samMrfref.indexOf('-')
        let lastIndx = samMrfref.lastIndexOf('-')
        let mrfId = samMrfref.substring(firstIndx + 2, lastIndx)
        setMrfId(mrfId);
        dispatch(formMrfAction(mrfId))
        setView('DETAILS_MR')
    }
    useEffect(() => {
        if (view === 'DETAILS_MR')
            dispatch({ type: 'GET_MR_INITIAL_DATA' })
    }, [view]);

    const getStateName = (stateId) => {
        if (!formStateList) return '';
        const abc = formStateList.find((a) => a.stateValue === stateId);
        return abc ? `${abc.stateValue} ${abc.stateName}` : '';
    }

    const getStepName = (stepId) => {
        if (!formStepList) return '';
        const abc = formStepList.find((a) => a.stepValue === stepId);
        return abc ? `${abc.stepValue} ${abc.stepName}` : '';
    }

    const handleClick = (data, fileName) => {
        mrExport(data, formStateList, formStepList, fileName);
    };


    const columns = [{
        title: '',
        dataIndex: '',
        key: 'y',
        width: 80,
    },
    {
        title: 'MRF Ref',
        dataIndex: '',
        key: 'samMrfref',
        width: 110,
        render: (data) => {
            return <Stack direction="column" spacing={1}>
                {data ? <>
                    <Button onClick={() => handleFormEdit(data.samMrfref)} variant="text">{data.samMrfref}</Button>
                </> : ''}
            </Stack>
        }
    },
    {
        title: 'Current State SAM ID',
        dataIndex: '',
        width: 120,
        key: 'y',
        render: (data) => {
            return <Stack direction="column" spacing={1}>
                {data ? <>
                    {data.stateId ? getStateName(data.stateId) : ""}
                    <Button sx={{ width: '60px' }} onClick={() => onSamDeatilsClick(data)} variant="text">{data.equipmentId}</Button>
                </> : ''}
            </Stack>
        }
    },
    {
        title: 'Current Step Donor',
        dataIndex: '',
        width: 120,
        key: 'y',
        render: (data) => {
            return <Stack direction="row" spacing={1}>
                {data ? <>
                    {data.stepId ? getStepName(data.stepId) : ""}<br />{data.equipmentCompanyName}
                </> : ''}
            </Stack>
        }
    },

    {
        title: 'Company Function - OEM Model',
        dataIndex: '', key: 'y',
        width: 120,
        render: (data) => {
            return <Stack direction="row" spacing={1}>
                {data ? <>
                      {data.requesterCompanyName}{data.equipmentFunction} {data.equipmentOemmodel}
                </> : ''}
            </Stack>
        }
    },
    {
        title: 'Request Date Valid until',
        dataIndex: '',
        width: 120,
        key: 'y',
        render: (data) => {
            return <Stack direction="row" spacing={1}>
                {data ? <>
                    {data.creationDate ? moment(data.creationDate).format('MM/DD/YYYY') : '' + "-" + data.requestValidityDate ? moment(data.requestValidityDate).format('MM/DD/YYYY') : ''}
                </> : ''}
            </Stack>
        }
    },
    {
        title: 'Requester Name Serial Number',
        dataIndex: '',
        width: 120,
        key: 'y',
        render: (data) => {
            return <Stack direction="row" spacing={1}>
                {data ? <>
                    {data.requesterDisplayName}{data.serialNumber}
                </> : ''}
            </Stack>
        }
    },
    {
        title: 'Reason SAP/P1 Ref',
        dataIndex: '',
        width: 120,
        key: 'y',
        render: (data) => {
            return <Stack direction="row" spacing={1}>
                {data ? <>
                    {data.reasonForRequest}{data.sapP1ref}
                </> : ''}
            </Stack>
        }
    },
    {
        title: 'Last Update SAM decision',
        dataIndex: '',
        width: 120,
        key: 'y',
        render: (data) => {
            return <Stack direction="row" spacing={1}>
                {data ? <>
                    {data.lastUpdateDate ? moment(data.lastUpdateDate).format('MM/DD/YYYY, h:mm:ss A') : ''}{data.decision}
                </> : ''}
            </Stack>
        }
    },
    {
        title: 'Last Update By',
        dataIndex: '',
        width: 120,
        key: 'y',
        render: (data) => {
            return <Stack direction="row" spacing={1}>
                {data ? <>
                    {data.lastUpdateBy}
                </> : ''}
            </Stack>
        }
    },
    {
        title: 'Status',
        dataIndex: 'equipmentStatusName',
        width: 120,
        key: 'equipmentStatusName',
    },


    ];

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            handleOnClickSearch();
        }
    }

    return <><MainCard border={false}>
        <DeleteModal modalData={openDeleteModal} deleteConfirm={deleteConfirm} toggleModal={() => toggleDeleteModal({})} />

        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Filter
                    </Typography>
                    <Grid container> <Grid item xs={12}>
                        <TextField
                            onChange={(e) => { onHeaderChange(e.target.value) }}
                            sx={{ width: '100%', mb: 2 }}
                            id="outlined-basic" size="small"
                            value={headerValue}
                            label="View Name" variant="standard" />
                    </Grid>

                        <Grid item p={2} sm={12}>
                            <Box display="flex" justifyContent="flex-end">

                                {isSmartFilterLoading ? <CircularProgress /> :
                                    <Button
                                        variant="contained"
                                        startIcon={<SaveOutlinedIcon />}
                                        size="small"
                                        sx={{ mr: 1 }}
                                        disabled={!headerValue}
                                        onClick={onSaveClick}
                                    >
                                        Save
                                    </Button>
                                }

                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
        <div>
            <Modal
                open={openDetails.open}
                onClose={handleDeatilsClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style1}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        Filter details:
                    </Typography>
                    <Box sx={{ maxHeight: '500px', overflowY: 'auto' }}>
                    <Grid container> <Grid item xs={12}>
                        <Grid container>{openDetails.open ? getFilterList(openDetails.id).map((a) => {
                            return <><Grid sx={{ fontWeight: 800 }} item xs={4}>{a.id}: </Grid><Grid item xs={8}> {a.value}</Grid></>
                        }) : ''}</Grid>
                    </Grid>

                        <Grid item p={1} mt={4} sm={12}>
                            <Box display="flex" justifyContent="flex-end">
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ mr: 1 }}
                                    onClick={handleDeatilsClose}
                                >
                                    Got it
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    </Box>
                </Box>
            </Modal>
            </div>
        {view === 'main' || view === 'DETAILS_MR' ?
            <Box sx={{ p: 1, display: view === 'DETAILS_MR' ? 'none' : 'block' }}>
            <Grid container sx={{ pl: 1, mt: 1 }} spacing={1}>
              <Grid item  xs={11} sx={{pl:1}}>
                       <Typography variant="h3">Machine Reservation Form - Dashboard</Typography>
                </Grid>
                    <Grid item xs={1} justifyContent="flex-end">
                        {lock ?
                            <Tooltip title="Click to unlock expand">
                                <LockOutlinedIcon onClick={() => {setExpanded('all');setLock(!lock)}} />
                            </Tooltip>
                            :
                            <Tooltip title="Click to lock expand(Default behaviour)">
                                <LockOpenOutlinedIcon onClick={() => {setExpanded('all');setLock(!lock)}} />
                            </Tooltip>
                        }
                    </Grid>
                </Grid>
             
                <Box sx={{ p: 1 }}>
                    <Accordion expanded={expanded === 'all' || expanded === 'panel1' } onChange={handleChange('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Seach criteria</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <>
                                {isDropdwonsLoading() ? <Box sx={{ width: '100%', mb: 1 }}>
                                    <LinearProgress />
                                </Box> : ''}
                                <Grid sx={{ pl: 0, pb: 2, pr: 2 }} xs={8}> Saved filters:
                                    {smartFilterListNew.map((a) => (
                                        <>  <Chip onClick={() => setChipSelected(a.pkSmartFilter)}
                                            color={selectedChip === a.pkSmartFilter ? 'primary' : 'default'}
                                            sx={{ mr: 2 }}
                                            icon={<><DeleteOutlineOutlinedIcon size="small" sx={{ cursor: 'pointer', ml: 2 }}
                                                onClick={(e) => onDelete(e, a.pkSmartFilter)} />
                                                <InfoOutlinedIcon onClick={(e) => { e.stopPropagation(); setOpenDetails({ id: a.pkSmartFilter, open: true }) }} />
                                            </>} label={a.title} /></>
                                    ))}
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.RequesterCompanyId}
                                            setSelectedOptions={setSelected}
                                            name='RequesterCompanyId'
                                            data={{ label: "Company", options: companyLists.map((dt) => ({ label: dt.longCompanyName, value: dt.pkCompany })) }}
                                        />

                                    </Grid>
                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.EquipmentCompanyId}
                                            setSelectedOptions={setSelected}
                                            name='EquipmentCompanyId'
                                            data={{ label: "Donor", options: companyLists.map((dt) => ({ label: dt.longCompanyName, value: dt.pkCompany })) }}
                                        />

                                    </Grid>
                                    <Grid sx={{ pr: 4 }} item xs={12} sm={3}>
                                        <TextField fullWidth name="SamMrfref" onChange={onInputChange}
                                            label="MRF Ref"
                                            value={formData.SamMrfref}
                                            size='small'
                                            onKeyDown={onKeyDown}
                                        />
                                    </Grid>
                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.StateId}
                                            setSelectedOptions={setSelected}
                                            name='StateId'
                                            data={{ label: "State", options: formStateList.map((dt) => ({ label: dt.stateName, value: dt.stateValue })) }}
                                        />

                                    </Grid>
                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.StepId}
                                            setSelectedOptions={setSelected}
                                            name='StepId'
                                            data={{ label: "Step", options: formStepList.map((dt) => ({ label: dt.stepName, value: dt.stepValue })) }}
                                        />

                                    </Grid>
                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.ReasonForRequest}
                                            setSelectedOptions={setSelected}
                                            name='ReasonForRequest'
                                            data={{ label: "Request Reason", options: requestReason.map((dt) => ({ label: dt.label, value: dt.id })) }}
                                        />
                                    </Grid>
                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.Step10Decision}
                                            setSelectedOptions={setSelected}
                                            name='Step10Decision'
                                            data={{ label: "Equipment Decision", options: eqDecision.map((dt) => ({ label: dt.label, value: dt.id })) }}
                                        />
                                    </Grid> <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.IsTransfered}
                                            setSelectedOptions={setSelected}
                                            // selectedOption={formData.RequesterCompanyId}
                                            name='IsTransfered'
                                            data={{ label: "Transferred", options: yesNoOption.map((dt) => ({ label: dt.label, value: dt.id })) }}
                                        />

                                    </Grid>
                                    <Grid sx={{ pr: 4 }} item xs={12} sm={3}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopDatePicker
                                                label="Request Month"
                                                inputFormat="MM/YYYY"
                                                views={['year', 'month']}
                                                emptyLabel="Request month"
                                                size="small"
                                                fullWidth
                                                value={formData.TransferredMonthYear || null}
                                                onChange={(value) => onInputChange({ target: { name: 'TransferredMonthYear', value } })}
                                                renderInput={(params) => <TextField label="Request month" fullWidth size="small" {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid sx={{ pr: 4 }} item xs={12} sm={3}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopDatePicker
                                                label="Validity Until"
                                                inputFormat="MM/DD/YYYY"
                                                emptyLabel="Select date"
                                                size="small"
                                                fullWidth
                                                value={formData.RequestValidityDate || null}
                                                onChange={(value) => onInputChange({ target: { name: 'RequestValidityDate', value } })}
                                                renderInput={(params) => <TextField label="Validity until" fullWidth size="small" {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ pt: 1 }} spacing={1}>
                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.Area}
                                            setSelectedOptions={setSelected}
                                            name='Area'
                                            data={{ label: "Area", options: areaList.map((dt) => ({ label: dt.areaName, value: dt.areaName })) }}
                                        />

                                    </Grid>

                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.EquipmentProcess}
                                            setSelectedOptions={setSelected}
                                            name='EquipmentProcess'
                                            data={{
                                                label: "Process", options: processList.map(({ processName }) =>
                                                    ({ value: processName, label: processName }))
                                            }}
                                        />

                                    </Grid>

                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.EquipmentSubProcess}
                                            setSelectedOptions={setSelected}
                                            name='EquipmentSubProcess'
                                            data={{
                                                label: "Subprocess", options: subProcessList.map(({ subProcessName }) =>
                                                    ({ value: subProcessName, label: subProcessName }))
                                            }}
                                        />

                                    </Grid>

                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.EquipmentFunction}
                                            setSelectedOptions={setSelected}
                                            name='EquipmentFunction'
                                            data={{
                                                label: "Function", options: functionList.map(({ functionName }) =>
                                                    ({ value: functionName, label: functionName }))
                                            }}
                                        />

                                    </Grid>

                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.EquipmentModelId}
                                            setSelectedOptions={setSelected}
                                            name='EquipmentOemmodel'
                                            data={{
                                                label: "Model", options: modelList.map(({ pkModel, modelName }) =>
                                                    ({ value: modelName, label: modelName }))
                                            }}
                                        />

                                    </Grid>
                                    <Grid sx={{ pr: "16px" }} item xs={12} sm={3}>
                                        <MultiSelect1
                                            resetFilter={resetFilter}
                                            onChange={onChange}
                                            selectedOptions={selectedOptions.EquipmentOemname}
                                            setSelectedOptions={setSelected}
                                            name='EquipmentOemname'
                                            data={{
                                                label: "OEM", options: oemList.map(({ oemName }) =>
                                                    ({ value: oemName, label: oemName }))
                                            }}
                                        />

                                    </Grid>
                                    <Grid sx={{ pr: 4 }} item xs={12} sm={3}>
                                        <TextField
                                            id={'srno'}
                                            fullWidth
                                            onKeyDown
                                            size="small"
                                            label='Serial number'
                                            name='SerialNumber'
                                            value={formData.SerialNumber}
                                            inputProps={{ maxLength: 20 }}
                                            onChange={onInputChange}
                                        />
                                    </Grid>
                                    <Grid sx={{ pr: 4 }} item xs={12} sm={3}>
                                        <TextField
                                            id='sap'
                                            fullWidth
                                            size="small"
                                            label='SAMID'
                                            name='EquipmentId'
                                            value={formData.EquipmentId}
                                            inputProps={{ maxLength: 20 }}
                                            onChange={onInputChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item p={4} sm={12}>
                                        <Box display="flex" justifyContent="flex-end">

                                            <Tooltip title="Maximum 3 smart filters can be added">
                                                <span>
                                                    <Button
                                                        variant="contained"
                                                        startIcon={<SaveOutlinedIcon />}
                                                        size="small"
                                                        sx={{ mr: 1 }}
                                                        disabled={!selectedChip && smartFilterListNew.length === 3}
                                                        onClick={handleOpen}
                                                    >
                                                        {selectedChip ? 'Update filter' : 'Save as filter'}
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                sx={{ mr: 2 }}
                                                onClick={handleOnClickClear}
                                            >
                                                Clear search
                                            </Button>
                                            <Button
                                                variant="contained"
                                                startIcon={<SearchOutlinedIcon />}
                                                size="small"
                                                onClick={() => handleOnClickSearch()}
                                            >
                                                Search
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>

                            </>
                        </AccordionDetails>

                    </Accordion>
                    <Accordion expanded={expanded === 'panel2' || expanded === 'all' || expanded === 'both'} onChange={handleChange('panel2')}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Typography>MRF In progress</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid xs={12}>
                                <Button variant="outlined" onClick={() => handleClick(machineReserList.filter(({ stateId }) => stateId === 10), 'MR_In_Progress.xlsx')}>
                                    Export
                                </Button>
                            </Grid>
                            <Grid xs={12}>
                                <Grid container sx={{ mt: 3 }}>
                                    <div style={{
                                      width: drawerOpen ? `${window.innerWidth - 430}px` : `${window.innerWidth - 250}px`
                                    }}>
                                        <StyledTreeDataGrid
                                            columns={columns}
                                            scroll={{ x: 1000, y: 500 }}
                                            loading={ismachineReserLoading}
                                            rows={machineReserList.length ? getTreeData(machineReserList.filter(({ stateId }) => stateId === 10)) : []}

                                            csvOptions={{
                                                fileName: 'Companies lists'
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3' || expanded === 'all' || expanded === 'both'} onChange={handleChange('panel3')}>
                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                            <Typography>MRF Completed</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Grid xs={12}>
                                <Button variant="outlined" onClick={() => handleClick(machineReserList.filter(({ stateId }) => stateId !== 10), 'MR_Completed.xlsx')}>
                                    Export
                                </Button>
                            </Grid>
                            <Grid container sx={{ mt: 3 }}>
                                <div style={{
                                       width: drawerOpen ? `${window.innerWidth - 430}px` : `${window.innerWidth - 250}px`
                                }}>
                                    <StyledTreeDataGrid
                                        columns={columns}
                                        scroll={{ x: 1000, y: 500 }}
                                        loading={ismachineReserLoading}
                                        rows={machineReserList.length ? getTreeData(machineReserList.filter(({ stateId }) => stateId !== 10)) : []}
                                        csvOptions={{
                                            fileName: 'Companies lists'
                                        }}
                                    />
                                </div>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Box>

            </Box>
            : ''}
        {view === 'DETAILS_MR' ? <NewMRRequest mrfId={mrfId} setView={() => { setMrfId(''); setView('main') }} needBack={true} /> : ''}
        {view === 'DETAILS_EQ' ? <TabPanel value={0} index={0}>
            <EquipmentTabs samid={eqId} setView={setView} />
        </TabPanel> : ""}
    </MainCard>
    </>
}