import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Button, Grid } from '@mui/material/index';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function FlModel({ modalData, okConfirm }) {
    const { open, payload } = modalData;

    return (
        <div>
             <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                // onClose={()=>okConfirm(payload, 'cancel')}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h5" component="h2">
                            Confirmation
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            <Box> FunctionalLocation/Status has changed.</Box>
                            <Box> Would you like the equipment to inherit the product formats from the functional location?
                            </Box>
                            <br/>
                            <Box> <strong>OK</strong>{`: Equipment will inherit product formats from the functional locations. Current equipment product formats will be overwritten`}
                            </Box>
                            <br/>
                            <Box> <strong>Cancel</strong>{`: Functional location and sibling equipment will inherit product formats from this equipment. Current functional location and sibling equipment product formats will be overwritten`}
                            </Box>
                            <Grid container>
                                <Grid item pt={3} sm={12}>
                                    <Box display="flex" justifyContent="flex-end">
                                        <Button
                                            variant="contained"
                                            size="small"
                                            sx={{ "marginRight": "1rem" }}
                                            onClick={() => okConfirm(payload, 'ok')}
                                        >
                                            OK
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            onClick={() => okConfirm(payload, 'cancel')}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Typography>
                    </Box>
                </Fade>
            </Modal> 
        </div>
    );
}