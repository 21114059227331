import { actionTypes } from "./actions";

export const initialState = {
  companyList: [],
  functionalLocationList: [],
  isFunctionLocationLoading: true,
  isChildLoading: true,
  productFormatData: [],
  childData: [],
  updateFLData: '',
  isUpdated : false
  
};

// ===========================|| COMMON REDUCER ||=========================== //


export const companyFunctionalLocationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COMPANY_VALUE_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.COMPANY_VALUE_GET_RECEIVE:
      return {
        ...state,
        companyList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isLoading: false,
      };
    case actionTypes.COMPANY_VALUE_GET_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};
export const functionalLocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FUNCTIONAL_LOCATIONS_VALUE_GET_REQUEST:
      return {
        ...state,
        isPFTLoading: true,
      };
    case actionTypes.FUNCTIONAL_LOCATIONS_VALUE_GET_RECEIVE:
      return {
        ...state,
        functionalLocationList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isPFTLoading: false,
      };
    case actionTypes.FUNCTIONAL_LOCATIONS_VALUE_GET_FAILURE:
      return {
        ...state,
        isPFTLoading: false
      };
    case actionTypes.GET_FUNCTION_LOCATION_PENDING:
      return {
          ...state,
          isFunctionLocationLoading : true
      };
    case actionTypes.GET_FUNCTION_LOCATION_RECEIVED:
      return {
          ...state,
          productFormatData:action.payload,
          isFunctionLocationLoading : false
      };
    case actionTypes.GET_FUNCTION_LOCATION_FAILED:
        return {
          ...state,
          isFunctionLocationLoading : false
      };
    case actionTypes.GET_FUNCTION_LOCATION_CHILD_PENDING:
      return {
          ...state,
          isChildLoading : true
      };
    case actionTypes.GET_FUNCTION_LOCATION_CHILD_RECEIVED:
      let sortedData = action.payload.sort((a,b)=>a.productFormatTypeValue>b.productFormatTypeValue?1:-1)
      return {
          ...state,
          childData: sortedData.map((dt) => ({ ...dt, label:dt.productFormatTypeValue, value:dt.pkProductFormatTypeValue})),
          isChildLoading : false
      };
    case actionTypes.GET_FUNCTION_LOCATION_CHILD_FAILED:
        return {
          ...state,
          isChildLoading : false
      };
    case actionTypes.UPDATE_FL_PENDING:
      return {
        ...state,
        isUpdated : true
      };
    case actionTypes.UPDATE_FL_RECEIVED:
      return {
          ...state,
          updateFLData: action.payload,
          isUpdated : false
      };
    case actionTypes.UPDATE_FL_FAILED:
      return {
        ...state,
        isUpdated : false
      };
      case 'CLEAR_FL_DATA':
        return {
          ...state,
          childData : [],
          productFormatData: []
      };
    default:
      return state;
  }
};
// export const functionProductFormatReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case actionTypes.PRODUCT_FORMAT_FUNCTION_REQUEST:
//       return {
//         ...state,
//         isFPFLoading: true,
//       };
//     case actionTypes.PRODUCT_FORMAT_FUNCTION_RECEIVED:
//       return {
//         ...state,
//         functionProductFormatTypeList: action.payload.map((dt, index) => ({ ...dt, id: index })),
//         isFPFLoading: false,
//       };
//     case actionTypes.PRODUCT_FORMAT_FUNCTION_FAILED:
//       return {
//         ...state,
//         isFPFLoading: false
//       };
//     default:
//       return state;
//   }
// };