import { API } from "api";

export const getRegionsListAPI = () => {
    return API.get("api/region/getall?page=0&size=0")
        .then((res) => res);
};

export const getAllActiveRegionListAPI = () => {
    return API.get("api/region/getallactive?page=0&size=0")
        .then((res) => res);
};

export const createRegionAPI = (payload) => {
    return API.post("/api/region/add", payload)
        .then((res) => res);
}

export const updateRegionAPI = (id, payload) => {
    return API.put(`/api/region/update/${id}`, payload)
        .then((res) => res);
}

export const deleteRegionAPI = (id) => {
    return API.delete(`/api/region/delete/${id}`)
        .then((res) => res);
}
