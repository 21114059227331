
// import { actionTypes } from "./actions";
import { getAllClusterListAPI, createClusterAPI, updateClusterAPI, deleteClusterAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    CLUSTER_REQUEST: "CLUSTER_REQUEST",
    CLUSTER_RECEIVE: "CLUSTER_RECEIVE",
    CLUSTER_FAILED: "CLUSTER_FAILED"
  };

const requestClusters= () => ({
    type: actionTypes.CLUSTER_REQUEST,
});
const receiveClusters = (payload) => ({
    type: actionTypes.CLUSTER_RECEIVE,
    payload,
});
const failureCluster = (payload) => ({
    type: actionTypes.CLUSTER_FAILED,
    payload,
});
export const getAllClusterAction = () => {
    return async (dispatch) => {
        dispatch(requestClusters());
        try {
            const response = await getAllClusterListAPI();                      
            if (response?.status === 200) {
                dispatch(receiveClusters(response?.data?.data));                
            }
        } catch (error) {            
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const createClusterAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestClusters());
        try {
            const response = await createClusterAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Cluster added successfully"));
            }
            dispatch(getAllClusterAction());
        } catch (error) {
            dispatch(failureCluster(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateClusterAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestClusters());
        try {
            const response = await updateClusterAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Cluster updated successfully"));
            }
            dispatch(getAllClusterAction());
        } catch (error) {
            dispatch(failureCluster(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deleteClusterAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestClusters());
        try {
            const response = await deleteClusterAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Cluster deleted successfully"));
            }
            dispatch(getAllClusterAction());
        } catch (error) {
            console.log('==>', error);
            dispatch(failureCluster(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}