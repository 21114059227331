import { actionTypes } from "./actions";

export const initialState = {
  flByIdList: {}
};

const flByIDReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FLID_GET_REQUEST:
      return {
        ...state,
        isflByIDLoading: true,
      };
      case 'CLEAR_FLID':
        return {
          ...state,
          flByIdList: {},
        };
    case actionTypes.FLID_GET_RECEIVE:
      return {
        ...state,
        flByIdList: action.payload,
        isflByIDLoading: false,
      };
    case actionTypes.FLID_GET_FAIL:
      return {
        ...state,
        isflByIDLoading: false,
        isflByIDFail: false,
      };
    default:
      return state;
  }
};

export default flByIDReducer;