import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, Typography, TextField, Box, Button, Alert, Stack, AlertTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, IconButton } from '@mui/material';
import StyledDataGrid from 'components/DataGrid/index';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import AddEditPopperWithFormik from 'components/AddEditPopperWithFormik';
import { uploadDocAction, downloadFileAction, updateDocumentAction, getAllDocIdAction, deleteDocAction } from './state/actions';
import DeleteModal from 'components/DeleteModal';
import { LinearProgress } from '@mui/material/index';
import { downloadContent } from './SearchConstants';

const inputFormControls = {
    header: "Create a new document",
    form: [
        {
            id: "documentName",
            label: "Name",
            placeholder: "Name",
            type: "text",
            validationType: "string",
            value: "",
            validations: [
                {
                    type: "required",
                    params: ["Name is required"]
                }
            ]
        },
    ]
};
export default function EquipmentDocument({ samid }) {
    const dispatch = useDispatch();
    const [fileName, setFileName] = useState("");
    const [submit, setSubmit] = useState(false)
    const [popperState, setPopperState] = useState({ open: false, placement: null });
    const { docList, docUrl, isDocLoaded, isDocLodaing = false } = useSelector((state) => state.documents);
    const [openDeleteModal, toggleDeleteModal] = useState({});

    useEffect(() => {
        dispatch(getAllDocIdAction(samid));
    }, []);

    useEffect(() => {
        return () => {
            dispatch({type: 'DOWNLOAD_FILE_FAILED'});
        }
    }, []);

    useEffect(() => {
        if (isDocLoaded) {
            let a = document.createElement('a');
            a.href = docUrl;
            document.body.appendChild(a);
            a.target='_blank';
            //a.download = true;
            //a.setAttribute('download','')
            a.click();           
            a.remove();
        }
    }, [isDocLoaded]);

    const onEditClick = (e, data) => {
        setPopperState({
            open: true,
            isEdit: true,
            inputForm: { ...inputFormControls, header: "Update document name" },
            placement: e.currentTarget,
            state: (({ pkDocument, documentName }) => ({ pkDocument, documentName }))(data)
        });
    };

    const onDeleteClick = (e, data) => {
        toggleDeleteModal({ open: true, data });
    }
    const deleteConfirm = (data) => {
        let inputData = {
            eqId: samid,
            docId: data?.pkDocument
        }
        dispatch(deleteDocAction(inputData));
        toggleDeleteModal({});
    }
    const onSubmit = (data) => {
        if (popperState.isEdit) {
            let tmpObj = {
                docId: popperState?.state?.pkDocument,
                docName: data.documentName
            }
            dispatch(updateDocumentAction(tmpObj, samid));
        }
        setPopperState({ ...popperState, open: false })
    }
    const onClosePopper = () => {
        setPopperState({ open: false, placement: null })
    }
    const onInputChange = (event) => {
        setFileName(event.target.value);
    }

    const handleClick = (dt) => {
        if(dt.document){
            downloadContent(dt.document, dt.documentName, dt.fileExtension)
        }else{
            let inputObj={eqId: samid,docId: dt.pkDocument}
            dispatch(downloadFileAction(inputObj));
        }
    }

    const columns = [
        {
            field: 'documentName',
            headerName: 'Name',
            width: 600,
            headerClassName: "dgHeader",
            renderCell: ({ row }) => (
                <Stack direction="row" spacing={1}>
                    <Button id='download' variant="text" onClick={() => handleClick(row)}
                    >{row.documentName}</Button>
                </Stack>
            )
        },
        {
            field: 'command',
            headerName: 'Command',
            width: 150,
            disableReorder: true,
            hideSortIcons: true,
            sortable: false,
            filterable: false,
            headerClassName: "dgHeader",
            renderCell: ({ row }) => (
                <Stack direction="row" spacing={1}>
                    <IconButton color="primary" aria-label="Edit" onClick={(e) => onEditClick(e, row)}>
                        <EditOutlinedIcon />
                    </IconButton>
                    <IconButton color="error" aria-label="Delete" onClick={(e) => onDeleteClick(e, row)}>
                        <ClearOutlinedIcon />
                    </IconButton>
                </Stack>
            )
        },
    ];
    const rows = [...docList]
    const onDrop = useCallback((acceptedFiles) => {
        setSubmit(false)
        if (fileName) {
            acceptedFiles.forEach((file) => {
                const reader = new FileReader()
                reader.readAsBinaryString(file)
                reader.onabort = () => console.log('file reading was aborted')
                reader.onerror = () => console.log('file reading has failed')
                reader.onload = () => {
                    let fd = new FormData
                    fd.append('EqId', samid)
                    fd.append('FileName', fileName)
                    fd.append('file', file)
                    dispatch(uploadDocAction(fd, samid))
                    setFileName('')
                }
            })
        } else {
            setSubmit(true)
        }
    }, [fileName])
    const { fileRejections, acceptedFiles, getRootProps, getInputProps } = useDropzone(
        {
            // accept: {
            //     'image/png': ['.png'],
            //     'image/jpeg': ['.jpeg', '.png'],
            //     'text/plain':['.txt']
            // },
            maxSize: 2000000,
            onDrop
        });

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        height: 150,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };
    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                <ul>
                    {
                        errors.map(e => (
                            <>
                                {e.code === "file-too-large" ? <li key={e.code}>File is larger than 2 MB</li> : <li key={e.code}>{e.message}</li>}
                            </>
                        ))}
                </ul>
            </Alert>
        </Stack>
    ));
    return (
        <>
            {isDocLodaing
                ? <Box sx={{ width: '100%' }} >
                    <LinearProgress />
                </Box> : ""}
            <DeleteModal modalData={openDeleteModal} deleteConfirm={deleteConfirm} toggleModal={() => toggleDeleteModal({})} />
            <AddEditPopperWithFormik onClosePopper={onClosePopper} onSubmit={onSubmit} popperState={popperState} />
            <Box sx={{ p: 3 }}>
                {docList.length > 0 ?
                    <Grid container sx={{ my: 2 }}>
                        <div style={{
                            width: '100%'
                        }}>
                            <StyledDataGrid
                                hideFooterPagination
                                disableColumnFilter
                                autoHeight
                                columns={columns}
                                rows={rows}
                                components={{}}
                                componentsProps={{
                                }}
                            />
                        </div>
                    </Grid>
                    : 'No document found'
                }
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ fontSize: '16px', fontWeight: '600', mb: 1 }}>Add a new document</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            id='fileName'
                            size="small"
                            label='Name'
                            name='fileName'
                            value={fileName}
                            onChange={onInputChange}
                            error={submit && !fileName}
                            helperText={"A name must be provided"}
                        />
                    </Grid>
                </Grid>

                <Box sx={{ mt: 2, height: '50%' }}>
                    <div {...getRootProps({ style: baseStyle })}>
                        <input {...getInputProps()} />
                        <p>First write the name and then select the file</p>
                        <p>Drag 'n' drop some files here, or click to select files</p>
                        <em>(Maximum file size should be 2MB)</em>
                    </div>
                    <aside>
                        <ul>{fileRejectionItems}</ul>
                    </aside>
                </Box>
            </Box>
        </>
    );
}