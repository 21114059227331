import { equipmentAPI } from "api";


export const getAllEquipmentTypeAPI = () => {
    return equipmentAPI.get("/api/equipmenttype/getall?page=1&size=100")
                .then((res) => res);
};
export const createEquipmentTypeAPI = (payload) => {
    return equipmentAPI.post("/api/equipmenttype/add", payload)
                .then((res) => res);
}

export const updateEquipmentColumnByIdAPI = (id, payload) => {
    return equipmentAPI.put(`/api/equipmenttype/UpdateCols/${id}`, payload)
                .then((res) => res);
}
export const deleteEquipmentTypeAPI = (id) => {
    return equipmentAPI.delete(`/api/equipmenttype/deleteeqcols/${id}`)
                .then((res) => res);
}
export const getEquipmentColumnByIdAPI = (id) => {
    return equipmentAPI.post(`api/equipmenttype/geteqmtcolbyequipmentid?EqmtID=${id}`)
                .then((res) => res);
};

export const AddEquipmentColumnByIdAPI = (payload) => {
    return equipmentAPI.post("api/equipmenttype/addeqmtcol",payload)
                .then((res) => res);
};


export const getAllColumnMapperActionAPI = () => {
    return equipmentAPI.get("/api/machinedetail/getallcolumnmapper")
                .then((res) => res);
};