import { actionTypes } from "./actions";

export const initialState = {
  eqDispSearchList: [],
  selectedDRId: ''
};

// ===========================|| COMMON REDUCER ||=========================== //

const eqDispSearchListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EQ_SEARCH_REQUEST:
      return {
        ...state,
        iseqDispSearchListLoading: true,
        eqDispSearchList: []
      };
       case 'CLEAR_DISP_DATA':
      return {
        ...state,
        ...initialState
      };
    case actionTypes.EQ_SEARCH_RECEIVE:
      return {
        ...state,
        eqDispSearchList: action?.payload?.data.map((dt, index) => ({ ...dt, id: index })),
        pageInfo: action?.payload?.pageInfo,
        iseqDispSearchListLoading: false,
      };
    case actionTypes.EQ_SEARCH_FAIL:
      return {
        ...state,
        iseqDispSearchListLoading: false,
        iseqDispSearchListFail: false,
        eqDispSearchList: [],
        pageInfo: {}
      };

      case actionTypes.EQ_BYUSER_REQUEST:
        return {
          ...state,
          iseqDispbyUserLoading: true,
        };
      case actionTypes.EQ_BYUSER_RECEIVE:
        return {
          ...state,
          eqDispobyUser: action?.payload?.data.map((dt, index) => ({ ...dt, id: index })),
          pageInfoByUser: action?.payload?.pageInfo,
          iseqDispbyUserLoading: false,
        };
      case actionTypes.EQ_BYUSER_FAIL:
        return {
          ...state,
          iseqDispbyUserLoading: false,
        };
    default:
      return state;
  }
};

export default eqDispSearchListReducer;
