import { actionTypes } from "./actions";

export const initialState = {
    smartFilterList: []
};

// ===========================|| COMMON REDUCER ||=========================== //

const smartFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SFILTER_GET_REQUEST:
            return {
                ...state,
                isSmartFilterLoading: true,
                isFilterSaved: false
            };
        case actionTypes.SFILTER_GET_RECEIVE:
            return {
                ...state,
                smartFilterList: action.payload.map((dt, index) => ({ ...dt, id: index })),
                isSmartFilterLoading: false,
            };
        case 'SFILTER_SET_FLAG':
            return {
                ...state,
                isFilterSaved: true,
                isSmartFilterLoading: false
            };
        case 'SFILTER_CLEAR_FLAG':
            return {
                ...state,
                isFilterSaved: false,
                isSmartFilterLoading: false
            };
        case actionTypes.SFILTER_GET_FAIL:
            return {
                ...state,
                isFilterSaved: false,
                isSmartFilterLoading: false,
                isSmartFilterFail: false,
            };
        default:
            return state;
    }
};

export default smartFilterReducer;