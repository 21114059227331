import { getMainScreenAPI, getAllEquipmentTypeAPI,getALLMachineExportAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    MAIN_SCREEN_GET_REQUEST: " MAIN_SCREEN_GET_REQUEST",
    MAIN_SCREEN_GET_RECEIVE: " MAIN_SCREEN_GET_RECEIVE",
    MAIN_SCREEN_GET_FAIL: " MAIN_SCREEN_GET_FAIL",
    MAIN_SCREEN_STOP_LOADING: " MAIN_SCREEN_STOP_LOADING",
    EXPORT_ALL_LOAD: "EXPORT_ALL_LOAD",
    EXPORT_ALL_RECEIVE: "EXPORT_ALL_RECEIVE",
    EXPORT_ALL_FAIL: "EXPORT_ALL_FAIL",
    EQUIPMENT_TYPE_GET_REQUEST: "EQUIPMENT_TYPE_GET_REQUEST",
    EQUIPMENT_TYPE_GET_RECEIVE: "EQUIPMENT_TYPE_GET_RECEIVE",
    EQUIPMENT_TYPE_GET_FAIL: "EQUIPMENT_TYPE_GET_FAIL"
  };


const requestMainScreen= (payload) => ({
    type: actionTypes.MAIN_SCREEN_GET_REQUEST,
    payload,
});
const receiveMainScreen = (payload) => ({
    type: actionTypes.MAIN_SCREEN_GET_RECEIVE,
    payload,
});
const failureMainScreen = (payload) => ({
    type: actionTypes.MAIN_SCREEN_GET_FAIL,
    payload,
});
const requestExportAll= () => ({
    type: actionTypes.EXPORT_ALL_LOAD,
});
const receiveExportAll = (payload) => ({
    type: actionTypes.EXPORT_ALL_RECEIVE,
    payload,
});
const failureExportAll = (payload) => ({
    type: actionTypes.EXPORT_ALL_FAIL,
    payload,
});

const requestEquipmentTypeValue= () => ({
    type: actionTypes.EQUIPMENT_TYPE_GET_REQUEST,
});
const receiveEquipmentTypeValue = (payload) => ({
    type: actionTypes.EQUIPMENT_TYPE_GET_RECEIVE,
    payload,
});
const failureEquipmentTypeValue = (payload) => ({
    type: actionTypes.EQUIPMENT_TYPE_GET_FAIL,
    payload,
});
export const getMainScreenAction = (a = 1,b = 500,payload ) => {
    return async (dispatch) => {
        dispatch(requestMainScreen(payload));
        try {
            const response = await getMainScreenAPI(a,b,payload );
            if (response?.status === 200) {
                dispatch(receiveMainScreen( response?.data?.data));
            }
        } catch (error) {
            dispatch(failureMainScreen(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const getALLMachineExportAction = (id) => {   
    return async (dispatch) => {
        dispatch(requestExportAll());
        try {
            const response = await getALLMachineExportAPI(id);
            if (response?.status === 200) {
                let a = document.createElement('a');
                a.href = response?.data?.data;
                document.body.appendChild(a);
                a.target='_blank';
                a.download = true;
                a.setAttribute('download','')
                a.click();           
                a.remove();
               dispatch(receiveExportAll(response?.data));
            }
        } catch (error) {
            dispatch(failureExportAll(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getAllEquipmentTypeAction = () => {
    return async (dispatch) => {
        dispatch(requestEquipmentTypeValue());
        try {
            const response = await getAllEquipmentTypeAPI();
            if (response?.status === 200) {
                dispatch(receiveEquipmentTypeValue(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureEquipmentTypeValue(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};