import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { createStore, compose, applyMiddleware } from "redux";
import { Provider as ReduxProvider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import rootReducer from './store/rootReducer';
import reportWebVitals from './reportWebVitals';
import thunk from 'redux-thunk';

const composeEnhancers =
  (process.env.NODE_ENV !== "production" &&
    typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const middlewares = [applyMiddleware(thunk)];
const store = createStore(rootReducer, composeEnhancers(...middlewares));

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <ReduxProvider store={store}>
      <HashRouter basename="/">
        <App />
      </HashRouter>
    </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
