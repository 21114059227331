import { deleteCompanyAPI, createCompanyAPI, updateCompanyAPI, getCompanyListAPI, getAllActiveCompanyListAPI, getCompanyPostAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    COMPANY_PENDING: "COMPANY_PENDING",
    COMPANY_RECEIVED: "COMPANY_RECEIVED",
    COMPANY_FAILED: "COMPANY_FAILED",
    COMPANY_GET_REQUEST: "COMPANY_GET_REQUEST",
    COMPANY_GET_RECEIVE: "COMPANY_GET_RECEIVE",
    COMPANY_GET_FAIL: "COMPANY_GET_FAIL"
  };

const requestCompany= () => ({
    type: actionTypes.COMPANY_PENDING,
});
const receiveCompany = (payload) => ({
    type: actionTypes.COMPANY_RECEIVED,
    payload,
});
const failureCompany = (payload) => ({
    type: actionTypes.COMPANY_FAILED,
    payload,
});

export const getAllActiveCompanyAction = () => {
    return async (dispatch) => {
        dispatch(requestCompany());
        try {
            const response = await getCompanyListAPI();
            if (response?.status === 200) {
                dispatch(receiveCompany(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureCompany(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const getAllActiveCompanyListsAction = () => {
    return async (dispatch) => {
        dispatch(requestCompany());
        try {
            const response = await getAllActiveCompanyListAPI();
            if (response?.status === 200) {
                dispatch(receiveCompany(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureCompany(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}


export const getCompanyPostListsAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestCompany());
        try {
            const response = await getCompanyPostAPI(payload);
            if (response?.status === 200) {
                dispatch(receiveCompany(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureCompany(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}



export const createCompanyAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestCompany());
        try {
            const response = await createCompanyAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Company added successfully"));
            }
            dispatch(getAllActiveCompanyAction());
        } catch (error) {
            dispatch(failureCompany(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateCompanyAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestCompany());
        try {
            const response = await updateCompanyAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Company updated successfully"));
            }
            dispatch(getAllActiveCompanyAction());
        } catch (error) {
            dispatch(failureCompany(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {                
                dispatch(actions.errorAlert(error.errorMessage));
            }
        }
    };
}


export const deleteCompanyAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestCompany());
        try {
            const response = await deleteCompanyAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Company deleted successfully"));
            }
            dispatch(getAllActiveCompanyAction());
        } catch (error) {
            dispatch(failureCompany(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
