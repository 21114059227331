import { actions } from 'store/Reducer/actions';
import { getFunctionalLocationListAPI, getCompanyListAPI, updateFunctionalLocationAPI, 
    createFunctionalLocationsAPI, deleteFunctionalLocationAPI, updateAPI,
    getAllByFunctionallocationAPI,getAllProductFormatTypesAPI, deleteFLProductFormatAPI } from 'containers/Management/FunctionalLocations/state/endpoints';

export const actionTypes = {
    FUNCTIONAL_LOCATIONS_VALUE_GET_REQUEST: "FUNCTIONAL_LOCATIONS_VALUE_GET_REQUEST",
    FUNCTIONAL_LOCATIONS_VALUE_GET_RECEIVE: "FUNCTIONAL_LOCATIONS_VALUE_GET_RECEIVE",
    FUNCTIONAL_LOCATIONS_VALUE_GET_FAILURE: "FUNCTIONAL_LOCATIONS_VALUE_GET_FAILURE",
    COMPANY_VALUE_GET_REQUEST: "COMPANY_VALUE_GET_REQUEST",
    COMPANY_VALUE_GET_RECEIVE: "COMPANY_VALUE_GET_RECEIVE",
    COMPANY_VALUE_GET_FAILURE: "COMPANY_VALUE_GET_FAILURE",
    FUNCTIONAL_LOCATIONS_VALUE_ADD_REQUEST: "FUNCTIONAL_LOCATIONS_VALUE_ADD_REQUEST",
    FUNCTIONAL_LOCATIONS_VALUE_ADD_FAILURE: "FUNCTIONAL_LOCATIONS_VALUE_ADD_FAILURE",
    GET_FUNCTION_LOCATION_PENDING: "GET_FUNCTION_LOCATION_PENDING",
    GET_FUNCTION_LOCATION_RECEIVED: "GET_FUNCTION_LOCATION_RECEIVED",
    GET_FUNCTION_LOCATION_FAILED: "GET_FUNCTION_LOCATION_FAILED",
    GET_FUNCTION_LOCATION_CHILD_PENDING: "GET_FUNCTION_LOCATION_CHILD_PENDING",
    GET_FUNCTION_LOCATION_CHILD_RECEIVED: "GET_FUNCTION_LOCATION_CHILD_RECEIVED",
    GET_FUNCTION_LOCATION_CHILD_FAILED: "GET_FUNCTION_LOCATION_CHILD_FAILED",
    UPDATE_FL_PENDING: "UPDATE_FL_PENDING",
    UPDATE_FL_RECEIVED: "UPDATE_FL_RECEIVED",
    UPDATE_FL_FAILED: "UPDATE_FL_FAILED",
};

const requestFunctionalLocation = () => ({
    type: actionTypes.FUNCTIONAL_LOCATIONS_VALUE_ADD_REQUEST,
});
const failureFunctionalLocation = (payload) => ({
    type: actionTypes.FUNCTIONAL_LOCATIONS_VALUE_ADD_FAILURE,
    payload,
});
const requestFunLoc = () => ({
    type: actionTypes.FUNCTIONAL_LOCATIONS_VALUE_GET_REQUEST,
});
const receiveFunLoc = (payload) => ({
    type: actionTypes.FUNCTIONAL_LOCATIONS_VALUE_GET_RECEIVE,
    payload,
});
const failureFunLoc = (payload) => ({
    type: actionTypes.FUNCTIONAL_LOCATIONS_VALUE_GET_FAILURE,
    payload,
});
const requestCompanyList = () => ({
    type: actionTypes.COMPANY_VALUE_GET_REQUEST,
});
const receiveCompanyList = (payload) => ({
    type: actionTypes.COMPANY_VALUE_GET_RECEIVE,
    payload,
});
const failureCompanyList = (payload) => ({
    type: actionTypes.COMPANY_VALUE_GET_FAILURE,
    payload,
});

const functionLocationRequestPending= () => ({
    type: actionTypes.GET_FUNCTION_LOCATION_PENDING,
});
const functionLocationRequestReceived = (payload) => ({
    type: actionTypes.GET_FUNCTION_LOCATION_RECEIVED,
    payload,
});
const functionLocationRequestFailed = (payload) => ({
    type: actionTypes.GET_FUNCTION_LOCATION_FAILED,
    payload,
});
const functionLocationChildRequestPending= () => ({
    type: actionTypes.GET_FUNCTION_LOCATION_CHILD_PENDING,
});
const functionLocationChildRequestReceived = (payload) => ({
    type: actionTypes.GET_FUNCTION_LOCATION_CHILD_RECEIVED,
    payload,
});
const functionLocationChildRequestFailed = (payload) => ({
    type: actionTypes.GET_FUNCTION_LOCATION_CHILD_FAILED,
    payload,
});

const updatePending= () => ({
    type: actionTypes.UPDATE_FL_PENDING,
});
const updateReceived = (payload) => ({
    type: actionTypes.UPDATE_FL_RECEIVED,
    payload,
});
const updateFailed = (payload) => ({
    type: actionTypes.UPDATE_FL_FAILED,
    payload,
})

export const getCompanyAction = () => {
    return async (dispatch) => {
        dispatch(requestCompanyList());
        try {
            const response = await getCompanyListAPI();
            if (response?.status === 200) {
                dispatch(receiveCompanyList(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureCompanyList(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const getFunctionalLocationAction = (id, areaListArray) => {
    return async (dispatch) => {
        dispatch(requestFunLoc());
        try {
            const response = await getFunctionalLocationListAPI(id);
            if (response?.status === 200) {
                const customArray = [];
                response?.data?.data?.forEach(element => {
                const matchedAreaIdOjbect = areaListArray?.find(({pkArea})=>pkArea===element.fkArea)
                element.areaName = matchedAreaIdOjbect?.areaName;
                customArray.push(element);
                });
                dispatch(receiveFunLoc(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureFunLoc(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const createFunctionalLocationAction = (payload, areaListArray) => {
    return async (dispatch) => {
        dispatch(requestFunLoc());
        try {
            const response = await createFunctionalLocationsAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Functional Locations added successfully"));
            }
            dispatch(getFunctionalLocationAction(payload.fkCompany, areaListArray));
        } catch (error) {
            dispatch(failureFunctionalLocation(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const updateFunctionalLocationAction = (id,payload, areaListFormats ) => {
    return async (dispatch) => {
        dispatch(requestFunLoc());
        try {
            const response = await updateFunctionalLocationAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Functional Locations updated successfully"));
            }
            dispatch(getFunctionalLocationAction(payload.fkCompany, areaListFormats));
        } catch (error) {
            dispatch(failureFunLoc(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const deleteFunctionalLocationAction = ( id,payload,areaListArray ) => {
    return async (dispatch) => {
        dispatch(requestFunLoc());
        try {
            const response = await deleteFunctionalLocationAPI(id);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Functional Locations deleted successfully"));
            }
            dispatch(getFunctionalLocationAction(payload, areaListArray));
        } catch (error) {
            dispatch(failureFunLoc(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));

            }
        }
    };
}
export const getAllByFunctionallocationAction = (payload) => {   
    return async (dispatch) => {
        dispatch(functionLocationRequestPending());
        try {
            const response = await getAllByFunctionallocationAPI(payload);
            if (response?.status === 200) 
                dispatch(functionLocationRequestReceived(response?.data?.data));
        } catch (error) {
            dispatch(functionLocationRequestFailed(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const getAllProductFormatTypesAction = (payload) => {   
    return async (dispatch) => {
        dispatch(functionLocationChildRequestPending());
        try {
            const response = await getAllProductFormatTypesAPI(payload);
            if (response?.status === 200 || response?.status === 201) 
                dispatch(functionLocationChildRequestReceived(response?.data?.data));
        } catch (error) {
            dispatch(functionLocationChildRequestFailed(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const updateAction = (id,payload) => { 
    return async (dispatch) => {
        dispatch(updatePending());
        try {
            const response = await updateAPI(id,payload);   
            if (response?.status === 201 || response?.status === 200) {
                dispatch(updateReceived(response.data));
                dispatch(actions.successAlert("Product format updated successfully"));
            }
        } catch (error) {   
            console.log("error",error) 
            dispatch(updateFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const deleteFLProductFormatAction = ( id, payload,areaListArray ) => {
    return async (dispatch) => {
        dispatch(requestFunLoc());
        try {
            const response = await deleteFLProductFormatAPI(id);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Product Format deleted successfully"));
            }
            dispatch(getFunctionalLocationAction(payload, areaListArray));
        } catch (error) {
            dispatch(failureFunLoc(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));

            }
        }
    };
}