import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { Divider } from '@mui/material';
import StyledDataGrid from 'components/DataGrid/index';
import { useSelector,useDispatch } from 'react-redux';
import { Stack } from '@mui/material';
import { getAllDocIdAction } from './state/actions';
import { downloadContent } from './SearchConstants';
import { LinearProgress, Button } from '../../../node_modules/@mui/material/index';

export default function EquipmentDocuments({samid}) {
    const dispatch = useDispatch();
    const { docList,docUrl, isDocLoaded, isDocLodaing } = useSelector((state) => state.documents);
    useEffect(() => {
        dispatch(getAllDocIdAction(samid));
    }, []);

    useEffect(() => {
        if (isDocLoaded) {
            let a = document.createElement('a');
            a.href = docUrl;
            document.body.appendChild(a); 
            a.click();
            a.remove(); 
        }
    }, [isDocLoaded]);

    const handleClick =(dt) =>{
        downloadContent(dt.document, dt.documentName,dt.fileExtension)
    }

    const columns = [
        {
            field: 'pkDocument',
            headerName: 'Name',
            width: 300,
            headerClassName: "dgHeader",
        },
        {
            field: 'fullName',
            headerName: `Link`,
            width: 500,
            filterable: false,
            headerClassName: "dgHeader",
            renderCell: ({ row }) => (
                <Stack direction="row" spacing={1}>
                    <Button id='download' variant="text" onClick={() => handleClick(row)}
                    >{row.documentName}</Button>
                </Stack>
            )
        },
    ];
    const rows = [...docList]
    return <>
    {isDocLodaing ? <LinearProgress /> : "" }
        <Box sx={{ p: 3 }}>
            {docList.length >0?
                <Grid container sx={{ my: 2 }}>
                <div style={{
                    width: '100%'
                }}>
                    <StyledDataGrid
                        hideFooterPagination
                        disableColumnFilter
                        autoHeight
                        columns={columns}
                        rows={rows}
                        components={{}}
                        componentsProps={{
                        }}
                    />
                </div>
            </Grid>
            :'No document found'
            }
            <Divider sx={{ my: 2 }} />
        </Box>
    </>

}