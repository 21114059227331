
 const optionsData = {
    data: {
        oem: {
            options: [
                { label: "Hauni", value: "Hauni" },
                { label: "AMG", value: "AMG" },
                { label: "AMF Legg", value: "AMFLegg" },
            ],
            child: {
                model: {
                    options: [
                        { label: "Filtromat-S3", value: "Filtromat-S3", parent: "Hauni" },
                        { label: "MP-0.5-6/4-L/EEE", value: "MP", parent: "Hauni" },
                        { label: "RC4", value: "RC4", parent: "AMG" },
                        { label: "Oven ", value: "Oven", parent: "AMFLegg" },
                    ]
                }
            }
        }
    },
    companyOtions: {
        label: "Company", options: [
            { label: 'Van Henry', value: "as" },
            { label: 'April Tucker', value: "ad" },
            { label: 'Ralph Hubbard', value: "af" }
        ]
    },

    srnoOtions: {
        label: "Serial Number", options: [
            { label: 'Van Henry', value: "as" },
            { label: 'April Tucker', value: "ad" },
            { label: 'Ralph Hubbard', value: "af" }
        ]
    },

    areaOption: {
        label: "Area", options: [
            { label: 'Van Henry', value: "as" },
            { label: 'April Tucker', value: "ad" },
            { label: 'Ralph Hubbard', value: "af" }
        ]
    },

    locationOtions: {
        label: "Functional location", options: [
            { label: 'Van Henry', value: "as" },
            { label: 'April Tucker', value: "ad" },
            { label: 'Ralph Hubbard', value: "af" }
        ]
    },

    processOption: {
        label: "Process \\ Sub-process", options: [
            { label: 'Van Henry', value: "as" },
            { label: 'April Tucker', value: "ad" },
            { label: 'Ralph Hubbard', value: "af" }
        ]
    },

    functionOption: {
        label: "Function", options: [
            { label: 'Van Henry', value: "as" },
            { label: 'April Tucker', value: "ad" },
            { label: 'Ralph Hubbard', value: "af" }
        ]
    },

    formatOtions: {
        label: "Format", options: [
            { label: 'format 1', value: "as" },
            { label: 'format 2', value: "ad" },
        ]
    },
    sapOtions: {
        label: "SAP/P1 Ref.", options: [
            { label: '675', value: "675" },
            { label: '877737', value: "877737" },
        ]
    },

    OEMOtions: {
        label: "OEM", options: [
            { label: 'Van Henry', value: "as" },
            { label: 'April Tucker', value: "ad" },
            { label: 'Ralph Hubbard', value: "af" }
        ]
    },

    modelOption: {
        label: "Model", options: [
            { label: 'Van Henry', value: "as" },
            { label: 'April Tucker', value: "ad" },
            { label: 'Ralph Hubbard', value: "af" }
        ]
    },

    statusOption: {
        label: "Status", options: [
            { label: 'Van Henry', value: "as" },
            { label: 'April Tucker', value: "ad" },
            { label: 'Ralph Hubbard', value: "af" }
        ]
    },

    conditionOtions: {
        label: "Condition", options: [
            { label: 'Van Henry', value: "as" },
            { label: 'April Tucker', value: "ad" },
            { label: 'Ralph Hubbard', value: "af" }
        ]
    },

    archivedOption: {
        label: "Archived", options: [
            { label: 'All', value: "All" },
            { label: 'Yes', value: "True" },
            { label: 'No', value: "False" }
        ]
    },

    availablefortransOption: {
        label: "Available for transfer", options: [
            { label: 'All', value: "All" },
            { label: 'Yes', value: "True" },
            { label: 'No', value: "False" }
        ]
    },

    regionOtions: {
        label: "Region", options: [
            { label: 'Van Henry', value: "as" },
            { label: 'April Tucker', value: "ad" },
            { label: 'Ralph Hubbard', value: "af" }
        ]
    },

    reservedOption: {
        label: "Reserved", options: [
            { label: 'All', value: "All" },
            { label: 'Yes', value: "True" },
            { label: 'No', value: "False" }
        ]
    },

    disposalOption: {
        label: "For disposal", options: [
            { label: 'All', value: "all" },
            { label: 'Not for disposal', value: "False" },
            { label: 'For disposal', value: "True" }
        ]
    },
    
    makerOptions: {
        label: "MRF Type", options: [
            { label: 'Make-Pack CC', value: "M" },
            { label: 'RRP Secondary', value: "R" },
            { label: 'Filter making', value: "F" }
        ]
    },

    makerInSamTeamFocusOptions: {
        label: "Maker SAM Team Focus", options: [
            { label: 'YES', value: "YES" },
            { label: 'NO', value: "NO" }
        ]
    },

    packerInSamTeamFocusOptions: {
        label: "Packer In SAM Team Focus", options: [
            { label: 'YES', value: "YES" },
            { label: 'NO', value: "NO" }
        ]
    }
}

export default optionsData;


export function downloadContent(file, documentName, ext) {
    const a = document.createElement("a");
    a.href = `data:application/octet-stream;base64,${file}`;
    a.download = documentName+"."+ext;
    a.click();
  }

