
import {  getInitiatorAPI, searchContactAPI, saveInitiatorAPI} from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
   
    FORM_INITIATOR_REQUEST: "FORM_INITIATOR_REQUEST",
    FORM_INITIATOR_RECEIVE: "FORM_INITIATOR_RECEIVE",
    FORM_INITIATOR_FAIL: "FORM_INITIATOR_FAIL",
    GET_CONTACTS_PENDING: "GET_CONTACTS_PENDING",
    GET_CONTACTS_RECEIVED: "GET_CONTACTS_RECEIVED",
    GET_CONTACTS_FAILED: "GET_CONTACTS_FAILED",
    SAVE_INITIATOR_PENDING: "SAVE_INITIATOR_PENDING",
    SAVE_INITIATOR_RECEIVED: "SAVE_INITIATOR_RECEIVED",
    SAVE_INITIATOR_FAILED: "SAVE_INITIATOR_FAILED"
    
};


const requestFormInitiator = () => ({
    type: actionTypes.FORM_INITIATOR_REQUEST,
});
const receiveFormInitiator= (payload) => ({
    type: actionTypes.FORM_INITIATOR_RECEIVE,
    payload,
});
const failFormInitiator = (payload) => ({
    type: actionTypes.FORM_INITIATOR_FAIL,
    payload,
});

const requestContactsPending= () => ({
    type: actionTypes.GET_CONTACTS_PENDING,
});
const requestContactsReceived = (payload) => ({
    type: actionTypes.GET_CONTACTS_RECEIVED,
    payload,
});
const requestContactsFailed = (payload) => ({
    type: actionTypes.GET_CONTACTS_FAILED,
    payload,
})


const saveInitiatorPending= () => ({
    type: actionTypes.SAVE_INITIATOR_PENDING,
});
const saveInitiatorReceived = (payload) => ({
    type: actionTypes.SAVE_INITIATOR_RECEIVED,
    payload,
});
const saveInitiatorFailed = (payload) => ({
    type: actionTypes.SAVE_INITIATOR_FAILED,
    payload,
})

export const getInitiatorAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestFormInitiator());
        try {
            const response = await getInitiatorAPI(payload);
            if (response?.status === 200) {
                dispatch(receiveFormInitiator(response?.data?.data));
            }
        } catch (error) {
            dispatch(failFormInitiator(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage?error?.response?.data?.errorMessage:error?.response?.data?.title));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const searchContactAction = (payload) => { 
    return async (dispatch) => {
        dispatch(requestContactsPending());
        try {
            const response = await searchContactAPI(payload);                      
            if (response?.status === 200) {
                dispatch(requestContactsReceived(response?.data?.data));
            }
        } catch (error) {    
            dispatch(requestContactsFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const saveInitiatorAction = (payload) => { 
    return async (dispatch) => {
        dispatch(saveInitiatorPending());
        try {
            const response = await saveInitiatorAPI(payload);                      
            if (response?.status === 200) {
                dispatch(saveInitiatorReceived(response?.data?.data));
                dispatch(actions.successAlert("Initiator updated successfully"));
            }
        } catch (error) {    
            dispatch(saveInitiatorFailed(error?.response?.data));        
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}