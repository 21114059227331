import { actionTypes } from "./actions";

export const initialState = {
  areaList: {},
  processList: {},
  subProcessList: {},
  functionProductFormatList: [],
  loading: false,
  functionList: {},
  productFormatTypeList: {},
  functionProductFormatTypeList: {}
};

// ===========================|| COMMON REDUCER ||=========================== //

export const areaListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AREA_PENDING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.AREA_RECEIVED:
      return {
        ...state,
        areaListFormats: action.payload.map((dt, index) => ({ ...dt, id: index })),
        loading: false,
      };
    case actionTypes.AREA_FAILED:
      return {
        ...state,
        isAreaListFail: false,
        loading: false
      };
    default:
      return state;
  }
};

export const ProcessReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROCESS_PENDING:
      return {
        ...state,
        processloading: true,
      };
    case actionTypes.PROCESS_RECEIVED:
      return {
        ...state,
        processListFormats: action.payload.map((dt, index) => ({ ...dt, id: index })),
        processloading: false,
      };
    case actionTypes.PROCESS_FAILED:
      return {
        ...state,
        isProcessListFail: false,
        processloading: false
      };
    default:
      return state;
  }
};
export const SubProcessReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUBPROCESS_PENDING:
      return {
        ...state,
        subProcessloading: true,
      };
    case actionTypes.SUBPROCESS_RECEIVED:
      return {
        ...state,
        subProcessListFormats: action.payload.map((dt, index) => ({ ...dt, id: index })),
        subProcessloading: false,
      };
    case actionTypes.SUBPROCESS_FAILED:
      return {
        ...state,
        isSubProcessListFail: false,
        subProcessloading: false
      };
    default:
      return state;
  }
};


export const fpfReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FPF_PENDING:
      return {
        ...state,
        isFPFLoading: true,
      };
    case actionTypes.FPF_RECEIVED:
      return {
        ...state,
        functionProductFormatList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isFPFLoading: false,
      };
    case actionTypes.FPF_FAILED:
      return {
        ...state,
        isFPFLoading: false
      };
    default:
      return state;
  }
};
export const functionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FUNCTION_PENDING:
      return {
        ...state,
        isFPFLoading: true,
      };
    case actionTypes.FUNCTION_RECEIVED:
      return {
        ...state,
        functionFormatList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isFPFLoading: false,
      };
    case actionTypes.FUNCTION_FAILED:
      return {
        ...state,
        isFPFLoading: false
      };
    default:
      return state;
  }
};
export const productFormatTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCT_FORMAT_TYPE_PENDING:
      return {
        ...state,
        isPFTLoading: true,
      };
    case actionTypes.PRODUCT_FORMAT_TYPE_RECEIVED:
      return {
        ...state,
        productFormatTypeList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isPFTLoading: false,
      };
    case actionTypes.PRODUCT_FORMAT_TYPE_FAILED:
      return {
        ...state,
        isPFTLoading: false
      };
    default:
      return state;
  }
};
export const functionProductFormatReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCT_FORMAT_FUNCTION_REQUEST:
      return {
        ...state,
        isFPFLoading: true,
      };
    case actionTypes.PRODUCT_FORMAT_FUNCTION_RECEIVED:
      return {
        ...state,
        functionProductFormatTypeList: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isFPFLoading: false,
      };
    case actionTypes.PRODUCT_FORMAT_FUNCTION_FAILED:
      return {
        ...state,
        isFPFLoading: false
      };
    default:
      return state;
  }
};