
import { Excel } from "antd-table-saveas-excel";

const manualExport = (list, fileName = "Workbook.xlsx", column, newData) => {

    const excel = new Excel();
    excel
        .addSheet("Workbook")
        .addColumns(column)
        .addDataSource(list, {
            str2Percent: true
        })
        .saveAs(fileName);
}

export default manualExport;
