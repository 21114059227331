import { actionTypes } from "./actions";

export const initialState = {
  bulkUploadSuccess: false,
  isDocLoading: false,
};

// ===========================|| COMMON REDUCER ||=========================== //

const bulkUploadReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.BULK_UPLOAD_PENDING:
      return {
        ...state,
        isDocLoading: true,
      };
      case actionTypes.BULK_UPLOAD_RECEIVED:
      return {
        ...state,
        bulkUploadSuccess:action.payload,
        isDocLoading: false,
      };
      case actionTypes.BULK_UPLOAD_FAILED:
      return {
        ...state,
        isDocLoading: false,
      };
    default:
      return state;
  }
};

export default bulkUploadReducer;
