import { actionTypes } from "./actions";

export const initialState = {
  isRegionLoading: false,
  regionData: {},
  formUploadData:{},
  PkForm: '',
  decisionData: {},
  showNotification: false,
  documentList:[],
  isDocLoading: false,
  docUrl: '',
  isDocDownloaded: false,
  isDeleted: false,
  contactList:[],
  isContactLoading : false,
  detailData:{},
  isDetailLoading: false,
  initialData:{},
  isFormInitialLoading:false,
  isSubmitSuccessfull: false,
  resubmitData:{}
};
const mrfReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REGION_PENDING:
        return {
            ...state,
            isRegionLoading : true
        };
    case actionTypes.GET_REGION_RECEIVED:
      return {
          ...state,
          regionData:action.payload,
          isRegionLoading : false
      };
    case actionTypes.GET_REGION_FAILED:
        return {
          ...state,
          isRegionLoading : false
      };
    case actionTypes.MR_UPLOAD_PENDING:
      return {
        isDocLoading : true,
          ...state,
      };
    case actionTypes.MR_UPLOAD_RECEIVED:
      let docData = {...action.payload}
      docData.id=action.payload.pkFormDocument
      return {
          ...state,
          isDocLoading : false,
          formUploadData:docData
      };
    case actionTypes.MR_UPLOAD_FAILED:
        return {
          ...state,
          isDocLoading : false,
          formUploadData:action.payload,
      };
    case actionTypes.MR_SUBMIT_PENDING:
      return {
          ...state,
          showNotification : false
      };
    case actionTypes.MR_SUBMIT_RECEIVED:
      let data = action.payload //"Pkform: 6121"

      let firstIndx = data.indexOf('[')
      let lastIndx = data.lastIndexOf(']')
      let tempData = data.substring(firstIndx+1,lastIndx)

      firstIndx = tempData.indexOf('-')
      lastIndx = tempData.lastIndexOf('-')
      let FormId = tempData.substring(firstIndx+1,lastIndx)

      return {
          ...state,
          PkForm:FormId,
          showNotification : true
      };
    case actionTypes.MR_SUBMIT_FAILED:
      return {
        ...state,
        showNotification : false
      };
    case actionTypes.MR_DECISION_PENDING:
      return {
          ...state,
          showNotification : false
      };
    case actionTypes.MR_DECISION_RECEIVED:
      return {
          ...state,
          decisionData:action.payload,
          showNotification : true
      };
    case actionTypes.MR_DECISION_FAILED:
      return {
        ...state,
        showNotification : false
      };
    case actionTypes.MR_UPDATE_PENDING:
      return {
        ...state,
        showNotification : false
      };
    case actionTypes.MR_UPDATE_RECEIVED:
      return {
          ...state,
          updateData:action.payload,
          showNotification : true
      };
    case actionTypes.MR_UPDATE_FAILED:
      return {
        ...state,
        showNotification : false
      }
    case actionTypes.MR_TRANSFER_RECEIVED:
      return {
          ...state,
          updateData:action.payload,
      };
    case actionTypes.MR_RESUBMIT_PENDING:
      return {
        ...state,
        showNotification : false
      };
    case actionTypes.MR_RESUBMIT_RECEIVED:
      return {
          ...state,
          resubmitData:action.payload,
          showNotification : true
      };
    case actionTypes.MR_RESUBMIT_FAILED:
      return {
        ...state,
        showNotification : false
      };
    case actionTypes.MR_COMPLETE_PENDING:
      return {
        ...state,
        showNotification : false
      };
    case actionTypes.MR_COMPLETE_RECEIVED:
      return {
          ...state,
          completeData:action.payload,
          showNotification : true
      };
    case actionTypes.MR_COMPLETE_FAILED:
      return {
        ...state,
        showNotification : false
      };
    case actionTypes.MR_CANCEL_PENDING:
      return {
        ...state,
        showNotification : false
      };
    case actionTypes.MR_CANCEL_RECEIVED:
      return {
          ...state,
          cancelData:action.payload,
          showNotification : true
      };
    case actionTypes.MR_CANCEL_FAILED:
      return {
        ...state,
        showNotification : false
      };
    case actionTypes.DOWNLOAD_DOC_PENDING:
      return {
        ...state,
        isDocDownloaded: false,
      };
    case actionTypes.DOWNLOAD_DOC_RECEIVED:
      return {
        ...state,
        docUrl:action.payload,
        isDocDownloaded: true,
      };
    case actionTypes.DOWNLOAD_DOC_FAILED:
      return {
        ...state,
        isDocDownloaded: false,
      };
    case actionTypes.DELETE_DOC_PENDING:
      return {
        ...state,
        isDeleted: false,
      };
    case actionTypes.DELETE_DOC_RECEIVED:
      return {
        ...state,
        isDeleted: true,
      };
    case actionTypes.DELETE_DOC_FAILED:
      return {
        ...state,
        isDeleted: false,
      };
    case actionTypes.GET_CONTACTS_PENDING:
      return {
          ...state,
          isContactLoading : true
      };
    case actionTypes.GET_CONTACTS_RECEIVED:
      return {
          ...state,
          contactList:action.payload.map((dt, index)=>({...dt, id:index,label:dt.displayName,value:dt.loginName})),
          isContactLoading : false
      };
    case actionTypes.GET_CONTACTS_FAILED:
        return {
          ...state,
          isContactLoading : false
      };
    case actionTypes.GET_DETAIL_PENDING:
      return {
          ...state,
          detailData:{},
          isDetailLoading : true
      };
    case actionTypes.GET_DETAIL_RECEIVED:
      return {
          ...state,
          detailData:action.payload,
          isDetailLoading : false,
      };
    case actionTypes.GET_DETAIL_FAILED:
      return {
        ...state,
        isDetailLoading : false
      };
    case actionTypes.GET_MR_INITIAL_PENDING:
      return {
          ...state,
          isFormInitialLoading : true
      };
    case actionTypes.GET_MR_INITIAL_RECEIVED:
      return {
          ...state,
          initialData:action.payload,
          isFormInitialLoading : false
      };
    case actionTypes.GET_MR_INITIAL_FAILED:
      return {
        ...state,
        isFormInitialLoading : false
      };
    case 'GET_MR_INITIAL_DATA':
      return {
        ...state,
        showNotification : false,
        formUploadData:{},
        detailData:{},
        PkForm:''
      };
    case  "CLEAR_MR_DATA":
      return {
        ...state,
        isDetailLoading: false,
        formUploadData:{},
        detailData: {},
        PkForm:''
      };
    default:
      return state;
  }
};

export default mrfReducer;