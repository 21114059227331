import React from "react";
import { GridToolbarQuickFilter, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

export default function CustomToolbar(props) {
    return (<>
      {props.hideCSV ?  "" : <GridToolbarExport sx={{pt:2}} printOptions={{ disableToolbarButton: true }} csvOptions={props.csvOptions} />}
      <GridToolbarContainer sx={{ display:'flex', justifyContent: 'flex-end' }}>
            <GridToolbarQuickFilter />
      </GridToolbarContainer>

      </>
    );
}
