
import {getAllEquipmentTypeAPI, updateEquipmentTypeAPI, createEquipmentTypeAPI, deleteEquipmentTypeAPI,getAllEqmtDynamicFormAPI,AddEquipmentDtlsAPI,GetAllEquipmentMainDataDtlsAPI,deleteMachineTypeAPI,GetAllEquipmentRowDtlAPI,UpdateEquipmentRowDtlAPI} from '../State/endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    EQUIPMENT_TYPE_GET_REQUEST: "EQUIPMENT_TYPE_GET_REQUEST",
    EQUIPMENT_TYPE_GET_RECEIVE: "EQUIPMENT_TYPE_GET_RECEIVE",
    EQUIPMENT_TYPE_GET_FAIL: "EQUIPMENT_TYPE_GET_FAIL",
    EQUIPMENT_DYFORM_GET_REQUEST: "EQUIPMENT_DYFORM_GET_REQUEST",
    EQUIPMENT_DYFORM_GET_RECEIVE: "EQUIPMENT_DYFORM_GET_RECEIVE",
    EQUIPMENT_DYFORM_GET_FAIL: "EQUIPMENT_DYFORM_GET_FAIL",
    EQUIPMENT_DTL_GET_REQUEST: "EQUIPMENT_DTL_GET_REQUEST",
    EQUIPMENT_DTL_GET_RECEIVE: "EQUIPMENT_DTL_GET_RECEIVE",
    EQUIPMENT_DTL_GET_FAIL: "EQUIPMENT_DTL_GET_FAIL",
    EQUIPMENT_MAINDATA_GET_REQUEST: "EQUIPMENT_MAINDATA_GET_REQUEST",
    EQUIPMENT_MAINDATA_GET_RECEIVE: "EQUIPMENT_MAINDATA_GET_RECEIVE",
    EQUIPMENT_MAINDATA_GET_FAIL: "EQUIPMENT_MAINDATA_GET_FAIL",
    EQUIPMENT_ROWDATA_GET_REQUEST: "EQUIPMENT_ROWDATA_GET_REQUEST",
    EQUIPMENT_ROWDATA_GET_RECEIVE: "EQUIPMENT_ROWDATA_GET_RECEIVE",
    EQUIPMENT_ROWDATA_GET_FAIL: "EQUIPMENT_ROWDATA_GET_FAIL"
  };

const requestEquipmentTypeValue= () => ({
    type: actionTypes.EQUIPMENT_TYPE_GET_REQUEST,
});
const receiveEquipmentTypeValue = (payload) => ({
    type: actionTypes.EQUIPMENT_TYPE_GET_RECEIVE,
    payload,
});
const failureEquipmentTypeValue = (payload) => ({
    type: actionTypes.EQUIPMENT_TYPE_GET_FAIL,
    payload,
});
const requestEquipmentMainDataValue= () => ({
    type: actionTypes.EQUIPMENT_MAINDATA_GET_REQUEST,
});
const receiveEquipmentMainDataValue = (payload) => ({
    type: actionTypes.EQUIPMENT_MAINDATA_GET_RECEIVE,
    payload,
});
const failureEquipmentMainDataValue = (payload) => ({
    type: actionTypes.EQUIPMENT_MAINDATA_GET_FAIL,
    payload,
});
const requestEquipmentRowDataValue= () => ({
    type: actionTypes.EQUIPMENT_ROWDATA_GET_REQUEST,
});
const receiveEquipmentRowDataValue = (payload) => ({
    type: actionTypes.EQUIPMENT_ROWDATA_GET_RECEIVE,
    payload,
});
const failureEquipmentRowDataValue = (payload) => ({
    type: actionTypes.EQUIPMENT_ROWDATA_GET_FAIL,
    payload,
});
const requestEqDynFormValue= () => ({
    type: actionTypes.EQUIPMENT_DYFORM_GET_REQUEST,
});
const receiveEqDynFormValue = (payload) => ({
    type: actionTypes.EQUIPMENT_DYFORM_GET_RECEIVE,
    payload,
});
const failureEqDynFormValue = (payload) => ({
    type: actionTypes.EQUIPMENT_DYFORM_GET_FAIL,
    payload,
});
const requestEqDtlData= () => ({
    type: actionTypes.EQUIPMENT_DTL_GET_REQUEST,
});
const receiveEqDtlData = (payload) => ({
    type: actionTypes.EQUIPMENT_DTL_GET_RECEIVE,
    payload,
});
const failureEqDtlData = (payload) => ({
    type: actionTypes.EQUIPMENT_DTL_GET_FAIL,
    payload,
});
export const getAllEquipmentDyFormAction = (id) => {
    return async (dispatch) => {
        dispatch(requestEqDynFormValue());
        try {
            const response = await getAllEqmtDynamicFormAPI(id);
            if (response?.status === 200) {
                console.log("my Data Response",response)
                dispatch(receiveEqDynFormValue(response?.data?.data));
                console.log("After Response",response)
            }
        } catch (error) {
            dispatch(failureEqDynFormValue(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getAllEquipmentMainDataAction = (EqmtID) => {
    return async (dispatch) => {
        dispatch(requestEquipmentMainDataValue(EqmtID));
        try {
            const response = await GetAllEquipmentMainDataDtlsAPI(EqmtID);
            if (response?.status === 200) {
                console.log("equ action....data",response)
                dispatch(receiveEquipmentMainDataValue(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureEquipmentMainDataValue(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getAllEquipmentMainRowDataAction = (RowID) => {
    return async (dispatch) => {
        dispatch(requestEquipmentRowDataValue(RowID));
        try {
            const response = await GetAllEquipmentRowDtlAPI(RowID);
            if (response?.status === 200) {
                console.log("getAllEquipmentMainRowDataAction....data",response)
                dispatch(receiveEquipmentRowDataValue(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureEquipmentRowDataValue(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const UpdateEquipmentMainRowDataAction = (RowID,payload) => {
    return async (dispatch) => {
        dispatch(requestEquipmentRowDataValue(RowID));
        try {
            const response = await UpdateEquipmentRowDtlAPI(RowID,payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Data updated successfully"));
            }
        } catch (error) {
            dispatch(failureEquipmentRowDataValue(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const getAllEquipmentTypeAction = () => {
    return async (dispatch) => {
        dispatch(requestEquipmentTypeValue());
        try {
            const response = await getAllEquipmentTypeAPI();
            if (response?.status === 200) {
                console.log("equ action....data",response)
                dispatch(receiveEquipmentTypeValue(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureEquipmentTypeValue(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const updateEquipmentTypeAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestEquipmentTypeValue());
        try {
            const response = await updateEquipmentTypeAPI(payload.pkDSEquipmentType, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Equipment Type updated successfully"));
            }
            dispatch(getAllEquipmentTypeAction());
        } catch (error) {
            dispatch(failureEquipmentTypeValue(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const createEquipmentTypeAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestEquipmentTypeValue());
        try {
            const response = await createEquipmentTypeAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Equipment Type added successfully"));
            }
            dispatch(getAllEquipmentTypeAction());
        } catch (error) {
            dispatch(failureEquipmentTypeValue(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const deleteEquipmentTypeAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestEquipmentTypeValue());
        try {
            const response = await deleteEquipmentTypeAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Equipment Type deleted successfully"));
            }
            dispatch(getAllEquipmentTypeAction());
        } catch (error) {
            dispatch(failureEqDtlData(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const AddEquipmentDetailsAction = (payload,EqmtID) => {
    return async (dispatch) => {
        dispatch(requestEqDtlData(payload));
        try {
            const response = await AddEquipmentDtlsAPI(payload,EqmtID);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Equipment Details added successfully"));
            }
            dispatch(getAllEquipmentMainDataAction(EqmtID));
        } catch (error) {
            dispatch(failureEquipmentTypeValue(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const DeleteEquipmentMainDataAction = (pkMachTypeDtl,EqmtID) => {
    return async (dispatch) => {
        dispatch(requestEquipmentMainDataValue(EqmtID));
        try {
            const response = await deleteMachineTypeAPI(pkMachTypeDtl);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Equipment details deleted successfully"));
            }
            dispatch(getAllEquipmentMainDataAction(EqmtID));
        } catch (error) {
            dispatch(failureEquipmentMainDataValue(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
