import { actionTypes } from "./actions";

export const initialState = {
    allRequestHistoryList:[]
};


const allRequestHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    
            case actionTypes.REQUEST_ALL_HISTORY:
              return {
                ...state,
                allRequestHistoryListLoading: true,
              };
            case actionTypes.RECEIVE_ALL_HISTORY:    
                return {
                  ...state,
                 allRequestHistoryList: action.payload.map((dt, index) => ({ ...dt, id: index })),
                  allRequestHistoryListLoading: false,
                };
              case actionTypes.FAIL_ALL_HISTORY:
                 return {
              ...state,
              allRequestHistoryListLoading: false,
            };
    default:
      return state;
  }
};

export default allRequestHistoryReducer;
