import {getCompanyValidationValueListAPI, validateCompanyAPI} from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    VALIDATE_COMPANY_GET_REQUEST: "VALIDATE_COMPANY_GET_REQUEST",
    VALIDATE_COMPANY_GET_RECEIVE: "VALIDATE_COMPANY_GET_RECEIVE",
    VALIDATE_COMPANY_GET_FAILURE: "VALIDATE_COMPANY_GET_FAILURE",
};

const requestValidateCompany = () => ({
    type: actionTypes.VALIDATE_COMPANY_GET_REQUEST,
});
const receiveValidateCompany = (payload) => ({
    type: actionTypes.VALIDATE_COMPANY_GET_RECEIVE,
    payload,
});
const failureValidateCompany = (payload) => ({
    type: actionTypes.VALIDATE_COMPANY_GET_FAILURE,
    payload,
});

export const getValidateCompanyAction = (id) => {
    return async (dispatch) => {
        dispatch(requestValidateCompany());
        try {
            const response = await getCompanyValidationValueListAPI(id);
            if (response?.status === 200) {
                dispatch(receiveValidateCompany([response?.data?.data]));
            }
        } catch (error) {
            dispatch(failureValidateCompany(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const updateValidateCompanyAction = (id) => {
    return async (dispatch) => {
        dispatch(requestValidateCompany());
        try {
            const response = await validateCompanyAPI(id);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Company validated successfully"));
            }
            dispatch(getValidateCompanyAction(id));
        } catch (error) {
            dispatch(failureValidateCompany(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
