import { API } from "api";

export const getProductFormatTypeAPI = () => { 
    let apiUrl = `api/productformattype/getall?page=0&size=0`
    return API.get(apiUrl).then((res) => res);
}

export const createProductFormatTypeAPI = (payload) => {
    return API.post("/api/productformattype/add", payload)
        .then((res) => res);
}
export const updateProductFormatTypeAPI = (payload,id) => {
    return API.put(`/api/productformattype/update/${id}`, payload)
        .then((res) => res);
}

export const deleteProductFormatTypeAPI = (id) => {
    return API.delete(`/api/productformattype/delete/${id}`)
                .then((res) => res);
}