import { API } from "api";

export const getCompanyValidationValueListAPI = (id) => {
    return API.get(`api/companyvalidation/${id}`)
                .then((res) => res);
};

export const validateCompanyAPI = (id) => {
    return API.put(`api/companyvalidation/validate/${id}`)
                .then((res) => res);
};