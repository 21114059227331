import { equipmentAPI } from "api";

export const getMainScreenAPI = (p,s,payload) => {
    return equipmentAPI.post("api/machinedetail/GetAllbyEqmtID?PageNo="+p+"&PageSize="+s+"&StrserviceRequest="+payload+"&opt=main")
        .then((res) => res);

};
export const getALLMachineExportAPI = (id) => {
    return equipmentAPI.post("/api/machinedetail/exportxml?strEqmtIDs="+JSON.stringify(id)).then((res) => res);
};

export const getAllEquipmentTypeAPI = () => {
    return equipmentAPI.get("/api/equipmenttype/getall?page=1&size=100")
                .then((res) => res);
};