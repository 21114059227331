import { API } from "api";

export const getAllClusterListAPI = () => {
    return API.get("/api/cluster/getall?page=0&size=0")
                .then((res) => res);
};

export const createClusterAPI = (payload) => {
    return API.post("/api/cluster/add", payload)
                .then((res) => res);
}

export const updateClusterAPI = (id, payload) => {
    return API.put(`/api/cluster/update/${id}`, payload)
                .then((res) => res);
}

export const deleteClusterAPI = (id) => {
    return API.delete(`/api/cluster/delete/${id}`)
                .then((res) => res);
}
