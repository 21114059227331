
import { getAllCurrencyAPI, createCurrencyAPI, updateCurrencyAPI, deleteCurrencyAPI } from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    CURRENCY_PENDING: "CURRENCY_PENDING",
    CURRENCY_RECEIVED: "CURRENCY_RECEIVED",
    CURRENCY_FAILED: "CURRENCY_FAILED"
  };

const requestCurrency= () => ({
    type: actionTypes.CURRENCY_PENDING,
});
const receiveCurrencies = (payload) => ({
    type: actionTypes.CURRENCY_RECEIVED,
    payload,
});
const failureCurrency = (payload) => ({
    type: actionTypes.CURRENCY_FAILED,
    payload,
});
export const getAllCurrencyAction = () => {
    return async (dispatch) => {
        dispatch(requestCurrency());
        try {
            const response = await getAllCurrencyAPI();
            if (response?.status === 200) {
                dispatch(receiveCurrencies(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureCurrency(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};

export const createCurrencyAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestCurrency());
        try {
            const response = await createCurrencyAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("Currency added successfully"));
            }
            dispatch(getAllCurrencyAction());
        } catch (error) {
            dispatch(failureCurrency(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateCurrencyAction = (id, payload) => {
    return async (dispatch) => {
        dispatch(requestCurrency());
        try {
            const response = await updateCurrencyAPI(id, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Currency updated successfully"));
            }
            dispatch(getAllCurrencyAction());
        } catch (error) {
            dispatch(failureCurrency(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const deleteCurrencyAction = (payload) => {
    return async (dispatch) => {
        dispatch(requestCurrency());
        try {
            const response = await deleteCurrencyAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("Currency deleted successfully"));
            }
            dispatch(getAllCurrencyAction());
        } catch (error) {
            dispatch(failureCurrency(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
